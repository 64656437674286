import { useReactiveVar } from '@apollo/client';
import { setDrawerAction } from 'state/actions/ui';
import { DrawerStates, UiState } from 'state/types';
import { uiVar } from 'state/vars';

const useDrawer = <T extends DrawerStates>() => {
  const { drawerState } = useReactiveVar<UiState<T>>(uiVar);

  const hideDrawer = () => {
    setDrawerAction(null);
  };

  const showDrawer = (drawer: DrawerStates) => {
    setDrawerAction(drawer);
  };

  return { hideDrawer, showDrawer, drawerState };
};

export default useDrawer;
