import React from 'react';

const Trash: React.FC = () => (
  <>
    <path d="M2 4H3.33333H14" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.33344 4.00004V2.66671C5.33344 2.31309 5.47391 1.97395 5.72396 1.7239C5.97401 1.47385 6.31315 1.33337 6.66677 1.33337H9.33344C9.68706 1.33337 10.0262 1.47385 10.2762 1.7239C10.5263 1.97395 10.6668 2.31309 10.6668 2.66671V4.00004M12.6668 4.00004V13.3334C12.6668 13.687 12.5263 14.0261 12.2762 14.2762C12.0262 14.5262 11.6871 14.6667 11.3334 14.6667H4.66677C4.31315 14.6667 3.97401 14.5262 3.72396 14.2762C3.47391 14.0261 3.33344 13.687 3.33344 13.3334V4.00004H12.6668Z"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export default Trash;
