import React, { useEffect, useState } from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import useUnregisterMobileDevice from 'hooks/useUnregisterMobileDevice/useUnregisterMobileDevice';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useDrawer from 'hooks/useDrawer/useDrawer';
import { disconnectDeviceAction } from 'state/actions/auth';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import { authVar } from 'state/vars';
import useTranslation from 'hooks/useTranslation/useTranslation';
import RemoveOrganizationFromMobile from '../../../assets/images/RemoveOrganizationFromMobile.gif';

const RemoveOrganizationFromMobileGif = styled.div`
  background-image: url(${RemoveOrganizationFromMobile});
  width: 299px;
  height: 199px;
`;

const RemoveOrganizationFromMobileApp: React.FC = () => {
  const { translation } = useTranslation();
  const { user } = useReactiveVar(authVar);
  const { hideModal } = useModal();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { unregisterMobileDeviceById, unregisterMobileDeviceByIdMutationLoading } = useUnregisterMobileDevice({
    handleFetchError
  });
  const { hideDrawer } = useDrawer();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleUnregisterMobileDevice = () => {
    if (user?.email && !unregisterMobileDeviceByIdMutationLoading) {
      unregisterMobileDeviceById(user.id, user.mobileDevices!.find((device) => device.role === 'ACCESS')!.id);
      disconnectDeviceAction();
      hideDrawer();
      hideModal();
    }
  };

  useEffect(() => {
    const buttonAvailableAfter = 5000;
    setTimeout(() => setIsButtonDisabled(false), buttonAvailableAfter);
  }, []);

  return (
    <Modal>
      <ComponentWrapper
        flexDirection="column"
        width="22.75rem"
        gap="1rem"
        margin="1rem 0"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="Alert" color="error" width={40} height={40} viewBox="0 0 24 24" id="delete-bin-icon" />
        <Typography variant="title2" color="error">
          {`${translation.important}!`}
        </Typography>
        <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="body3" color="dTextHigh">
            {translation.do_not_forget_to_remove_the_organization_from_your_atlas_access_app}
          </Typography>
        </ComponentWrapper>
        <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="baseline">
          <Typography variant="body3" color="dTextHigh">
            {`1. ${translation.open_your_atlas_access_app}`}
          </Typography>
          <Typography variant="body3" color="dTextHigh">
            {`2. ${translation.navigate_to_my_organization}`}
          </Typography>
          <Typography variant="body3" color="dTextHigh">
            {`3. ${translation.select_your_organization}`}
          </Typography>
          <Typography variant="body3" color="dTextHigh">
            {`4. ${translation.tap_remove_organization}`}
          </Typography>
          <Typography variant="body3" color="dTextHigh">
            {`5. ${translation.confirm_the_removal}`}
          </Typography>
        </ComponentWrapper>
        <ComponentWrapper>
          <RemoveOrganizationFromMobileGif />
        </ComponentWrapper>
        <ComponentWrapper width="100%">
          <Button
            onClick={handleUnregisterMobileDevice}
            availableAfter={5}
            disabled={isButtonDisabled}
            id="understand-button-modal"
          >
            {translation.i_understand}
          </Button>
        </ComponentWrapper>
      </ComponentWrapper>
    </Modal>
  );
};

export default RemoveOrganizationFromMobileApp;
