import React, { forwardRef } from 'react';
import styled from 'styled-components';

export interface Props {
  id?: string;
}

const TableLoaderWrapper = styled.div`
  height: 3.375rem;
  flex: 1 1 100%;
  background-color: ${({ theme }) => theme.colors.b2};
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
`;

const TableLoader = forwardRef<HTMLDivElement | null, React.PropsWithChildren<Props>>(({ ...props }, ref) => {
  const { id, children } = props;

  return (
    <TableLoaderWrapper ref={ref} id={id}>
      {children}
    </TableLoaderWrapper>
  );
});

export default TableLoader;
