import { GetDoorsForTenantWithLocations_getDoorsForTenant_complete as DoorGroup } from 'state/types';
import { doorsWithLocationsVar } from 'state/vars';

export const setDoorWithLocationsAction = (doorsWithLocations: DoorGroup[]) => {
  doorsWithLocationsVar(doorsWithLocations);
};

export const updateDoorWithLocationsAction = (updatedDoorWithLocation: DoorGroup) => {
  const currentDoorWithLocations = doorsWithLocationsVar();
  const updatedDoorWithLocations = currentDoorWithLocations.map((group) =>
    group.id === updatedDoorWithLocation.id ? updatedDoorWithLocation : group
  );

  doorsWithLocationsVar(updatedDoorWithLocations);
};

export const deleteDoorWithLocationsAction = (deletedDoorWithLocationsId: string) => {
  const currentDoorWithLocations = doorsWithLocationsVar();
  const updatedDoorWithLocations = currentDoorWithLocations.filter((door) => door.id !== deletedDoorWithLocationsId);

  doorsWithLocationsVar(updatedDoorWithLocations);
};
