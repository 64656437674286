/* eslint-disable import/prefer-default-export */
import {
  GetDoorsForTenantWithLocations,
  GetDoorsForTenantWithLocations_getDoorsForTenant
} from 'graphql/generated/GetDoorsForTenantWithLocations';
import { createMockedDoorWithLocations } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/doorWithLocations';
import { GetDoorsForTenantWithOfficeMode_getDoorsForTenant_officeMode_weekDays } from 'graphql/generated/GetDoorsForTenantWithOfficeMode';
import { createLocationWithDoors } from '../objectsBaseOnSchemaType/locationWithDoors';
import { createMockedLocation } from '../objectsBaseOnSchemaType/location';

export function mockedGetDoorsForTenantWithLocationsData(
  getDoorsForTenant: Array<GetDoorsForTenantWithLocations_getDoorsForTenant>
): GetDoorsForTenantWithLocations {
  return {
    getDoorsForTenant
  };
}

export const mockedGetDoorsForTenantWithLocationsDataUnitTests = mockedGetDoorsForTenantWithLocationsData([
  createMockedDoorWithLocations({ id: 'mockedDoorId0', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 0' }),
  createMockedDoorWithLocations({ id: 'mockedDoorId1', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 1' }),
  createMockedDoorWithLocations({ id: 'mockedDoorId2', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 2' })
]);

const weekDays: GetDoorsForTenantWithOfficeMode_getDoorsForTenant_officeMode_weekDays[] = [
  {
    dayName: 'monday',
    from: '00:00:00',
    to: '23:59:00',
    __typename: 'WeekScheduleDay'
  },
  {
    dayName: 'tuesday',
    from: '00:00:00',
    to: '23:59:00',
    __typename: 'WeekScheduleDay'
  },
  {
    dayName: 'wednesday',
    from: '00:00:00',
    to: '23:59:00',
    __typename: 'WeekScheduleDay'
  },
  {
    dayName: 'thursday',
    from: '00:00:00',
    to: '23:59:00',
    __typename: 'WeekScheduleDay'
  },
  {
    dayName: 'friday',
    from: '00:00:00',
    to: '23:59:00',
    __typename: 'WeekScheduleDay'
  },
  {
    dayName: 'saturday',
    from: '00:00:00',
    to: '23:59:00',
    __typename: 'WeekScheduleDay'
  },
  {
    dayName: 'sunday',
    from: '00:00:00',
    to: '23:59:00',
    __typename: 'WeekScheduleDay'
  }
];

export const mockedGetDoorsForTenantWithLocationsDataAdmin = mockedGetDoorsForTenantWithLocationsData([
  createMockedDoorWithLocations({
    id: 'mockedDoorId1',
    batteryWarningLevel: 0,
    domSerialNumber: '65',
    name: 'Test door 1',
    manualLocations: [],
    externalLocation: createMockedLocation({ id: 'TestRoom1', name: 'Test room 1' }),
    officeMode: {
      __typename: 'WeekSchedule',
      weekDays
    }
  }),
  createMockedDoorWithLocations({
    id: 'mockedDoorId2',
    batteryWarningLevel: 3,
    domSerialNumber: '7F',
    name: 'Test door 2',
    manualLocations: [],
    externalLocation: createMockedLocation({ id: 'TestRoom2', name: 'Test room 2' }),
    officeMode: {
      __typename: 'WeekSchedule',
      weekDays
    }
  }),
  createMockedDoorWithLocations({
    id: 'mockedDoorId3',
    batteryWarningLevel: 2,
    domSerialNumber: 'A3',
    name: 'Test door 3',
    manualLocations: [],
    externalLocation: createMockedLocation({ id: 'TestRoom3', name: 'Test room 3' }),
    officeMode: {
      __typename: 'WeekSchedule',
      weekDays
    }
  }),
  createMockedDoorWithLocations({
    id: 'mockedDoorId4',
    batteryWarningLevel: 1,
    domSerialNumber: 'ZZ',
    name: 'Test door 4',
    manualLocations: [createLocationWithDoors({ id: 'TestGroup1', name: 'Test group 1' })],
    externalLocation: null,
    officeMode: {
      __typename: 'WeekSchedule',
      weekDays
    }
  }),
  createMockedDoorWithLocations({
    id: 'mockedDoorId5',
    batteryWarningLevel: 0,
    domSerialNumber: 'ZZZ',
    name: 'Test door 5',
    manualLocations: [createLocationWithDoors({ id: 'TestGroup2', name: 'Test group 2' })],
    externalLocation: null
  }),
  createMockedDoorWithLocations({
    id: 'mockedDoorId6',
    batteryWarningLevel: 0,
    domSerialNumber: '66',
    name: 'Test door 6',
    manualLocations: [],
    externalLocation: createMockedLocation({ id: 'TestRoom4', name: 'Test room 4' }),
    officeMode: {
      __typename: 'WeekSchedule',
      weekDays
    }
  })
]);
