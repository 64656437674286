import { AssignAccessGrantsToDoors_assignAccessGrantsToDoors as AddedDoor } from 'graphql/generated/AssignAccessGrantsToDoors';
import { UnassignAccessGrantsFromDoors_unassignAccessGrantsFromDoors as DeletedDoor } from 'graphql/generated/UnassignAccessGrantsFromDoors';
import { GetVisitorGroupsForTenant_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenant';
import { visitorsGroupsVar } from 'state/vars';

export const setVisitorsGroupsAction = (visitorsGroups: GetVisitorGroupsForTenant_getVisitorGroupsForTenant[]) => {
  visitorsGroupsVar(visitorsGroups);
};

const updateDefaultAccessGrant = (
  visitorGroup: GetVisitorGroupsForTenant_getVisitorGroupsForTenant,
  updatedAccessGrant: AddedDoor | DeletedDoor
) =>
  visitorGroup.defaultAccessGrants.map((defaultAccessGrant) => {
    if (defaultAccessGrant.id === updatedAccessGrant.id) {
      return { ...defaultAccessGrant, doors: updatedAccessGrant.doors };
    }
    return defaultAccessGrant;
  });

const updateScheduledAccessGrant = (
  visitorGroup: GetVisitorGroupsForTenant_getVisitorGroupsForTenant,
  updatedAccessGrant: AddedDoor | DeletedDoor
) =>
  visitorGroup.scheduledAccessGrants.map((scheduledAccessGrant) => {
    if (scheduledAccessGrant.id === updatedAccessGrant.id) {
      return { ...scheduledAccessGrant, doors: updatedAccessGrant.doors };
    }
    return scheduledAccessGrant;
  });

export const updateAccessGrantsAction = (updatedAccessGrant: AddedDoor | DeletedDoor) => {
  const currentVisitorGroups = visitorsGroupsVar();
  const updatedVisitorGroups = currentVisitorGroups.map((visitorGroup) => {
    if (visitorGroup.defaultAccessGrants.length < visitorGroup.scheduledAccessGrants.length)
      return {
        ...visitorGroup,
        scheduledAccessGrants: updateScheduledAccessGrant(visitorGroup, updatedAccessGrant)
      };
    return {
      ...visitorGroup,
      defaultAccessGrants: updateDefaultAccessGrant(visitorGroup, updatedAccessGrant)
    };
  });

  visitorsGroupsVar(updatedVisitorGroups);
};

export const setAccessGrantsForGroupAction = (
  accessGrantsForGroup: GetVisitorGroupsForTenant_getVisitorGroupsForTenant
) => {
  const currentVisitorGroups = visitorsGroupsVar();
  const updatedVisitorGroups: GetVisitorGroupsForTenant_getVisitorGroupsForTenant[] = (
    currentVisitorGroups as GetVisitorGroupsForTenant_getVisitorGroupsForTenant[]
  ).map((visitorGroup) => {
    if (visitorGroup.id === accessGrantsForGroup.id)
      return {
        ...visitorGroup,
        defaultAccessGrants: accessGrantsForGroup.defaultAccessGrants,
        scheduledAccessGrants: accessGrantsForGroup.scheduledAccessGrants
      };
    return {
      ...visitorGroup
    };
  });
  visitorsGroupsVar(updatedVisitorGroups);
};
