/* eslint-disable import/prefer-default-export */

import {
  SetAccessGrantsIsOfficeModeEnabled,
  SetAccessGrantsIsOfficeModeEnabled_setAccessGrantsIsOfficeModeEnabled
} from 'graphql/generated/SetAccessGrantsIsOfficeModeEnabled';
import { createMockedAccessGrantWithIsOfficeModeEnabled } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/accessGrantWithIsOfficeModeEnabled';

export function mockedSetIsAdminForGroup(
  accessGrant: SetAccessGrantsIsOfficeModeEnabled_setAccessGrantsIsOfficeModeEnabled
): SetAccessGrantsIsOfficeModeEnabled {
  return {
    setAccessGrantsIsOfficeModeEnabled: accessGrant
  };
}

export const mockedUpdateAccessGrantWithIsOfficeModeForMutation1 = mockedSetIsAdminForGroup(
  createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedGrantId' })
);
