/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule
} from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';

interface Props {
  id: string;
  accessSchedule: GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule;
}

export function createMockedAccessGrantWithSchedules({
  id,
  accessSchedule
}: Props):
  | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants
  | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants {
  return {
    __typename: 'AccessGrant',
    id,
    accessSchedule
  };
}
