import React from 'react';

const Visitor: React.FC = () => (
  <>
    <path
      d="M13 7H3C2.46957 7 1.96086 7.21071 1.58579 7.58579C1.21071 7.96086 1 8.46957 1 9V27C1 27.5304 1.21071 28.0391 1.58579 28.4142C1.96086 28.7893 2.46957 29 3 29H29C29.5304 29 30.0391 28.7893 30.4142 28.4142C30.7893 28.0391 31 27.5304 31 27V9C31 8.46957 30.7893 7.96086 30.4142 7.58579C30.0391 7.21071 29.5304 7 29 7H19"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 6C19 5.20435 18.6839 4.44129 18.1213 3.87868C17.5587 3.31607 16.7956 3 16 3C15.2044 3 14.4413 3.31607 13.8787 3.87868C13.3161 4.44129 13 5.20435 13 6V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11H18C18.2652 11 18.5196 10.8946 18.7071 10.7071C18.8946 10.5196 19 10.2652 19 10V6Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 22C12.933 22 14.5 20.433 14.5 18.5C14.5 16.567 12.933 15 11 15C9.067 15 7.5 16.567 7.5 18.5C7.5 20.433 9.067 22 11 22Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 29C5 27.4087 5.63214 25.8826 6.75736 24.7574C7.88258 23.6321 9.4087 23 11 23C12.5913 23 14.1174 23.6321 15.2426 24.7574C16.3679 25.8826 17 27.4087 17 29H5Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19 17H25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 21H27" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </>
);

export default Visitor;
