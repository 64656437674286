import React from 'react';
import Separator from 'ui/atoms/Separator/Separator';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Tab, { Props as TabProps } from '../../atoms/Tab/Tab';

export interface Props {
  tabs: TabProps[];
}

const Tabs: React.FC<Props> = ({ tabs }) => (
  <>
    <ComponentWrapper flexDirection="row" gap="1.5rem">
      {tabs.map((tab, index) => (
        <Tab key={tabs.indexOf(tab)} index={index} {...tab} />
      ))}
    </ComponentWrapper>
    <Separator marginTop="-0.5rem" />
  </>
);

export default Tabs;
