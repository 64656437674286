import { useReactiveVar } from '@apollo/client';
import { authVar } from 'state/vars';
import { Props as MenuItemProps } from 'ui/atoms/MenuItem/MenuItem';
import { isMobile } from 'react-device-detect';
import { Translation } from 'hooks/useTranslation/useTranslation';

const usePagesAvailability = (translation: Translation) => {
  const { user } = useReactiveVar(authVar);
  const isAdmin = user?.isAdmin;
  const isAccessByScheduleEnabled = user?.isAccessByScheduleEnabled;
  const mobileDevices = user?.mobileDevices;
  const isRegistered = mobileDevices?.some((device) => device.role === 'ACCESS' && device.isRegistered);

  const isRegistrationAvailable = () => !isRegistered;

  const isReservationsAvailable = () =>
    Boolean(!isAccessByScheduleEnabled && (isRegistered || (!isRegistered && isAdmin)));

  const isEventsAvailable = () => isAdmin;

  const isPermissionAvailable = () => Boolean(isAdmin);

  const isHardwareAvailable = () => Boolean(isAdmin);

  const isAccessAvailable = () => Boolean(isAdmin);

  const isUsersAvailable = () => Boolean(isAdmin);

  const isMyProfileAvailable = () => isAccessByScheduleEnabled || isRegistered || isAdmin;

  const isMobileRegistrationAvailable = () => Boolean(isMobile && !isRegistered);
  const isMobileOfficeDaysAvailable = () => Boolean(!isAccessByScheduleEnabled && isMobile && isRegistered);
  const isMobileMobileNotSupportedAvailable = () => Boolean(isMobile);

  const getSideNavItems = () => {
    const items: MenuItemProps[] = [];

    if (isReservationsAvailable())
      items.push({
        location: '/reservations',
        text: translation.reservations,
        icon: 'CalendarCheckFill'
      });
    if (isEventsAvailable())
      items.push({
        location: '/events',
        text: translation.events,
        icon: 'Booklet'
      });

    if (isPermissionAvailable())
      items.push({
        location: '/permissions',
        text: translation.permissions,
        icon: 'Permissions'
      });
    if (isHardwareAvailable())
      items.push({
        location: '/hardware',
        text: translation.hardware,
        icon: 'Hardware'
      });
    if (isAccessAvailable())
      items.push({
        location: '/access',
        text: translation.access,
        icon: 'Access'
      });
    if (isUsersAvailable())
      items.push({
        location: '/users',
        text: translation.users,
        icon: 'UserMenu'
      });

    return items;
  };

  return {
    isRegistrationAvailable,
    isReservationsAvailable,
    isEventsAvailable,
    isPermissionAvailable,
    isHardwareAvailable,
    isAccessAvailable,
    isUsersAvailable,
    isMyProfileAvailable,
    getSideNavItems,
    isMobileRegistrationAvailable,
    isMobileOfficeDaysAvailable,
    isMobileMobileNotSupportedAvailable
  };
};

export default usePagesAvailability;
