import useDrawer from 'hooks/useDrawer/useDrawer';
import React, { useState } from 'react';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import ManageLockGroupsUI from 'ui/organisms/Drawer/variants/ManageLockGroups';
import useModal from 'hooks/useModal/useModal';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { GetLocationsWithDoorsForTenant_getLocationsForTenant_doors } from 'graphql/generated/GetLocationsWithDoorsForTenant';
import { useForm } from 'react-hook-form';
import useDoorsAndLocations from 'hooks/useDoorsAndLocations/useDoorsAndLocations';
import { sortBy } from 'lodash';
import useTranslation from 'hooks/useTranslation/useTranslation';

const ManageLockGroups: React.FC = () => {
  const { translation } = useTranslation();
  const { handleSubmit } = useForm();
  const { hideDrawer } = useDrawer();
  const { showModal } = useModal();
  const [filterStr, setFilterStr] = useState('');
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { locationsWithDoors } = useDoorsAndLocations({
    handleFetchError
  });

  const getFilterData = () => {
    if (filterStr !== '')
      return locationsWithDoors.filter((location) =>
        (location.name.toLowerCase() + location.doors.length).includes(filterStr.toLowerCase())
      );
    return locationsWithDoors;
  };

  const onSubmit = async () => {
    hideDrawer();
  };

  const handleOpenCreateLockGroupModal = () => {
    showModal({ type: 'manageLockGroup', contentValue: { isEditing: false } });
    hideDrawer();
  };

  const handleOnDeleteLockGroupIconClick = (locationId: string) => {
    showModal({ type: 'deleteLockGroup', contentValue: { locationId } });
    hideDrawer();
  };

  const handleOpenEditLockGroupModal = (
    locationId: string,
    lockGroupName: string,
    selectedDoors: GetLocationsWithDoorsForTenant_getLocationsForTenant_doors[]
  ) => {
    showModal({
      type: 'manageLockGroup',
      contentValue: {
        isEditing: true,
        locationId,
        lockGroupName,
        selectedDoors
      }
    });
    hideDrawer();
  };

  return (
    <DrawerTemplate
      title={translation.manage_lock_groups}
      confirmButtonText={translation.close}
      closeButtonText={translation.cancel}
      closeButton={false}
      id="drawer-manage-lock-groups-lock"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ManageLockGroupsUI
        handleOpenCreateLockGroupModal={handleOpenCreateLockGroupModal}
        handleOpenEditLockGroupModal={handleOpenEditLockGroupModal}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
        searchGroupInputValue={filterStr}
        searchGroupInputOnChange={setFilterStr}
        handleOnDeleteLockGroupIconClick={handleOnDeleteLockGroupIconClick}
        doorGroups={sortBy(getFilterData(), (data) => data.name)}
      />
    </DrawerTemplate>
  );
};
export default ManageLockGroups;
