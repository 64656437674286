/* eslint-disable import/prefer-default-export */
import {
  ON_OFFICE_DAY_ADDED_BY_ID,
  ON_REGISTRATION_TOKEN_SAVED_BY_ID,
  ON_OFFICE_DAY_DELETED_BY_ID
} from 'graphql/subscriptions';
import { mockedOnRegistrationTokenSavedDataForSubscription } from '../graphqlApollo/subscription/onRegistrationTokenSaved';
import { mockedOnOfficeDayAddedDataForSubscription } from '../graphqlApollo/subscription/onOfficeDayAdded';
import { mockedOnOfficeDayDeletedDataForSubscription } from '../graphqlApollo/subscription/onOfficeDayDeleted';

export const subscriptions = [
  {
    request: {
      query: ON_OFFICE_DAY_ADDED_BY_ID,
      variables: {
        id: 'mockedBobVisitorId'
      }
    },
    newData: () => ({
      data: mockedOnOfficeDayAddedDataForSubscription
    })
  },
  {
    request: {
      query: ON_OFFICE_DAY_DELETED_BY_ID,
      variables: {
        id: 'mockedBobVisitorId'
      }
    },
    newData: () => ({
      data: mockedOnOfficeDayDeletedDataForSubscription
    })
  },
  {
    request: {
      query: ON_REGISTRATION_TOKEN_SAVED_BY_ID,
      variables: { id: 'mockedBobVisitorId' }
    },
    newData: () => ({
      data: mockedOnRegistrationTokenSavedDataForSubscription
    })
  }
];
