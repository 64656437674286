import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS } from 'graphql/queries';
import { useEffect } from 'react';
import {
  GetVisitorGroupsForTenantWithMembers,
  GetVisitorGroupsForTenantWithMembersVariables
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { visitorsGroupsMembersVar } from 'state/vars';
import { setVisitorGroupsMembersAction } from 'state/actions/visitorGroupsMembers';
import { BaseHookProps } from '../shared/types';

const useVisitorsGroupsMembers = ({ handleFetchError }: BaseHookProps) => {
  const visitorsGroupsMembers = useReactiveVar(visitorsGroupsMembersVar);

  const [getVisitorsGroupsMembers, { loading }] = useLazyQuery<
    GetVisitorGroupsForTenantWithMembers,
    GetVisitorGroupsForTenantWithMembersVariables
  >(GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS, {
    onCompleted: (data) => {
      setVisitorGroupsMembersAction(data.getVisitorGroupsForTenant);
    },
    onError: () => {
      handleFetchError('Error while fetching visitor groups');
    }
  });

  useEffect(() => {
    getVisitorsGroupsMembers();
  }, [getVisitorsGroupsMembers]);

  return {
    visitorsGroupsMembers,
    loading
  };
};

export default useVisitorsGroupsMembers;
