/* eslint-disable no-magic-numbers */
/* eslint-disable import/prefer-default-export */

import {
  GetTenantLegalDocuments,
  GetTenantLegalDocuments_getTenantLegalDocuments
} from 'graphql/generated/GetTenantLegalDocuments';
import { LegalDocumentType } from 'graphql/generated/globalTypes';

export function mockedGetTenantLegalDocumentsData(
  getTenantLegalDocuments: GetTenantLegalDocuments_getTenantLegalDocuments
): GetTenantLegalDocuments {
  return {
    getTenantLegalDocuments
  };
}

export const mockedGetTenantLegalDocumentsDataForQuery = mockedGetTenantLegalDocumentsData({
  __typename: 'TenantLegalDocuments',
  tenant: {
    __typename: 'BasicTenant',
    tenantId: 'mockedTenantId',
    name: 'mockedTenantName'
  },
  dpa: {
    __typename: 'LegalDocument',
    documentType: LegalDocumentType.DPA,
    currentVersion: 'mockedCurrentVersion',
    id: 'DPAid',
    currentText: '<h1>mocked dpa text</h1>',
    acceptedVersion: 'mockedVersion',
    acceptedOn: null
  },
  sla: {
    __typename: 'LegalDocument',
    documentType: LegalDocumentType.SLA,
    currentVersion: 'mockedCurrentVersion',
    id: 'SLAid',
    currentText: '<h1>mocked sla text</h1>',
    acceptedVersion: 'mockedVersion',
    acceptedOn: null
  }
});
