import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/atoms/Typography/Typography';
import Button from 'ui/atoms/Button/Button';
import useDrawer from 'hooks/useDrawer/useDrawer';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Loader from 'ui/atoms/Loader/Loader';
import { kebabCase } from 'lodash';

export interface Props {
  children: React.ReactNode;
  title: string;
  headerSideText?: JSX.Element;
  confirmButtonText: string;
  disableConfirmButton?: boolean;
  closeButton?: boolean;
  closeButtonText: string;
  isLoading?: boolean;
  handleCloseDrawer?: () => void;
  id: string;
  headerSideTextOnClick?: () => void;
  onSubmit: () => void;
}

const StyledTitleContainer = styled.div`
  display: flex;
  max-width: 23rem;
  width: 100%;
  margin-bottom: 3.5rem;
  justify-content: space-between;
  align-items: center;
`;

const StyledEditContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 22.9375rem;
  height: 62.5vh;
  overflow-y: auto;
  row-gap: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonsSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button:first-child {
    display: flex;
    justify-content: center;
  }

  button:last-child {
    display: flex;
    justify-content: center;
  }
`;

const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DrawerTemplate: React.FC<Props> = ({
  children,
  title,
  headerSideText,
  headerSideTextOnClick,
  confirmButtonText,
  disableConfirmButton = false,
  closeButton = true,
  closeButtonText,
  isLoading = false,
  handleCloseDrawer,
  id,
  onSubmit
}) => {
  const { hideDrawer } = useDrawer();
  const closeDrawer = () => {
    if (handleCloseDrawer) handleCloseDrawer();
    hideDrawer();
  };

  return (
    <ComponentWrapper flexDirection="column" justifyContent="space-between" height="100%" id={id}>
      <StyledTitleContainer>
        <Typography variant="title2" color="dTextHigh" id={kebabCase(`drawer-${title}`)}>
          {title}
        </Typography>
        <StyledEditContainer onClick={headerSideTextOnClick}>{headerSideText}</StyledEditContainer>
      </StyledTitleContainer>
      {isLoading ? (
        <ComponentWrapper justifyContent="center" alignItems="center" width="22.9375rem" height="100%">
          <Loader size={100} />
        </ComponentWrapper>
      ) : (
        <StyledForm onSubmit={onSubmit}>
          <Container>{children}</Container>
          <ButtonsSection>
            {closeButton && (
              <Button backgroundColor="transparent" textColor="b1" onClick={closeDrawer} id="drawer-button-cancel">
                {closeButtonText}
              </Button>
            )}
            <Button
              type="submit"
              backgroundColor={disableConfirmButton ? 'b5' : 'primary'}
              textColor={disableConfirmButton ? 'lTextMedium' : 'lTextHigh'}
              id={kebabCase(`drawer-button-${confirmButtonText}`)}
              disabled={disableConfirmButton}
            >
              {confirmButtonText}
            </Button>
          </ButtonsSection>
        </StyledForm>
      )}
    </ComponentWrapper>
  );
};

export default DrawerTemplate;
