/* eslint-disable import/prefer-default-export */
import { GetDoorsForTenant_getDoorsForTenant_location } from 'graphql/generated/GetDoorsForTenant';
import { GetLocationsForTenant_getLocationsForTenant } from 'graphql/generated/GetLocationsForTenant';
import { LocationType } from 'graphql/generated/globalTypes';

interface Props {
  id: string;
  name?: string;
  type?: LocationType;
}

export function createMockedLocation({
  id,
  name = 'createMockedLocation',
  type = LocationType.EXTERNAL
}: Props): GetDoorsForTenant_getDoorsForTenant_location | GetLocationsForTenant_getLocationsForTenant {
  return {
    __typename: 'Location',
    name,
    id,
    type
  };
}
