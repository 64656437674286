import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'theme/theme';
import { isMobile } from 'react-device-detect';

const ThemeProviderExtended: React.FC = ({ children }) => (
  <ThemeProvider theme={{ ...theme, isMobile }}>{children}</ThemeProvider>
);

export default ThemeProviderExtended;
