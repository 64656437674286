import { GetLocationsWithDoorsForTenant_getLocationsForTenant as LocationsWithDoors } from 'graphql/generated/GetLocationsWithDoorsForTenant';
import { locationsWithDoorsVar } from 'state/vars';

export const setLocationsWithDoorsAction = (locations: LocationsWithDoors[]) => {
  locationsWithDoorsVar(locations);
};

export const addLocationsWithDoorsAction = (location: LocationsWithDoors) => {
  const currentLocationsWithDoors = locationsWithDoorsVar();
  const updatedLocationsWithDoors = [...currentLocationsWithDoors, location];

  locationsWithDoorsVar(updatedLocationsWithDoors);
};

export const updateLocationsWithDoorsAction = (location: LocationsWithDoors) => {
  const currentLocationsWithDoors = locationsWithDoorsVar();
  const updatedLocationsWithDoors = currentLocationsWithDoors.map((group) =>
    group.id === location.id ? location : group
  );

  locationsWithDoorsVar(updatedLocationsWithDoors);
};

export const deleteLocationsWithDoorsAction = (locationId: string) => {
  const currentLocationsWithDoors = locationsWithDoorsVar();
  const updatedLocationsWithDoors = currentLocationsWithDoors.filter((group) => group.id !== locationId);

  locationsWithDoorsVar(updatedLocationsWithDoors);
};
