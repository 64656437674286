/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StickyToTop = styled.div`
  background-color: ${({ theme }) => theme.colors.bgDark};
  z-index: 2;
  height: 4rem;
  display: flex;
  margin: 1.5rem 0 0.5rem 0;
  display: flex;
  justify-content: end;
  align-items: center;
`;
