/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';
import {
  GetVisitorGroupsForTenantWithMembersAndRole,
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';
import { Role } from 'graphql/generated/globalTypes';
import { createMockedMobileDevice } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/mobileDevice';
import { createMockedVisitorGroupMembership } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupMembership';
import { createMockedVisitorGroupWithMembersAndRoleCoContractors } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithMembersAndRoleCoContractors';
import { createMockedVisitorWithVisitorGroupMembership } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorWithVisitorGroupMembership';

export function getVisitorGroupsForTenantWithMembersAndRoleDataForQuery(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithMembersAndRole {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithMembersAndRoleDataForQuery =
  getVisitorGroupsForTenantWithMembersAndRoleDataForQuery([
    createMockedVisitorGroupWithMembersAndRoleCoContractors({
      id: 'mockedVisitorGroupIdExternalUsers1',
      members: [
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdExternalUser1',
          name: 'mockedVisitorNameExternalUser1',
          email: 'mockedVisitorEmailExternalUser1',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({
              visitorGroupExternalRef: 'mockedVisitorGroupExternalRefs',
              role: Role.EXTERNAL_GROUP_USER_MANAGER
            })
          ]
        }),
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdExternalUser2',
          name: 'mockedVisitorNameExternalUser2',
          email: 'mockedVisitorEmailExternalUser2',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({ visitorGroupExternalRef: 'mockedVisitorGroupExternalRefs' })
          ]
        })
      ],
      name: 'co-contractors1',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs'
    })
  ]);

export const mockedGetVisitorGroupsForTenantWithMembersAndRoleDataAdmin =
  getVisitorGroupsForTenantWithMembersAndRoleDataForQuery([
    createMockedVisitorGroupWithMembersAndRoleCoContractors({
      id: 'mockedIdCleaners',
      members: [
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdCleaner1',
          name: 'cleaner1',
          email: 'cleaner1@atlas.com',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDeviceCleaner1', isRegistered: false })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({
              visitorGroupExternalRef: 'mockedExternalRefCleaners',
              role: Role.EXTERNAL_GROUP_USER_MANAGER
            })
          ]
        }),
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdCleaner2',
          name: 'cleaner2',
          email: 'cleaner2@atlas.com',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDeviceCleaner2', isRegistered: true })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({
              visitorGroupExternalRef: 'mockedExternalRefCleaners',
              role: Role.EXTERNAL_GROUP_USER
            })
          ]
        })
      ],
      name: 'cleaners',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedExternalRefCleaners'
    }),
    createMockedVisitorGroupWithMembersAndRoleCoContractors({
      id: 'mockedIdCo-contractors',
      members: [
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdCo-contractor1',
          name: 'co-contractor1',
          email: 'co-contractor1@atlas.com',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDeviceCo-Contractor1', isRegistered: false })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({
              visitorGroupExternalRef: 'mockedExternalRefCo-contractors',
              role: Role.EXTERNAL_GROUP_USER_MANAGER
            })
          ],
          expirationDate: dayjs(dayjs().add(1, 'hour')).toISOString()
        }),
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdCo-contractor2',
          name: 'co-contractor2',
          email: 'co-contractor2@atlas.com',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDeviceCo-Contractor2', isRegistered: false })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({
              visitorGroupExternalRef: 'mockedExternalRefCo-contractors',
              role: Role.EXTERNAL_GROUP_USER_MANAGER
            })
          ],
          expirationDate: dayjs(dayjs().add(1, 'hour').add(1, 'day')).toISOString()
        }),
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdCo-contractor3',
          name: 'co-contractor3',
          email: 'co-contractor3@atlas.com',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDeviceCo-Contractor3', isRegistered: false })],
          isEditable: true,
          visitorGroupMembership: [
            createMockedVisitorGroupMembership({
              visitorGroupExternalRef: 'mockedExternalRefCo-contractors',
              role: Role.EXTERNAL_GROUP_USER_MANAGER
            })
          ],
          expirationDate: dayjs(dayjs().add(1, 'hour').add(1, 'week')).toISOString()
        })
      ],
      name: 'co-contractors',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedExternalRefCo-contractors'
    })
  ]);
