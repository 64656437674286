import SideNav from 'components/SideNav/SideNav';
import React from 'react';
import styled from 'styled-components';
import TopBar from 'components/TopBar/TopBar';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

interface Props {
  showSideNav: boolean;
}

const RenderWithLayout = styled.div`
  display: flex;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
  flex: 1;

  ${({ theme: { isMobile } }) => (isMobile ? 'margin-left: 0;' : '')};
`;

const TopBarWrapper = styled.div`
  display: flex;
  height: 4.5rem;
  width: 100vw;
`;

const SideNavWrapper = styled.div`
  display: flex;
  flex: 0 0 14rem;
  top: 0;
  left: 0;
  height: calc(100vh - 4.5rem);
`;

const ChildrenWrapper = styled.div`
  height: calc(100vh - 4.5rem);
  ${({ theme: { isMobile } }) => (isMobile ? '' : 'flex: 1 1 auto;')};
  ${({ theme: { isMobileLandscape } }) => (isMobileLandscape ? 'overflow: auto; height: 100vh;' : '')}
`;

const Layout: React.FC<Props> = ({ children, showSideNav }) => (
  <RenderWithLayout>
    {showSideNav ? (
      <ComponentWrapper flexDirection="column" height="100vh">
        <TopBarWrapper>
          <TopBar />
        </TopBarWrapper>
        <ComponentWrapper height="calc(100vh - 4.5rem)">
          <SideNavWrapper id="sidebar">
            <SideNav />
          </SideNavWrapper>
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </ComponentWrapper>
      </ComponentWrapper>
    ) : (
      <ChildrenWrapper>{children}</ChildrenWrapper>
    )}
  </RenderWithLayout>
);

export default Layout;
