/* eslint-disable no-magic-numbers */
import React from 'react';
import Icon from 'ui/atoms/Icon/Icon';

export interface Props {
  isNextMonthShown: boolean;
  isMobile: boolean;
  onArrowClick: () => void;
}

const CalendarArrow: React.FC<Props> = ({ isNextMonthShown, isMobile, onArrowClick }) => {
  const iconProps = {
    width: 17,
    height: 36,
    style: { marginRight: isMobile ? 0 : '1.5rem', cursor: 'pointer' }
  };

  return (
    <>
      {isNextMonthShown ? (
        <Icon {...iconProps} name="ArrowLeft" onClick={onArrowClick} id="previous-month-button" />
      ) : (
        <Icon {...iconProps} name="ArrowRight" onClick={onArrowClick} id="next-month-button" />
      )}
    </>
  );
};
export default CalendarArrow;
