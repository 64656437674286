import React from 'react';
import styled from 'styled-components';
import { Colors } from 'theme/theme';
import ComponentWrapper, { Flex, WidthOrHeight } from 'ui/templates/ComponentWrapper/ComponentWrapper';

interface CssProps {
  withScroll?: boolean;
  backgroundColor?: keyof Colors;
  scrollbarTrackColor?: keyof Colors;
  scrollbarThumbColor?: keyof Colors;
  height?: WidthOrHeight;
  flex?: Flex;
}

export type Props = CssProps & {
  header?: React.ReactNode;
  rowsHeight?: string;
  id?: string;
  headerId?: string;
  rowsWrapperId?: string;
  isStickyHeader?: boolean;
};

const HeaderWrapper = styled.div<Pick<Props, 'isStickyHeader' | 'backgroundColor'>>`
  ${({ isStickyHeader }) =>
    isStickyHeader === true
      ? `
  position:  sticky;
    `
      : ``};

  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor !== undefined ? backgroundColor : theme.colors.bgDark};
  z-index: 1;
  top: 0;
  left: 0;
  padding-right: 1rem;
`;

const RowsWrapper = styled.div<Partial<Props>>`
  row-gap: 0.5rem;
  display: flex;
  flex-direction: column;
  z-index: 0;
  padding-right: 1rem;

  ${({ withScroll }) =>
    withScroll
      ? `
  overflow-y: overlay;
  overflow-x: hidden;
    `
      : ``};
  ${({ rowsHeight }) =>
    rowsHeight
      ? `
      height: ${rowsHeight};
    `
      : ``};

  ${({ theme, scrollbarTrackColor }) =>
    scrollbarTrackColor
      ? `  ::-webkit-scrollbar-thumb {
    background:
    ${theme.colors[scrollbarTrackColor]}
  }`
      : ``};

  ${({ theme, scrollbarThumbColor }) =>
    scrollbarThumbColor
      ? `
  ::-webkit-scrollbar-track {
    background: ${theme.colors[scrollbarThumbColor]};
  }`
      : ``};
`;

const Table: React.FC<Props> = ({
  header,
  rowsHeight,
  height = 'inherit',
  flex = '1 1 auto',
  children,
  id,
  headerId,
  rowsWrapperId,
  withScroll = true,
  scrollbarTrackColor,
  scrollbarThumbColor,
  isStickyHeader = true,
  backgroundColor
}) => (
  <ComponentWrapper flexDirection="column" height={height} width="inherit" flex={flex} id={id}>
    <HeaderWrapper id={headerId} isStickyHeader={isStickyHeader} backgroundColor={backgroundColor}>
      {header}
    </HeaderWrapper>
    <RowsWrapper
      id={rowsWrapperId}
      rowsHeight={rowsHeight}
      withScroll={withScroll}
      scrollbarTrackColor={scrollbarTrackColor}
      scrollbarThumbColor={scrollbarThumbColor}
    >
      {children}
    </RowsWrapper>
  </ComponentWrapper>
);

export default Table;
