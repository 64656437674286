/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';

type AWSFormat = 'AWSDateTime' | 'AWSDate';

export const getDateWithFormat = (
  format: AWSFormat,
  year: number,
  month: number = 0,
  date: number = 0,
  hour: number = 0,
  minute: number = 0,
  second: number = 0,
  millisecond: number = 0
) => {
  const createdDate = dayjs()
    .set('year', year)
    .set('month', month)
    .set('date', date)
    .set('hour', hour)
    .set('minute', minute)
    .set('second', second)
    .set('millisecond', millisecond);

  if (format === 'AWSDate') return createdDate.format('YYYY-MM-DD');
  return createdDate.toISOString();
};
