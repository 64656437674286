/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants,
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_scheduledAccessGrants,
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule,
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_scheduledAccessGrants_doors,
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_scheduledAccessGrants_locations
} from 'graphql/generated/GetVisitorGroupsForTenantWithAccessGrants';

interface Props {
  id: string;
  doors?: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_scheduledAccessGrants_doors[];
  locations?: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_scheduledAccessGrants_locations[];
  accessSchedule?: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule;
}

export function createMockedAccessGrantWithLocations({
  id,
  doors = [],
  locations = [],
  accessSchedule = { __typename: 'WeekSchedule', weekDays: [] }
}: Props):
  | GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_scheduledAccessGrants
  | GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants {
  return {
    __typename: 'AccessGrant',
    id,
    doors,
    locations,
    accessSchedule
  };
}
