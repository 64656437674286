/* eslint-disable no-magic-numbers */
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { AddLocation, AddLocationVariables } from 'graphql/generated/AddLocation';
import { DeleteDoorById, DeleteDoorByIdVariables } from 'graphql/generated/DeleteDoorById';
import { DeleteLocation, DeleteLocationVariables } from 'graphql/generated/DeleteLocation';
import {
  GetDoorsForTenantWithLocations,
  GetDoorsForTenantWithLocations_getDoorsForTenant
} from 'graphql/generated/GetDoorsForTenantWithLocations';
import { GetLocationsWithDoorsForTenant } from 'graphql/generated/GetLocationsWithDoorsForTenant';
import { LocationInput, LocationType } from 'graphql/generated/globalTypes';
import {
  UpdateDoorWithLocations,
  UpdateDoorWithLocationsVariables,
  UpdateDoorWithLocations_updateDoor
} from 'graphql/generated/UpdateDoorWithLocations';
import { UpdateLocation, UpdateLocationVariables } from 'graphql/generated/UpdateLocation';
import {
  ADD_LOCATION,
  DELETE_DOOR_BY_ID,
  DELETE_LOCATION,
  UPDATE_DOOR_WITH_LOCATIONS,
  UPDATE_LOCATION
} from 'graphql/mutations';
import { GET_DOORS_FOR_TENANT_WITH_LOCATIONS, GET_LOCATIONS_WITH_DOORS_FOR_TENANT } from 'graphql/queries';
import { useEffect } from 'react';
import {
  deleteDoorWithLocationsAction,
  setDoorWithLocationsAction,
  updateDoorWithLocationsAction
} from 'state/actions/doorsWithLocations';
import {
  addLocationsWithDoorsAction,
  deleteLocationsWithDoorsAction,
  setLocationsWithDoorsAction,
  updateLocationsWithDoorsAction
} from 'state/actions/locationsWithDoors';
import {
  GetDoorsForTenantWithLocations_getDoorsForTenant_complete,
  GetDoorsForTenantWithLocations_getDoorsForTenant_manualLocations_complete
} from 'state/types';
import { doorsWithLocationsVar, locationsAndDoorsRefetchVar, locationsWithDoorsVar } from 'state/vars';
import ArrayUtil from 'utils/Array/Array.util';
import { BaseHookProps } from '../shared/types';

const overwriteBatteryStatus = (
  doors: GetDoorsForTenantWithLocations_getDoorsForTenant[] | UpdateDoorWithLocations_updateDoor[]
) =>
  doors.map((door) => {
    const firstTwoChars = door.domSerialNumber?.slice(0, 2);
    switch (firstTwoChars) {
      case '65':
      case '66':
        return { ...door, batteryWarningLevel: 4 };
      default:
        return door;
    }
  });

const useDoorsAndLocations = ({ handleFetchError }: BaseHookProps) => {
  const doorsWithLocations = useReactiveVar(doorsWithLocationsVar);
  const locationsWithDoors = useReactiveVar(locationsWithDoorsVar);
  const locationsAndDoorsRefetch = useReactiveVar(locationsAndDoorsRefetchVar);

  const getLocationsOfDoor = (doorId: string) =>
    locationsWithDoors.filter((location) => location.doors.some((door) => door.id === doorId));

  const [getDoorsWithLocationsForTenant, { loading: doorsWithLocationsLoading }] =
    useLazyQuery<GetDoorsForTenantWithLocations>(GET_DOORS_FOR_TENANT_WITH_LOCATIONS, {
      onCompleted: (data) => {
        const doors = overwriteBatteryStatus(data.getDoorsForTenant);
        const doorsComplete = doors.map(
          (door): GetDoorsForTenantWithLocations_getDoorsForTenant_complete => ({
            ...door,
            manualLocations:
              door.manualLocations?.map(
                (location): GetDoorsForTenantWithLocations_getDoorsForTenant_manualLocations_complete => ({
                  ...location,
                  doors: doors.filter((locationDoor) =>
                    locationDoor.manualLocations?.some((mloc) => mloc.id === location.id)
                  )
                })
              ) ?? null
          })
        );
        setDoorWithLocationsAction(doorsComplete);
      },
      onError: () => {
        handleFetchError('Error while fetching doors');
      },
      fetchPolicy: 'network-only'
    });

  useEffect(() => {
    getDoorsWithLocationsForTenant();
  }, [getDoorsWithLocationsForTenant]);

  const [getLocationsWithDoorsForTenant, { loading: locationsWithDoorsLoading }] =
    useLazyQuery<GetLocationsWithDoorsForTenant>(GET_LOCATIONS_WITH_DOORS_FOR_TENANT, {
      onCompleted: (data) => {
        setLocationsWithDoorsAction(
          data.getLocationsForTenant.filter((location) => location.type === LocationType.MANUAL)
        );
      },
      onError: () => {
        handleFetchError('Error while fetching locations');
      },
      fetchPolicy: 'network-only'
    });

  useEffect(() => {
    getLocationsWithDoorsForTenant();
  }, [getLocationsWithDoorsForTenant]);

  useEffect(() => {
    if (locationsAndDoorsRefetch === true) {
      getDoorsWithLocationsForTenant();
      getLocationsWithDoorsForTenant();
      locationsAndDoorsRefetchVar(false);
    }
  }, [getDoorsWithLocationsForTenant, getLocationsWithDoorsForTenant, locationsAndDoorsRefetch]);

  const [updateDoorWithLocationsMutation, { loading: updateDoorWithLocationsLoading }] = useMutation<
    UpdateDoorWithLocations,
    UpdateDoorWithLocationsVariables
  >(UPDATE_DOOR_WITH_LOCATIONS, {
    onCompleted: (data) => {
      if (data && data.updateDoor) {
        const updatedDoor = overwriteBatteryStatus([data.updateDoor])[0];
        const updateDoorComplete: GetDoorsForTenantWithLocations_getDoorsForTenant_complete = {
          ...updatedDoor,
          manualLocations:
            updatedDoor.manualLocations?.map((manualLoc) => ({
              ...manualLoc,
              doors: []
            })) ?? null
        };
        updateDoorWithLocationsAction(updateDoorComplete);
      }
      locationsAndDoorsRefetchVar(true);
    },
    onError: () => {
      handleFetchError('Error while updating doors');
    }
  });

  const [deleteDoorWithLocationsMutation, { loading: deleteDoorWithLocationsLoading }] = useMutation<
    DeleteDoorById,
    DeleteDoorByIdVariables
  >(DELETE_DOOR_BY_ID, {
    onCompleted: (data) => {
      if (data.deleteDoorById) {
        deleteDoorWithLocationsAction(data.deleteDoorById);
      }
      locationsAndDoorsRefetchVar(true);
    },
    onError: () => {
      handleFetchError('Error while deleting doors');
    }
  });
  const updateDoorWithLocations = async (
    doorId: string,
    name: string,
    externalLocationId?: string | null,
    manualLocationIds?: string[] | null,
    officeModeFrom?: string,
    officeModeTo?: string
  ) => {
    const { data } = await updateDoorWithLocationsMutation({
      variables: {
        doorId,
        name,
        externalLocationId,
        manualLocationIds,
        officeMode: {
          scheduleType: 'weekSchedule',
          weekSchedule:
            officeModeFrom && officeModeTo
              ? // eslint-disable-next-line no-magic-numbers
                ArrayUtil.Range(7).map((_, index) => ({
                  dayName: dayjs().weekday(index).format('dddd').toLowerCase(),
                  from: officeModeFrom,
                  to: officeModeTo
                }))
              : []
        }
      }
    });
    return data;
  };

  const deleteDoor = async (doorId: string) => {
    const { data } = await deleteDoorWithLocationsMutation({
      variables: {
        doorId
      }
    });
    return data;
  };

  const isOfficeMode = (
    door: GetDoorsForTenantWithLocations_getDoorsForTenant_complete | GetDoorsForTenantWithLocations_getDoorsForTenant
  ) => door.officeMode.weekDays.some((day) => day !== null);

  const [addLocationMutation, { loading: addLocationLoading }] = useMutation<AddLocation, AddLocationVariables>(
    ADD_LOCATION,
    {
      onCompleted: (data) => {
        addLocationsWithDoorsAction(data.addLocation);
        locationsAndDoorsRefetchVar(true);
      },
      onError: () => {
        handleFetchError('Error while adding location');
      }
    }
  );

  const [updateLocationMutation, { loading: updateLocationLoading }] = useMutation<
    UpdateLocation,
    UpdateLocationVariables
  >(UPDATE_LOCATION, {
    onCompleted: (data) => {
      if (data && data.updateLocation) updateLocationsWithDoorsAction(data.updateLocation);
      locationsAndDoorsRefetchVar(true);
    },

    onError: () => {
      handleFetchError('Error while updating location');
    }
  });

  const [deleteLocationMutation, { loading: deleteLocationLoading }] = useMutation<
    DeleteLocation,
    DeleteLocationVariables
  >(DELETE_LOCATION, {
    onCompleted: (data) => {
      if (data.deleteLocation) {
        deleteLocationsWithDoorsAction(data.deleteLocation.id);
      }
      locationsAndDoorsRefetchVar(true);
    },
    onError: () => {
      handleFetchError('Error while deleting location');
    }
  });

  const addLocation = async (location: LocationInput) => {
    const { data } = await addLocationMutation({
      variables: {
        location
      }
    });
    return data;
  };

  const updateLocation = async (id: string, location: LocationInput) => {
    const { data } = await updateLocationMutation({
      variables: {
        id,
        location
      }
    });
    return data;
  };

  const deleteLocation = async (id: string) => {
    const { data } = await deleteLocationMutation({
      variables: {
        id
      }
    });
    return data;
  };

  return {
    doorsWithLocations,
    doorsWithLocationsLoading,
    getLocationsWithDoorsForTenant,
    getDoorsWithLocationsForTenant,
    updateDoorWithLocations,
    updateDoorWithLocationsLoading,
    deleteDoor,
    deleteDoorWithLocationsLoading,
    isOfficeMode,
    locationsWithDoors,
    locationsWithDoorsLoading,
    getLocationsOfDoor,
    addLocation,
    addLocationLoading,
    updateLocation,
    updateLocationLoading,
    deleteLocation,
    deleteLocationLoading
  };
};

export default useDoorsAndLocations;
