/* eslint-disable import/prefer-default-export */

import {
  AcceptLegalDocuments,
  AcceptLegalDocuments_acceptLegalDocuments
} from 'graphql/generated/AcceptLegalDocuments';

export function mockedAcceptLegalDocumentsData(
  acceptLegalDocuments: AcceptLegalDocuments_acceptLegalDocuments
): AcceptLegalDocuments {
  return {
    acceptLegalDocuments
  };
}

export const mockedAcceptLegalDocumentsDataForMutation1 = mockedAcceptLegalDocumentsData({
  __typename: 'AgreementAccepted',
  acceptedAt: 'AgreementAcceptedAcceptedAt'
});

export const mockedAcceptLegalDocumentsDataForMutation2 = mockedAcceptLegalDocumentsData({
  __typename: 'AgreementRejected',
  reason: 'AgreementRejectedReason'
});
