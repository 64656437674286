import dayjs from 'dayjs';
import { AddOfficeDay_addOfficeDay } from 'graphql/generated/AddOfficeDay';
import { OnOfficeDayAddedById_onOfficeDayAddedById } from 'graphql/generated/OnOfficeDayAddedById';
import { OfficeDaysState, OfficeAvailableDaysState } from 'state/types';
import { officeDaysVar, officeAvailableDaysVar } from 'state/vars';

export const setOfficeDaysAction = (officeDays: OfficeDaysState) => {
  officeDaysVar(officeDays);
};

export const addOfficeDaysAction = (
  addedVisit: AddOfficeDay_addOfficeDay | OnOfficeDayAddedById_onOfficeDayAddedById
) => {
  const currentOfficeDays = officeDaysVar();
  const updatedOfficeDays = [...currentOfficeDays, addedVisit];
  officeDaysVar(updatedOfficeDays);
};

export const removeOfficeDaysAction = (date: GraphQL_AWSDate) => {
  const currentOfficeDays = officeDaysVar();
  const updatedOfficeDays = currentOfficeDays.filter((visit) => !dayjs(visit.date).isSame(dayjs(date), 'date'));
  officeDaysVar(updatedOfficeDays);
};

export const setOfficeAvailableDaysAction = (officeAvailableDays: OfficeAvailableDaysState) => {
  officeAvailableDaysVar(officeAvailableDays);
};
