import React from 'react';
import { Colors } from 'theme/theme';
import styled from 'styled-components';
import TypographySkeleton from '../TypographySkeleton/TypographySkeleton';
import StatusConnected from '../StatusConnected/StatusConnected';
import StatusDisconnected from '../StatusDisconnected/StatusDisconnected';

export interface CssProps {
  firstLineOpacity?: string;
  firstLineColor?: keyof Colors;
  secondLineColor?: keyof Colors;
  flex?: string;
  isLoading?: boolean;
}

export type Props = CssProps & {
  status: 'connected' | 'disconnected';
  id?: string;
};

const TableCellWrapper = styled.div<Partial<CssProps>>`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${({ flex }) => flex && `flex: ${flex}`};
`;

const TableCellStatus: React.FC<Props> = ({ status, flex = 'auto', id, isLoading = false }) => (
  <TableCellWrapper flex={flex} id={id}>
    {isLoading ? (
      <TypographySkeleton variant="body3">test</TypographySkeleton>
    ) : (
      <>{status === 'connected' ? <StatusConnected /> : <StatusDisconnected />}</>
    )}
  </TableCellWrapper>
);

export default TableCellStatus;
