import { User } from 'state/types';
import { authVar } from 'state/vars';

export const authenticationFailAction = () => {
  authVar({ loading: false, user: null });
};
export const authenticationSuccessAction = (user: User) => {
  authVar({ loading: false, user });
};
export const signOutAction = () => {
  authVar({ loading: false, user: null });
};

export const disconnectDeviceAction = () => {
  const currentUser = authVar();
  const mobileDevice = currentUser.user?.mobileDevices?.find((device) => device.role === 'ACCESS');
  if (currentUser.user && currentUser.user.mobileDevices && mobileDevice) {
    const currentUserUser = {
      ...currentUser,
      user: {
        ...currentUser.user,
        mobileDevices: [
          ...currentUser.user.mobileDevices.map((device) =>
            device.role === 'ACCESS'
              ? {
                  ...device,
                  isRegistered: false,
                  registrationToken: null,
                  registrationDate: null
                }
              : device
          )
        ]
      }
    };
    authVar(currentUserUser);
  }
};
