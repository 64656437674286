/* eslint-disable import/prefer-default-export */
import {
  GetLocationsWithDoorsForTenant_getLocationsForTenant,
  GetLocationsWithDoorsForTenant_getLocationsForTenant_doors
} from 'graphql/generated/GetLocationsWithDoorsForTenant';
import { LocationType } from 'graphql/generated/globalTypes';

interface Props {
  id: string;
  name?: string;
  externalRef?: string | null;
  type?: LocationType;
  doors?: GetLocationsWithDoorsForTenant_getLocationsForTenant_doors[];
}

export function createLocationWithDoors({
  id,
  name = 'mockedLocationWithDoorsName',
  externalRef = 'mockedLocationWithDoorsExternalRef',
  doors = [],
  type = LocationType.MANUAL
}: Props): GetLocationsWithDoorsForTenant_getLocationsForTenant {
  return {
    __typename: 'Location',
    id,
    name,
    externalRef,
    doors,
    type
  };
}
