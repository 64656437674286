import React from 'react';
import Typography from 'ui/atoms/Typography/Typography';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Button from 'ui/atoms/Button/Button';
import { useMsal } from '@azure/msal-react';
import useTranslation from 'hooks/useTranslation/useTranslation';

const MobileNotSupported: React.FC = () => {
  const { translation } = useTranslation();
  const { instance, accounts } = useMsal();

  const handleLogout = async () => {
    await instance.logout({ account: accounts[0], postLogoutRedirectUri: process.env.REACT_APP_SSO_REDIRECT_URI });
  };

  return (
    <ComponentWrapper flexDirection="column">
      <BackgroundImage id="background" />
      <ComponentWrapper
        width="100vw"
        height="90vh"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="1rem"
      >
        <Typography color="primary" variant="title1">
          ATLAS
        </Typography>
        <ComponentWrapper
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="4rem 1.5rem"
          margin="0 auto"
          backgroundColor="bgLight"
          gap="1.5rem"
        >
          <ComponentWrapper flexDirection="column" alignItems="center">
            <Typography color="dTextHigh" variant="title4">
              {translation.this_screen_size_is_not_supported_yet_for_this_page}
            </Typography>
          </ComponentWrapper>
          <ComponentWrapper flexDirection="column" alignItems="center">
            <Typography color="dTextHigh" variant="body4">
              {translation.please_switch_to_your_desktop_or_laptop_to_use_the_atlas_web_portal}
            </Typography>
          </ComponentWrapper>
        </ComponentWrapper>
      </ComponentWrapper>
      <ComponentWrapper alignSelf="center" width="15rem" justifyContent="center" alignItems="center">
        <Button onClick={handleLogout}>{translation.logout}</Button>
      </ComponentWrapper>
    </ComponentWrapper>
  );
};

export default MobileNotSupported;
