/* eslint-disable import/prefer-default-export */
/* eslint-disable no-magic-numbers */
import dayjs from 'dayjs';
import { OptionProps } from 'ui/molecules/Select/Select';
import utc from 'dayjs/plugin/utc';
import { Translation } from 'hooks/useTranslation/useTranslation';

dayjs.extend(utc);

export interface DateTimeSelectItem {
  value: number;
  unit: dayjs.ManipulateType;
}

export const createDataTimeSelectOptions = (
  translation: Translation
): Array<OptionProps<null | DateTimeSelectItem>> => [
  {
    value: null,
    label: translation.custom_range
  },
  {
    value: { value: 15, unit: 'minutes' },
    label: `${translation.last} 15 ${translation.minutes}`
  },
  {
    value: { value: 1, unit: 'hour' },
    label: `${translation.last} 1 ${translation.hour}`
  },
  {
    value: { value: 6, unit: 'hour' },
    label: `${translation.last} 6 ${translation.hour}`
  },
  {
    value: { value: 12, unit: 'hour' },
    label: `${translation.last} 12 ${translation.hour}`
  },
  {
    value: { value: 24, unit: 'hour' },
    label: `${translation.last} 24 ${translation.hour}`
  },
  {
    value: { value: 48, unit: 'hour' },
    label: `${translation.last} 48 ${translation.hour}`
  },
  {
    value: { value: 72, unit: 'hour' },
    label: `${translation.last} 72 ${translation.hour}`
  }
];
