import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export const loginRequest = {
  scopes: [`${process.env.REACT_APP_SSO_CLIENT_ID}/.default`]
};

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_SSO_CLIENT_ID as string,
    authority: process.env.REACT_APP_SSO_AUTHORITY as string,
    redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI as string
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
};

export const getMsalInstance = () => new PublicClientApplication(msalConfig);
