import { GetOfficeDays_getOfficeDays } from 'graphql/generated/GetOfficeDays';

const Range = (length: number, startIndex: number = 0) =>
  Array.from(new Array(length)).map((_, index) => index + startIndex);

export interface SkeletonArrayType {
  id: number;
}

// eslint-disable-next-line no-magic-numbers, @typescript-eslint/no-unsafe-return
const SkeletonArray = (length: number = 5): SkeletonArrayType[] =>
  Array.from(new Array(length)).map((_, index) => ({ id: index }));

export const getUniqueArray = <T extends GetOfficeDays_getOfficeDays>(prevItems: T[], nextItems: T[]) => {
  const allVisits = [...prevItems, ...nextItems];
  const result: T[] = [];
  const map = new Map();

  allVisits.forEach((visit) => {
    if (!map.has(visit)) {
      map.set(visit, true);
      result.push(visit);
    }
  });

  return result;
};

export default { Range, SkeletonArray };
