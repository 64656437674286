import React from 'react';
import { Colors } from 'theme/theme';
import styled from 'styled-components';
import ComponentWrapper, { WidthOrHeight } from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Typography from '../Typography/Typography';
import TypographySkeleton from '../TypographySkeleton/TypographySkeleton';
import Icon, { Props as IconProps } from '../Icon/Icon';

export interface CssProps {
  firstLineOpacity?: string;
  firstLineColor?: keyof Colors;
  secondLineColor?: keyof Colors;
  flex?: string;
  isLoading?: boolean;
  width?: WidthOrHeight;
  isRowWithIconAndSwitch?: boolean;
}

export type Props = CssProps & {
  firstLineText: string;
  secondLineText?: string;
  id?: string;
  firstLineId?: string;
  secondLineId?: string;
  beforeContentIconProps?: Pick<IconProps, 'name' | 'color' | 'fill' | 'viewBox' | 'width' | 'height' | 'id'>;
};

const TableCellWrapper = styled.div<Partial<CssProps>>`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ isRowWithIconAndSwitch }) => isRowWithIconAndSwitch && `width: 0;`};
  ${({ width }) => width && `width:${width};`};
`;

const TableCell: React.FC<Props> = ({
  firstLineText,
  secondLineText,
  firstLineOpacity,
  firstLineColor = 'lTextHigh',
  secondLineColor = 'lTextMedium',
  isRowWithIconAndSwitch = false,
  flex = '1',
  width,
  id,
  firstLineId,
  secondLineId,
  isLoading = false,
  beforeContentIconProps
}) => (
  <TableCellWrapper isRowWithIconAndSwitch={isRowWithIconAndSwitch} flex={flex} width={width} id={id}>
    {isLoading ? (
      <TypographySkeleton variant="body3">{firstLineText}</TypographySkeleton>
    ) : (
      <>
        {beforeContentIconProps ? (
          <ComponentWrapper flexDirection="row" alignItems="center">
            <ComponentWrapper margin="0 0.25rem 0 0">
              <Icon {...beforeContentIconProps} />
            </ComponentWrapper>
            <Typography
              whiteSpace="nowrap"
              variant="body3"
              opacity={firstLineOpacity}
              color={firstLineColor}
              id={firstLineId}
            >
              {firstLineText}
            </Typography>
          </ComponentWrapper>
        ) : (
          <Typography
            whiteSpace="nowrap"
            variant="body3"
            opacity={firstLineOpacity}
            color={firstLineColor}
            id={firstLineId}
          >
            {firstLineText}
          </Typography>
        )}
        {secondLineText && (
          <Typography whiteSpace="nowrap" variant="body4" color={secondLineColor} id={secondLineId}>
            {secondLineText}
          </Typography>
        )}
      </>
    )}
  </TableCellWrapper>
);

export default TableCell;
