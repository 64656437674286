import React from 'react';

const ScheduledAccess: React.FC = () => (
  <>
    <path d="M8.19365 9.06111V8.62742C8.19365 7.93729 8.46781 7.27542 8.95581 6.78742C9.4438 6.29942 10.1057 6.02527 10.7958 6.02527C11.4859 6.02527 12.1478 6.29942 12.6358 6.78742C13.1238 7.27542 13.398 7.93729 13.398 8.62742V9.06111H14.2653C14.3804 9.06111 14.4907 9.1068 14.572 9.18814C14.6533 9.26947 14.699 9.37978 14.699 9.4948V14.6991C14.699 14.8141 14.6533 14.9244 14.572 15.0058C14.4907 15.0871 14.3804 15.1328 14.2653 15.1328H7.32627C7.21125 15.1328 7.10094 15.0871 7.0196 15.0058C6.93827 14.9244 6.89258 14.8141 6.89258 14.6991V9.4948C6.89258 9.37978 6.93827 9.26947 7.0196 9.18814C7.10094 9.1068 7.21125 9.06111 7.32627 9.06111H8.19365ZM13.8316 9.92849H7.75996V14.2654H13.8316V9.92849ZM10.3621 12.4144C10.1968 12.3189 10.0675 12.1716 9.99445 11.9952C9.92138 11.8188 9.90856 11.6232 9.95798 11.4387C10.0074 11.2543 10.1163 11.0913 10.2678 10.9751C10.4193 10.8589 10.6049 10.7959 10.7958 10.7959C10.9867 10.7959 11.1723 10.8589 11.3238 10.9751C11.4753 11.0913 11.5842 11.2543 11.6336 11.4387C11.6831 11.6232 11.6702 11.8188 11.5972 11.9952C11.5241 12.1716 11.3949 12.3189 11.2295 12.4144V13.398H10.3621V12.4144ZM9.06104 9.06111H12.5306V8.62742C12.5306 8.16733 12.3478 7.72609 12.0225 7.40075C11.6971 7.07542 11.2559 6.89265 10.7958 6.89265C10.3357 6.89265 9.89447 7.07542 9.56914 7.40075C9.24381 7.72609 9.06104 8.16733 9.06104 8.62742V9.06111Z" />
    <path d="M4.55131 0.121744C3.70133 0.294765 2.51252 0.893319 1.86366 1.47477C1.17428 2.09247 0.521376 3.07877 0.229763 3.94268C0.0278708 4.54106 0 4.75749 0 5.72991C0 6.706 0.0274408 6.91783 0.233118 7.5283C0.645075 8.75102 1.53101 9.8734 2.62925 10.5641C3.20095 10.9236 4.12043 11.29 4.62125 11.3579C4.95295 11.4029 5.01832 11.3844 5.1415 11.2103C5.41798 10.8199 5.25118 10.586 4.57221 10.412C3.03105 10.0168 1.7551 8.89042 1.18477 7.42157C1.00714 6.96404 0.948301 6.63562 0.915269 5.91715C0.876731 5.08055 0.894279 4.93315 1.10994 4.27706C1.90452 1.86047 4.40163 0.467617 6.89626 1.04957C7.5938 1.21221 8.49385 1.65655 8.40955 1.79664C8.37531 1.85357 8.27011 2.00498 8.17583 2.13315C8.08155 2.26123 8.02753 2.38906 8.05583 2.41706C8.08413 2.44506 8.49996 2.44038 8.97978 2.40677C10.0088 2.3346 10.1264 2.26404 10.0005 1.79434C9.7514 0.864127 9.56817 0.325659 9.5009 0.325659C9.45944 0.325659 9.34185 0.459702 9.23948 0.623531C9.13712 0.787361 9.04095 0.921404 9.02563 0.921404C9.01041 0.921404 8.67484 0.77 8.28 0.585063C7.06804 0.0172336 5.82391 -0.137405 4.55131 0.121744ZM5.44611 3.22762C5.28163 3.39034 5.27002 3.47179 5.31535 4.14251C5.34271 4.54753 5.3895 5.42345 5.41935 6.08906C5.47682 7.37162 5.55845 7.61213 5.87036 7.41817C6.01987 7.32515 6.03854 7.14549 6.17565 4.47587C6.2277 3.46268 6.22004 3.38089 6.05798 3.22055C5.8369 3.00183 5.67243 3.0037 5.44611 3.22762ZM5.544 7.76838C5.31621 8.03987 5.6523 8.46668 5.92043 8.24651C6.08224 8.11357 6.09712 7.88906 5.95346 7.74693C5.81557 7.61051 5.67002 7.61808 5.544 7.76838Z" />
  </>
);

export default ScheduledAccess;
