import React, { useState } from 'react';
import styled from 'styled-components';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { Colors } from 'theme/theme';
import Typography from '../Typography/Typography';

export interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
}

export interface ColorProps {
  isFocused?: boolean;
  isHovered?: boolean;
  isChecked?: boolean;
  isDisabled?: boolean;
}
const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  position: absolute;
`;

const handleBorderColor = ({
  isFocused = false,
  isHovered = false,
  isChecked = false,
  isDisabled = false
}: ColorProps): keyof Colors => {
  if ((isFocused && !isDisabled) || (isFocused && isChecked)) return 'primary';
  if (!isDisabled || isHovered || isChecked) return 'dTextHigh';
  if (isDisabled) return 'dTextMedium';
  return 'transparent';
};

const handleTextColor = ({
  isFocused = false,
  isHovered = false,
  isChecked = false,
  isDisabled = false
}: ColorProps): keyof Colors => {
  if (isHovered || (isFocused && !isDisabled) || (isFocused && isChecked)) return 'primary';
  if (!isDisabled || isChecked) return 'dTextHigh';
  if (isDisabled) return 'dTextMedium';
  return 'transparent';
};
const StyledRadioButton = styled.span<Pick<Props & ColorProps, 'isFocused' | 'isHovered' | 'checked' | 'disabled'>>`
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 8px;
  cursor: pointer;

  ${HiddenRadioButton}:hover + & {
    border-color: ${({ theme, isHovered }) => theme.colors[handleBorderColor({ isHovered })]};
  }
  ${HiddenRadioButton}:focus :enabled + & {
    border-color: ${({ theme, isFocused }) => theme.colors[handleBorderColor({ isFocused, isDisabled: false })]};
  }
  ${HiddenRadioButton}::checked + & {
    border-color: ${({ theme }) => theme.colors[handleBorderColor({ isChecked: true })]};
  }
  ${HiddenRadioButton}:focus ::checked + & {
    border-color: ${({ theme, isFocused }) => theme.colors[handleBorderColor({ isFocused, isChecked: true })]};
  }
  ${HiddenRadioButton}:disabled + & {
    border-color: ${({ theme }) => theme.colors[handleBorderColor({ isDisabled: true })]};
  }
  ${HiddenRadioButton}:enabled + & {
    border-color: ${({ theme }) => theme.colors[handleBorderColor({ isDisabled: false })]};
  }

  ${HiddenRadioButton}:checked + &::after {
    content: '';
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.colors.primary};
  }

  ${HiddenRadioButton}:focus + & {
    outline: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

const Radio: React.FC<Props> = ({ checked, defaultChecked, disabled, label, onClick, onChange, id }, props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    // @ts-ignore
    <ComponentWrapper onClick={onClick} id={id}>
      <HiddenRadioButton
        {...props}
        onChange={onChange}
        defaultChecked={defaultChecked}
        checked={checked}
        type="radio"
      />
      <StyledRadioButton
        {...props}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isFocused={isFocused}
        isHovered={isHovered}
        isChecked={checked}
        isDisabled={disabled}
      />
      {label && (
        <Typography
          variant="body2"
          color={handleTextColor({
            isHovered,
            isChecked: checked,
            isDisabled: disabled,
            isFocused
          })}
        >
          {label}
        </Typography>
      )}
    </ComponentWrapper>
  );
};

export default Radio;
