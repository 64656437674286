import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import InvalidOTR from 'ui/templates/InvalidOTR/InvalidOTR';
import { tenantsServiceTokenVar } from 'state/vars';
import SyncingUsers from 'ui/templates/ConnectEntraID/SyncingUsers';
import AlmostReady from 'ui/templates/ConnectEntraID/AlmostReady';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useTenantRegistrationInfo from 'hooks/useTenantRegistrationInfo/useTenantRegistrationInfo';
import useTenantName from 'hooks/useTenantName/useTenantName';

const SyncingUsersAndFinishingPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('state');
  const azureTenantId = searchParams.get('tenant');
  tenantsServiceTokenVar(id);
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { registerAzureTenant, registerAzureTenantLoading } = useTenantRegistrationInfo({
    handleFetchError
  });
  const { tenantName } = useTenantName({
    handleFetchError,
    azureTenantId
  });
  const [isOTRValid, setIsOTRValid] = useState(false);
  const [isSynchronizingStarted, setIsSynchronizingStarted] = useState(false);

  const handleStartButtonOnClick = async () => {
    if (!registerAzureTenantLoading && azureTenantId) {
      try {
        await registerAzureTenant({ azureTenantId });
      } catch (err: unknown) {
        enqueueSnackbar(`Error while register Azure Tenant`, { snackbartype: 'error' });
      } finally {
        setIsSynchronizingStarted(true);
      }
    }
  };

  const handleGoToAtlasWebPortalButtonClick = () => {
    window.open('/permissions/permissions', '_blank');
  };

  useEffect(() => {
    if (id === '' || id === undefined || id === null) setIsOTRValid(false);
    else setIsOTRValid(true);
  }, [id]);

  if (!isOTRValid) return <InvalidOTR />;

  if (isSynchronizingStarted)
    return <AlmostReady onGoToAtlasWebPortalButtonClick={handleGoToAtlasWebPortalButtonClick} />;
  return <SyncingUsers organizationName={tenantName} onStartButtonClick={handleStartButtonOnClick} />;
};

export default SyncingUsersAndFinishingPage;
