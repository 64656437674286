/* eslint-disable import/prefer-default-export */
import { TabsType } from 'components/Tabs/Tabs';
import { Translation } from 'hooks/useTranslation/useTranslation';

export const createPermissionsTabs = (translation: Translation): TabsType[] => [
  {
    text: translation.permissions,
    location: '/permissions/permissions'
  }
];
