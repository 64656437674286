import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { GET_TENANT_REGISTRATION_INFO } from 'graphql/queries';
import { tenantRegistrationInfoVar } from 'state/vars';
import { GetTenantRegistrationInfo } from 'graphql/generated/GetTenantRegistrationInfo';
import { useEffect } from 'react';
import { RegisterAzureTenant, RegisterAzureTenantVariables } from 'graphql/generated/RegisterAzureTenant';
import { REGISTER_AZURE_TENANT } from 'graphql/mutations';
import { RegisterAzureTenantInput } from 'graphql/generated/globalTypes';
import { setTenantRegistrationInfoAction } from 'state/actions/tenantRegistrationInfo';
import { BaseHookProps } from '../shared/types';

const useTenantRegistrationInfo = ({ handleFetchError }: BaseHookProps) => {
  const tenantRegistrationInfo = useReactiveVar(tenantRegistrationInfoVar);

  const [getTenantRegistrationInfo, { loading }] = useLazyQuery<GetTenantRegistrationInfo>(
    GET_TENANT_REGISTRATION_INFO,
    {
      onCompleted: (data) => {
        setTenantRegistrationInfoAction(data.getTenantRegistrationInfo);
      },
      onError: () => {
        handleFetchError('Error while fetching tenant registration info');
      }
    }
  );

  useEffect(() => {
    getTenantRegistrationInfo();
  }, [getTenantRegistrationInfo]);

  const [registerAzureTenantMutation, { loading: registerAzureTenantLoading }] = useMutation<
    RegisterAzureTenant,
    RegisterAzureTenantVariables
  >(REGISTER_AZURE_TENANT);

  const registerAzureTenant = async (input: RegisterAzureTenantInput) => {
    await registerAzureTenantMutation({
      variables: {
        input
      }
    });
  };

  return {
    tenantRegistrationInfo,
    loading,
    registerAzureTenant,
    registerAzureTenantLoading
  };
};

export default useTenantRegistrationInfo;
