import React from 'react';
import styled from 'styled-components';

export interface Props {
  id?: string;
}

const StyledModalBackground = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  opacity: 1;
`;

const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.bgLight};
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
  border: 1px solid #e7e9de;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 1500;
`;

const Modal: React.FC<Props> = ({ id, children }) => (
  <>
    <StyledModalBackground />
    <StyledModal id={id}>{children}</StyledModal>
  </>
);

export default Modal;
