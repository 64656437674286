/* eslint-disable import/prefer-default-export */
import {
  ADD_OFFICE_DAY,
  ASSIGN_ACCESS_GRANTS_TO_DOORS,
  DELETE_DOOR_BY_ID,
  DELETE_VISITOR_BY_ID,
  DELETE_VISITOR_GROUP_BY_ID,
  DELETE_OFFICE_DAY,
  SET_ACCESS_GRANTS_FOR_GROUPS,
  UNASSIGN_ACCESS_GRANTS_FROM_DOORS,
  UNREGISTER_MOBILE_DEVICE_BY_ID,
  UPDATE_ACCESS_GRANT,
  UPDATE_DOOR,
  SET_INSTALLER,
  UNSET_INSTALLER_BY_ID,
  UPDATE_DOOR_WITH_OFFICE_MODE,
  SET_IS_ADMIN_FOR_GROUP,
  UPDATE_ACCESS_GRANT_WITH_IS_OFFICE_MODE_ENABLED,
  ADD_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  UPDATE_DOOR_WITH_LOCATIONS,
  ADD_VISITOR_WITH_ROLE,
  UNASSIGN_TO_ACCESS_GRANTS,
  ASSIGN_TO_ACCESS_GRANTS,
  SET_VISITOR_BY_ID,
  ADD_VISITOR_GROUP_WITH_RETAIL,
  UPDATE_VISITOR_GROUP_WITH_RETAIL,
  SET_VISITOR_BY_ID_WITH_ROLE,
  ACCEPT_LEGAL_DOCUMENTS
} from 'graphql/mutations';
import { UpdateDoorWithLocationsVariables } from 'graphql/generated/UpdateDoorWithLocations';
import { LegalDocumentType, LocationInput, LocationType, Role } from 'graphql/generated/globalTypes';
import { mockedAcceptLegalDocumentsDataForMutation1 } from 'mocks/graphqlApollo/mutation/acceptLegalDocuments';
import {
  mockedDeleteDoorByIdDataForMutation,
  mockedDeleteDoorByIdDataForMutation1
} from '../graphqlApollo/mutation/deleteDoorById';
import { mockedUnregisterMobileDeviceDataForMutation } from '../graphqlApollo/mutation/unregisterMobileDevice';
import {
  mockedUpdateDoorDataForMutation,
  mockedUpdateDoorWithOfficeModeDataForMutation
} from '../graphqlApollo/mutation/updateDoor';
import { mockedAssignAccessGrantsToDoorsDataForMutation } from '../graphqlApollo/mutation/assignAccessGrantsToDoors';
import { mockedUnassignAccessGrantsToDoorsDataForMutation } from '../graphqlApollo/mutation/unassignAccessGrantsToDoors';
import { mockedDeleteVisitorByIdDataForMutation } from '../graphqlApollo/mutation/deleteVisitorById';
import {
  mockedSetVisitorDataForMutation,
  mockedUpdateInstallerDataForMutation
} from '../graphqlApollo/mutation/setVisitorById';
import {
  mockedSetAccessGrantsForGroupsDataForMutation0,
  mockedSetAccessGrantsForGroupsDataForMutation1
} from '../graphqlApollo/mutation/setAccessGrantsForGroups';
import {
  mockedUpdateAccessGrantForMutation1,
  mockedUpdateAccessGrantForMutation2
} from '../graphqlApollo/mutation/updateAccessGrant';
import { mockedUpdateVisitorGroupDataForMutation } from '../graphqlApollo/mutation/updateVisitorGroup';
import { mockedAddVisitorGroupDataForMutation } from '../graphqlApollo/mutation/addVisitorGroupCoContractor';
import { mockedDeleteVisitorGroupDataForMutation } from '../graphqlApollo/mutation/deleteVisitorGroup';
import { mockedAddOfficeDayDataForMutation } from '../graphqlApollo/mutation/addOfficeDay';
import { mockedDeleteOfficeDayDataForMutation } from '../graphqlApollo/mutation/removeVisit';
import { mockedUnsetInstallerDataDataForMutation } from '../graphqlApollo/mutation/unsetInstallerById';
import { mockedSetInstallerDataForMutation } from '../graphqlApollo/mutation/setInstaller';
import { mockedSetIsAdminForGroupDataForMutation } from '../graphqlApollo/mutation/setIsAdminForGroup';
import { mockedUpdateAccessGrantWithIsOfficeModeForMutation1 } from '../graphqlApollo/mutation/updateAccessGrantWithIsOfficeModeEnabled';
import { mockedUpdateLocationWithDoorsDataForMutation } from '../graphqlApollo/mutation/updateLocationWithDoors';
import { mockedAddLocationWithDoorsDataForMutation } from '../graphqlApollo/mutation/addLocationWithDoors';
import { mockedDeleteLocationDataForMutation } from '../graphqlApollo/mutation/deleteLocation';
import {
  mockedAssignToAccessGrantsDataForMutation,
  mockedAssignToAccessGrantsDataForMutation1,
  mockedAssignToAccessGrantsDataForMutation2
} from '../graphqlApollo/mutation/assignToAccessGrants';
import { mockedAddVisitorWithRoleDataForMutation } from '../graphqlApollo/mutation/addVisitorWithRole';
import { mockedUpdateDoorWithLocationsDataForMutation } from '../graphqlApollo/mutation/updateDoorWithLocations';
import {
  mockedUnassignToAccessGrantsDataForMutation,
  mockedUnassignToAccessGrantsDataForMutation1,
  mockedUnassignToAccessGrantsDataForMutation2
} from '../graphqlApollo/mutation/unassignToAccessGrants';
import { mockedSetVisitorWithRoleDataForMutation } from '../graphqlApollo/mutation/setVisitorByIdWithRole';

export const mutations = [
  {
    request: {
      query: ADD_OFFICE_DAY,
      variables: {
        input: { date: '2020-03-05' }
      }
    },
    newData: () => ({
      data: mockedAddOfficeDayDataForMutation
    })
  },
  {
    request: {
      query: DELETE_OFFICE_DAY,
      variables: { date: '2020-03-05' }
    },
    newData: () => ({
      data: mockedDeleteOfficeDayDataForMutation
    })
  },
  {
    request: {
      query: UNREGISTER_MOBILE_DEVICE_BY_ID,
      variables: { visitorId: 'mockedAutomatedTestsUIAdminVisitorId', mobileDeviceId: 'mockedMobileDevice' }
    },
    newData: () => ({
      data: mockedUnregisterMobileDeviceDataForMutation
    })
  },
  {
    request: {
      query: UPDATE_DOOR,
      variables: { doorId: 'mockedDoorId0', name: 'mockedDoorNameUpdated', locationId: 'mockedLocationId' }
    },
    newData: () => ({
      data: {
        updateDoor: mockedUpdateDoorDataForMutation.updateDoor
      }
    })
  },
  {
    request: {
      query: UPDATE_DOOR_WITH_OFFICE_MODE,
      variables: {
        doorId: 'mockedDoorId0',
        name: 'mockedDoorNameUpdated',
        locationId: 'mockedLocationId',
        officeMode: { scheduleType: 'weekSchedule', weekSchedule: [] }
      }
    },
    newData: () => ({
      data: {
        updateDoor: mockedUpdateDoorWithOfficeModeDataForMutation.updateDoor
      }
    })
  },
  {
    request: {
      query: DELETE_DOOR_BY_ID,
      variables: { doorId: 'mockedDoorId0' }
    },
    newData: () => ({
      data: mockedDeleteDoorByIdDataForMutation
    })
  },
  {
    request: {
      query: ASSIGN_ACCESS_GRANTS_TO_DOORS,
      variables: { doorIds: ['mockedDoorId0'], accessGrantIds: ['mockedAccessGrantId'] }
    },
    newData: () => ({
      data: mockedAssignAccessGrantsToDoorsDataForMutation
    })
  },
  {
    request: {
      query: UNASSIGN_ACCESS_GRANTS_FROM_DOORS,
      variables: { doorIds: ['mockedDoorId0'], accessGrantIds: ['mockedAccessGrantId'] }
    },
    newData: () => ({
      data: mockedUnassignAccessGrantsToDoorsDataForMutation
    })
  },
  {
    request: {
      query: SET_ACCESS_GRANTS_FOR_GROUPS,
      variables: {
        visitorGroupId: 'mockedVisitorGroupId0',
        defaultAccessGrantIds: [],
        scheduledAccessGrantIds: ['mockedAccessGrantId0']
      }
    },
    newData: () => ({
      data: mockedSetAccessGrantsForGroupsDataForMutation0
    })
  },
  {
    request: {
      query: SET_ACCESS_GRANTS_FOR_GROUPS,
      variables: {
        visitorGroupId: 'mockedVisitorGroupId1',
        defaultAccessGrantIds: ['mockedAccessGrantId1'],
        scheduledAccessGrantIds: []
      }
    },
    newData: () => ({
      data: mockedSetAccessGrantsForGroupsDataForMutation1
    })
  },
  {
    request: {
      query: UNSET_INSTALLER_BY_ID,
      variables: { visitorId: 'mockedVisitorIdInstaller1' }
    },
    newData: () => ({
      data: mockedUnsetInstallerDataDataForMutation
    })
  },
  {
    request: {
      query: DELETE_VISITOR_BY_ID,
      variables: { visitorId: 'mockedVisitorIdExternalUser1' }
    },
    newData: () => ({
      data: mockedDeleteVisitorByIdDataForMutation
    })
  },
  {
    request: {
      query: SET_VISITOR_BY_ID_WITH_ROLE,
      variables: {
        visitorId: 'mockedExternalUserId',
        name: 'mockedExternalUserName',
        visitorGroupExternalRefs: ['mockedVisitorGroupExternalRefs']
      }
    },
    newData: () => ({
      data: mockedSetVisitorDataForMutation
    })
  },
  {
    request: {
      query: SET_VISITOR_BY_ID_WITH_ROLE,
      variables: {
        visitorId: 'mockedVisitorIdExternalUser1',
        name: 'mockedVisitorNameExternalUser1AfterUpdate',
        visitorGroupExternalRefs: ['mockedVisitorGroupExternalRefs']
      }
    },
    newData: () => ({
      data: mockedSetVisitorDataForMutation
    })
  },
  {
    request: {
      query: SET_VISITOR_BY_ID_WITH_ROLE,
      variables: {
        visitorId: 'mockedVisitorIdExternalUser6',
        name: 'mockedVisitorEmailExternalUser6Edited',
        visitorGroupMembership: [
          { role: 'EXTERNAL_GROUP_USER', visitorGroupExternalRef: 'mockedVisitorGroupExternalRefs2' }
        ]
      }
    },
    newData: () => ({
      data: mockedSetVisitorWithRoleDataForMutation
    })
  },
  {
    request: {
      query: SET_VISITOR_BY_ID_WITH_ROLE,
      variables: {
        visitorId: 'mockedVisitorIdExternalUser6',
        name: 'mockedVisitorEmailExternalUser6Edited',
        visitorGroupMembership: [
          { role: Role.EXTERNAL_GROUP_USER, visitorGroupExternalRef: 'mockedVisitorGroupExternalRefs3' }
        ]
      }
    },
    newData: () => ({
      data: mockedSetVisitorWithRoleDataForMutation
    })
  },
  {
    request: {
      query: SET_VISITOR_BY_ID,
      variables: {
        visitorId: 'mockedVisitorIdInstaller1',
        name: 'mockedVisitorNameInstaller1Updated',
        visitorGroupExternalRefs: ['mockedVisitorGroupExternalRefsInstallers']
      }
    },
    newData: () => ({
      data: mockedUpdateInstallerDataForMutation
    })
  },
  {
    request: {
      query: SET_INSTALLER,
      variables: {
        email: 'mockedInstallerEmailNew',
        name: 'mockedInstallerNameNew'
      }
    },
    newData: () => ({
      data: mockedSetInstallerDataForMutation
    })
  },
  {
    request: {
      query: UPDATE_ACCESS_GRANT,
      variables: {
        accessGrantId: 'mockedAccessGrantWithDays',
        input: {
          scheduleType: 'weekSchedule',
          weekSchedule: [
            {
              dayName: 'monday',
              from: '08:00:00',
              to: '21:00:00'
            }
          ]
        }
      }
    },
    newData: () => ({
      data: mockedUpdateAccessGrantForMutation1
    })
  },
  {
    request: {
      query: UPDATE_ACCESS_GRANT,
      variables: {
        accessGrantId: 'mockedAccessGrantWithDays5',
        input: {
          scheduleType: 'weekSchedule',
          weekSchedule: [
            {
              dayName: 'sunday',
              from: '08:00:00',
              to: '21:00:00'
            },
            {
              dayName: 'saturday',
              from: '08:00:00',
              to: '21:00:00'
            }
          ]
        }
      }
    },
    newData: () => ({
      data: mockedUpdateAccessGrantForMutation2
    })
  },
  {
    request: {
      query: ADD_VISITOR_GROUP_WITH_RETAIL,
      variables: { name: 'co-contractors-new', maxNumberOfMembers: 2, type: 'CO_CONTRACTORS' }
    },
    newData: () => ({
      data: mockedAddVisitorGroupDataForMutation
    })
  },
  {
    request: {
      query: UPDATE_VISITOR_GROUP_WITH_RETAIL,
      variables: {
        name: 'co-contractors1',
        visitorGroupId: 'mockedVisitorGroupIdExternalUsers1',
        maxNumberOfMembers: 3
      }
    },
    newData: () => ({
      data: mockedUpdateVisitorGroupDataForMutation
    })
  },
  {
    request: {
      query: ADD_VISITOR_WITH_ROLE,
      variables: {
        email: 'mockedExternalUserEmail',
        name: 'mockedExternalUserName',
        visitorGroupMembership: [
          {
            role: Role.EXTERNAL_GROUP_USER,
            visitorGroupExternalRef: 'mockedVisitorGroupExternalRefs3'
          }
        ]
      }
    },
    newData: () => ({
      data: mockedAddVisitorWithRoleDataForMutation
    })
  },
  {
    request: {
      query: DELETE_VISITOR_GROUP_BY_ID,
      variables: {
        visitorGroupId: 'mockedVisitorGroupId1',
        withVisitors: true
      }
    },
    newData: () => ({
      data: mockedDeleteVisitorGroupDataForMutation
    })
  },
  {
    request: {
      query: SET_IS_ADMIN_FOR_GROUP,
      variables: {
        externalRef: 'mockedVisitorGroupExternalRef1',
        isAdmin: true
      }
    },
    newData: () => ({
      data: mockedSetIsAdminForGroupDataForMutation
    })
  },
  {
    request: {
      query: UPDATE_ACCESS_GRANT_WITH_IS_OFFICE_MODE_ENABLED,
      variables: {
        accessGrantId: 'mockedAccessGrantId1',
        isOfficeModeEnabled: false
      }
    },
    newData: () => ({
      data: mockedUpdateAccessGrantWithIsOfficeModeForMutation1
    })
  },
  {
    request: {
      query: DELETE_DOOR_BY_ID,
      variables: { doorId: 'mockedDoorId1' }
    },
    newData: () => ({
      data: mockedDeleteDoorByIdDataForMutation1
    })
  },
  {
    request: {
      query: ADD_LOCATION,
      variables: { location: { doorIds: [], name: 'mockedLocationName', type: LocationType.MANUAL } as LocationInput }
    },
    newData: () => ({
      data: mockedAddLocationWithDoorsDataForMutation
    })
  },
  {
    request: {
      query: UPDATE_LOCATION,
      variables: {
        id: 'mockedLocationId0',
        location: { doorIds: [], name: 'mockedUpdatedLocationName', type: LocationType.MANUAL } as LocationInput
      }
    },
    newData: () => ({
      data: mockedUpdateLocationWithDoorsDataForMutation
    })
  },
  {
    request: {
      query: DELETE_LOCATION,
      variables: { id: 'mockedAddedLocationId' }
    },
    newData: () => ({
      data: mockedDeleteLocationDataForMutation
    })
  },
  {
    request: {
      query: UPDATE_DOOR_WITH_LOCATIONS,
      variables: {
        doorId: 'mockedDoorId0',
        externalLocationId: undefined,
        manualLocationIds: undefined,
        name: 'mockedDoorNameUpdated',
        officeMode: { scheduleType: 'weekSchedule', weekSchedule: [] }
      } as UpdateDoorWithLocationsVariables
    },
    newData: () => ({
      data: mockedUpdateDoorWithLocationsDataForMutation
    })
  },
  {
    request: {
      query: ASSIGN_TO_ACCESS_GRANTS,
      variables: {
        accessGrantIds: ['mockedAccessGrantId0'],
        doorIds: [],
        locationIds: ['mockedLocationId']
      }
    },
    newData: () => ({
      data: mockedAssignToAccessGrantsDataForMutation
    })
  },
  {
    request: {
      query: ASSIGN_TO_ACCESS_GRANTS,
      variables: {
        accessGrantIds: ['mockedAccessGrantId0'],
        doorIds: ['mockedDoorId0'],
        locationIds: []
      }
    },
    newData: () => ({
      data: mockedAssignToAccessGrantsDataForMutation1
    })
  },
  {
    request: {
      query: ASSIGN_TO_ACCESS_GRANTS,
      variables: {
        accessGrantIds: ['mockedAccessGrantId0', 'mockedAccessGrantId1', 'mockedAccessGrantId2'],
        doorIds: ['mockedDoorId0'],
        locationIds: []
      }
    },
    newData: () => ({
      data: mockedAssignToAccessGrantsDataForMutation2
    })
  },
  {
    request: {
      query: UNASSIGN_TO_ACCESS_GRANTS,
      variables: {
        accessGrantIds: ['mockedAccessGrantId0'],
        doorIds: [],
        locationIds: ['mockedLocationId']
      }
    },
    newData: () => ({
      data: mockedUnassignToAccessGrantsDataForMutation
    })
  },

  {
    request: {
      query: UNASSIGN_TO_ACCESS_GRANTS,
      variables: {
        accessGrantIds: ['mockedAccessGrantId0'],
        doorIds: ['mockedDoorId0'],
        locationIds: []
      }
    },
    newData: () => ({
      data: mockedUnassignToAccessGrantsDataForMutation1
    })
  },
  {
    request: {
      query: UNASSIGN_TO_ACCESS_GRANTS,
      variables: {
        accessGrantIds: ['mockedAccessGrantId0'],
        doorIds: [],
        locationIds: ['mockedLocationId']
      }
    },
    newData: () => ({
      data: mockedUnassignToAccessGrantsDataForMutation
    })
  },
  {
    request: {
      query: UNASSIGN_TO_ACCESS_GRANTS,
      variables: {
        accessGrantIds: ['mockedAccessGrantId0', 'mockedAccessGrantId1', 'mockedAccessGrantId2'],
        doorIds: ['mockedDoorId0'],
        locationIds: []
      }
    },
    newData: () => ({
      data: mockedUnassignToAccessGrantsDataForMutation2
    })
  },
  {
    request: {
      query: ACCEPT_LEGAL_DOCUMENTS,
      variables: {
        dpa: {
          documentType: LegalDocumentType.DPA,
          versionHash: 'DPAVersionHash',
          acceptedVersion: 'mockedCurrentVersion'
        },
        sla: {
          documentType: LegalDocumentType.SLA,
          versionHash: 'SLAVersionHash',
          acceptedVersion: 'mockedCurrentVersion'
        }
      }
    },
    newData: () => ({
      data: mockedAcceptLegalDocumentsDataForMutation1
    })
  },
  {
    request: {
      query: ACCEPT_LEGAL_DOCUMENTS,
      variables: {
        dpa: 'DPAid',
        sla: 'SLAid'
      }
    },
    newData: () => ({
      data: {}
    })
  }
];
