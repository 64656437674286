/* eslint-disable import/prefer-default-export */
import { GET_CURRENT_HOST, GET_AVAILABLE_OFFICE_DAYS, GET_OFFICE_DAYS } from 'graphql/queries';
import dayjs from 'dayjs';
import { getDateWithFormat } from 'utils/DateFormat/DateFormat.util';
import { mockedGetCurrentHostNonAdminDataForQuery } from '../graphqlApollo/query/getCurrentHost';
import { firstDate } from '../graphqlApollo/dateUsedInMocks';
import { mockedGetAvailableDaysDataForQuery } from '../graphqlApollo/query/getAvailableOfficeDays';
import { mockedGetOfficeDaysDataForQuery } from '../graphqlApollo/query/getOfficeDays';

export const queries = [
  {
    request: {
      query: GET_OFFICE_DAYS,
      variables: {
        filter: {
          dateRange: {
            rangeStart: getDateWithFormat(
              'AWSDateTime',
              dayjs(firstDate).year(),
              dayjs(firstDate).month(),
              dayjs(firstDate).startOf('month').date()
            ),
            rangeEnd: getDateWithFormat(
              'AWSDateTime',
              dayjs(firstDate).year(),
              dayjs(firstDate).add(1, 'month').month(),
              dayjs(firstDate).endOf('month').date()
            )
          }
        }
      }
    },
    newData: () => ({
      data: mockedGetOfficeDaysDataForQuery
    })
  },
  {
    request: {
      query: GET_AVAILABLE_OFFICE_DAYS,
      variables: {
        from: '2020-03-01',
        to: '2020-04-30'
      }
    },
    newData: () => ({
      data: mockedGetAvailableDaysDataForQuery
    })
  },
  {
    request: {
      query: GET_CURRENT_HOST
    },
    newData: () => ({
      data: mockedGetCurrentHostNonAdminDataForQuery
    })
  }
];
