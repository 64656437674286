import React from 'react';
import styled from 'styled-components';
import Button from 'ui/atoms/Button/Button';
import Icon from 'ui/atoms/Icon/Icon';
import Title from 'components/Title/Title';
import Typography from '../../atoms/Typography/Typography';

const TITLE_HEIGHT = 58;
const HAMBURGER_HEIGHT = 24;
const CLOSE_HEIGHT = 14;

const TitleAndButtonWrapper = styled.div<Partial<Props>>`
  ${({ isMobile }) =>
    isMobile
      ? ``
      : `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`}
`;

const TitleWrapper = styled.div<Partial<Props>>`
  margin: 4.3125rem 0 2.5625rem 0;

  ${({ isMobile }) =>
    isMobile
      ? `
    display: flex;
    margin: 2.875rem calc(3.375vw + 2rem) 1.3125rem 4vw; // right margin + space for hamburger
    align-items: center;
    flex-wrap: wrap;
    gap: 2.9375rem;
    z-index: 2;
    `
      : ''};
`;

const HamburgerWrapper = styled.div<Record<'isMobileMenuOpen', boolean>>`
  position: absolute;
  right: 3.375vw; // right margin of TitleWrapper
  top: ${({ isMobileMenuOpen }) =>
    isMobileMenuOpen
      ? `calc(2.875rem + (${TITLE_HEIGHT}px - ${CLOSE_HEIGHT}px) / 2)`
      : `calc(2.875rem + (${TITLE_HEIGHT}px - ${HAMBURGER_HEIGHT}px) / 2)`};
  display: flex;
  z-index: 2;
`;

const ButtonWrapper = styled.div`
  margin: 5rem 0 2.125rem 0;

  button {
    width: 11.75rem;
  }
`;

export interface Props {
  title: string;
  titleId?: string;
  isMobile?: boolean;
  isMobileMenuOpen?: boolean;
  setIsMobileMenuOpen?: (isMobileMenuOpen: boolean) => void;
  buttonText?: string;
  buttonId?: string;
  buttonOnClick?: () => void;
}

const TitleAndSubtitle: React.FC<Props> = ({
  title,
  titleId,
  isMobile = false,
  isMobileMenuOpen = false,
  setIsMobileMenuOpen,
  buttonText,
  buttonId,
  buttonOnClick
}) => (
  <TitleAndButtonWrapper>
    <TitleWrapper isMobile={isMobile} id={titleId}>
      {isMobile && <Title />}
      {!isMobileMenuOpen && <Typography variant={isMobile ? 'title2' : 'title1'}>{title}</Typography>}
      {isMobile && setIsMobileMenuOpen && (
        <HamburgerWrapper
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          isMobileMenuOpen={isMobileMenuOpen}
          id={isMobileMenuOpen ? 'close-menu-button' : 'open-menu-button'}
        >
          <Icon
            name={isMobileMenuOpen ? 'Close' : 'Hamburger'}
            width={isMobileMenuOpen ? CLOSE_HEIGHT : HAMBURGER_HEIGHT}
            height={isMobileMenuOpen ? CLOSE_HEIGHT : HAMBURGER_HEIGHT}
          />
        </HamburgerWrapper>
      )}
    </TitleWrapper>
    {buttonText && (
      <ButtonWrapper>
        <Button onClick={buttonOnClick} id={buttonId}>
          {buttonText}
        </Button>
      </ButtonWrapper>
    )}
  </TitleAndButtonWrapper>
);

export default TitleAndSubtitle;
