/* eslint-disable no-magic-numbers */
/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithAccessGrants,
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithAccessGrants';
import { createMockedAccessGrantWithLocations } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/accessGrantWithLocations';
import { createMockedVisitorGroupWithAccessGrants } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithAccessGrants';
import { mockedGetLocationWithDoorsForTenantDataUnitTests } from './getLocationWithDoorsForTenant';
import { mockedGetDoorsForTenantWithLocationsDataAdmin } from './getDoorsForTenantWithLocations';

export function mockedGetVisitorGroupsForTenantWithAccessGrantsData(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithAccessGrants {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithAccessGrantsDataDataForQuery =
  mockedGetVisitorGroupsForTenantWithAccessGrantsData([
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedVisitorGroupId0',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantId0',
          locations: [mockedGetLocationWithDoorsForTenantDataUnitTests.getLocationsForTenant[0]]
        })
      ],
      name: 'group 0'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedVisitorGroupId1',
      scheduledAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantId1',
          locations: [mockedGetLocationWithDoorsForTenantDataUnitTests.getLocationsForTenant[1]]
        })
      ],
      name: 'group 1'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedVisitorGroupId2',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantId2',
          locations: [
            mockedGetLocationWithDoorsForTenantDataUnitTests.getLocationsForTenant[1],
            mockedGetLocationWithDoorsForTenantDataUnitTests.getLocationsForTenant[2]
          ]
        })
      ],
      name: 'group 2'
    })
  ]);

export const mockedGetVisitorGroupsForTenantWithAccessGrantsDataAdmin =
  mockedGetVisitorGroupsForTenantWithAccessGrantsData([
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedIdInstallers',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantIdInstallers'
        })
      ],
      name: 'installers'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedIdCleaners',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantIdCleaners',
          doors: [mockedGetDoorsForTenantWithLocationsDataAdmin.getDoorsForTenant[0]]
        })
      ],
      name: 'cleaners'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedIdCo-contractors',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantIdCo-contractors',
          doors: [mockedGetDoorsForTenantWithLocationsDataAdmin.getDoorsForTenant[1]]
        })
      ],
      name: 'co-contractors'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedIdAdmins',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantIdAdmins'
        })
      ],
      name: 'admins',
      type: 'AZURE_AD'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedIdEmployees',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantIdEmployees'
        })
      ],
      name: 'employees',
      type: 'AZURE_AD'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedIdVisitor_group_1',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantIdVisitor_group_1',
          doors: [mockedGetDoorsForTenantWithLocationsDataAdmin.getDoorsForTenant[1]]
        })
      ],
      name: 'visitor_group_1',
      type: 'AZURE_AD'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedIdVisitor_group_2',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantIdVisitor_group_2',
          doors: [mockedGetDoorsForTenantWithLocationsDataAdmin.getDoorsForTenant[2]]
        })
      ],
      name: 'visitor_group_2',
      type: 'AZURE_AD'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedIdVisitor_group_3',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantIdVisitor_group_3'
        })
      ],
      name: 'visitor_group_3',
      type: 'AZURE_AD'
    }),
    createMockedVisitorGroupWithAccessGrants({
      id: 'mockedIdVisitors',
      defaultAccessGrants: [
        createMockedAccessGrantWithLocations({
          id: 'mockedAccessGrantIdVisitors'
        })
      ],
      name: 'visitors',
      type: 'EXTERNAL_VISITORS'
    })
  ]);
