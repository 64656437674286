/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members,
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members_mobileDevices,
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members_visitorGroupMembership
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';
import { createMockedMobileDevice } from './mobileDevice';
import { createMockedVisitorGroupMembership } from './visitorGroupMembership';

interface Props {
  id: string;
  name?: string;
  email?: GraphQL_AWSEmail;
  mobileDevices?: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members_mobileDevices[];
  isEditable?: boolean;
  expirationDate?: string | null;
  visitorGroupMembership?: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members_visitorGroupMembership[];
}

export function createMockedVisitorWithVisitorGroupMembership({
  id,
  name = 'mockedVisitorName',
  email = 'mockedVisitorEmail',
  mobileDevices = [createMockedMobileDevice({ id: 'mockedMobileDevice' })],
  isEditable = true,
  expirationDate = null,
  visitorGroupMembership = [createMockedVisitorGroupMembership({})]
}: Props): GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members {
  return {
    __typename: 'Visitor',
    id,
    name,
    email,
    mobileDevices,
    isEditable,
    expirationDate,
    visitorGroupMembership
  };
}
