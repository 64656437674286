import { useLazyQuery } from '@apollo/client';
import { GET_TENANT_NAME } from 'graphql/queries';
import { GetTenantName, GetTenantNameVariables } from 'graphql/generated/GetTenantName';
import { useEffect, useState } from 'react';
import { BaseHookProps } from '../shared/types';

interface Props extends BaseHookProps {
  azureTenantId: string | null;
}

const useTenantName = ({ handleFetchError, azureTenantId }: Props) => {
  const [tenantName, setTenantName] = useState<string>();
  const [getTenantNameQuery, { loading }] = useLazyQuery<GetTenantName, GetTenantNameVariables>(GET_TENANT_NAME, {
    onCompleted: (data) => {
      setTenantName(data.getTenantName);
    },
    onError: () => {
      handleFetchError('Error while fetching tenant name');
    }
  });

  useEffect(() => {
    if (azureTenantId)
      getTenantNameQuery({
        variables: {
          azureTenantId
        }
      });
  }, [azureTenantId, getTenantNameQuery]);

  return {
    tenantName,
    loading
  };
};

export default useTenantName;
