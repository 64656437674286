import { EditInstallerForm } from 'components/Drawer/types';
import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { MobileType } from 'state/types';
import Button from 'ui/atoms/Button/Button';
import StatusConnected from 'ui/atoms/StatusConnected/StatusConnected';
import StatusDisconnected from 'ui/atoms/StatusDisconnected/StatusDisconnected';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  register: UseFormRegister<EditInstallerForm>;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  nameInputValidation: string | undefined;
  emailInputValue: string;
  statusAccess: boolean;
  statusConfig: boolean;
  showValidation: boolean;
  isEditable?: boolean;
  isResendInvitationButtonVisibleConfig: boolean;
  isResendInvitationButtonVisibleAccess: boolean;
  handleOnResendInvitationClick: (type: MobileType) => void;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const EditInstaller: React.FC<Props> = ({
  register,
  nameInputValue,
  emailInputValue,
  handleNameInputOnChange,
  nameInputValidation,
  statusAccess,
  statusConfig,
  showValidation,
  isEditable,
  isResendInvitationButtonVisibleConfig,
  isResendInvitationButtonVisibleAccess,
  handleOnResendInvitationClick,
  inputOnKeyDown
}) => {
  const { translation } = useTranslation();
  return (
    <>
      <ComponentWrapper flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center" gap="1rem">
        <Typography color="dTextHigh" variant="label">
          {translation.atlas_access_app}
        </Typography>
        {statusAccess ? <StatusConnected /> : <StatusDisconnected />}
        <Typography color="dTextHigh" variant="label">
          {translation.atlas_config_app}
        </Typography>
        {statusConfig ? <StatusConnected /> : <StatusDisconnected />}
        <Input
          {...register('name')}
          onChange={isEditable ? (event) => handleNameInputOnChange(event.target.value) : undefined}
          customRequired
          label={`${translation.name}*`}
          value={nameInputValue}
          id="drawer-input-name"
          failed={showValidation && nameInputValidation !== undefined}
          failedText={nameInputValidation}
          failedId="drawer-input-name-validation"
          disabled={!isEditable}
          onKeyDown={inputOnKeyDown}
        />
        <Input
          {...register('email')}
          required
          label={`${translation.e_mail}*`}
          value={emailInputValue}
          id="drawer-input-email"
          disabled
          onKeyDown={inputOnKeyDown}
        />
        {isResendInvitationButtonVisibleAccess && (
          <Button onClick={() => handleOnResendInvitationClick('ACCESS')} id="resend-access-invitation-button">
            {translation.resend_access_invitation}
          </Button>
        )}
        {isResendInvitationButtonVisibleConfig && (
          <Button onClick={() => handleOnResendInvitationClick('CONFIG')} id="resend-config-invitation-button">
            {translation.resend_config_invitation}
          </Button>
        )}
      </ComponentWrapper>
    </>
  );
};

export default EditInstaller;
