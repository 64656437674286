import React from 'react';
import { Route, Navigate, Routes, useLocation } from 'react-router-dom';
import Layout from 'ui/templates/Layout/Layout';
import OfficeDaysPage from 'pages/Reservations/OfficeDays/OfficeDays';
import { useReactiveVar } from '@apollo/client';
import { authVar } from 'state/vars';
import AccessEventsPage from 'pages/Events/AccessEvents/AccessEvents';
import PermissionsPage from 'pages/Permissions/Permissions/Permissions';
import AccessProfiles from 'pages/Access/AccessProfiles/AccessProfiles';
import SchedulesPage from 'pages/Access/SchedulesPage/SchedulesPage';
import InstallersPage from 'pages/Users/Installers/Installers';
import LocksWithGroupsPage from 'pages/Hardware/Locks/Locks';
import RegistrationPage from 'pages/Registration/Registration';
import AccessAppPage from 'pages/MyProfile/AccessApp/AccessApp';
import AccessSettingsPage from 'pages/Access/AccessMode/AccessSettings';
import MobileNotSupportedPage from 'pages/MobileNotSupported/MobileNotSupported';
import usePagesAvailability from 'hooks/usePagesAvailability/usePagesAvailability';
import ExternalUsersPage from 'pages/Users/ExternalUsers/ExternalUsers';
import { isMobile } from 'react-device-detect';
import useTranslation from 'hooks/useTranslation/useTranslation';

const handleNavigation = (isAdmin: boolean, isAccessByScheduleEnabled: boolean, isMobileDeviceRegistered: boolean) => {
  if (isAdmin && !isMobile) return 'events/access-events';
  if (!isAccessByScheduleEnabled && isMobileDeviceRegistered) return '/reservations/office-days';
  if (isAccessByScheduleEnabled && !isMobile) return '/my-profile/access-app';
  if (isAccessByScheduleEnabled && isMobile) return '/mobile-page-not-supported';
  return 'registration';
};

const handleRegistrationRedirect = () => <RegistrationPage />;

const handleReservationsOfficeDaysRedirect = (
  isAccessByScheduleEnabled: boolean,
  isMobileDeviceRegistered: boolean
) => {
  if (isMobile && isAccessByScheduleEnabled) return <MobileNotSupportedPage />;
  if (!isMobile && isAccessByScheduleEnabled) return <Navigate to="/my-profile/access-app" />;
  if (!isMobileDeviceRegistered) return <Navigate to="/registration" />;
  return <OfficeDaysPage />;
};

const handleReservationsRedirect = () => <Navigate to="/reservations/office-days" />;

const handleEventsAccessEventsRedirect = () => <AccessEventsPage />;
const handleEventsRedirect = () => <Navigate to="/events/access-events" />;

const handlePermissionsPermissionsRedirect = () => <PermissionsPage />;
const handlePermissionsRedirect = () => <Navigate to="/permissions/permissions" />;

const handleHardwareLocksRedirect = () => <LocksWithGroupsPage />;
const handleHardwareRedirect = () => <Navigate to="/hardware/locks" />;

const handleAccessAccessSettingsRedirect = () => <AccessSettingsPage />;
const handleAccessAccessProfilesRedirect = () => <AccessProfiles />;
const handleAccessSchedulesRedirect = () => <SchedulesPage />;
const handleAccessRedirect = () => <Navigate to="/access/access-profiles" />;

const handleUsersExternalGroupsRedirect = () => <ExternalUsersPage />;
const handleUsersInstallersRedirect = () => <InstallersPage />;
const handleUsersRedirect = () => <Navigate to="/users/external-groups" />;

const handleMyProfileAccessAppRedirect = () => <AccessAppPage />;
const handleMyProfileRedirect = () => <Navigate to="my-profile/access-app" />;

const handleMobileReportsRedirect = () => <RegistrationPage />;
const handleMobileOfficeDays = () => <OfficeDaysPage />;
const handleMobileMobileNotSupportedRedirect = () => <MobileNotSupportedPage />;

const Dashboard: React.FC = () => {
  const { translation } = useTranslation();
  const location = useLocation();
  const { user } = useReactiveVar(authVar);
  const isAdmin = user?.isAdmin!; // TODO: Nested destruction not work, dont know why
  const isAccessByScheduleEnabled = user?.isAccessByScheduleEnabled!;
  const mobileDevices = user?.mobileDevices!;
  const {
    isRegistrationAvailable,
    isReservationsAvailable,
    isEventsAvailable,
    isPermissionAvailable,
    isHardwareAvailable,
    isAccessAvailable,
    isUsersAvailable,
    isMyProfileAvailable,
    isMobileRegistrationAvailable,
    isMobileOfficeDaysAvailable,
    isMobileMobileNotSupportedAvailable
  } = usePagesAvailability(translation);

  const handleShowSideNav = () => {
    if (location.pathname.includes('registration') || isMobile) return false;
    return true;
  };

  return (
    <Layout showSideNav={handleShowSideNav()}>
      {!isMobile ? (
        <Routes>
          {isRegistrationAvailable() && <Route path="/registration" element={handleRegistrationRedirect()} />}
          {isReservationsAvailable() && (
            <Route path="/reservations">
              <Route
                path="/reservations/office-days"
                element={handleReservationsOfficeDaysRedirect(
                  isAccessByScheduleEnabled,
                  mobileDevices?.some((device) => device.isRegistered && device.role === 'ACCESS')
                )}
              />
              <Route path="/reservations" element={handleReservationsRedirect()} />
            </Route>
          )}

          {isEventsAvailable() && (
            <Route path="/events">
              <Route path="/events/access-events" element={handleEventsAccessEventsRedirect()} />
              <Route path="/events" element={handleEventsRedirect()} />
            </Route>
          )}

          {isPermissionAvailable() && (
            <Route path="/permissions">
              <Route path="/permissions/permissions" element={handlePermissionsPermissionsRedirect()} />
              <Route path="/permissions" element={handlePermissionsRedirect()} />
            </Route>
          )}

          {isHardwareAvailable() && (
            <Route path="/hardware">
              <Route path="/hardware/locks" element={handleHardwareLocksRedirect()} />
              <Route path="/hardware" element={handleHardwareRedirect()} />
            </Route>
          )}

          {isAccessAvailable() && (
            <Route path="/access">
              <Route path="/access/access-profiles" element={handleAccessAccessProfilesRedirect()} />
              <Route path="/access/schedules" element={handleAccessSchedulesRedirect()} />
              <Route path="/access/access-settings" element={handleAccessAccessSettingsRedirect()} />
              <Route path="/access" element={handleAccessRedirect()} />
            </Route>
          )}

          {isUsersAvailable() && (
            <Route path="/users">
              <Route path="/users/external-groups" element={handleUsersExternalGroupsRedirect()} />
              <Route path="/users/installers" element={handleUsersInstallersRedirect()} />
              <Route path="/users" element={handleUsersRedirect()} />
            </Route>
          )}

          {isMyProfileAvailable() && (
            <Route path="/my-profile">
              <Route path="/my-profile/access-app" element={handleMyProfileAccessAppRedirect()} />
              <Route path="/my-profile" element={handleMyProfileRedirect()} />
            </Route>
          )}
          <Route
            path="*"
            element={
              <Navigate
                to={handleNavigation(
                  isAdmin,
                  isAccessByScheduleEnabled,
                  mobileDevices?.some((device) => device.isRegistered)
                )}
              />
            }
          />
        </Routes>
      ) : (
        <Routes>
          {isMobileRegistrationAvailable() && <Route path="/registration" element={handleMobileReportsRedirect()} />}
          {isMobileOfficeDaysAvailable() && (
            <Route path="/reservations/office-days" element={handleMobileOfficeDays()} />
          )}
          {isMobileMobileNotSupportedAvailable() && (
            <Route path="/mobile-page-not-supported" element={handleMobileMobileNotSupportedRedirect()} />
          )}
          <Route
            path="*"
            element={
              <Navigate
                to={handleNavigation(
                  isAdmin,
                  isAccessByScheduleEnabled,
                  mobileDevices?.some((device) => device.isRegistered)
                )}
              />
            }
          />
        </Routes>
      )}
    </Layout>
  );
};

export default Dashboard;
