import { GET_ACCESS_EVENTS_FOR_TENANT } from 'graphql/queries';
import { useEffect, useState } from 'react';
import {
  GetAccessEventsForTenant_getAccessEventsForTenant_items as AccessEvents,
  GetAccessEventsForTenant,
  GetAccessEventsForTenantVariables
} from 'graphql/generated/GetAccessEventsForTenant';
import { useLazyQuery } from '@apollo/client';
import { BaseHookProps } from 'hooks/shared/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AccessEventsDateRange, MobileOpCode } from 'graphql/generated/globalTypes';
import { AccessEventsFilterWithDateTimeSelectedOption } from 'hooks/useAccessEventsFilters/useAccessEventsFilters';

dayjs.extend(utc);

const getDateRange = (dateRange: AccessEventsDateRange) => ({
  rangeStart: dayjs(dateRange.rangeStart).second(0).millisecond(0).utc().format(),
  // subtract here one millisecond here so the date range interval is not closed
  rangeEnd: dayjs(dateRange.rangeEnd).second(0).millisecond(0).subtract(1, 'millisecond').utc().format()
});

const useExportAccessEvents = ({ handleFetchError }: BaseHookProps) => {
  const [filters, setFilters] = useState<null | AccessEventsFilterWithDateTimeSelectedOption>(null);
  const [accessEvents, setAccessEvents] = useState<AccessEvents[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState<boolean>(false);
  const [isDownloadDone, setIsDownloadDone] = useState<boolean>(false);

  const [getAccessEvents] = useLazyQuery<GetAccessEventsForTenant, GetAccessEventsForTenantVariables>(
    GET_ACCESS_EVENTS_FOR_TENANT,
    {
      onCompleted: (data) => {
        if (data.getAccessEventsForTenant.nextToken !== nextToken)
          setAccessEvents([...accessEvents, ...data.getAccessEventsForTenant.items]);
        else setAccessEvents(data.getAccessEventsForTenant.items);
        setNextToken(data.getAccessEventsForTenant.nextToken);

        if (data.getAccessEventsForTenant.nextToken === null) setIsDownloadDone(true);
      },
      onError: () => {
        handleFetchError(`Error while fetching Access Events`);
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (isDownloadDone) {
      setIsDownloadInProgress(false);
    }
  }, [isDownloadDone]);

  useEffect(() => {
    if (!isDownloadDone && isDownloadInProgress) {
      if (filters) {
        const { dataTimeSelectedOption, ...accessEventFiltersWithoutIsFilterApplied } = filters;
        getAccessEvents({
          variables: {
            nextToken,
            filter: {
              ...accessEventFiltersWithoutIsFilterApplied,
              actions: accessEventFiltersWithoutIsFilterApplied.actions
                ? [...accessEventFiltersWithoutIsFilterApplied.actions]
                : [MobileOpCode.ACCESS_DENIED, MobileOpCode.ACCESS_GRANTED],
              dateRange: filters.dateRange ? getDateRange(filters.dateRange) : undefined
            }
          }
        });
      } else {
        getAccessEvents({
          variables: {
            nextToken,
            filter: {
              actions: [MobileOpCode.ACCESS_DENIED, MobileOpCode.ACCESS_GRANTED]
            }
          }
        });
      }
    }
  }, [filters, getAccessEvents, isDownloadDone, isDownloadInProgress, nextToken]);

  useEffect(() => {
    if (isDownloadDone) {
      const headers = ['Date/Time', 'User Name', 'User Email', 'Lock', 'Action', 'Reason'];

      const rows = accessEvents
        .map(
          (item) =>
            `${dayjs(item.createdAt).toISOString()},"${item.visitor.name.replace(
              /"/g,
              '""'
            )}","${item.visitor.email.replace(/"/g, '""')}","${item.door.name.replace(
              /"/g,
              '""'
            )}","${item.action.replace(/"/g, '""')}","${item.reason || ''.replace(/"/g, '""')}"`
        )
        .join('\n');

      const data = `${headers}\n${rows}`;
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `accessEventsExport ${dayjs().format('YYYY-MM-DD HH-mm')}`);
      link.click();
      window.URL.revokeObjectURL(url);
    }
  }, [accessEvents, isDownloadDone]);

  const startDownloadingAccessEventsToFile = (filter: null | AccessEventsFilterWithDateTimeSelectedOption) => {
    setFilters(filter);
    if (!isDownloadInProgress) setIsDownloadInProgress(true);
  };

  const abortDownloadingAccessEventsToFile = () => {
    setAccessEvents([]);
    setIsDownloadInProgress(false);
    setIsDownloadDone(false);
  };

  return {
    startDownloadingAccessEventsToFile,
    abortDownloadingAccessEventsToFile,
    isDownloadInProgress,
    isDownloadDone
  };
};

export default useExportAccessEvents;
