import dayjs from 'dayjs';
import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import Label from 'ui/atoms/Label/Label';
import StatusConnected from 'ui/atoms/StatusConnected/StatusConnected';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  activeSinceDate: dayjs.Dayjs;
}

const MyProfileStatusConnected: React.FC<Props> = ({ activeSinceDate }) => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper flexDirection="column" gap="1rem">
      <ComponentWrapper flexDirection="column" gap="0.625rem">
        <Label>{translation.status}</Label>
        <StatusConnected id="status" />
      </ComponentWrapper>
      <ComponentWrapper flexDirection="column" gap="0.625rem">
        <Label>{translation.active_since}</Label>
        <Typography variant="body1" id="drawer-my-profile-date" color="dTextHigh">
          {activeSinceDate.format('DD/MM/YYYY - HH:mm')}
        </Typography>
      </ComponentWrapper>
    </ComponentWrapper>
  );
};
export default MyProfileStatusConnected;
