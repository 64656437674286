/* eslint-disable no-magic-numbers */
import {
  GetLocationsWithDoorsForTenant_getLocationsForTenant,
  GetLocationsWithDoorsForTenant_getLocationsForTenant_doors
} from 'graphql/generated/GetLocationsWithDoorsForTenant';
import { GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithAccessGrants';
import { Translation } from 'hooks/useTranslation/useTranslation';

export type Variant = 'icon' | 'switch';

export interface SecondLineText {
  id: string;
  text: string;
}

export interface DisabledRowsData {
  id: string;
  locationList: GetLocationsWithDoorsForTenant_getLocationsForTenant[];
}
export interface SecondRowsTextWarning {
  id: string;
  text: string;
}

type DataType = 'VisitorGroup' | 'LockGroup' | 'Door';

const hasLocksAssigned = (visitorGroup: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant) => {
  const accessGrant = visitorGroup.scheduledAccessGrants[0] ?? visitorGroup.defaultAccessGrants[0];
  return [...accessGrant.doors, ...accessGrant.locations.flatMap((location) => location.doors)].length > 0;
};

const isScheduleAssigned = (visitorGroup: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant) => {
  const accessGrant = visitorGroup.scheduledAccessGrants[0] ?? visitorGroup.defaultAccessGrants[0];
  return Boolean(accessGrant.accessSchedule.weekDays.length > 0);
};

const isUserGroupAssignedToDoor = (
  door: GetLocationsWithDoorsForTenant_getLocationsForTenant_doors,
  visitorGroups: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant[]
) =>
  visitorGroups.filter((group) => {
    const accessGrant = group.scheduledAccessGrants[0] ?? group.defaultAccessGrants[0];
    return (
      accessGrant.doors.some((doorInAccessGrant) => doorInAccessGrant.id === door.id) ||
      accessGrant.locations.some((location) =>
        location.doors.some((doorInLocationInAccessGrant) => doorInLocationInAccessGrant.id === door.id)
      )
    );
  }).length > 0;

const isUserGroupAssignedToLocation = (
  lockGroup: GetLocationsWithDoorsForTenant_getLocationsForTenant,
  visitorGroups: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant[]
) =>
  visitorGroups.filter((group) => {
    const isAccessByScheduleEnabled = group.scheduledAccessGrants.length > group.defaultAccessGrants.length;
    if (isAccessByScheduleEnabled)
      return group.scheduledAccessGrants[0].locations.some(
        (locationInAccessGrant) => locationInAccessGrant.id === lockGroup.id
      );
    return group.defaultAccessGrants[0].locations.some(
      (locationInAccessGrant) => locationInAccessGrant.id === lockGroup.id
    );
  }).length > 0;

export const getSecondLineTextWarning = (
  translation: Translation,
  type: DataType,
  item:
    | GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant
    | GetLocationsWithDoorsForTenant_getLocationsForTenant
    | GetLocationsWithDoorsForTenant_getLocationsForTenant_doors,
  visitorGroups?: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant[]
): SecondRowsTextWarning | undefined => {
  const isAnyLocksAssigned =
    type === 'VisitorGroup' &&
    hasLocksAssigned(item as GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant);
  const isAnyScheduleAssigned =
    type === 'VisitorGroup' &&
    isScheduleAssigned(item as GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant);
  const isAnyUserGroupsAssignedToDoor =
    type === 'Door' &&
    visitorGroups &&
    isUserGroupAssignedToDoor(item as GetLocationsWithDoorsForTenant_getLocationsForTenant_doors, visitorGroups);
  const isAnyUserGroupsAssignedToLock =
    type === 'LockGroup' &&
    visitorGroups &&
    isUserGroupAssignedToLocation(item as GetLocationsWithDoorsForTenant_getLocationsForTenant, visitorGroups);

  switch (type) {
    case 'Door':
      if (!isAnyUserGroupsAssignedToDoor) return { id: item.id, text: translation.no_user_group_s__assigned };
      break;
    case 'LockGroup':
      if (!isAnyUserGroupsAssignedToLock) return { id: item.id, text: translation.no_user_group_s__assigned };
      break;
    case 'VisitorGroup':
      if (!isAnyLocksAssigned && !isAnyScheduleAssigned)
        return { id: item.id, text: translation.no_lock_s__and_schedule_assigned };
      if (!isAnyLocksAssigned) return { id: item.id, text: translation.no_lock_s__assigned };
      if (!isAnyScheduleAssigned) return { id: item.id, text: translation.no_schedule_assigned };
      break;
    default:
      break;
  }

  return undefined;
};

export const ARROW_DOWN_WIDTH = 16;
export const ARROW_DOWN_HEIGHT = 16;
export const ARROW_RIGHT_WIDTH = 13;
export const ARROW_RIGHT_HEIGHT = 17;
