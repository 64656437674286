import React from 'react';
import Tabs, { Props as TabsProps } from 'components/Tabs/Tabs';
import styled from 'styled-components';
import TitleAndSubtitle, { Props as TitleAndSubtitleProps } from 'ui/molecules/TitleAndSubtitle/TitleAndSubtitle';
import MobileNav from 'components/MobileNav/MobileNav';
import Separator from 'ui/atoms/Separator/Separator';

interface CssProps {
  withScroll?: boolean;
}
export interface Props extends TitleAndSubtitleProps, TabsProps, CssProps {
  isMobile?: boolean;
  isMobileMenuOpen?: boolean;
  setIsMobileMenuOpen?: (isMobileMenuOpen: boolean) => void;
}

const PageContentWrapper = styled.div<Partial<Props>>`
  flex-direction: column;
  display: flex;

  ${({ isMobile }) =>
    isMobile
      ? `
      height: 100vh;
      margin: 0;
      width: 100vw;
    `
      : `margin: 0 5rem 0 6rem;`};
`;

const StickyToTop = styled.div<Partial<Props>>`
  height: 12.125rem;
  background-color: ${({ theme }) => theme.colors.bgDark};
  ${({ isMobile }) =>
    isMobile
      ? `
    width: 100%;
    `
      : ``}
`;

const ChildrenWrapper = styled.div<Partial<Props>>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20rem);

  ${({ withScroll }) =>
    withScroll
      ? `
  overflow-y: auto;
  overflow-x: hidden;
    `
      : ``};
`;

const PageContent: React.FC<Props> = ({
  title,
  titleId,
  buttonText,
  buttonId,
  buttonOnClick,
  tabs,
  isMobile = false,
  isMobileMenuOpen = false,
  setIsMobileMenuOpen,
  withScroll = false,
  children
}) => (
  <PageContentWrapper isMobile={isMobile}>
    {isMobileMenuOpen && isMobile && <MobileNav />}
    <StickyToTop isMobile={isMobile} id={isMobile ? 'topbar' : ''}>
      <TitleAndSubtitle
        title={title}
        titleId={titleId}
        buttonText={buttonText}
        buttonOnClick={buttonOnClick}
        buttonId={buttonId}
        isMobile={isMobile}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      {!isMobile && <Tabs tabs={tabs} />}
      {isMobile && <Separator />}
    </StickyToTop>
    {isMobile ? children : <ChildrenWrapper withScroll={withScroll}>{children}</ChildrenWrapper>}
  </PageContentWrapper>
);

export default PageContent;
