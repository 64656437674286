import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography/Typography';

export interface Props {
  title: string;
  value: string;
  deleteOnClick?: () => void;
  id?: string;
}

const StyledChip = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 0.125rem 0.25rem;
  border-radius: 5px;
  column-gap: 0.25rem;
  background-color: ${(props) => props.theme.colors.secondary};
  align-items: center;
`;

const StyledDeleteWrapper = styled.div`
  cursor: pointer;
`;

const Chip: React.FC<Props> = ({ title, value, deleteOnClick, id }) => (
  <StyledChip id={id}>
    <Typography variant="body4" overflow="">
      {title}
    </Typography>
    <Typography variant="body4" overflow="" whiteSpace="nowrap">
      {value}
    </Typography>
    <StyledDeleteWrapper onClick={deleteOnClick}>
      <Typography variant="body4" color="dTextHigh" overflow="">
        X
      </Typography>
    </StyledDeleteWrapper>
  </StyledChip>
);

export default Chip;
