import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { GET_TENANT_LEGAL_DOCUMENTS } from 'graphql/queries';
import { tenantLegalDocumentsVar } from 'state/vars';
import { GetTenantLegalDocuments } from 'graphql/generated/GetTenantLegalDocuments';
import { AcceptLegalDocuments, AcceptLegalDocumentsVariables } from 'graphql/generated/AcceptLegalDocuments';
import { ACCEPT_LEGAL_DOCUMENTS } from 'graphql/mutations';
import { AcceptedLegalDocuments } from 'graphql/generated/globalTypes';
import { setTenantLegalDocumentsAction, updateTenantLegalDocumentsAction } from 'state/actions/tenantLegalDocuments';
import { BaseHookProps } from '../shared/types';

const useTenantLegalDocuments = ({ handleFetchError }: BaseHookProps) => {
  const tenantLegalDocuments = useReactiveVar(tenantLegalDocumentsVar);

  const [getTenantLegalDocuments, { loading }] = useLazyQuery<GetTenantLegalDocuments>(GET_TENANT_LEGAL_DOCUMENTS, {
    onCompleted: (data) => {
      setTenantLegalDocumentsAction(data.getTenantLegalDocuments);
    },
    onError: () => {
      handleFetchError('Error while fetching legal documents');
    }
  });

  const [acceptedLegalDocumentsMutation, { loading: acceptedLegalDocumentsLoading }] = useMutation<
    AcceptLegalDocuments,
    AcceptLegalDocumentsVariables
  >(ACCEPT_LEGAL_DOCUMENTS, {
    onCompleted: (data) => {
      if (data.acceptLegalDocuments) updateTenantLegalDocumentsAction();
    },
    onError: () => {
      handleFetchError('Error while accepting Legal Documents');
    }
  });

  const acceptedLegalDocuments = async (acceptedLegalDocumentsVariables: AcceptedLegalDocuments) => {
    await acceptedLegalDocumentsMutation({
      variables: {
        acceptedLegalDocuments: acceptedLegalDocumentsVariables
      }
    });
  };

  return {
    tenantLegalDocuments,
    getTenantLegalDocuments,
    loading,
    acceptedLegalDocuments,
    acceptedLegalDocumentsLoading
  };
};

export default useTenantLegalDocuments;
