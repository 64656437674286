import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography/Typography';

export interface Props {
  firstLineText: string;
  id?: string;
}

const TypographyWrapper = styled.div`
  width: fit-content;
  height: 1.125rem;
  margin: 0.5rem;
`;

const TooltipInfo: React.FC<Props> = ({ firstLineText, id }) => (
  <TypographyWrapper id={id}>
    <Typography color="lTextHigh" variant="body4" whiteSpace="nowrap">
      {firstLineText}
    </Typography>
  </TypographyWrapper>
);

export default TooltipInfo;
