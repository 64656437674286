/* eslint-disable import/prefer-default-export */
import { GetDoorsForTenantWithLocations_getDoorsForTenant_externalLocation } from 'graphql/generated/GetDoorsForTenantWithLocations';
import { GetDoorsForTenantWithOfficeMode_getDoorsForTenant_officeMode } from 'graphql/generated/GetDoorsForTenantWithOfficeMode';
import {
  UpdateDoorWithLocations_updateDoor,
  UpdateDoorWithLocations_updateDoor_externalLocation
} from 'graphql/generated/UpdateDoorWithLocations';
import {
  GetDoorsForTenantWithLocations_getDoorsForTenant_complete,
  GetDoorsForTenantWithLocations_getDoorsForTenant_manualLocations_complete
} from 'state/types';
import { createMockedLocation } from './location';
import { createLocationWithDoors } from './locationWithDoors';

interface Props {
  id: string;
  batteryWarningLevel?: number | null;
  domSerialNumber?: string;
  externalLocation?:
    | GetDoorsForTenantWithLocations_getDoorsForTenant_externalLocation
    | UpdateDoorWithLocations_updateDoor_externalLocation
    | null;
  manualLocations?: GetDoorsForTenantWithLocations_getDoorsForTenant_manualLocations_complete[] | null;
  name?: string;
  officeMode?: GetDoorsForTenantWithOfficeMode_getDoorsForTenant_officeMode;
}

export function createMockedDoorWithLocations({
  id,
  batteryWarningLevel = null,
  domSerialNumber = 'mockedDomSerialNumber',
  externalLocation = createMockedLocation({ id: 'mockedExternalLocationId' }),
  manualLocations = [createLocationWithDoors({ id: 'mockedManualLocationId' })],
  name = 'mockedDoorName',
  officeMode = {
    __typename: 'WeekSchedule',
    weekDays: []
  }
}: Props): GetDoorsForTenantWithLocations_getDoorsForTenant_complete | UpdateDoorWithLocations_updateDoor {
  return {
    __typename: 'Door',
    id,
    name,
    externalLocation,
    manualLocations,
    domSerialNumber,
    batteryWarningLevel,
    officeMode
  };
}
