import React from 'react';
import styled from 'styled-components';
import { AutocompleteGetTagProps } from '@mui/base';
import Typography from '../Typography/Typography';
import Icon from '../Icon/Icon';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 0.5rem;
  border-radius: 100px;
  column-gap: 0.25rem;
  background-color: ${({ theme }) => theme.colors.secondary};
  align-items: center;
  width: fit-content;
`;

const StyledTypographyWrapper = styled.div`
  max-width: 17rem;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lTextHigh};
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;

export interface Props extends ReturnType<AutocompleteGetTagProps> {
  label: string;
  id: string;
}

const ChipMultiselect: React.FC<Props> = ({ label, onDelete, id, ...other }) => (
  <StyledWrapper {...other} id={id}>
    <StyledTypographyWrapper>
      <Typography variant="body3" color="lTextHigh" whiteSpace="nowrap">
        {label}
      </Typography>
    </StyledTypographyWrapper>
    <IconWrapper>
      <Icon name="Close" color="secondary" onClick={onDelete} viewBox="0 0 14 14" width={12} height={12} />
    </IconWrapper>
  </StyledWrapper>
);

export default ChipMultiselect;
