/* eslint-disable no-magic-numbers */
/* eslint-disable id-blacklist */
/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';
import {
  GetAvailableOfficeDays,
  GetAvailableOfficeDays_getAvailableOfficeDays
} from 'graphql/generated/GetAvailableOfficeDays';
import { createMockedAvailableDays } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/availableDays';

export function mockedGetAvailableDays(
  availableDays: GetAvailableOfficeDays_getAvailableOfficeDays[]
): GetAvailableOfficeDays {
  return {
    getAvailableOfficeDays: availableDays
  };
}

export const mockedGetAvailableDaysDataForQuery = mockedGetAvailableDays([
  createMockedAvailableDays({ date: '2020-03-01' })
]);

const currentMonth = dayjs().month();
const nextMonth = dayjs().add(1, 'month').month();
const currentYear = dayjs().year();

const firstDayOfMonth = dayjs(`${currentYear}-${currentMonth + 1}-1`);
const firstDayOfNextMonth = dayjs(`${currentYear}-${nextMonth + 1}-1`);

const daysInMonth = firstDayOfMonth.daysInMonth();
const daysInNextMonth = firstDayOfNextMonth.daysInMonth();

const weekendDays = [];
const weekendDaysNextMonth = [];

for (let i = 1; i <= daysInMonth; i += 1) {
  const day = dayjs(`${currentYear}-${currentMonth + 1}-${i}`);

  if (day.day() === 1 || day.day() === 2 || day.day() === 3 || day.day() === 4 || day.day() === 5) {
    weekendDays.push(i);
  }
}

for (let i = 1; i <= daysInNextMonth; i += 1) {
  const day = dayjs(`${currentYear}-${nextMonth + 1}-${i}`);

  if (day.day() === 1 || day.day() === 2 || day.day() === 3 || day.day() === 4 || day.day() === 5) {
    weekendDaysNextMonth.push(i);
  }
}

export const mockedGetAvailableDaysDataAdmin = mockedGetAvailableDays([
  ...weekendDays.map((day) =>
    createMockedAvailableDays({ date: dayjs(`${currentYear}-${currentMonth + 1}-${day}`).format('YYYY-MM-DD') })
  ),
  ...weekendDaysNextMonth.map((day) =>
    createMockedAvailableDays({ date: dayjs(`${currentYear}-${nextMonth + 1}-${day}`).format('YYYY-MM-DD') })
  )
]);
