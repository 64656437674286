import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'theme/theme';
import Typography from '../Typography/Typography';

export type AvatarVariant = 'large' | 'small';
export interface Props {
  text: string;
  variant?: AvatarVariant;
  id?: string;
  backgroundColor?: keyof Colors;
  textColor?: keyof Colors;
}

export function handleAvatarVariant(variant: AvatarVariant) {
  switch (variant) {
    case 'small':
      return css`
        height: 1.75rem;
        width: 1.75rem;
      `;
    case 'large':
    default:
      return css`
        height: 3rem;
        width: 3rem;
      `;
  }
}
const StyledAvatar = styled.div<Pick<Props, 'backgroundColor' | 'variant'>>`
  border-radius: 100%;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors.b5};
  border: 2px solid ${(props) => props.theme.colors.b2};
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ variant }) => handleAvatarVariant(variant || 'large')}
`;

const getAvatarText = (text: string) => {
  const SUBSTRING_MAX_VALUE = 2;
  const splitHostName = text.split(' ');
  const firstLetter = splitHostName[0].toString().split('').shift()?.toUpperCase();
  const secondLetter = splitHostName[splitHostName.length - 1].split('').shift()?.toUpperCase();
  return text.includes(' ')
    ? `${firstLetter}${secondLetter}`
    : `${text.substring(0, SUBSTRING_MAX_VALUE).toUpperCase()}`;
};

const Avatar: React.FC<Props> = ({
  text,
  variant = 'large',
  id = 'avatar',
  backgroundColor = 'b5',
  textColor = 'dTextHigh'
}) => (
  <StyledAvatar variant={variant} id={id} backgroundColor={backgroundColor}>
    <Typography id="avatar-letter" color={textColor} variant={variant === 'large' ? 'body2' : 'helper'}>
      {getAvatarText(text)}
    </Typography>
  </StyledAvatar>
);

export default Avatar;
