/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant,
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';

interface Props {
  id: string;
  members: Array<GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members>;
  name?: string;
  type?: string;
  externalRef?: string;
  maxNumberOfMembers?: number | null;
}

export function createMockedVisitorGroupWithMembersAndRoleAndMaxNumberOfUsersCoContractors({
  id,
  members,
  name = 'mockedVisitorGroupName',
  type = 'CO_CONTRACTORS',
  externalRef = '',
  maxNumberOfMembers = null
}: Props): GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant {
  return {
    __typename: 'VisitorGroup',
    id,
    name,
    members,
    type,
    externalRef,
    maxNumberOfMembers
  };
}
