import React from 'react';
import styled from 'styled-components';
import MicrosoftIcon from 'assets/images/MicrosoftIcon.png';
import Typography from '../Typography/Typography';

export type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  isMobile: boolean;
};

const StyledButton = styled.button<Required<Props>>`
  padding: 0.625rem 3.5rem;
  height: 2.75rem;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.b5};

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

const LoginImageWrapper = styled.div`
  margin-right: 1.5rem;
`;

const MSButton: React.FC<Props> = ({ children, onClick, id, isMobile }, props) => (
  <StyledButton {...props} onClick={onClick} id={id}>
    <LoginImageWrapper>
      <img src={MicrosoftIcon} width="24" alt="MicrosoftIcon" />
    </LoginImageWrapper>
    <Typography variant={isMobile ? 'body3' : 'button'} color="dTextHigh">
      {children}
    </Typography>
  </StyledButton>
);

export default MSButton;
