import React from 'react';
import styled from 'styled-components';
import Avatar from '../../atoms/Avatar/Avatar';
import Typography from '../../atoms/Typography/Typography';

export interface Props {
  name: string;
  picture?: string;
  hoverEffect?: boolean;
  onClick?: () => void;
}

const StyledContainer = styled.div<Partial<Props>>`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  cursor: ${({ hoverEffect }) => (hoverEffect ? 'pointer' : '')};

  img {
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 100%;
    margin-right: 1rem;
  }

  p {
    text-transform: capitalize;
  }
`;

const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 1.75rem;
  width: 1.75rem;
  margin-right: 1rem;
  img {
    border-radius: 100%;
  }
`;

const Profile: React.FC<Props> = ({ picture, name, hoverEffect, onClick }) => (
  <StyledContainer id="profile" hoverEffect={hoverEffect} onClick={onClick}>
    <Typography id="welcome-text" variant="body2">
      {name}
    </Typography>
    <StyledImageWrapper>
      {picture ? <img src={picture} alt="user-profile" /> : <Avatar text={name[0]} variant="small" />}
    </StyledImageWrapper>
  </StyledContainer>
);

export default Profile;
