import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import styled from 'styled-components';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const IconWrapper = styled.div`
  margin: auto;
  margin-bottom: 1.75rem;
`;

const TitleWrapper = styled.div`
  margin: auto;
  margin-bottom: 1.5rem;
`;

const TextWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const FilterNoResult: React.FC = () => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper flexDirection="column" width="20.125rem" justifyContent="center" alignSelf="auto">
      <IconWrapper>
        <Icon name="Search" width={54} height={54} viewBox="0 0 11 11" id="filter-no-result-icon" />
      </IconWrapper>
      <TitleWrapper>
        <Typography variant="title4" id="filter-no-result-title">
          {translation.no_results}
        </Typography>
      </TitleWrapper>
      <TextWrapper>
        <Typography variant="body4" id="filter-no-result-subtitle">
          {
            translation.this_may_be_the_answer_you_re_looking_for_if_not_try_removing_or_changing_your_filters_to_make_them_less_specific
          }
        </Typography>
      </TextWrapper>
    </ComponentWrapper>
  );
};

export default FilterNoResult;
