import React from 'react';
import styled from 'styled-components';
import { Colors } from 'theme/theme';

export type FlexDirection = 'column' | 'row';
export type JustifyContent =
  | 'center'
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'left'
  | 'right'
  | 'normal'
  | 'space-between'
  | 'space-evenly'
  | 'stretch';
export type AlignItems = 'normal' | 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'baseline';
export type AlignSelf = 'auto' | 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline';
export type FlexWrap = 'nowrap' | 'wrap';
export type Cursor = 'auto' | 'pointer';

type WidthOrHeightUnit = '%' | 'rem' | 'vh' | 'vw' | '';
export type WidthOrHeight =
  | `${number}${WidthOrHeightUnit}`
  | 'inherit'
  | 'auto'
  | `calc(${number}${WidthOrHeightUnit} - ${number}${WidthOrHeightUnit})`;
type MarginType = `${number}${WidthOrHeightUnit}` | 'auto';
export type MarginOrPadding =
  | `${MarginType}`
  | `${MarginType} ${MarginType}`
  | `${MarginType} ${MarginType} ${MarginType} ${MarginType}`;

export type Flex = 'none' | `${MarginType}` | `${MarginType} ${MarginType} ${MarginType}`;

export interface CssProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  flexDirection?: FlexDirection;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  alignSelf?: AlignSelf;
  width?: WidthOrHeight;
  maxWidth?: WidthOrHeight;
  height?: WidthOrHeight;
  maxHeight?: WidthOrHeight;
  flex?: Flex;
  gap?: `${number}${WidthOrHeightUnit}` | `${number}${WidthOrHeightUnit} ${number}${WidthOrHeightUnit}`;
  columnGap?: `${number}${WidthOrHeightUnit}`;
  rowGap?: `${number}${WidthOrHeightUnit}`;
  margin?: MarginOrPadding;
  padding?: MarginOrPadding;
  flexWrap?: FlexWrap;
  backgroundColor?: keyof Colors;
  cursor?: Cursor;
  onClick?: () => void;
  id?: string;
}

const StyledComponentWrapper = styled.div<Partial<CssProps>>`
  display: flex;

  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`};
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`};
  ${({ width }) => width && `width:${width};`};
  ${({ maxWidth }) => maxWidth && `max-width:${maxWidth};`};
  ${({ height }) => height && `height: ${height};`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`};
  ${({ flex }) => flex && `flex: ${flex};`};
  ${({ gap }) => gap && `gap: ${gap};`};
  ${({ columnGap }) => columnGap && `column-gap: ${columnGap};`};
  ${({ rowGap }) => rowGap && `row-gap: ${rowGap};`};
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`};
  ${({ backgroundColor, theme }) => backgroundColor && `background-color: ${theme.colors[backgroundColor]};`};
  ${({ cursor }) => cursor && `cursor: ${cursor};`};
`;

const ComponentWrapper: React.FC<CssProps> = ({
  children,
  flexDirection,
  justifyContent,
  alignItems,
  alignSelf,
  width,
  maxWidth,
  height,
  maxHeight,
  flex,
  gap,
  columnGap,
  rowGap,
  margin,
  padding,
  flexWrap,
  backgroundColor,
  cursor,
  onClick,
  id
}) => (
  <StyledComponentWrapper
    flexDirection={flexDirection}
    justifyContent={justifyContent}
    alignItems={alignItems}
    alignSelf={alignSelf}
    width={width}
    maxWidth={maxWidth}
    height={height}
    maxHeight={maxHeight}
    flex={flex}
    gap={gap}
    columnGap={columnGap}
    rowGap={rowGap}
    margin={margin}
    padding={padding}
    flexWrap={flexWrap}
    backgroundColor={backgroundColor}
    cursor={cursor}
    onClick={onClick}
    id={id}
  >
    {children}
  </StyledComponentWrapper>
);

export default ComponentWrapper;
