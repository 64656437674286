/* eslint-disable import/prefer-default-export */
import {
  GetDoorsForTenant_getDoorsForTenant,
  GetDoorsForTenant_getDoorsForTenant_location
} from 'graphql/generated/GetDoorsForTenant';
import {
  GetVisitorGroupsForTenant_getVisitorGroupsForTenant_defaultAccessGrants_doors,
  GetVisitorGroupsForTenant_getVisitorGroupsForTenant_scheduledAccessGrants_doors
} from 'graphql/generated/GetVisitorGroupsForTenant';
import { UpdateDoor_updateDoor } from 'graphql/generated/UpdateDoor';
import { createMockedLocation } from './location';

interface Props {
  id: string;
  batteryWarningLevel?: number | null;
  domSerialNumber?: string;
  location?: GetDoorsForTenant_getDoorsForTenant_location | null;
  name?: string;
}

export function createMockedDoor({
  id,
  batteryWarningLevel = null,
  domSerialNumber = 'mockedDomSerialNumber',
  location = createMockedLocation({ id: 'mockedLocationId' }),
  name = 'mockedDoorName'
}: Props):
  | GetDoorsForTenant_getDoorsForTenant
  | GetVisitorGroupsForTenant_getVisitorGroupsForTenant_defaultAccessGrants_doors
  | GetVisitorGroupsForTenant_getVisitorGroupsForTenant_scheduledAccessGrants_doors
  | UpdateDoor_updateDoor {
  return {
    __typename: 'Door',
    id,
    name,
    location,
    domSerialNumber,
    batteryWarningLevel
  };
}
