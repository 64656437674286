import React, { ChangeEvent, useCallback, useState } from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import EditScheduleRow from 'ui/organisms/EditScheduleRow/EditScheduleRow/EditScheduleRow';
import {
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants_accessSchedule_weekDays,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule_weekDays
} from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import useSchedules, { DayName } from 'hooks/useSchedules/useSchedules';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { WeekScheduleDayInput } from 'graphql/generated/globalTypes';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import Separator from 'ui/atoms/Separator/Separator';
import EditScheduleRowAllDay from 'ui/organisms/EditScheduleRow/EditScheduleRowAllDay/EditScheduleRowAllDay';
import useTranslation, { Translation } from 'hooks/useTranslation/useTranslation';
import { getTranslatedDayName } from 'pages/Access/SchedulesPage/SchedulesPage';

dayjs.extend(isSameOrBefore);

const CONSTANT_DATE = '2000-01-01';

const compareTime = (from: string, to: string) =>
  dayjs(`${CONSTANT_DATE} ${to}`).isSameOrBefore(dayjs(`${CONSTANT_DATE} ${from}`));

const getTimeWithSec = (time: string) => dayjs(`${CONSTANT_DATE} ${time}`).format('HH:mm:ss');
const getTimeWithoutSec = (time?: string) => (time ? dayjs(`${CONSTANT_DATE} ${time}`).format('HH:mm') : undefined);

export interface DayState {
  isDayEnable: boolean;
  weekDays:
    | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule_weekDays
    | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants_accessSchedule_weekDays
    | null;
  displayName: string;
}

export interface AllDaysState {
  isAllDaysEnabled: boolean;
  from?: string;
  to?: string;
}

const getInitialStateDayByNameDay = (
  weekDays:
    | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants_accessSchedule_weekDays[]
    | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule_weekDays[],
  dayName: DayName,
  allDays: AllDaysState,
  translation: Translation
): DayState => {
  const days = weekDays.filter((day) => day.dayName === dayName);
  if (days.length > 0)
    return { isDayEnable: true, weekDays: days[0], displayName: getTranslatedDayName(dayName, translation) };
  if (allDays.isAllDaysEnabled && allDays.from && allDays.to) {
    return {
      isDayEnable: false,
      weekDays: {
        __typename: 'WeekScheduleDay',
        dayName,
        from: allDays.from,
        to: allDays.to
      },
      displayName: getTranslatedDayName(dayName, translation)
    };
  }
  return {
    isDayEnable: false,
    weekDays: {
      __typename: 'WeekScheduleDay',
      dayName,
      from: '07:00',
      to: '22:00'
    },
    displayName: getTranslatedDayName(dayName, translation)
  };
};

const getInitialStateForAllDay = (
  weekDays:
    | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants_accessSchedule_weekDays[]
    | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule_weekDays[]
): AllDaysState => {
  const isDaysTimesSameForEveryDay =
    weekDays.length > 0 &&
    weekDays.filter((day) => day.from === weekDays[0]?.from && day.to === weekDays[0]?.to).length === weekDays.length;
  return {
    isAllDaysEnabled: isDaysTimesSameForEveryDay,
    from: weekDays[0]?.from || undefined,
    to: weekDays[0]?.to || undefined
  };
};
const handleDaySwitchOnChange = (setHandler: React.Dispatch<React.SetStateAction<DayState>>, day: DayState) =>
  setHandler({
    isDayEnable: !day.isDayEnable,
    weekDays: day.weekDays,
    displayName: day.displayName
  });

const handleInputTimeOnChange = (
  setHandler: React.Dispatch<React.SetStateAction<DayState>>,
  day: DayState,
  valueFrom?: string,
  valueTo?: string
) => {
  setHandler({
    isDayEnable: day.isDayEnable,
    weekDays: { ...day.weekDays!, from: valueFrom || day.weekDays!.from, to: valueTo || day.weekDays!.to },
    displayName: day.displayName
  });
};

const EditSchedule: React.FC = () => {
  const { translation } = useTranslation();
  const { hideModal, modalState } = useModal();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { updateAccessGrant, updateAccessGrantMutationLoading } = useSchedules({ handleFetchError });
  const group: GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant = modalState.contentValue;
  const grant =
    group[
      group.defaultAccessGrants.length > group.scheduledAccessGrants.length
        ? 'defaultAccessGrants'
        : 'scheduledAccessGrants'
    ][0];

  const [allDays, setAllDays] = useState<AllDaysState>(getInitialStateForAllDay(grant.accessSchedule.weekDays));
  const [monday, setMonday] = useState<DayState>(
    getInitialStateDayByNameDay(grant.accessSchedule.weekDays, 'monday', allDays, translation)
  );
  const [tuesday, setTuesday] = useState<DayState>(
    getInitialStateDayByNameDay(grant.accessSchedule.weekDays, 'tuesday', allDays, translation)
  );
  const [wednesday, setWednesday] = useState<DayState>(
    getInitialStateDayByNameDay(grant.accessSchedule.weekDays, 'wednesday', allDays, translation)
  );
  const [thursday, setThursday] = useState<DayState>(
    getInitialStateDayByNameDay(grant.accessSchedule.weekDays, 'thursday', allDays, translation)
  );
  const [friday, setFriday] = useState<DayState>(
    getInitialStateDayByNameDay(grant.accessSchedule.weekDays, 'friday', allDays, translation)
  );
  const [saturday, setSaturday] = useState<DayState>(
    getInitialStateDayByNameDay(grant.accessSchedule.weekDays, 'saturday', allDays, translation)
  );
  const [sunday, setSunday] = useState<DayState>(
    getInitialStateDayByNameDay(grant.accessSchedule.weekDays, 'sunday', allDays, translation)
  );

  const getWeekScheduleDayInputItem = useCallback(
    (day: DayState): WeekScheduleDayInput | null => {
      if (day.weekDays) {
        if (allDays.isAllDaysEnabled && allDays.from && allDays.to) {
          return {
            dayName: day.weekDays.dayName,
            from: getTimeWithSec(allDays.from),
            to: getTimeWithSec(allDays.to)
          };
        }
        return {
          dayName: day.weekDays.dayName,
          from: getTimeWithSec(day.weekDays.from),
          to: getTimeWithSec(day.weekDays.to)
        };
      }
      return null;
    },
    [allDays.from, allDays.isAllDaysEnabled, allDays.to]
  );

  const getWeekScheduleDayInputArray = useCallback((): WeekScheduleDayInput[] => {
    const weekSchedule = [];
    if (monday.isDayEnable && monday.weekDays) weekSchedule.push(getWeekScheduleDayInputItem(monday));
    if (tuesday.isDayEnable && tuesday.weekDays) weekSchedule.push(getWeekScheduleDayInputItem(tuesday));
    if (wednesday.isDayEnable && wednesday.weekDays) weekSchedule.push(getWeekScheduleDayInputItem(wednesday));
    if (thursday.isDayEnable && thursday.weekDays) weekSchedule.push(getWeekScheduleDayInputItem(thursday));
    if (friday.isDayEnable && friday.weekDays) weekSchedule.push(getWeekScheduleDayInputItem(friday));
    if (saturday.isDayEnable && saturday.weekDays) weekSchedule.push(getWeekScheduleDayInputItem(saturday));
    if (sunday.isDayEnable && sunday.weekDays) weekSchedule.push(getWeekScheduleDayInputItem(sunday));
    return weekSchedule as WeekScheduleDayInput[];
  }, [friday, getWeekScheduleDayInputItem, monday, saturday, sunday, thursday, tuesday, wednesday]);

  const isCorrectTimeSetForDay = (day: DayState) =>
    !day.isDayEnable || !compareTime(day.weekDays!.from, day.weekDays!.to);

  const isCorrectTimeSetForAllDays = () =>
    isCorrectTimeSetForDay(monday) &&
    isCorrectTimeSetForDay(tuesday) &&
    isCorrectTimeSetForDay(wednesday) &&
    isCorrectTimeSetForDay(thursday) &&
    isCorrectTimeSetForDay(friday) &&
    isCorrectTimeSetForDay(saturday) &&
    isCorrectTimeSetForDay(sunday);

  const setEveryDayInputValue = (from?: string, to?: string) => {
    const fromWithSec = from ? getTimeWithSec(from) : undefined;
    const toWithSec = to ? getTimeWithSec(to) : undefined;
    handleInputTimeOnChange(setMonday, monday, fromWithSec, toWithSec);
    handleInputTimeOnChange(setTuesday, tuesday, fromWithSec, toWithSec);
    handleInputTimeOnChange(setWednesday, wednesday, fromWithSec, toWithSec);
    handleInputTimeOnChange(setThursday, thursday, fromWithSec, toWithSec);
    handleInputTimeOnChange(setFriday, friday, fromWithSec, toWithSec);
    handleInputTimeOnChange(setSaturday, saturday, fromWithSec, toWithSec);
    handleInputTimeOnChange(setSunday, sunday, fromWithSec, toWithSec);
  };

  const handleAllDaysSwitchOnChange = () => {
    if (allDays.isAllDaysEnabled === true) {
      setAllDays({ ...allDays, isAllDaysEnabled: false });
    } else if (allDays.isAllDaysEnabled === false) {
      if (allDays.from === undefined && allDays.to === undefined) {
        setAllDays({ isAllDaysEnabled: true, from: '07:00', to: '22:00' });
        setEveryDayInputValue(allDays.from, allDays.to);
      } else {
        setEveryDayInputValue(allDays.from, allDays.to);
        setAllDays({ ...allDays, isAllDaysEnabled: true });
      }
    }
  };

  const handleAllDaysInputFromOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (allDays.isAllDaysEnabled) {
      setAllDays({ ...allDays, from: event.target.value });
      setEveryDayInputValue(event.target.value);
    }
  };

  const handleAllDaysInputToOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (allDays.isAllDaysEnabled) {
      setAllDays({ ...allDays, to: event.target.value });
      setEveryDayInputValue(undefined, event.target.value);
    }
  };

  const handleUpdateSchedule = async () => {
    const weekScheduleDayInputArray = getWeekScheduleDayInputArray();
    if (isCorrectTimeSetForAllDays() && !updateAccessGrantMutationLoading) {
      updateAccessGrant(grant.id, {
        scheduleType: 'weekSchedule',
        weekSchedule: weekScheduleDayInputArray
      });
      hideModal();
    }
  };

  return (
    <Modal>
      <ComponentWrapper
        flexDirection="column"
        width="45rem"
        gap="2rem"
        margin="1rem 0"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="Calendar" color="dTextHigh" width={40} height={40} viewBox="0 0 24 24" id="delete-bin-icon" />
        <Typography variant="title2" color="dTextHigh">
          {translation.edit_schedule}
        </Typography>
        <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center" width="100%">
          <EditScheduleRowAllDay
            day={allDays}
            switchChecked={allDays.isAllDaysEnabled}
            handleSwitchOnChange={handleAllDaysSwitchOnChange}
            inputFromValue={getTimeWithoutSec(allDays.from)}
            handleInputFromOnChange={handleAllDaysInputFromOnChange}
            setDay={setAllDays}
            inputValidationFailed={allDays.from && allDays.to ? compareTime(allDays.from, allDays.to) : false}
            inputToValue={getTimeWithoutSec(allDays.to)}
            handleInputToOnChange={handleAllDaysInputToOnChange}
            onKeyDown={(event) => formKeyDownHandler(event, handleUpdateSchedule)}
          />
          <ComponentWrapper width="85%" margin="1.5rem 0 1.5rem 0">
            <Separator color="b6" />
          </ComponentWrapper>
          <EditScheduleRow
            variant={allDays.isAllDaysEnabled ? 'label' : 'input'}
            day={monday}
            switchChecked={monday.isDayEnable}
            handleSwitchOnChange={() => handleDaySwitchOnChange(setMonday, monday)}
            inputFromValue={getTimeWithoutSec(monday.weekDays!.from)}
            handleInputTimeOnChange={handleInputTimeOnChange}
            setDay={setMonday}
            inputValidationFailed={
              monday.weekDays?.from && monday.weekDays?.to
                ? compareTime(monday.weekDays.from, monday.weekDays.to)
                : false
            }
            inputToValue={getTimeWithoutSec(monday.weekDays!.to)}
            disabledInputs={allDays.isAllDaysEnabled}
            onKeyDown={(event) => formKeyDownHandler(event, handleUpdateSchedule)}
          />
          <EditScheduleRow
            variant={allDays.isAllDaysEnabled ? 'label' : 'input'}
            day={tuesday}
            switchChecked={tuesday.isDayEnable}
            handleSwitchOnChange={() => handleDaySwitchOnChange(setTuesday, tuesday)}
            inputFromValue={getTimeWithoutSec(tuesday.weekDays!.from)}
            handleInputTimeOnChange={handleInputTimeOnChange}
            setDay={setTuesday}
            inputValidationFailed={
              tuesday.weekDays?.from && tuesday.weekDays?.to
                ? compareTime(tuesday.weekDays.from, tuesday.weekDays.to)
                : false
            }
            inputToValue={getTimeWithoutSec(tuesday.weekDays!.to)}
            disabledInputs={allDays.isAllDaysEnabled}
            onKeyDown={(event) => formKeyDownHandler(event, handleUpdateSchedule)}
          />
          <EditScheduleRow
            variant={allDays.isAllDaysEnabled ? 'label' : 'input'}
            day={wednesday}
            switchChecked={wednesday.isDayEnable}
            handleSwitchOnChange={() => handleDaySwitchOnChange(setWednesday, wednesday)}
            inputFromValue={getTimeWithoutSec(wednesday.weekDays!.from)}
            handleInputTimeOnChange={handleInputTimeOnChange}
            setDay={setWednesday}
            inputValidationFailed={
              wednesday.weekDays?.from && wednesday.weekDays?.to
                ? compareTime(wednesday.weekDays.from, wednesday.weekDays.to)
                : false
            }
            inputToValue={getTimeWithoutSec(wednesday.weekDays!.to)}
            disabledInputs={allDays.isAllDaysEnabled}
            onKeyDown={(event) => formKeyDownHandler(event, handleUpdateSchedule)}
          />
          <EditScheduleRow
            variant={allDays.isAllDaysEnabled ? 'label' : 'input'}
            day={thursday}
            switchChecked={thursday.isDayEnable}
            handleSwitchOnChange={() => handleDaySwitchOnChange(setThursday, thursday)}
            inputFromValue={getTimeWithoutSec(thursday.weekDays!.from)}
            handleInputTimeOnChange={handleInputTimeOnChange}
            setDay={setThursday}
            inputValidationFailed={
              thursday.weekDays?.from && thursday.weekDays?.to
                ? compareTime(thursday.weekDays.from, thursday.weekDays.to)
                : false
            }
            inputToValue={getTimeWithoutSec(thursday.weekDays!.to)}
            disabledInputs={allDays.isAllDaysEnabled}
            onKeyDown={(event) => formKeyDownHandler(event, handleUpdateSchedule)}
          />
          <EditScheduleRow
            variant={allDays.isAllDaysEnabled ? 'label' : 'input'}
            day={friday}
            switchChecked={friday.isDayEnable}
            handleSwitchOnChange={() => handleDaySwitchOnChange(setFriday, friday)}
            inputFromValue={getTimeWithoutSec(friday.weekDays!.from)}
            handleInputTimeOnChange={handleInputTimeOnChange}
            setDay={setFriday}
            inputValidationFailed={
              friday.weekDays?.from && friday.weekDays?.to
                ? compareTime(friday.weekDays.from, friday.weekDays.to)
                : false
            }
            inputToValue={getTimeWithoutSec(friday.weekDays!.to)}
            disabledInputs={allDays.isAllDaysEnabled}
            onKeyDown={(event) => formKeyDownHandler(event, handleUpdateSchedule)}
          />
          <EditScheduleRow
            variant={allDays.isAllDaysEnabled ? 'label' : 'input'}
            day={saturday}
            switchChecked={saturday.isDayEnable}
            handleSwitchOnChange={() => handleDaySwitchOnChange(setSaturday, saturday)}
            inputFromValue={getTimeWithoutSec(saturday.weekDays!.from)}
            handleInputTimeOnChange={handleInputTimeOnChange}
            setDay={setSaturday}
            inputValidationFailed={
              saturday.weekDays?.from && saturday.weekDays?.to
                ? compareTime(saturday.weekDays.from, saturday.weekDays.to)
                : false
            }
            inputToValue={getTimeWithoutSec(saturday.weekDays!.to)}
            disabledInputs={allDays.isAllDaysEnabled}
            onKeyDown={(event) => formKeyDownHandler(event, handleUpdateSchedule)}
          />
          <EditScheduleRow
            variant={allDays.isAllDaysEnabled ? 'label' : 'input'}
            day={sunday}
            switchChecked={sunday.isDayEnable}
            handleSwitchOnChange={() => handleDaySwitchOnChange(setSunday, sunday)}
            inputFromValue={getTimeWithoutSec(sunday.weekDays!.from)}
            handleInputTimeOnChange={handleInputTimeOnChange}
            setDay={setSunday}
            inputValidationFailed={
              sunday.weekDays?.from && sunday.weekDays?.to
                ? compareTime(sunday.weekDays.from, sunday.weekDays.to)
                : false
            }
            inputToValue={getTimeWithoutSec(sunday.weekDays!.to)}
            disabledInputs={allDays.isAllDaysEnabled}
            onKeyDown={(event) => formKeyDownHandler(event, handleUpdateSchedule)}
          />
        </ComponentWrapper>
        <ComponentWrapper width="25.75rem" gap="0.75rem">
          <Button backgroundColor="transparent" textColor="b1" onClick={hideModal} id="edit-schedule-cancel-button">
            {translation.cancel}
          </Button>
          <Button
            onClick={handleUpdateSchedule}
            textColor={isCorrectTimeSetForAllDays() ? 'lTextHigh' : 'dTextLow'}
            disabled={!isCorrectTimeSetForAllDays()}
            id="edit-schedule-save-button"
            backgroundColor={isCorrectTimeSetForAllDays() ? 'primary' : 'b5'}
          >
            {translation.save_schedule}
          </Button>
        </ComponentWrapper>
      </ComponentWrapper>
    </Modal>
  );
};

export default EditSchedule;
