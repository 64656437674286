import styled from 'styled-components';
import backgroundLogin from 'assets/images/backgroundLogin.png';

const BackgroundImage = styled.div`
  background-image: url(${backgroundLogin});
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  filter: blur(0.625rem);
  overflow: hidden;
  z-index: -1;
  position: absolute;
  background-size: cover;
`;

export default BackgroundImage;
