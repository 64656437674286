import React from 'react';
import styled, { keyframes } from 'styled-components';
import { BORDER_RADIUS_MULTIPLIER } from 'ui/shared/const';
import ArrayUtil from 'utils/Array/Array.util';

export interface Props {
  size?: number;
  id?: string;
}

const StyledLoader = styled.div<Required<Props>>`
  position: relative;
  width: ${(props) => props.size / props.theme.typography.size}rem;
  height: ${(props) => props.size / props.theme.typography.size}rem;
`;

const fade = keyframes`
0% { opacity: 1; }
100% { opacity: 0.25; }
`;

interface BarProps {
  key: number;
  index: number;
}

const rotateDeg = 45;
const startingAnimationTime = -0.9;
const animationDivider = 10;

const StyledBar = styled.div<Partial<BarProps>>`
  width: 6%;
  height: 16%;
  background-color: ${(props) => props.theme.colors.primary};
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  border-radius: ${(props) => props.theme.borderRadius * BORDER_RADIUS_MULTIPLIER}px;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: ${fade};

  transform: rotate(${({ index }) => index! * rotateDeg}deg) translate(0, -130%);
  animation-delay: ${({ index }) => startingAnimationTime + index! / animationDivider}s;
`;

const DEFAULT_LOADER_SIZE = 24;
const arrayLength = 9;

const Loader: React.FC<Props> = ({ size = DEFAULT_LOADER_SIZE, id }) => (
  <StyledLoader size={size} data-testid="Loader" id={id || 'Loader'}>
    {ArrayUtil.Range(arrayLength).map((value) => (
      <StyledBar key={value} index={value} />
    ))}
  </StyledLoader>
);

export default Loader;
