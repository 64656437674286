import { externalUsersGroupsVar } from 'state/vars';
import { GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { AddVisitor_addVisitor } from 'graphql/generated/AddVisitor';
import { SetVisitor_setVisitor } from 'graphql/generated/SetVisitor';
import { AddVisitorWithRole_addVisitor } from 'graphql/generated/AddVisitorWithRole';
import { SetVisitorWithRole_setVisitor } from 'graphql/generated/SetVisitorWithRole';

export const setExternalUsersGroupsAction = (
  groups: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant[]
) => {
  externalUsersGroupsVar(groups);
};

export const addExternalUsersGroupsAction = (
  addedGroup: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant
) => {
  const currentGroups = externalUsersGroupsVar();
  externalUsersGroupsVar([...currentGroups, addedGroup]);
};

export const updateExternalUsersGroupsAction = (
  updatedGroup: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant
) => {
  const currentGroups = externalUsersGroupsVar();
  const updatedGroups = currentGroups.map((group) => {
    if (group.id === updatedGroup.id) {
      return updatedGroup;
    }
    return group;
  });
  externalUsersGroupsVar(updatedGroups);
};

export const addExternalUsersMemberAction = (
  member: AddVisitor_addVisitor | AddVisitorWithRole_addVisitor,
  visitorGroupExternalRefs: string
) => {
  const currentGroups = externalUsersGroupsVar();
  const updatedGroups = currentGroups.map((group) => {
    if (group.externalRef === visitorGroupExternalRefs) {
      return { ...group, members: [...group.members, member] };
    }
    return group;
  });
  externalUsersGroupsVar(updatedGroups);
};

export const deleteVisitorGroupByIdAction = (visitorGroupId: string | null) => {
  const currentGroups = externalUsersGroupsVar();
  const updatedGroups = currentGroups.filter((group) => group.id !== visitorGroupId);
  externalUsersGroupsVar(updatedGroups);
};

export const setExternalUsersMemberAction = (
  updatedMember: SetVisitor_setVisitor | SetVisitorWithRole_setVisitor,
  visitorGroupExternalRefs: string[]
) => {
  const currentGroups = externalUsersGroupsVar();
  const updatedGroups = currentGroups.map((group) => {
    if (group.externalRef === visitorGroupExternalRefs[0])
      if (group.members.some((member) => member.id === updatedMember.id))
        return {
          ...group,
          members: group.members.map((member) => (member.id === updatedMember.id ? updatedMember : member))
        };
      else
        return {
          ...group,
          members: [...group.members, updatedMember]
        };
    return {
      ...group,
      members: group.members.filter((member) => member.id !== updatedMember.id)
    };
  });
  externalUsersGroupsVar(updatedGroups);
};

export const deleteVisitorByIdAction = (visitorId: string | null) => {
  const currentGroups = externalUsersGroupsVar();

  const groupWithDeletedMember = currentGroups.filter((group) =>
    group.members.some((member) => member.id === visitorId)
  )[0];

  const updatedGroups = currentGroups.map((group) => {
    if (group.id === groupWithDeletedMember.id)
      return {
        ...group,
        members: group.members.filter((member) => member.id !== visitorId)
      };
    return group;
  });

  externalUsersGroupsVar(updatedGroups);
};
