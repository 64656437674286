import React from 'react';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Typography from '../Typography/Typography';

export interface Props {
  withAdditionalText?: boolean;
}

const Title: React.FC<Props> = ({ withAdditionalText = false }) => (
  <ComponentWrapper alignItems="start" data-testid="title" id="logo-title" flexDirection="column">
    <Typography variant="title1" color="primary">
      ATLAS
    </Typography>
    {withAdditionalText && <Typography variant="label">by TKH SECURITY</Typography>}
  </ComponentWrapper>
);

export default Title;
