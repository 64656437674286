import { useReactiveVar } from '@apollo/client';
import useBatteryStatusUI from 'hooks/useBatteryStatusUI/useBatteryStatusUI';
import { Translation } from 'hooks/useTranslation/useTranslation';
import { orderBy } from 'lodash';
import { useCallback, useState } from 'react';
import { GetDoorsForTenantWithLocations_getDoorsForTenant_complete } from 'state/types';
import { doorsWithLocationsVar } from 'state/vars';

interface Sorting {
  isAscending?: boolean;
  columnName?: string;
}

const useSortingLocks = (translation: Translation) => {
  const doorsWithLocations = useReactiveVar(doorsWithLocationsVar);
  const [sorting, setSorting] = useState<Sorting>({ isAscending: true, columnName: translation.name });
  const { getBatteryStatus, getBatteryData } = useBatteryStatusUI(translation);

  const handleSorting = useCallback(
    (item: GetDoorsForTenantWithLocations_getDoorsForTenant_complete, columnName?: string) => {
      switch (columnName) {
        case translation.serial_number:
          return item.domSerialNumber;
        case translation.room:
          return item.externalLocation?.name;
        case translation.battery_status:
          return getBatteryData(getBatteryStatus(item.batteryWarningLevel)).sortNumber;
        case translation.office_mode:
          return item.officeMode.weekDays.some((day) => day !== null)
            ? // eslint-disable-next-line no-magic-numbers
              item.officeMode.weekDays[0].from.slice(0, -3)
            : '';
        case translation.name:
        default:
          return item.name;
      }
    },
    [
      getBatteryData,
      getBatteryStatus,
      translation.battery_status,
      translation.name,
      translation.office_mode,
      translation.room,
      translation.serial_number
    ]
  );

  const handleSortingOnClick = useCallback(
    (columnName: string) => {
      if (sorting.isAscending !== undefined && sorting.columnName === columnName) {
        return setSorting({ isAscending: !sorting.isAscending, columnName });
      }
      return setSorting({ isAscending: true, columnName });
    },
    [sorting.columnName, sorting.isAscending]
  );

  const handleSortingIcon = (columnName: string) => {
    if (sorting.isAscending !== undefined)
      if (sorting.columnName === columnName) return sorting.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  const getSortedArray = useCallback(
    () =>
      orderBy(doorsWithLocations, (item) => handleSorting(item, sorting.columnName), [
        sorting.isAscending ? 'asc' : 'desc'
      ]),
    [doorsWithLocations, handleSorting, sorting.columnName, sorting.isAscending]
  );

  return { handleSortingOnClick, handleSortingIcon, getSortedArray };
};

export default useSortingLocks;
