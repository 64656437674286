import { gql } from '@apollo/client';

export const ON_OFFICE_DAY_ADDED_BY_ID = gql`
  subscription OnOfficeDayAddedById($visitorId: ID!) {
    onOfficeDayAddedById(visitorId: $visitorId) {
      date
    }
  }
`;

export const ON_OFFICE_DAY_DELETED_BY_ID = gql`
  subscription OnOfficeDayDeletedById($visitorId: ID!) {
    onOfficeDayDeletedById(visitorId: $visitorId) {
      date
    }
  }
`;

export const ON_MOBILE_DEVICE_REGISTERED_BY_ID = gql`
  subscription onMobileDeviceRegisteredById($visitorId: ID!) {
    onMobileDeviceRegisteredById(visitorId: $visitorId) {
      visitorId
      mobileDeviceId
      registrationDate
    }
  }
`;

export const ON_REGISTRATION_TOKEN_SAVED_BY_ID = gql`
  subscription onRegistrationTokenSavedById($visitorId: ID!) {
    onRegistrationTokenSavedById(visitorId: $visitorId) {
      visitorId
      mobileDeviceId
      registrationToken
    }
  }
`;
