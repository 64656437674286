import React from 'react';
import styled from 'styled-components';
import Tooltip, { Props as TooltipProps } from 'ui/atoms/Tooltip/Tooltip';
import Icon, { Props as IconProps } from '../../atoms/Icon/Icon';

interface CssProps {
  columnGap?: string;
}

export type Props = CssProps & {
  id?: string;
  placeForAvatarOrSwitchOrCheckbox?: boolean;
  placeForArrow?: boolean;
  iconBeforeHeaderProps?: Pick<
    IconProps,
    'name' | 'height' | 'width' | 'color' | 'opacity' | 'onClick' | 'viewBox' | 'id' | 'stroke'
  >;
  iconAfterHeaderHover?: boolean;
  iconAfterHeaderProps?: Pick<
    IconProps,
    'name' | 'height' | 'width' | 'color' | 'opacity' | 'onClick' | 'viewBox' | 'id'
  >;

  iconAfterHeaderBorder?: boolean;
  tooltipForIconAfterHeader?: TooltipProps;
  disabled?: boolean;
};

const TableHeaderRowWrapper = styled.div<Partial<Props>>`
  height: 3.375rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: static;
`;

const IconWrapper = styled.div<Partial<Props>>`
  margin: 0 0.25rem 0 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;

  border: ${({ iconAfterHeaderBorder, theme }) =>
    iconAfterHeaderBorder ? `1px solid ${theme.colors.secondary}` : undefined};
  padding: ${({ iconAfterHeaderBorder }) => (iconAfterHeaderBorder ? `0.5rem` : undefined)};
  cursor: ${({ iconBeforeHeaderProps, iconAfterHeaderProps }) =>
    iconBeforeHeaderProps?.onClick || iconAfterHeaderProps?.onClick ? 'pointer' : ''};

  ${({ iconAfterHeaderProps, theme }) =>
    iconAfterHeaderProps
      ? `
      background-color: ${theme.colors.b2};
      border-radius: ${theme.borderRadius}px;
    `
      : `
    `};
`;

const HeadersRowWrapper = styled.div<Partial<Props>>`
  height: 3.375rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: static;
  column-gap: ${({ columnGap }) => columnGap};
  width: 100%;
  ${({ iconAfterHeaderProps }) =>
    iconAfterHeaderProps
      ? `
    `
      : `
      flex: 1 1 100%;
    `};

  ${({ placeForAvatarOrSwitchOrCheckbox }) =>
    placeForAvatarOrSwitchOrCheckbox
      ? `
      margin-left: 4.75rem;
    `
      : `
      margin-left: 1rem
    `};

  ${({ placeForArrow }) =>
    placeForArrow
      ? `
      margin-right: 3.5625rem;
    `
      : `
      margin-right: 1rem
    `};
`;

const TableHeaderRow: React.FC<Props> = ({
  columnGap = '1rem',
  children,
  placeForAvatarOrSwitchOrCheckbox = false,
  placeForArrow = false,
  iconBeforeHeaderProps,
  iconAfterHeaderHover = false,
  iconAfterHeaderProps,
  iconAfterHeaderBorder = false,
  tooltipForIconAfterHeader,
  id,
  disabled
}) => (
  <TableHeaderRowWrapper id={id} disabled={disabled}>
    {iconBeforeHeaderProps?.name && (
      <IconWrapper iconBeforeHeaderProps={iconBeforeHeaderProps}>
        <Icon {...iconBeforeHeaderProps} />
      </IconWrapper>
    )}
    <HeadersRowWrapper
      columnGap={columnGap}
      placeForAvatarOrSwitchOrCheckbox={placeForAvatarOrSwitchOrCheckbox}
      placeForArrow={placeForArrow}
      iconAfterHeaderProps={iconAfterHeaderProps}
      iconAfterHeaderBorder={iconAfterHeaderBorder}
    >
      {children}
    </HeadersRowWrapper>

    {iconAfterHeaderProps?.name &&
      (tooltipForIconAfterHeader ? (
        <Tooltip {...tooltipForIconAfterHeader}>
          <IconWrapper
            iconAfterHeaderProps={iconAfterHeaderProps}
            iconAfterHeaderHover={iconAfterHeaderHover}
            iconAfterHeaderBorder={iconAfterHeaderBorder}
            onClick={(iconAfterHeaderProps.onClick as unknown as React.MouseEventHandler<HTMLDivElement>) ?? undefined}
          >
            <Icon {...iconAfterHeaderProps} />
          </IconWrapper>
        </Tooltip>
      ) : (
        <IconWrapper
          iconAfterHeaderProps={iconAfterHeaderProps}
          iconAfterHeaderBorder={iconAfterHeaderBorder}
          onClick={(iconAfterHeaderProps.onClick as unknown as React.MouseEventHandler<HTMLDivElement>) ?? undefined}
        >
          <Icon {...iconAfterHeaderProps} />
        </IconWrapper>
      ))}
  </TableHeaderRowWrapper>
);

export default TableHeaderRow;
