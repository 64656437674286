import { AllDaysState } from 'components/Modal/variants/EditSchedule';
import useTranslation from 'hooks/useTranslation/useTranslation';
import React, { ChangeEvent, MouseEventHandler } from 'react';
import Switch from 'ui/atoms/Switch/Switch';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  day: AllDaysState;
  switchChecked?: boolean;
  handleSwitchOnChange: MouseEventHandler<HTMLInputElement>;
  inputFromValue?: string;
  setDay: React.Dispatch<React.SetStateAction<AllDaysState>>;
  handleInputFromOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inputToValue?: string;
  handleInputToOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inputValidationFailed?: boolean;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const EditScheduleRowAllDay: React.FC<Props> = ({
  switchChecked = false,
  handleSwitchOnChange,
  inputFromValue,
  handleInputFromOnChange,
  inputToValue,
  handleInputToOnChange,
  inputValidationFailed,
  onKeyDown
}) => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper flexDirection="row" justifyContent="center" alignItems="center" width="100%" gap="1.5rem">
      <ComponentWrapper flexDirection="row" justifyContent="space-between" width="13rem" alignItems="center" gap="1rem">
        <ComponentWrapper width="10rem">
          <Typography variant="body1" color="dTextHigh">
            {translation.same_every_day}
          </Typography>
        </ComponentWrapper>
        <Switch
          variant="dark"
          checked={switchChecked}
          onClick={handleSwitchOnChange}
          id="edit-schedule-switch-all-days"
        />
      </ComponentWrapper>
      <ComponentWrapper flexDirection="row" gap="0.625rem" alignItems="center">
        <ComponentWrapper width="10.25rem">
          <Input
            type="time"
            value={inputFromValue}
            onChange={(event) => handleInputFromOnChange(event)}
            disabled={!switchChecked}
            failed={inputValidationFailed}
            hideValidationText
            hideValidationEmptySpace
            hideMarginTop
            id="edit-schedule-input-from-all-days"
            required
            onKeyDown={onKeyDown}
          />
        </ComponentWrapper>
        <Typography variant="body1" color="dTextHigh">
          {translation.to}
        </Typography>
        <ComponentWrapper width="10.25rem">
          <Input
            type="time"
            value={inputToValue}
            onChange={(event) => handleInputToOnChange(event)}
            disabled={!switchChecked}
            failed={inputValidationFailed}
            hideValidationText
            hideValidationEmptySpace
            hideMarginTop
            id="edit-schedule-input-to-all-days"
            required
            onKeyDown={onKeyDown}
          />
        </ComponentWrapper>
      </ComponentWrapper>
    </ComponentWrapper>
  );
};

export default EditScheduleRowAllDay;
