/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenant_getVisitorGroupsForTenant_defaultAccessGrants,
  GetVisitorGroupsForTenant_getVisitorGroupsForTenant_defaultAccessGrants_doors,
  GetVisitorGroupsForTenant_getVisitorGroupsForTenant_scheduledAccessGrants,
  GetVisitorGroupsForTenant_getVisitorGroupsForTenant_scheduledAccessGrants_doors
} from 'graphql/generated/GetVisitorGroupsForTenant';

interface Props {
  id: string;
  doors:
    | Array<GetVisitorGroupsForTenant_getVisitorGroupsForTenant_defaultAccessGrants_doors>
    | Array<GetVisitorGroupsForTenant_getVisitorGroupsForTenant_scheduledAccessGrants_doors>;
}

export function createMockedAccessGrant({
  id,
  doors
}: Props):
  | GetVisitorGroupsForTenant_getVisitorGroupsForTenant_defaultAccessGrants
  | GetVisitorGroupsForTenant_getVisitorGroupsForTenant_scheduledAccessGrants {
  return {
    __typename: 'AccessGrant',
    id,
    doors
  };
}
