import React from 'react';

const Sort: React.FC = () => (
  <>
    <path
      d="M11.4959 2.50422L9.16254 0.170864C9.16243 0.170754 9.16233 0.1707 9.16224 0.17059C9.14874 0.15711 9.13457 0.14434 9.11981 0.132227C9.11305 0.126676 9.10594 0.121864 9.099 0.116668C9.09071 0.110461 9.08259 0.104036 9.07395 0.0982661C9.06564 0.0927153 9.05702 0.0879028 9.04849 0.0828169C9.04067 0.0781411 9.03304 0.0732466 9.025 0.0689263C9.01631 0.0642778 9.0074 0.0603403 8.99851 0.0561567C8.99011 0.0521919 8.98186 0.0480083 8.97327 0.0444263C8.96466 0.0408716 8.95585 0.0379731 8.9471 0.034856C8.93789 0.0315474 8.92881 0.02802 8.9194 0.0251763C8.91065 0.0225239 8.90174 0.0205825 8.89291 0.0183677C8.88331 0.0159341 8.87382 0.0132544 8.86406 0.011313C8.85381 0.00928955 8.84347 0.00805908 8.83316 0.00658252C8.82466 0.00537939 8.81629 0.00373877 8.80765 0.00289111C8.76937 -0.000882324 8.73079 -0.000882324 8.6925 0.00289111C8.68389 0.00373877 8.67552 0.00535205 8.66699 0.00658252C8.65668 0.00805908 8.64632 0.00926221 8.63609 0.011313C8.62631 0.0132544 8.61684 0.0159341 8.60725 0.0183677C8.59839 0.0205825 8.5895 0.0225239 8.58075 0.0251763C8.57135 0.02802 8.56224 0.0315474 8.55305 0.034856C8.5443 0.0380005 8.5355 0.0408716 8.52688 0.0444263C8.51827 0.047981 8.51004 0.0521919 8.50165 0.0561567C8.49279 0.0603403 8.48384 0.0642778 8.47515 0.0689263C8.46711 0.0732466 8.45948 0.0781411 8.45166 0.0828169C8.44313 0.0879028 8.43452 0.0927153 8.4262 0.0982661C8.41756 0.104036 8.40947 0.110461 8.40116 0.116668C8.39421 0.121864 8.3871 0.126676 8.38035 0.132227C8.36558 0.14434 8.35139 0.15711 8.33791 0.17059C8.3378 0.1707 8.33769 0.170754 8.33761 0.170864L6.00429 2.50419C5.77648 2.73199 5.77648 3.10135 6.00429 3.32915C6.23209 3.55695 6.60145 3.55695 6.82925 3.32915L8.16677 1.99163V8.75002C8.16677 9.07218 8.42793 9.33334 8.75009 9.33334C9.07226 9.33334 9.33342 9.07218 9.33342 8.75002V1.99166L10.6709 3.32918C10.8987 3.55698 11.2681 3.55698 11.4959 3.32918C11.7237 3.10135 11.7237 2.73202 11.4959 2.50422Z"
      fill="#909296"
    />
    <path
      d="M7.17088 10.6709L5.83336 12.0084V5.25001C5.83336 4.92785 5.5722 4.66669 5.25004 4.66669C4.92787 4.66669 4.66671 4.92785 4.66671 5.25001V12.0084L3.32919 10.6709C3.10139 10.4431 2.73203 10.4431 2.50423 10.6709C2.27642 10.8987 2.27642 11.268 2.50423 11.4958L4.83755 13.8291C4.83766 13.8292 4.83777 13.8293 4.83788 13.8294C4.85136 13.8429 4.86556 13.8557 4.88029 13.8678C4.88705 13.8733 4.89418 13.8781 4.90113 13.8834C4.90942 13.8896 4.91754 13.896 4.92615 13.9018C4.93446 13.9073 4.94307 13.9121 4.95161 13.9172C4.95943 13.9219 4.96706 13.9268 4.97509 13.9311C4.98379 13.9357 4.99273 13.9397 5.00162 13.9438C5.00999 13.9478 5.01824 13.952 5.02683 13.9555C5.03544 13.9591 5.04425 13.962 5.053 13.9651C5.06221 13.9684 5.07129 13.972 5.0807 13.9748C5.08945 13.9775 5.09833 13.9794 5.10719 13.9816C5.11679 13.984 5.12628 13.9867 5.13607 13.9887C5.14632 13.9907 5.15666 13.9919 5.16697 13.9934C5.17547 13.9946 5.18386 13.9962 5.19248 13.9971C5.23076 14.0008 5.26934 14.0008 5.30762 13.9971C5.31624 13.9962 5.3246 13.9946 5.33313 13.9934C5.34344 13.9919 5.35381 13.9907 5.36403 13.9886C5.37382 13.9867 5.38328 13.984 5.39288 13.9816C5.40174 13.9794 5.41063 13.9774 5.41938 13.9748C5.42878 13.9719 5.43789 13.9684 5.44708 13.9651C5.45583 13.9619 5.46463 13.9591 5.47324 13.9555C5.48183 13.952 5.49006 13.9478 5.49843 13.9438C5.50731 13.9396 5.51628 13.9357 5.52498 13.931C5.53299 13.9267 5.54062 13.9218 5.54841 13.9172C5.55694 13.9121 5.56558 13.9073 5.5739 13.9017C5.58251 13.8959 5.5906 13.8895 5.59886 13.8834C5.60583 13.8781 5.61297 13.8733 5.61975 13.8677C5.63438 13.8557 5.64846 13.8431 5.66186 13.8297C5.66208 13.8295 5.6623 13.8293 5.66249 13.8291L7.99582 11.4958C8.22362 11.268 8.22362 10.8986 7.99582 10.6708C7.76802 10.443 7.39871 10.4431 7.17088 10.6709Z"
      fill="#909296"
    />
  </>
);

export default Sort;
