import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE
} from 'graphql/queries';
import { useEffect } from 'react';
import { externalUsersGroupsVar } from 'state/vars';
import {
  addExternalUsersGroupsAction,
  addExternalUsersMemberAction,
  deleteVisitorGroupByIdAction,
  deleteVisitorByIdAction,
  setExternalUsersGroupsAction,
  setExternalUsersMemberAction,
  updateExternalUsersGroupsAction
} from 'state/actions/externalUsersGroups';
import {
  ADD_VISITOR_GROUP,
  ADD_VISITOR_GROUP_WITH_RETAIL,
  ADD_VISITOR_WITH_ROLE,
  DELETE_VISITOR_BY_ID,
  DELETE_VISITOR_GROUP_BY_ID,
  SEND_INVITATION_FOR_MOBILE_DEVICE_BY_ID,
  SET_VISITOR_BY_ID_WITH_ROLE,
  UPDATE_VISITOR_GROUP,
  UPDATE_VISITOR_GROUP_WITH_RETAIL
} from 'graphql/mutations';
import { AddVisitorGroup, AddVisitorGroupVariables } from 'graphql/generated/AddVisitorGroup';
import { UpdateVisitorGroup, UpdateVisitorGroupVariables } from 'graphql/generated/UpdateVisitorGroup';
import { DeleteVisitorGroupById, DeleteVisitorGroupByIdVariables } from 'graphql/generated/DeleteVisitorGroupById';
import { DeleteVisitorById, DeleteVisitorByIdVariables } from 'graphql/generated/DeleteVisitorById';
import dayjs from 'dayjs';
import {
  GetVisitorGroupsForTenantWithMembersAndRole,
  GetVisitorGroupsForTenantWithMembersAndRoleVariables
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';
import { VisitorGroupMembershipInput } from 'graphql/generated/globalTypes';
import { AddVisitorWithRole, AddVisitorWithRoleVariables } from 'graphql/generated/AddVisitorWithRole';
import {
  SendInvitationForMobileDeviceById,
  SendInvitationForMobileDeviceByIdVariables
} from 'graphql/generated/SendInvitationForMobileDeviceById';
import { SetVisitorByIdWithRole, SetVisitorByIdWithRoleVariables } from 'graphql/generated/SetVisitorByIdWithRole';
import {
  AddVisitorGroupWithRetail,
  AddVisitorGroupWithRetailVariables
} from 'graphql/generated/AddVisitorGroupWithRetail';
import {
  UpdateVisitorGroupWithRetail,
  UpdateVisitorGroupWithRetailVariables
} from 'graphql/generated/UpdateVisitorGroupWithRetail';
import { BaseHookProps } from '../shared/types';

const useExternalUsers = ({ handleFetchError }: BaseHookProps) => {
  const externalUsersGroups = useReactiveVar(externalUsersGroupsVar);

  const [getExternalUsersMembersWithRole, { loading: loadingWithRole }] = useLazyQuery<
    GetVisitorGroupsForTenantWithMembersAndRole,
    GetVisitorGroupsForTenantWithMembersAndRoleVariables
  >(GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE, {
    onCompleted: (data) => {
      setExternalUsersGroupsAction(data.getVisitorGroupsForTenant);
    },
    onError: () => {
      handleFetchError('Error while fetching external groups');
    }
  });

  useEffect(() => {
    getExternalUsersMembersWithRole({
      variables: {
        filter: { type: 'CO_CONTRACTORS' }
      }
    });
  }, [getExternalUsersMembersWithRole]);

  const [addVisitorGroupMutation, { loading: addVisitorGroupMutationLoading }] = useMutation<
    AddVisitorGroup,
    AddVisitorGroupVariables
  >(ADD_VISITOR_GROUP, {
    onCompleted: (data) => {
      addExternalUsersGroupsAction(data.addVisitorGroup);
    },
    onError: () => {
      handleFetchError('Error while adding external group');
    }
  });

  const [
    addVisitorGroupWithMaxNumberOfMembersMutation,
    { loading: addVisitorGroupWithMaxNumberOfMembersMutationLoading }
  ] = useMutation<AddVisitorGroupWithRetail, AddVisitorGroupWithRetailVariables>(ADD_VISITOR_GROUP_WITH_RETAIL, {
    onCompleted: (data) => {
      addExternalUsersGroupsAction(data.addVisitorGroup);
    },
    onError: () => {
      handleFetchError('Error while adding external group');
    }
  });

  const [updateVisitorGroupMutation, { loading: updateVisitorGroupMutationLoading }] = useMutation<
    UpdateVisitorGroup,
    UpdateVisitorGroupVariables
  >(UPDATE_VISITOR_GROUP, {
    onCompleted: (data) => {
      updateExternalUsersGroupsAction(data.updateVisitorGroup);
    },
    onError: () => {
      handleFetchError('Error while updating external group');
    }
  });

  const [
    updateVisitorGroupWithMaxNumberOfMembersMutation,
    { loading: updateVisitorGroupWithMaxNumberOfMembersMutationLoading }
  ] = useMutation<UpdateVisitorGroupWithRetail, UpdateVisitorGroupWithRetailVariables>(
    UPDATE_VISITOR_GROUP_WITH_RETAIL,
    {
      onCompleted: (data) => {
        updateExternalUsersGroupsAction(data.updateVisitorGroup);
      },
      onError: () => {
        handleFetchError('Error while updating external group');
      }
    }
  );

  const [
    setVisitorByIdWithRoleMutation,
    { error: setVisitorByIdWithRoleMutationError, loading: updateVisitorMemberByIdWithRoleLoading }
  ] = useMutation<SetVisitorByIdWithRole, SetVisitorByIdWithRoleVariables>(SET_VISITOR_BY_ID_WITH_ROLE, {
    onCompleted: (data) => data,
    onError: (error) => {
      if (error.message.includes('Visitor is not only CO_CONTRACTOR'))
        handleFetchError('Expiration date cannot be set for this user, as this user also exist as an Installer');
      else handleFetchError('Error while updating external group member');
    }
  });

  const [addVisitorMemberWithRoleMutation, { loading: addVisitorMemberMutationWithRoleLoading }] = useMutation<
    AddVisitorWithRole,
    AddVisitorWithRoleVariables
  >(ADD_VISITOR_WITH_ROLE, {
    onCompleted: (data) => data,
    onError: (error) => {
      if (error.message === 'Visitor already exists') handleFetchError('Visitor with given email already exists');
      if (error.message === 'Visitor is not only CO_CONTRACTOR')
        handleFetchError('Expiration date cannot be set for this user, as this user also exist as an Installer');
      else handleFetchError('Error while adding external group member');
    }
  });

  const [deleteVisitorByIdMutation, { loading: deleteVisitorByIdMutationLoading }] = useMutation<
    DeleteVisitorById,
    DeleteVisitorByIdVariables
  >(DELETE_VISITOR_BY_ID, {
    onCompleted: (data) => {
      deleteVisitorByIdAction(data.deleteVisitorById);
    },
    onError: () => {
      handleFetchError('Error while deleting Visitor');
    }
  });
  const [
    deleteVisitorGroupByIdMutation,
    { error: deleteVisitorGroupByIdMutationError, loading: deleteVisitorGroupByIdMutationLoading }
  ] = useMutation<DeleteVisitorGroupById, DeleteVisitorGroupByIdVariables>(DELETE_VISITOR_GROUP_BY_ID, {
    onCompleted: (data) => {
      deleteVisitorGroupByIdAction(data.deleteVisitorGroupById);
    }
  });

  const addVisitorGroup = async (name: string) => {
    const { data } = await addVisitorGroupMutation({
      variables: { name, type: 'CO_CONTRACTORS' },
      update(cache, { data: addedGroup }) {
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: [...externalUsersGroups, addedGroup?.addVisitorGroup]
          }
        });
      }
    });
    return data;
  };

  const addVisitorGroupWithMaxNumberOfMembers = async (name: string, maxNumberOfMembers: number | null) => {
    const { data } = await addVisitorGroupWithMaxNumberOfMembersMutation({
      variables: { name, maxNumberOfMembers, type: 'CO_CONTRACTORS' },
      update(cache, { data: addedGroup }) {
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: [...externalUsersGroups, addedGroup?.addVisitorGroup]
          }
        });
      }
    });
    return data;
  };

  const [sendInvitationForMobileDeviceByIdMutation, { loading: sendInvitationForMobileDeviceByIdMutationLoading }] =
    useMutation<SendInvitationForMobileDeviceById, SendInvitationForMobileDeviceByIdVariables>(
      SEND_INVITATION_FOR_MOBILE_DEVICE_BY_ID,
      {
        onError: (error) => {
          if (
            error.message === 'Too many requests' ||
            error.message === 'MobileDevice does not have registration token'
          )
            handleFetchError('Unable to send invite. Please wait 10 minutes before trying again');
          else handleFetchError('Error while resending invitation');
        }
      }
    );

  const updateVisitorGroup = async (name: string, visitorGroupId: string) => {
    const { data } = await updateVisitorGroupMutation({
      variables: { name, visitorGroupId },
      update(cache, { data: updatedGroup }) {
        const updatedGroups = externalUsersGroups.map((group) => {
          if (group.id === updatedGroup?.updateVisitorGroup.id) {
            return updatedGroup?.updateVisitorGroup;
          }
          return group;
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    return data;
  };

  const updateVisitorGroupWithMaxNumberOfMembers = async (
    name: string,
    visitorGroupId: string,
    maxNumberOfMembers: number | null
  ) => {
    const { data } = await updateVisitorGroupWithMaxNumberOfMembersMutation({
      variables: { name, visitorGroupId, maxNumberOfMembers },
      update(cache, { data: updatedGroup }) {
        const updatedGroups = externalUsersGroups.map((group) => {
          if (group.id === updatedGroup?.updateVisitorGroup.id) {
            return updatedGroup?.updateVisitorGroup;
          }
          return group;
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    return data;
  };

  const addVisitorMemberWithRole = async (
    name: string,
    email: string,
    visitorGroupExternalRefs: string[],
    visitorGroupMembership: VisitorGroupMembershipInput[],
    expirationDate?: string
  ) => {
    const { data } = await addVisitorMemberWithRoleMutation({
      variables: {
        name,
        email,
        visitorGroupMembership,
        expirationDate: expirationDate ? dayjs(expirationDate).toISOString() : undefined
      },
      update(cache, { data: addedMember }) {
        const updatedGroups = externalUsersGroups.map((group) => {
          if (group.externalRef === visitorGroupExternalRefs[0] && addedMember) {
            return { ...group, members: [...group.members, addedMember.addVisitor] };
          }
          return group;
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      addExternalUsersMemberAction(data.addVisitor, visitorGroupExternalRefs[0]);
    }
    return data;
  };

  const updateVisitorMemberByIdWithRole = async (
    visitorId: string,
    name: string,
    visitorGroupMembership: VisitorGroupMembershipInput[],
    expirationDate?: string
  ) => {
    const { data } = await setVisitorByIdWithRoleMutation({
      variables: {
        visitorId,
        name,
        expirationDate: expirationDate ? dayjs(expirationDate).toISOString() : undefined,
        visitorGroupMembership
      },
      update(cache, { data: updatedMember }) {
        const updatedGroups = externalUsersGroups.map((group) => {
          if (group.externalRef === visitorGroupMembership[0].visitorGroupExternalRef)
            if (group.members.some((member) => member.id === updatedMember?.setVisitorById.id))
              return {
                ...group,
                members: group.members.map((member) =>
                  member.id === updatedMember?.setVisitorById.id ? updatedMember?.setVisitorById : member
                )
              };
            else
              return {
                ...group,
                members: { ...group.members, ...updatedMember?.setVisitorById }
              };
          return {
            ...group,
            members: group.members.filter((member) => member.id !== updatedMember?.setVisitorById.id)
          };
        });
        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    if (data) {
      setExternalUsersMemberAction(
        data.setVisitorById,
        visitorGroupMembership.map((membership) => membership.visitorGroupExternalRef)
      );
    }
    return data;
  };

  const deleteVisitorById = async (visitorId: string) => {
    const { data } = await deleteVisitorByIdMutation({
      variables: { visitorId },
      update(cache, { data: deletedMemberData }) {
        const groupWithDeletedMember = externalUsersGroups.filter((group) =>
          group.members.some((member) => member.id === visitorId)
        )[0];

        const updatedGroups = externalUsersGroups.map((group) => {
          if (group.id === groupWithDeletedMember.id)
            return {
              ...group,
              members: group.members.filter((member) => member.id !== deletedMemberData?.deleteVisitorById)
            };
          return group;
        });

        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    return data;
  };

  const deleteVisitorGroupById = async (visitorGroupId: string) => {
    const { data } = await deleteVisitorGroupByIdMutation({
      variables: { visitorGroupId, withVisitors: true },
      update(cache, { data: removedVisitorGroupById }) {
        const updatedGroups = externalUsersGroups.filter(
          (group) => group.id !== removedVisitorGroupById?.deleteVisitorGroupById
        );

        cache.writeQuery({
          query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
          variables: {
            filter: { type: 'CO_CONTRACTORS' }
          },
          data: {
            getVisitorGroupsForTenant: updatedGroups
          }
        });
      }
    });
    return data;
  };

  const sendInvitationForMobileDeviceById = async (visitorId: string, mobileDeviceId: string) => {
    const { data } = await sendInvitationForMobileDeviceByIdMutation({
      variables: { visitorId, mobileDeviceId }
    });
    return data;
  };

  return {
    externalUsersGroups,
    addVisitorGroup,
    addVisitorGroupMutationLoading,
    addVisitorGroupWithMaxNumberOfMembers,
    addVisitorGroupWithMaxNumberOfMembersMutationLoading,
    updateVisitorGroup,
    updateVisitorGroupMutationLoading,
    updateVisitorGroupWithMaxNumberOfMembers,
    updateVisitorGroupWithMaxNumberOfMembersMutationLoading,
    updateVisitorMemberByIdWithRole,
    updateVisitorMemberByIdWithRoleLoading,
    addVisitorMemberWithRole,
    addVisitorMemberMutationWithRoleLoading,
    deleteVisitorById,
    deleteVisitorByIdMutationLoading,
    deleteVisitorGroupByIdMutationError,
    deleteVisitorGroupByIdMutationLoading,
    deleteVisitorGroupById,
    setVisitorByIdWithRoleMutationError,
    sendInvitationForMobileDeviceById,
    sendInvitationForMobileDeviceByIdMutationLoading,
    loadingWithRole
  };
};

export default useExternalUsers;
