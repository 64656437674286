/* eslint-disable import/prefer-default-export */
import { GetVisitorGroupsForTenantWithIsAdminGroup_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithIsAdminGroup';

interface Props {
  id: string;
  name?: string;
  isAdminGroup?: boolean;
  externalRef?: string;
}

export function createMockedVisitorGroupWithIsAdminGroup({
  id,
  name = 'mockedVisitorGroupName',
  isAdminGroup = false,
  externalRef = 'mockedVisitorGroupExternalRef'
}: Props): GetVisitorGroupsForTenantWithIsAdminGroup_getVisitorGroupsForTenant {
  return {
    __typename: 'VisitorGroup',
    id,
    name,
    isAdminGroup,
    externalRef
  };
}
