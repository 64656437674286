import { useReactiveVar } from '@apollo/client';
import { setSortingMenuAccessProfilesAction } from 'state/actions/ui';
import { SortingMenuState } from 'state/types';
import { uiVar } from 'state/vars';

const useSortingMenuAccessProfilesLockGroups = () => {
  const { sortingMenuState } = useReactiveVar(uiVar);
  const sortingMenuValue = sortingMenuState as SortingMenuState;
  const hideSortingMenu = () => {
    setSortingMenuAccessProfilesAction(null);
  };

  const showSortingMenu = (sortingMenu: SortingMenuState) => {
    setSortingMenuAccessProfilesAction(sortingMenu);
  };

  return { hideSortingMenu, showSortingMenu, sortingMenuValue };
};

export default useSortingMenuAccessProfilesLockGroups;
