import { useMsal } from '@azure/msal-react';
import React from 'react';
import MobileNavUI from 'ui/organisms/MobileNav/MobileNav';

const MobileNav = () => {
  const { instance, accounts } = useMsal();

  const handleLogout = async () => {
    await instance.logout({ account: accounts[0], postLogoutRedirectUri: process.env.REACT_APP_SSO_REDIRECT_URI });
  };

  return <MobileNavUI singOut={handleLogout} />;
};

export default MobileNav;
