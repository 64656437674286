/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import TimePickerComponent from 'ui/atoms/TimePicker/TimePicker';
import dayjs from 'dayjs';
import DatePicker from 'ui/atoms/DatePicker/DatePicker';
import Button from 'ui/atoms/Button/Button';
import styled from 'styled-components';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import useAccessEventsFilters from 'hooks/useAccessEventsFilters/useAccessEventsFilters';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import useTranslation from 'hooks/useTranslation/useTranslation';

const IconWrapper = styled.div``;

const PickersWrapper = styled.div`
  display: flex;
`;

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 20rem;
`;

const DataTimePicker: React.FC = () => {
  const { translation } = useTranslation();
  const [dateStart, setDateStart] = useState<null | dayjs.Dayjs>(null);
  const [dateEnd, setDateEnd] = useState<null | dayjs.Dayjs>(null);
  const [timeStart, setTimeStart] = useState<null | dayjs.Dayjs>(dayjs().hour(8).minute(0));
  const [timeEnd, setTimeEnd] = useState<null | dayjs.Dayjs>(dayjs().hour(17).minute(0));
  const { hideModal } = useModal();
  const { addDataTimeCustomAccessEventFilter } = useAccessEventsFilters();

  const handleSet = async () => {
    if (timeStart && timeEnd && dateStart && dateEnd) {
      addDataTimeCustomAccessEventFilter({
        rangeStart: dayjs()
          .month(dateStart.month())
          .date(dateStart.date())
          .hour(timeStart.hour())
          .minute(timeStart.minute())
          .utc()
          .format(),
        rangeEnd: dayjs()
          .month(dateEnd.month())
          .date(dateEnd.date())
          .hour(timeEnd.hour())
          .minute(timeEnd.minute())
          .utc()
          .format()
      });
      hideModal();
    }
  };

  return (
    <Modal>
      <IconWrapper>
        <Icon name="Clock" width={48} height={48} viewBox="0 0 24 24" color="b1" id="clock-icon" />
      </IconWrapper>
      <PickersWrapper>
        <PickerWrapper>
          <Typography color="primary" variant="title3" id="select-date-title">
            {translation.select_date}
          </Typography>
          <DatePicker valueStart={dateStart} valueEnd={dateEnd} onStartChange={setDateStart} onEndChange={setDateEnd} />
        </PickerWrapper>
        <PickerWrapper>
          <Typography color="primary" variant="title3" id="start-time-title">
            {translation.start_time}
          </Typography>
          <TimePickerComponent
            value={timeStart}
            setValue={setTimeStart}
            disabled={!dateStart && !dateEnd}
            maxTime={dateStart?.isSame(dateEnd, 'day') ? timeEnd?.subtract(1, 'minute') : null}
            onKeyDown={(event) => formKeyDownHandler(event, handleSet)}
          />
        </PickerWrapper>
        <PickerWrapper>
          <Typography color="primary" variant="title3" id="end-time-title">
            {translation.end_time}
          </Typography>
          <TimePickerComponent
            value={timeEnd}
            setValue={setTimeEnd}
            disabled={!dateStart && !dateEnd}
            minTime={dateStart?.isSame(dateEnd, 'day') ? timeStart?.add(1, 'minute') : null}
            onKeyDown={(event) => formKeyDownHandler(event, handleSet)}
          />
        </PickerWrapper>
      </PickersWrapper>
      <ButtonWrapper>
        <Button backgroundColor="transparent" textColor="b1" onClick={hideModal}>
          {translation.cancel}
        </Button>
        <Button onClick={handleSet}>{translation.set}</Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default DataTimePicker;
