/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant,
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants,
  GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_scheduledAccessGrants
} from 'graphql/generated/GetVisitorGroupsForTenantWithAccessGrants';

interface Props {
  id: string;
  defaultAccessGrants?: Array<GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_defaultAccessGrants>;
  scheduledAccessGrants?: Array<GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant_scheduledAccessGrants>;
  name?: string;
  externalRef?: string;
  type?: string;
}

export function createMockedVisitorGroupWithAccessGrants({
  id,
  defaultAccessGrants = [],
  scheduledAccessGrants = [],
  name = 'mockedVisitorGroupName',
  externalRef = '',
  type = ''
}: Props): GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant {
  return {
    __typename: 'VisitorGroup',
    id,
    name,
    defaultAccessGrants,
    scheduledAccessGrants,
    externalRef,
    type
  };
}
