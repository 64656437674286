import { EditExternalUserGroupForm } from 'components/Drawer/types';
import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import Switch from 'ui/atoms/Switch/Switch';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  register: UseFormRegister<EditExternalUserGroupForm>;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  isNameUnique: boolean;
  switchIsMaximumUserLimitEnable: boolean;
  handleSwitchIsMaximumUserLimitEnableOnChange: () => void;
  inputsMaximumUserLimitValue: number | null;
  handlesMaximumUserLimitInputOnChange: (value: number) => void;
  inputsMaximumUserLimitValueValidation: string | undefined;
  showValidation: boolean;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}
const EditExternalUserGroup: React.FC<Props> = ({
  register,
  nameInputValue,
  handleNameInputOnChange,
  isNameUnique,
  switchIsMaximumUserLimitEnable,
  handleSwitchIsMaximumUserLimitEnableOnChange,
  inputsMaximumUserLimitValue,
  handlesMaximumUserLimitInputOnChange,
  inputsMaximumUserLimitValueValidation,
  showValidation,
  inputOnKeyDown
}) => {
  const { translation } = useTranslation();
  return (
    <>
      <Input
        {...register('name')}
        onChange={(event) => handleNameInputOnChange(event.target.value)}
        required
        label={`${translation.name}*`}
        value={nameInputValue}
        id="drawer-input-name"
        failed={!isNameUnique}
        failedText={translation.group_name_must_be_unique}
        failedId="drawer-input-name-validation"
        onKeyDown={inputOnKeyDown}
      />
      <ComponentWrapper flexDirection="row" gap="1rem" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="body1" color="dTextHigh">
          {translation.enable_maximum_user_limit}
        </Typography>
        <Switch
          variant="dark"
          {...register('isMaximumUserLimit')}
          id="drawer-enable-is-maximum-user-limit-span"
          inputId="drawer-enable-is-maximum-user-limit-input"
          checked={switchIsMaximumUserLimitEnable}
          onClick={handleSwitchIsMaximumUserLimitEnableOnChange}
          onKeyDown={inputOnKeyDown}
        />
      </ComponentWrapper>
      <Input
        {...register('maxNumberOfMembers')}
        customRequired
        label={translation.maximum_users}
        failed={showValidation && inputsMaximumUserLimitValueValidation !== undefined}
        failedText={inputsMaximumUserLimitValueValidation}
        type="number"
        min={0}
        max={999}
        value={inputsMaximumUserLimitValue || undefined}
        disabled={!switchIsMaximumUserLimitEnable}
        id="drawer-input-maximum-user-limit"
        failedId="drawer-input-maximum-user-limit-validation"
        onKeyDown={inputOnKeyDown}
        onChange={
          switchIsMaximumUserLimitEnable
            ? (event) => handlesMaximumUserLimitInputOnChange(Number(event.target.value))
            : undefined
        }
      />
    </>
  );
};

export default EditExternalUserGroup;
