/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import { GetCurrentHost_getCurrentHost_mobileDevices } from 'graphql/generated/GetCurrentHost';
import { GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members_mobileDevices } from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface Props {
  id: string;
  isRegistered?: boolean;
  registrationToken?: string | null;
  registrationDate?: string | null;
  role?: string;
}

export function createMockedMobileDevice({
  id,
  isRegistered = true,
  registrationToken = null,
  registrationDate = '2023-01-01T09:00:00Z',
  role = 'ACCESS'
}: Props):
  | GetCurrentHost_getCurrentHost_mobileDevices
  | GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members_mobileDevices {
  return {
    __typename: 'MobileDevice',
    id,
    isRegistered,
    registrationToken,
    registrationDate,
    role
  };
}
