/* eslint-disable import/prefer-default-export */

import { OnOfficeDayAddedById_onOfficeDayAddedById } from 'graphql/generated/OnOfficeDayAddedById';

interface Props {
  date: GraphQL_AWSDate;
}

export function createMockedSubscriptionOfficeDayAdded({ date }: Props): OnOfficeDayAddedById_onOfficeDayAddedById {
  return {
    __typename: 'SubscriptionOfficeDayById',
    date
  };
}
