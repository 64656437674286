import { isEqual, orderBy } from 'lodash';
import { DoorsState } from 'state/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { setDoorsAction } from 'state/actions/doors';
import { Translation } from 'hooks/useTranslation/useTranslation';

export const createLocksPrioritySorting = (translation: Translation) => [
  translation.no_priority,
  translation.reservable
];

export const createSecondColumnPrioritySorting = (translation: Translation) => [translation.selected];

export interface Sorting {
  isMenuOpen: boolean;
  isAlphabeticAscending?: boolean;
  priority: string;
}

interface Props {
  translation: Translation;
  locks: DoorsState;
  selectedIds: string[] | null;
  isSortingEnable: boolean;
}

const useSortingAccessProfilesTableLock = ({ translation, locks, selectedIds, isSortingEnable }: Props) => {
  const [sorting, setSorting] = useState<Sorting>({
    isMenuOpen: false,
    isAlphabeticAscending: true,
    priority: translation.no_priority
  });

  const handleOpenSortingMenu = () => {
    setSorting({ ...sorting, isMenuOpen: !sorting.isMenuOpen });
  };

  const handleSortingOnChange = (priority: string, isAlphabeticAscending?: boolean) => {
    setSorting({ ...sorting, isMenuOpen: false, isAlphabeticAscending, priority });
  };

  const getSortedDoors = useCallback(() => {
    switch (sorting.priority) {
      case translation.reservable:
        return orderBy(
          locks,
          ['location', sorting.isAlphabeticAscending !== undefined && 'name'],
          ['asc', sorting.isAlphabeticAscending ? 'asc' : 'desc']
        );

      case translation.selected: {
        const selectedDoors = locks.filter((door) => selectedIds?.includes(door.id));
        const unSelectedDoors = locks.filter((door) => !selectedIds?.includes(door.id));
        if (sorting.isAlphabeticAscending !== undefined) {
          return [
            ...orderBy(selectedDoors, ['name'], [sorting.isAlphabeticAscending ? 'asc' : 'desc']),
            ...orderBy(unSelectedDoors, ['name'], [sorting.isAlphabeticAscending ? 'asc' : 'desc'])
          ];
        }
        return [...selectedDoors, ...unSelectedDoors];
      }
      case 'No priority':
      default:
        return orderBy(locks, [(lock) => lock.name.toLowerCase()], [sorting.isAlphabeticAscending ? 'asc' : 'desc']);
    }
  }, [
    locks,
    selectedIds,
    sorting.isAlphabeticAscending,
    sorting.priority,
    translation.reservable,
    translation.selected
  ]);

  useEffect(() => {
    if (isSortingEnable) {
      const sortedDoors = getSortedDoors();
      if (!isEqual(sortedDoors, locks)) {
        setDoorsAction(sortedDoors);
      }
    }
  }, [locks, getSortedDoors, isSortingEnable]);

  const sortingMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeOpenMenus = (event: MouseEvent) => {
      event.stopPropagation();
      if (sortingMenuRef.current && sorting.isMenuOpen && !sortingMenuRef.current.contains(event.target as Node)) {
        setSorting({
          ...sorting,
          isMenuOpen: false
        });
      }
    };

    document.addEventListener('mousedown', closeOpenMenus);
    return () => {
      document.removeEventListener('mousedown', closeOpenMenus);
    };
  }, [sorting]);

  return { sorting, handleOpenSortingMenu, handleSortingOnChange, sortingMenuRef };
};

export default useSortingAccessProfilesTableLock;
