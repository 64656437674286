import { installersGroupsVar } from 'state/vars';
import { GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { AddVisitor_addVisitor } from 'graphql/generated/AddVisitor';
import { SetVisitor_setVisitor } from 'graphql/generated/SetVisitor';

export const setInstallersGroupsAction = (groups: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant[]) => {
  installersGroupsVar(groups);
};

export const setInstallersMemberAction = (
  installer: SetVisitor_setVisitor | AddVisitor_addVisitor,
  visitorGroupExternalRefs: string[]
) => {
  const currentGroups = installersGroupsVar();
  const updatedGroups = currentGroups.map((group) => {
    if (group.externalRef === visitorGroupExternalRefs[0])
      if (group.members.some((member) => member.email === installer.email))
        return {
          ...group,
          members: group.members.map((member) => (member.email === installer.email ? installer : member))
        };
      else
        return {
          ...group,
          members: [...group.members, installer]
        };
    return {
      ...group,
      members: group.members.filter((member) => member.email !== installer.email)
    };
  });
  installersGroupsVar(updatedGroups);
};

export const unsetInstallerByIdAction = (visitorId: string) => {
  const currentGroups = installersGroupsVar();

  const groupWithDeletedMember = currentGroups.find((group) => group.members.some((member) => member.id === visitorId));

  const updatedGroups = currentGroups.map((group) => {
    if (group.id === groupWithDeletedMember?.id)
      return {
        ...group,
        members: group.members.filter((member) => member.id !== visitorId)
      };
    return group;
  });

  installersGroupsVar(updatedGroups);
};
