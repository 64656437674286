import { permissionsVar } from 'state/vars';
import { GetVisitorGroupsForTenantWithIsAdminGroup_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithIsAdminGroup';

export const setPermissionsGroups = (groups: GetVisitorGroupsForTenantWithIsAdminGroup_getVisitorGroupsForTenant[]) => {
  permissionsVar(groups);
};

export const setIsAdminGroupAction = (externalRef: string) => {
  const currentGroups = permissionsVar();
  const updatedGroups = currentGroups.map((group) => {
    if (group.externalRef === externalRef)
      return {
        ...group,
        isAdminGroup: !group.isAdminGroup
      };
    return group;
  });
  permissionsVar(updatedGroups);
};
