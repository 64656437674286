/* eslint-disable import/prefer-default-export */
import { GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members_visitorGroupMembership } from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';
import { Role } from 'graphql/generated/globalTypes';

interface Props {
  visitorGroupExternalRef?: string;
  role?: Role;
  groupType?: string;
}

export function createMockedVisitorGroupMembership({
  visitorGroupExternalRef = 'mockedVisitorGroupExternalRef',
  role = Role.EXTERNAL_GROUP_USER,
  groupType = 'CO_CONTRACTORS'
}: Props): GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members_visitorGroupMembership {
  return {
    __typename: 'VisitorGroupMembership',
    visitorGroup: {
      __typename: 'VisitorGroup',
      externalRef: visitorGroupExternalRef,
      type: groupType
    },
    role
  };
}
