/* eslint-disable import/prefer-default-export */
import {
  GetDoorsForTenantWithOfficeMode_getDoorsForTenant,
  GetDoorsForTenantWithOfficeMode_getDoorsForTenant_location,
  GetDoorsForTenantWithOfficeMode_getDoorsForTenant_officeMode
} from 'graphql/generated/GetDoorsForTenantWithOfficeMode';
import { UpdateDoorWithOfficeMode_updateDoor } from 'graphql/generated/UpdateDoorWithOfficeMode';

interface Props {
  id: string;
  batteryWarningLevel?: number | null;
  domSerialNumber?: string;
  location?: GetDoorsForTenantWithOfficeMode_getDoorsForTenant_location | null;
  name?: string;
  officeMode?: GetDoorsForTenantWithOfficeMode_getDoorsForTenant_officeMode;
}

export function createMockedDoorWithOfficeMode({
  id,
  batteryWarningLevel = null,
  domSerialNumber = 'mockedDomSerialNumber',
  location = null,
  name = 'mockedDoorName',
  officeMode = {
    __typename: 'WeekSchedule',
    weekDays: []
  }
}: Props): GetDoorsForTenantWithOfficeMode_getDoorsForTenant | UpdateDoorWithOfficeMode_updateDoor {
  return {
    __typename: 'Door',
    id,
    name,
    location,
    domSerialNumber,
    batteryWarningLevel,
    officeMode
  };
}
