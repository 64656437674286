import { useAutocomplete } from '@mui/base';
import React, { useEffect } from 'react';
import MultiselectMUI from 'ui/molecules/Multiselect/Multiselect';

export interface Value {
  value: string;
  label: string;
}

export interface Props {
  label: string;
  placeholder?: string;
  handleValueOnChange: (value: Value[]) => void;
  options: ReadonlyArray<Value>;
  defaultValue?: Array<Value>;
  id: string;
  hideClearAllButton?: boolean;
}

const Multiselect: React.FC<Props> = ({
  label,
  placeholder,
  handleValueOnChange,
  options,
  defaultValue,
  id,
  hideClearAllButton = false
}) => {
  const {
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    setAnchorEl,
    getClearProps
  } = useAutocomplete({
    id,
    defaultValue,
    multiple: true,
    options,
    getOptionLabel: (option) => option.label,
    isOptionEqualToValue: (option, optionValue) => option.value === optionValue.value,
    openOnFocus: true
  });

  useEffect(() => {
    handleValueOnChange(value);
  }, [handleValueOnChange, value]);

  return (
    <MultiselectMUI
      label={label}
      placeholder={placeholder}
      setAnchorEl={setAnchorEl}
      value={value}
      getInputProps={getInputProps}
      getTagProps={getTagProps}
      getListboxProps={getListboxProps}
      getOptionProps={getOptionProps}
      groupedOptions={groupedOptions as Value[]}
      getClearProps={getClearProps}
      id={id}
      hideClearAllButton={hideClearAllButton}
    />
  );
};

export default Multiselect;
