import React from 'react';
import styled from 'styled-components';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import useBatteryStatusUI, { BatteryStatus } from 'hooks/useBatteryStatusUI/useBatteryStatusUI';
import { Colors } from 'theme/theme';
import useTranslation from 'hooks/useTranslation/useTranslation';

export interface Props {
  batteryStatus: BatteryStatus;
  id?: string;
}

interface BatteryStatusWrapperProps {
  backgroundColor: keyof Colors;
  borderColor: keyof Colors;
}

const BatteryStatusWrapper = styled.div<BatteryStatusWrapperProps>`
  display: flex;
  flex-direction: row;
  min-width: 0;
  gap: 0.5rem;
  align-items: center;
  background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
  padding: 0.75rem 0.5rem;
  border: 1px solid ${({ borderColor, theme }) => theme.colors[borderColor]};
`;

const BatteryStatusIconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
`;
const BatteryStatusBox: React.FC<Props> = ({ batteryStatus, id }) => {
  const { translation } = useTranslation();
  const { getBatteryData } = useBatteryStatusUI(translation);

  return (
    <BatteryStatusWrapper
      id={id}
      backgroundColor={getBatteryData(batteryStatus).backgroundColor}
      borderColor={getBatteryData(batteryStatus).iconColor}
    >
      <BatteryStatusIconWrapper>
        <Icon name={batteryStatus} color={getBatteryData(batteryStatus).iconColor} />
      </BatteryStatusIconWrapper>
      <Typography variant="body4" color="dTextHigh">
        {getBatteryData(batteryStatus).boxText}
      </Typography>
    </BatteryStatusWrapper>
  );
};

export default BatteryStatusBox;
