import React from 'react';

const Home: React.FC = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8337 56.5H42.0005L36.5005 51.1667H35.167V35.1667H29.8337V51.1667H16.5003V24.9187L32.5003 10.3747L48.5003 24.9187L48.5005 35.9999L50.5005 33.4999L53.8337 36.4999V29.8333H61.8337L48.0643 17.316L34.295 4.79866C33.804 4.35193 33.1641 4.10437 32.5003 4.10437C31.8365 4.10437 31.1966 4.35193 30.7057 4.79866L3.16699 29.8333H11.167V53.8333C11.167 54.5406 11.4479 55.2188 11.948 55.7189C12.4481 56.219 13.1264 56.5 13.8337 56.5Z"
    />
    <path d="M49.4375 46.5186H59.0002V51.8519H49.4375L54.3148 56.7266L50.5442 60.4999L39.2295 49.1853L50.5442 37.8706L54.3148 41.6439L49.4375 46.5186Z" />
  </>
);

export default Home;
