/* eslint-disable import/prefer-default-export */

import { AddLocation, AddLocation_addLocation } from 'graphql/generated/AddLocation';
import { createLocationWithDoors } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/locationWithDoors';

export function mockedAddLocationWithDoorsData(addLocation: AddLocation_addLocation): AddLocation {
  return {
    addLocation
  };
}

export const mockedAddLocationWithDoorsDataForMutation = mockedAddLocationWithDoorsData(
  createLocationWithDoors({ id: 'mockedAddedLocationId', name: 'mockedLocationName' })
);
