/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithIsOfficeModeEnabled,
  GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithIsOfficeModeEnabled';
import { createMockedAccessGrantWithIsOfficeModeEnabled } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/accessGrantWithIsOfficeModeEnabled';
import { createMockedVisitorGroupWithIsOfficeModeEnabled } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithIsOfficeModeEnabled';

export function mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledData(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithIsOfficeModeEnabled {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledDataForQuery =
  mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledData([
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedVisitorGroupId0',
      defaultAccessGrants: [createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantId0' })]
    }),
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedVisitorGroupId1',
      scheduledAccessGrants: [
        createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantId1', isOfficeModeEnabled: false })
      ]
    })
  ]);

export const mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledDataAdmin =
  mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledData([
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedIdInstallers',
      scheduledAccessGrants: [createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantIdInstallers' })],
      defaultAccessGrants: []
    }),
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedIdCleaners',
      scheduledAccessGrants: [createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantIdCleaners' })],
      defaultAccessGrants: []
    }),
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedIdCo-contractors',
      scheduledAccessGrants: [
        createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantIdCo-contractors' })
      ],
      defaultAccessGrants: []
    }),
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedIdAdmins',
      defaultAccessGrants: [createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantIdAdmins' })]
    }),
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedIdEmployees',
      defaultAccessGrants: [createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantIdEmployees' })]
    }),
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedIdVisitor_group_1',
      defaultAccessGrants: [
        createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantIdVisitor_group_1' })
      ]
    }),
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedIdVisitor_group_2',
      defaultAccessGrants: [
        createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantIdVisitor_group_2' })
      ]
    }),
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedIdVisitor_group_3',
      defaultAccessGrants: [
        createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantIdVisitor_group_3' })
      ]
    }),
    createMockedVisitorGroupWithIsOfficeModeEnabled({
      id: 'mockedIdVisitors',
      defaultAccessGrants: [createMockedAccessGrantWithIsOfficeModeEnabled({ id: 'mockedAccessGrantIdVisitors' })]
    })
  ]);
