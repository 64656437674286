import { Props as IconProps } from 'ui/atoms/Icon/Icon';

export const getLockGroupsIconProperties = (
  isSelected: boolean,
  isDoorAssigned: boolean,
  isWarning: boolean = false
): Pick<IconProps, 'name' | 'color'> => {
  if (isSelected)
    return {
      name: 'Folder',
      color: 'primary'
    };
  if (!isDoorAssigned || isWarning)
    return {
      name: 'Folder',
      color: 'warning'
    };
  return {
    name: 'Folder',
    color: 'lTextMedium'
  };
};

export default { getLockGroupsIconProperties };
