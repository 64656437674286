/* eslint-disable import/prefer-default-export */

import { GetAvailableOfficeDays_getAvailableOfficeDays } from 'graphql/generated/GetAvailableOfficeDays';

interface Props {
  date: GraphQL_AWSDate;
}

export function createMockedAvailableDays({ date }: Props): GetAvailableOfficeDays_getAvailableOfficeDays {
  return {
    __typename: 'AvailableDay',
    date
  };
}
