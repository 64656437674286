import React from 'react';
import styled from 'styled-components';
import MenuItem, { Props as MenuItemProps } from 'ui/atoms/MenuItem/MenuItem';
import Separator from 'ui/atoms/Separator/Separator';

const StyledNav = styled.nav`
  box-shadow: inset 0 0.25rem 0.625rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 2rem 1.5rem 1.125rem 1.5rem;
  background: ${(props) => props.theme.colors.bgDark};
`;

export interface Props {
  items: MenuItemProps[];
  isAdmin: boolean;
}

const SideNav: React.FC<Props> = ({ items, isAdmin }) => (
  <StyledNav id="sidebar">
    {items.map(({ icon, text, location, isSelected }, index) =>
      isAdmin && index === 1 ? (
        <div key={`SideNavItem-${location}`}>
          <Separator height="0.5rem" />
          <MenuItem key={location} icon={icon} text={text} location={location} isSelected={isSelected} />
          <Separator height="0.5rem" />
        </div>
      ) : (
        <MenuItem key={location} icon={icon} text={text} location={location} isSelected={isSelected} />
      )
    )}
  </StyledNav>
);

export default SideNav;
