import { isEqual, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { externalUsersGroupsVar } from 'state/vars';
import { setExternalUsersGroupsAction } from 'state/actions/externalUsersGroups';
import {
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant,
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';
import { Translation } from 'hooks/useTranslation/useTranslation';

interface Sorting {
  isAscending?: boolean;
  columnName?: string;
}

const getMemberRole = (
  item: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members,
  externalUsersGroups: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant[],
  translation: Translation
) => {
  const role = item.visitorGroupMembership.find((groupMembership) =>
    externalUsersGroups.some((group) => groupMembership.visitorGroup.externalRef === group.externalRef)
  )?.role;
  switch (role) {
    case 'EXTERNAL_GROUP_USER':
      return translation.user;
    case 'EXTERNAL_GROUP_USER_MANAGER':
      return translation.owner;

    default:
      return undefined;
  }
};

export const handleSortingUsers = (
  translation: Translation,
  item: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members,
  externalUsersGroups: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant[],
  columnName?: string
) => {
  switch (columnName) {
    case translation.atlas_access_app:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return !item.mobileDevices.find(({ role, isRegistered }) => role === 'ACCESS' && isRegistered)?.isRegistered;
    case translation.expiration_date:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return item.expirationDate;
    case translation.role:
      return getMemberRole(item, externalUsersGroups, translation);
    case translation.external_users:
    default:
      return item.name;
  }
};

export const handleSortingGroups = (
  translation: Translation,
  item: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant,
  columnName?: string
) => {
  switch (columnName) {
    case translation.external_groups:
    default:
      return item.name;
  }
};

export const getSortedArrayGroups = (
  translation: Translation,
  externalUsersGroups: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant[],
  sorting: Sorting
) =>
  orderBy(externalUsersGroups, (item) => handleSortingGroups(translation, item, sorting.columnName as string), [
    sorting.isAscending ? 'asc' : 'desc'
  ]);

export const getSortedArrayUsers = (
  translation: Translation,
  externalUsersMembers: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members[],
  externalUsersGroups: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant[],
  sorting: Sorting
) =>
  orderBy(
    externalUsersMembers,
    (item) => handleSortingUsers(translation, item, externalUsersGroups, sorting.columnName),
    [sorting.isAscending ? 'asc' : 'desc']
  );

const useSortingExternalUsersTable = (translation: Translation) => {
  const externalUsersGroups = useReactiveVar(
    externalUsersGroupsVar
  ) as GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant[];
  const [sortingGroups, setSortingGroups] = useState<Sorting>({
    isAscending: true,
    columnName: translation.external_groups
  });
  const [sortingUsers, setSortingUsers] = useState<Sorting>({
    isAscending: true,
    columnName: translation.external_users
  });

  const handleSortingGroupsOnClick = (columnName: string) => {
    if (sortingGroups.isAscending !== undefined && sortingGroups.columnName === columnName) {
      return setSortingGroups({ isAscending: !sortingGroups.isAscending, columnName });
    }
    return setSortingGroups({ isAscending: true, columnName });
  };

  const handleSortingUsersOnClick = (columnName: string) => {
    if (sortingUsers.isAscending !== undefined && sortingUsers.columnName === columnName) {
      return setSortingUsers({ isAscending: !sortingUsers.isAscending, columnName });
    }
    return setSortingUsers({ isAscending: true, columnName });
  };

  const handleSortingGroupsIcon = (columnName: string) => {
    if (sortingGroups.isAscending !== undefined)
      if (sortingGroups.columnName === columnName) return sortingGroups.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  const handleSortingUsersIcon = (columnName: string) => {
    if (sortingUsers.isAscending !== undefined)
      if (sortingUsers.columnName === columnName) return sortingUsers.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  useEffect(() => {
    const sortedExternalUsersGroups = getSortedArrayGroups(translation, externalUsersGroups, sortingGroups);
    const sortedExternalUsersGroupsWithSortedMembers = sortedExternalUsersGroups.map((group) => ({
      ...group,
      members: getSortedArrayUsers(translation, group.members, externalUsersGroups, sortingUsers)
    }));
    if (!isEqual(sortedExternalUsersGroupsWithSortedMembers, externalUsersGroups))
      setExternalUsersGroupsAction(sortedExternalUsersGroupsWithSortedMembers);
  }, [externalUsersGroups, sortingGroups, sortingUsers, translation]);

  return { handleSortingGroupsOnClick, handleSortingUsersOnClick, handleSortingGroupsIcon, handleSortingUsersIcon };
};

export default useSortingExternalUsersTable;
