import React from 'react';
import { useSnackbar as useDefaultSnackbar, OptionsObject } from 'notistack';
import Snackbar, { SnackbarType } from 'ui/molecules/Snackbar/Snackbar';
import { isMobile } from 'react-device-detect';

export const useEnqueueSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useDefaultSnackbar();

  return (message: string, options?: OptionsObject & { snackbartype?: SnackbarType }) => {
    enqueueSnackbar(message, {
      ...options,
      content: (key) => {
        const { snackbartype } = options || { snackbartype: undefined };
        return (
          <Snackbar
            id={`${key}`}
            message={message}
            snackbartype={snackbartype || 'success'}
            isMobile={isMobile}
            onClose={() => closeSnackbar(key)}
          />
        );
      }
    });
  };
};

export default useEnqueueSnackbar;
