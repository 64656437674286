import React from 'react';
import styled from 'styled-components';
import Label from 'ui/atoms/Label/Label';
import Typography from 'ui/atoms/Typography/Typography';
import useTranslation from 'hooks/useTranslation/useTranslation';
import { CssProps } from './Field';

const Wrapper = styled.div<Partial<Props>>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
`;

const EmptyBottomSpace = styled.div`
  height: 1.125rem;
`;
const StyledHelperText = styled(Typography)`
  margin-top: 0.375rem;
  text-align: right;
`;

export interface Props extends CssProps {
  label?: string;
  labelId?: string;
  required?: boolean;
  customRequired?: boolean;
  hidden?: boolean;
  hideValidationText?: boolean;
  hideValidationEmptySpace?: boolean;
  failedText?: string;
  failedId?: string;
}

const FieldTemplate: React.FC<Props> = ({
  label,
  labelId,
  failed,
  hideValidationText = false,
  hideValidationEmptySpace = false,
  failedText = 'Invalid',
  failedId,
  required,
  customRequired,
  hidden,
  disabled,
  children
}) => {
  const { translation } = useTranslation();
  return (
    <Wrapper hidden={hidden}>
      {label ? (
        <>
          <Label id={labelId} color={disabled ? 'dTextMedium' : 'dTextHigh'}>
            {`${label} ${!(required || customRequired) ? `(${translation.optional})` : ''}`}
          </Label>
          {children}
        </>
      ) : (
        children
      )}
      {failed && !hideValidationText ? (
        <StyledHelperText variant="helper" color="error" id={failedId}>
          {failedText}
        </StyledHelperText>
      ) : (
        !hideValidationEmptySpace && <EmptyBottomSpace />
      )}
    </Wrapper>
  );
};

export default FieldTemplate;
