/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants_accessSchedule,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants_accessSchedule_weekDays,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule_weekDays
} from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';

interface Props {
  weekDays?:
    | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule_weekDays[]
    | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants_accessSchedule_weekDays[];
}

export function createMockedAccessGrantWeekSchedule({
  weekDays = []
}: Props):
  | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule
  | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants_accessSchedule {
  return {
    __typename: 'WeekSchedule',
    weekDays
  };
}
