import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import Button from 'ui/atoms/Button/Button';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  handleOnButtonClick?: () => void;
}

const NoExternalUserGroups: React.FC<Props> = ({ handleOnButtonClick }) => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="1.5rem"
      width="28rem"
      height="16rem"
      id="no-external-groups-label"
    >
      <Icon name="UserCoContractors" width={56} height={56} viewBox="0 0 24 24" />
      <Typography variant="title4">{translation.no_groups}</Typography>
      <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="body4">{translation.there_are_no_groups_added}</Typography>
        <Typography variant="body4">{translation.click_add_to_add_a_new_group}</Typography>
      </ComponentWrapper>
      <ComponentWrapper width="11rem">
        <Button onClick={handleOnButtonClick} id="no-external-groups-button">
          {translation.add_group}
        </Button>
      </ComponentWrapper>
    </ComponentWrapper>
  );
};

export default NoExternalUserGroups;
