import React from 'react';
import styled from 'styled-components';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { Colors } from 'theme/theme';
import Typography from '../Typography/Typography';
import Icon, { Props as IconProps } from '../Icon/Icon';

type IconPosition = 'beforeHeader' | 'afterHeader';
type IconSorting = 'ArrowDown' | 'ArrowUp';
export interface CssProps {
  flex?: string;
}

export type Props = CssProps & {
  headerText: string;
  headerTextOpacity?: string;
  headerTextColor?: keyof Colors;
  superText?: string;
  iconPosition?: IconPosition;
  iconProps?: Pick<IconProps, 'name' | 'height' | 'width' | 'color'>;
  iconSorting?: IconSorting;
  iconSortingColor?: keyof Colors;
  onClick?: () => void;
  id?: string;
};

const TableHeaderWrapper = styled.div<Partial<Props>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  ${({ iconProps }) => iconProps?.name && `gap: 0.625rem;`};
  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ onClick }) => onClick && `cursor: pointer`};
`;

const TableHeader: React.FC<Props> = ({
  headerText,
  headerTextOpacity,
  headerTextColor,
  superText,
  iconPosition = 'afterHeader',
  iconProps,
  iconSorting,
  iconSortingColor = 'lTextHigh',
  flex,
  onClick,
  id
}) => (
  <TableHeaderWrapper flex={flex} iconProps={iconProps} id={id} onClick={onClick}>
    {iconPosition === 'beforeHeader' && iconProps?.name && <Icon {...iconProps} />}
    <Typography
      whiteSpace="nowrap"
      variant="body3"
      color={headerTextColor !== undefined ? headerTextColor : 'lTextHigh'}
      opacity={headerTextOpacity}
    >
      {headerText}
    </Typography>
    {superText && (
      <ComponentWrapper alignSelf="flex-start">
        <Typography
          whiteSpace="nowrap"
          variant="super"
          color={headerTextColor !== undefined ? headerTextColor : 'lTextHigh'}
          opacity={headerTextOpacity}
        >
          {superText}
        </Typography>
      </ComponentWrapper>
    )}
    {iconSorting && (
      <ComponentWrapper justifyContent="center" alignItems="center" width="2rem">
        <Icon
          name={iconSorting}
          color="transparent"
          stroke={iconSortingColor}
          width={24}
          height={16}
          opacity={headerTextOpacity}
        />
      </ComponentWrapper>
    )}
    {iconPosition === 'afterHeader' && iconProps?.name && <Icon {...iconProps} />}
  </TableHeaderWrapper>
);

export default TableHeader;
