import { useReactiveVar } from '@apollo/client';
import { DateTimeSelectItem } from 'components/Drawer/variants/accessEvents/AccessEventsFilters/dataTimeSelectOptions';
import { AccessEventsDateRange, AccessEventsFilter } from 'graphql/generated/globalTypes';
import {
  addAccessEventsFiltersAction,
  addDataTimeAccessEventFilterAction,
  addDataTimeCustomAccessEventFilterAction,
  applyEventsFiltersAction,
  removeAccessEventsActionAppliedFilterAction,
  removeAccessEventsActionFilterAction,
  removeAccessEventsDateTimeAppliedFilterAction,
  removeAccessEventsDateTimeFilterAction,
  removeAccessEventsLockAppliedFilterAction,
  removeAccessEventsLockFilterAction,
  removeAccessEventsReasonAppliedFilterAction,
  removeAccessEventsReasonFilterAction,
  removeAccessEventsVisitorAppliedFilterAction,
  removeAccessEventsVisitorFilterAction
} from 'state/actions/accessEventFilters';
import { AccessEventFiltersState } from 'state/types';
import { accessEventFiltersVar } from 'state/vars';

export interface AccessEventsFilterWithDateTimeSelectedOption extends AccessEventsFilter {
  dataTimeSelectedOption?: DateTimeSelectItem;
}

const useAccessEventsFilters = () => {
  const accessEventFiltersState = useReactiveVar<AccessEventFiltersState>(accessEventFiltersVar);

  const addDataTimeAccessEventFilter = (value: DateTimeSelectItem) => {
    addDataTimeAccessEventFilterAction(value);
  };
  const addDataTimeCustomAccessEventFilter = (value: AccessEventsDateRange) => {
    addDataTimeCustomAccessEventFilterAction(value);
  };

  const addFilter = (newFilter: AccessEventsFilter) => {
    addAccessEventsFiltersAction(newFilter);
  };

  const removeDateTimeFilter = () => {
    removeAccessEventsDateTimeFilterAction();
  };

  const removeVisitorFilter = () => {
    removeAccessEventsVisitorFilterAction();
  };

  const removeLockFilter = () => {
    removeAccessEventsLockFilterAction();
  };

  const removeActionFilter = () => {
    removeAccessEventsActionFilterAction();
  };
  const removeReasonFilter = () => {
    removeAccessEventsReasonFilterAction();
  };
  const removeDateTimeAppliedFilter = () => {
    removeAccessEventsDateTimeAppliedFilterAction();
  };

  const removeVisitorAppliedFilter = () => {
    removeAccessEventsVisitorAppliedFilterAction();
  };

  const removeLockAppliedFilter = () => {
    removeAccessEventsLockAppliedFilterAction();
  };

  const removeActionAppliedFilter = () => {
    removeAccessEventsActionAppliedFilterAction();
  };
  const removeReasonAppliedFilter = () => {
    removeAccessEventsReasonAppliedFilterAction();
  };
  const applyFilters = () => {
    applyEventsFiltersAction();
  };

  const isFilterApply = () =>
    Boolean(
      accessEventFiltersState.appliedFilters.dataTimeSelectedOption ||
        accessEventFiltersState.appliedFilters.dateRange ||
        accessEventFiltersState.appliedFilters.doorIds ||
        accessEventFiltersState.appliedFilters.visitorIds ||
        accessEventFiltersState.appliedFilters.actions ||
        accessEventFiltersState.appliedFilters.reason
    );

  return {
    accessEventFilters: accessEventFiltersState,
    addFilter,
    addDataTimeAccessEventFilter,
    addDataTimeCustomAccessEventFilter,
    removeDateTimeFilter,
    removeVisitorFilter,
    removeLockFilter,
    removeActionFilter,
    removeReasonFilter,
    removeDateTimeAppliedFilter,
    removeVisitorAppliedFilter,
    removeLockAppliedFilter,
    removeActionAppliedFilter,
    removeReasonAppliedFilter,
    applyFilters,
    isFilterApply
  };
};

export default useAccessEventsFilters;
