/* eslint-disable import/prefer-default-export */

import { UpdateDoor as UpdateDoorType, UpdateDoor_updateDoor } from 'graphql/generated/UpdateDoor';
import {
  UpdateDoorWithOfficeMode as UpdateDoorWithOfficeDayType,
  UpdateDoorWithOfficeMode_updateDoor
} from 'graphql/generated/UpdateDoorWithOfficeMode';
import { createMockedDoor } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/door';
import { createMockedDoorWithOfficeMode } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/doorWithOfficeMode';
import { createMockedLocation } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/location';

export function mockedUpdateDoorData(updateDoor: UpdateDoor_updateDoor): UpdateDoorType {
  return {
    updateDoor
  };
}

export function mockedUpdateDoorWithOfficeModeData(
  updateDoor: UpdateDoorWithOfficeMode_updateDoor
): UpdateDoorWithOfficeDayType {
  return {
    updateDoor
  };
}

export const mockedUpdateDoorDataForMutation = mockedUpdateDoorData(
  createMockedDoor({
    id: 'mockedDoorId0',
    domSerialNumber: 'mockedDomSerialNumber',
    location: createMockedLocation({ id: 'mockedLocationId' }),
    name: 'mockedDoorNameUpdated'
  })
);

export const mockedUpdateDoorWithOfficeModeDataForMutation = mockedUpdateDoorWithOfficeModeData(
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId0',
    domSerialNumber: 'mockedDomSerialNumber',
    location: createMockedLocation({ id: 'mockedLocationId' }),
    name: 'mockedDoorNameUpdated'
  })
);

export const mockedUpdateDoorWithOfficeModeAndDoorGroupDataForMutation = mockedUpdateDoorWithOfficeModeData(
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId1',
    domSerialNumber: 'mockedDomSerialNumber',
    location: createMockedLocation({ id: 'mockedLocationId' }),
    name: 'mockedDoorNameUpdated'
  })
);
