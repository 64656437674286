import React from 'react';
import styled from 'styled-components';
import { AvatarVariant, handleAvatarVariant } from './Avatar';

export interface Props {
  variant?: AvatarVariant;
  id?: string;
}

const StyledAvatar = styled.div<Partial<Props>>`
  border-radius: 100%;
  cursor: progress;
  ${({ variant }) => handleAvatarVariant(variant || 'large')};

  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({ theme }) => theme.colors.b4};
    }
    100% {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const Avatar: React.FC<Props> = ({ variant = 'large', id = 'avatar' }) => <StyledAvatar variant={variant} id={id} />;

export default Avatar;
