import { LockWithGroupsForm } from 'components/Drawer/types';
import dayjs from 'dayjs';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EditLockWithGroupsDrawer } from 'state/types';
import { OptionProps } from 'ui/molecules/Select/Select';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import LockWithGroups from 'ui/organisms/Drawer/variants/LockWithGroups';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import useLocations from 'hooks/useLocations';
import useBatteryStatusUI from 'hooks/useBatteryStatusUI/useBatteryStatusUI';
import { Value } from 'components/Multiselect/Multiselect';
import useDoorsAndLocations from 'hooks/useDoorsAndLocations/useDoorsAndLocations';
import { sortBy } from 'lodash';
import useTranslation from 'hooks/useTranslation/useTranslation';

const EditLockWithGroups: React.FC = () => {
  const { translation } = useTranslation();
  const CONSTANT_DATE = '2000-01-01';
  const { register, handleSubmit, setValue, control } = useForm<LockWithGroupsForm>();
  const { drawerState } = useDrawer<EditLockWithGroupsDrawer>();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { hideDrawer } = useDrawer();
  const {
    updateDoorWithLocations,
    updateDoorWithLocationsLoading,
    deleteDoorWithLocationsLoading,
    doorsWithLocationsLoading,
    doorsWithLocations,
    locationsWithDoorsLoading,
    locationsWithDoors
  } = useDoorsAndLocations({
    handleFetchError
  });
  const [selectInputValue, setSelectInputValue] = useState<string>(
    drawerState.contentValue.location ? drawerState.contentValue.location : ''
  );
  const [selectValue, setSelectValue] = useState<OptionProps<null | string>>({
    label: drawerState.contentValue.locationId ? drawerState.contentValue.locationId : '',
    value: drawerState.contentValue.location ? drawerState.contentValue.location : null
  });
  const [nameInputValue, setNameInputValue] = useState<string>();
  const [nameInputValidation, setNameInputValidation] = useState<string | undefined>(undefined);
  const [switchIsOfficeModeEnabled, setSwitchIsOfficeModeEnabled] = useState<boolean>();
  const [inputOfficeModeFromValue, setInputOfficeModeFromValue] = useState<string>();
  const [inputOfficeModeToValue, setInputOfficeModeToValue] = useState<string>();
  const [officeModeTimeInputValidation, setOfficeModeTimeInputValidation] = useState<string | undefined>(undefined);
  const [showValidation, setShowValidation] = useState(false);
  const [groupsMultiselect, setGroupsMultiselect] = useState<Array<Value>>([]);
  const { locations, loading: locationsLoading } = useLocations({ handleFetchError });
  const { getBatteryStatus } = useBatteryStatusUI(translation);

  const isLoading =
    locationsWithDoorsLoading ||
    locationsLoading ||
    doorsWithLocationsLoading ||
    updateDoorWithLocationsLoading ||
    deleteDoorWithLocationsLoading;

  const onSubmit = async () => {
    setShowValidation(true);
    if (
      !isLoading &&
      nameInputValue &&
      nameInputValidation === undefined &&
      officeModeTimeInputValidation === undefined
    ) {
      await updateDoorWithLocations(
        drawerState?.contentValue?.doorId || '',
        nameInputValue,
        selectValue.label !== '' ? selectValue.label : null,
        groupsMultiselect.map((item) => item.value),
        switchIsOfficeModeEnabled
          ? dayjs(`${CONSTANT_DATE} ${inputOfficeModeFromValue}`).format('HH:mm:ss')
          : undefined,
        switchIsOfficeModeEnabled ? dayjs(`${CONSTANT_DATE} ${inputOfficeModeToValue}`).format('HH:mm:ss') : undefined
      );
      hideDrawer();
    }
  };

  useEffect(() => {
    if (drawerState?.contentValue) {
      if (nameInputValue === undefined) setNameInputValue(drawerState.contentValue.name);
      if (switchIsOfficeModeEnabled === undefined)
        setSwitchIsOfficeModeEnabled(drawerState.contentValue.isOfficeModeEnabled);
      if (inputOfficeModeFromValue === undefined)
        setInputOfficeModeFromValue(drawerState.contentValue.officeModeFromValue || '07:00');
      if (inputOfficeModeToValue === undefined)
        setInputOfficeModeToValue(drawerState.contentValue.officeModeToValue || '22:00');
    }
  }, [
    drawerState,
    inputOfficeModeFromValue,
    inputOfficeModeToValue,
    nameInputValue,
    selectInputValue,
    selectValue,
    switchIsOfficeModeEnabled
  ]);

  const handleSelectChange = (value: OptionProps<string> | null) => {
    if (value) setSelectValue(value);
    else setSelectValue({ label: '', value: null });
  };

  const handleNameInputOnChange = (value: string) => {
    setNameInputValue(value);
    setNameInputValidation(undefined);
    if (doorsWithLocations.findIndex((door) => door.name === value) !== -1 && value !== drawerState.contentValue.name)
      setNameInputValidation(translation.name_must_be_unique);
    if (value === '') setNameInputValidation(translation.name_cannot_be_empty);
  };

  const handleSwitchIsOfficeModeEnabledOnChange = () => {
    setSwitchIsOfficeModeEnabled(!switchIsOfficeModeEnabled);
  };

  const isCorrectTimeSetForDay = useCallback(
    () =>
      Boolean(
        dayjs(`${CONSTANT_DATE} ${inputOfficeModeFromValue}`).isBefore(
          dayjs(`${CONSTANT_DATE} ${inputOfficeModeToValue}`)
        )
      ),
    [inputOfficeModeFromValue, inputOfficeModeToValue]
  );

  const getSelectOptions = () => {
    if (!locationsLoading && locations) {
      return locations.map((location) => ({ label: location.id, value: location.name }));
    }
    return [];
  };

  const getGroupsOptions = () =>
    !locationsWithDoorsLoading && locationsWithDoors
      ? sortBy(locationsWithDoors, (location) => location.name).map(
          (location) => ({ label: location.name, value: location.id } as Value)
        )
      : [];

  const getGroupsDefaultValue = () =>
    !locationsWithDoorsLoading && locationsWithDoors && drawerState.contentValue.groupIds
      ? drawerState.contentValue.groupIds.map((location) => ({ label: location.name, value: location.id }))
      : [];

  useEffect(() => {}, [doorsWithLocations, drawerState.contentValue.name, nameInputValue]);

  useEffect(() => {
    if (switchIsOfficeModeEnabled && !isCorrectTimeSetForDay())
      setOfficeModeTimeInputValidation(translation.start_time_cannot_be_after_end_time);
    else setOfficeModeTimeInputValidation(undefined);
  }, [isCorrectTimeSetForDay, switchIsOfficeModeEnabled, translation.start_time_cannot_be_after_end_time]);

  useEffect(() => setValue('locationId', selectValue?.label || ''), [selectValue, setValue]);

  return (
    <DrawerTemplate
      title={translation.edit_lock}
      confirmButtonText={translation.save_changes}
      closeButtonText={translation.cancel}
      id="drawer-edit-lock"
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      disableConfirmButton={
        showValidation &&
        (nameInputValidation !== undefined || officeModeTimeInputValidation !== undefined) &&
        isLoading
      }
    >
      <LockWithGroups
        defaultValues={drawerState?.contentValue}
        register={register}
        selectOption={getSelectOptions()}
        selectValue={selectValue?.value || null}
        inputValue={selectInputValue}
        handleSelectInputChange={setSelectInputValue}
        handleSelectChange={handleSelectChange}
        nameInputValue={nameInputValue || ''}
        handleNameInputOnChange={handleNameInputOnChange}
        control={control}
        switchIsOfficeModeEnabled={switchIsOfficeModeEnabled}
        handleSwitchIsOfficeModeEnabledOnChange={handleSwitchIsOfficeModeEnabledOnChange}
        inputOfficeModeFromValue={inputOfficeModeFromValue}
        handleInputOfficeModeFromValueOnChange={setInputOfficeModeFromValue}
        inputOfficeModeToValue={inputOfficeModeToValue}
        handleInputOfficeModeToValueOnChange={setInputOfficeModeToValue}
        inputValidationFailed={!isCorrectTimeSetForDay()}
        showValidation={showValidation}
        nameInputValidation={nameInputValidation}
        officeModeTimeInputValidation={officeModeTimeInputValidation}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
        batteryStatus={getBatteryStatus(drawerState?.contentValue.batteryWarningLevel)}
        groupsOptions={getGroupsOptions()}
        groupsDefaultValue={getGroupsDefaultValue()}
        handleGroupsOnChange={setGroupsMultiselect}
      />
    </DrawerTemplate>
  );
};
export default EditLockWithGroups;
