import React from 'react';
import styled from 'styled-components';

export interface Props {
  elementOnHover: React.ReactNode;
  id?: string;
  top?: string;
  right?: string;
}

const StyledTooltip = styled.div<Pick<Props, 'top' | 'right'>>`
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.b3};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.borderRadius}px;
  position: absolute;
  z-index: 100;
  visibility: hidden;
`;

const StyledContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    & ${StyledTooltip} {
      visibility: visible;
    }
  }
`;

const Tooltip: React.FC<Props> = ({ elementOnHover, top = '50%', right = '50%', id, children }) => (
  <StyledContainer data-testid="tooltip">
    <StyledTooltip top={top} right={right} id={id}>
      <div>{elementOnHover}</div>
    </StyledTooltip>
    {children}
  </StyledContainer>
);
export default Tooltip;
