import React, { useEffect, useState } from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { ChangeGroupExternalUserForm, ExternalUsersGroupsState } from 'state/types';
import useExternalUsers from 'hooks/useExternalUsers/useExternalUsers';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import Select, { OptionProps } from 'ui/molecules/Select/Select';
import useDrawer from 'hooks/useDrawer/useDrawer';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import useTranslation from 'hooks/useTranslation/useTranslation';

const ChangeGroupExternalUser: React.FC = () => {
  const { translation } = useTranslation();
  const { hideModal, modalState } = useModal();
  const { drawerState, showDrawer } = useDrawer();
  const modalStateInitialValue: ChangeGroupExternalUserForm = modalState.contentValue;
  const handleCancelButton = () => {
    hideModal();
  };
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { externalUsersGroups } = useExternalUsers({
    handleFetchError
  });

  const getVisitorGroupById = (groups: ExternalUsersGroupsState, id: string) => groups.find((group) => group.id === id);

  const [newGroupInputValue, setNewGroupInputValue] = useState<string>('');
  const [newGroupSelectValue, setNewGroupSelectValue] = useState<OptionProps<null | string>>({
    value: getVisitorGroupById(externalUsersGroups, modalState.contentValue.currentVisitorGroupId)?.id!,
    label: getVisitorGroupById(externalUsersGroups, modalState.contentValue.currentVisitorGroupId)?.name!
  });
  const [groupInputValidation, setGroupInputValidation] = useState<string | undefined>(undefined);
  const [showValidation, setShowValidation] = useState(false);

  const getSelectOptions = () => {
    const selectOptions: OptionProps<null | string>[] = [];
    if (externalUsersGroups.length > 0) {
      externalUsersGroups.forEach((group) => selectOptions.push({ label: group.name, value: group.id }));
    }
    return selectOptions;
  };

  const handleConfirmButton = async () => {
    setShowValidation(true);
    if (drawerState && newGroupSelectValue?.value && groupInputValidation === undefined)
      if (drawerState.type === 'editExternalUser') {
        showDrawer({
          type: 'editExternalUser',
          contentValue: { ...drawerState.contentValue, group: newGroupSelectValue?.value }
        });
      }
    hideModal();
  };

  useEffect(() => {
    if (newGroupSelectValue.value === null && newGroupSelectValue.label === '')
      setGroupInputValidation(translation.group_cannot_be_empty);
    else setGroupInputValidation(undefined);
  }, [newGroupSelectValue, translation.group_cannot_be_empty]);

  return (
    <Modal>
      <ComponentWrapper
        flexDirection="column"
        width="30rem"
        gap="2rem"
        margin="1rem 0"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="Calendar" color="dTextHigh" width={40} height={40} viewBox="0 0 24 24" id="delete-bin-icon" />
        <Typography variant="title2" color="dTextHigh">
          {translation.move_external_group_member}
        </Typography>
        <ComponentWrapper flexDirection="column" gap="0.75rem" width="26rem" justifyContent="flex-start">
          <Typography color="dTextHigh" variant="label">
            {translation.name}
          </Typography>
          <Typography color="dTextHigh" variant="body1">
            {modalStateInitialValue.name}
          </Typography>
          <Typography color="dTextHigh" variant="label">
            {translation.current_group}
          </Typography>
          <Typography color="dTextHigh" variant="body1">
            {getVisitorGroupById(externalUsersGroups, modalStateInitialValue.currentVisitorGroupId)!.name}
          </Typography>
          <Select
            fieldTemplateProps={{
              label: translation.new_group,
              failed: showValidation && groupInputValidation !== undefined,
              failedText: groupInputValidation,
              failedId: 'drawer-input-group-validation',
              customRequired: true
            }}
            autocompleteProps={{
              value: newGroupSelectValue,
              inputValue: newGroupInputValue,
              options: getSelectOptions(),
              onInputChange: (_, value) => {
                if (value !== 'undefined') setNewGroupInputValue(value);
              },
              onChange: (_, value) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                value
                  ? setNewGroupSelectValue(value as OptionProps<null | string>)
                  : setNewGroupSelectValue({ value: null, label: '' } as OptionProps<null | string>);
              },
              isOptionEqualToValue: (option, value) =>
                (option as OptionProps<null | string>).value === (value as OptionProps<null | string>).value,
              getOptionLabel: (option) => (option as OptionProps<null | string>).label,
              id: 'change-group-external-groups-select-autocomplete',
              onKeyDown: (event) => formKeyDownHandler(event, handleConfirmButton)
            }}
            textFieldProps={{
              id: 'change-group-external-groups-select-input'
            }}
          />
        </ComponentWrapper>
        <ComponentWrapper width="100%">
          <Button backgroundColor="transparent" textColor="b1" onClick={handleCancelButton} id="cancel-button">
            {translation.cancel}
          </Button>
          <Button
            onClick={handleConfirmButton}
            disabled={groupInputValidation !== undefined && showValidation}
            textColor={groupInputValidation !== undefined && showValidation ? 'dTextLow' : 'lTextHigh'}
            id="confirm-button"
            backgroundColor={groupInputValidation !== undefined && showValidation ? 'b5' : 'primary'}
          >
            {translation.confirm}
          </Button>
        </ComponentWrapper>
      </ComponentWrapper>
    </Modal>
  );
};

export default ChangeGroupExternalUser;
