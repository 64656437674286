/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants
} from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';

interface Props {
  id: string;
  name?: string;
  defaultAccessGrants?: GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants[];
  scheduledAccessGrants?: GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants[];
  type?: string;
}

export function createMockedVisitorGroupWithSchedules({
  id,
  name = 'mockedVisitorGroupName',
  defaultAccessGrants = [],
  scheduledAccessGrants = [],
  type = ''
}: Props): GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant {
  return {
    __typename: 'VisitorGroup',
    id,
    name,
    defaultAccessGrants,
    scheduledAccessGrants,
    type
  };
}
