/* eslint-disable import/prefer-default-export */
import { visitorsGroupsWithAccessGrantsVar } from 'state/vars';
import { AssignToAccessGrants_assignToAccessGrants } from 'graphql/generated/AssignToAccessGrants';
import { UnassignToAccessGrants_unassignFromAccessGrants } from 'graphql/generated/UnassignToAccessGrants';
import { GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithAccessGrants';

export const setVisitorGroupsWithAccessGrantsAction = (
  visitorsGroupsWithAccessGrants: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant[]
) => {
  visitorsGroupsWithAccessGrantsVar(visitorsGroupsWithAccessGrants);
};

const updateAccessGrant = (
  visitorGroup: GetVisitorGroupsForTenantWithAccessGrants_getVisitorGroupsForTenant,
  updatedAccessGrant: AssignToAccessGrants_assignToAccessGrants | UnassignToAccessGrants_unassignFromAccessGrants,
  grantType: 'default' | 'scheduled'
) => {
  const accessGrants = grantType === 'default' ? visitorGroup.defaultAccessGrants : visitorGroup.scheduledAccessGrants;

  return accessGrants.map((accessGrant) => {
    if (accessGrant.id === updatedAccessGrant.id) {
      return { ...accessGrant, doors: updatedAccessGrant.doors, locations: updatedAccessGrant.locations };
    }
    return accessGrant;
  });
};

export const updateToAccessGrantsAction = (
  updatedAccessGrant: AssignToAccessGrants_assignToAccessGrants | UnassignToAccessGrants_unassignFromAccessGrants
) => {
  const currentVisitorGroups = visitorsGroupsWithAccessGrantsVar();
  const updatedVisitorGroups = currentVisitorGroups.map((visitorGroup) => {
    if (visitorGroup.defaultAccessGrants.length < visitorGroup.scheduledAccessGrants.length)
      return {
        ...visitorGroup,
        scheduledAccessGrants: updateAccessGrant(visitorGroup, updatedAccessGrant, 'scheduled')
      };
    return {
      ...visitorGroup,
      defaultAccessGrants: updateAccessGrant(visitorGroup, updatedAccessGrant, 'default')
    };
  });

  visitorsGroupsWithAccessGrantsVar(updatedVisitorGroups);
};
