import { GET_DOORS_FOR_TENANT, GET_DOORS_FOR_TENANT_WITH_OFFICE_MODE } from 'graphql/queries';
import { useEffect } from 'react';
import { useMutation, useReactiveVar, useLazyQuery } from '@apollo/client';
import { DELETE_DOOR_BY_ID, UPDATE_DOOR, UPDATE_DOOR_WITH_OFFICE_MODE } from 'graphql/mutations';
import { deleteDoorsAction, setDoorsAction, updateDoorsAction } from 'state/actions/doors';
import { doorsVar } from 'state/vars';
import { DeleteDoorById, DeleteDoorByIdVariables } from 'graphql/generated/DeleteDoorById';
import {
  GetDoorsForTenantWithOfficeMode,
  GetDoorsForTenantWithOfficeMode_getDoorsForTenant
} from 'graphql/generated/GetDoorsForTenantWithOfficeMode';
import {
  UpdateDoorWithOfficeMode,
  UpdateDoorWithOfficeModeVariables
} from 'graphql/generated/UpdateDoorWithOfficeMode';
import ArrayUtil from 'utils/Array/Array.util';
import dayjs from 'dayjs';
import { BaseHookProps } from '../shared/types';

const useDoors = ({ handleFetchError }: BaseHookProps) => {
  const doors = useReactiveVar(doorsVar);

  const [getDoorsForTenant, { loading }] = useLazyQuery<GetDoorsForTenantWithOfficeMode>(
    GET_DOORS_FOR_TENANT_WITH_OFFICE_MODE,
    {
      onCompleted: (data) => {
        setDoorsAction(
          data.getDoorsForTenant.map((door) =>
            // we need to display 'powered' battery status for hi-sec reader
            door.domSerialNumber?.startsWith('65') || door.domSerialNumber?.startsWith('66')
              ? { ...door, batteryWarningLevel: 4 }
              : door
          )
        );
      },
      onError: () => {
        handleFetchError('Error while fetching doors');
      }
    }
  );

  const [updateDoorMutation, { loading: updateDoorLoading }] = useMutation<
    UpdateDoorWithOfficeMode,
    UpdateDoorWithOfficeModeVariables
  >(UPDATE_DOOR, {
    onCompleted: (data) => {
      updateDoorsAction(data.updateDoor);
    },
    onError: () => {
      handleFetchError('Error while updating doors');
    }
  });

  const [updateDoorWithOfficeModeMutation, { loading: updateDoorWithOfficeModeLoading }] = useMutation<
    UpdateDoorWithOfficeMode,
    UpdateDoorWithOfficeModeVariables
  >(UPDATE_DOOR_WITH_OFFICE_MODE, {
    onCompleted: (data) => {
      updateDoorsAction(data.updateDoor);
    },
    onError: () => {
      handleFetchError('Error while updating doors');
    }
  });

  const [deleteDoorMutation, { loading: deleteDoorLoading }] = useMutation<DeleteDoorById, DeleteDoorByIdVariables>(
    DELETE_DOOR_BY_ID,
    {
      onCompleted: (data) => {
        if (data.deleteDoorById) deleteDoorsAction(data.deleteDoorById);
      },
      onError: () => {
        handleFetchError('Error while deleting doors');
      }
    }
  );
  const updateDoor = async (doorId: string, name: string, locationId?: string | null) => {
    await updateDoorMutation({
      variables: {
        doorId,
        name,
        locationId
      },
      // Here Apollo doesn't update cache automatically, we have to do it manually
      update(cache, { data: updatedDoors }) {
        cache.writeQuery({
          query: GET_DOORS_FOR_TENANT,
          data: {
            getDoorsForTenant: doors.map((door) => (door.id === updatedDoors?.updateDoor.id ? updatedDoors : door))
          }
        });
      }
    });
  };

  const updateDoorWithOfficeMode = async (
    doorId: string,
    name: string,
    locationId?: string | null,
    officeModeFrom?: string,
    officeModeTo?: string
  ) => {
    await updateDoorWithOfficeModeMutation({
      variables: {
        doorId,
        name,
        locationId,
        officeMode: {
          scheduleType: 'weekSchedule',
          weekSchedule:
            officeModeFrom && officeModeTo
              ? // eslint-disable-next-line no-magic-numbers
                ArrayUtil.Range(7).map((_, index) => ({
                  dayName: dayjs().weekday(index).format('dddd').toLowerCase(),
                  from: officeModeFrom,
                  to: officeModeTo
                }))
              : []
        }
      },
      // Here Apollo doesn't update cache automatically, we have to do it manually
      update(cache, { data: updatedDoors }) {
        cache.writeQuery({
          query: GET_DOORS_FOR_TENANT,
          data: {
            getDoorsForTenant: doors.map((door) => (door.id === updatedDoors?.updateDoor.id ? updatedDoors : door))
          }
        });
      }
    });
  };

  const deleteDoor = async (doorId: string) => {
    await deleteDoorMutation({
      variables: {
        doorId
      },
      // Here Apollo doesn't update cache automatically, we have to do it manually
      update(cache, { data: deletedDoor }) {
        const updatedDoors = doors.filter(({ id }) => id !== deletedDoor?.deleteDoorById);
        cache.writeQuery({
          query: GET_DOORS_FOR_TENANT,
          data: {
            getDoorsForTenant: updatedDoors
          }
        });
      }
    });
  };

  const isOfficeMode = (door: GetDoorsForTenantWithOfficeMode_getDoorsForTenant) =>
    door.officeMode.weekDays.some((day) => day !== null);

  useEffect(() => {
    getDoorsForTenant();
  }, [getDoorsForTenant]);

  return {
    doors,
    updateDoor,
    updateDoorLoading,
    updateDoorWithOfficeMode,
    updateDoorWithOfficeModeLoading,
    deleteDoor,
    deleteDoorLoading,
    loading,
    isOfficeMode
  };
};

export default useDoors;
