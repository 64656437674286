/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant,
  GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';

interface Props {
  id: string;
  members?: Array<GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members>;
  name?: string;
  type?: string;
  externalRef?: string;
}

export function createMockedVisitorGroupWithMembersAndType({
  id,
  members = [],
  name = 'mockedVisitorGroupName',
  type = 'CO_CONTRACTORS',
  externalRef = ''
}: Props): GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant {
  return {
    __typename: 'VisitorGroup',
    id,
    name,
    members,
    type,
    externalRef
  };
}
