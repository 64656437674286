import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { GET_VISITOR_GROUPS_FOR_TENANT_WITH_IS_OFFICE_MODE_ENABLED } from 'graphql/queries';
import { useEffect } from 'react';
import { visitorGroupsWithIsOfficeModeEnabledVar } from 'state/vars';
import { UPDATE_ACCESS_GRANT_WITH_IS_OFFICE_MODE_ENABLED } from 'graphql/mutations';
import {
  GetVisitorGroupsForTenantWithIsOfficeModeEnabled,
  GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithIsOfficeModeEnabled';
import {
  SetAccessGrantsIsOfficeModeEnabled,
  SetAccessGrantsIsOfficeModeEnabledVariables
} from 'graphql/generated/SetAccessGrantsIsOfficeModeEnabled';
import {
  setIsOfficeModeEnabledAction,
  updateAccessGrantsArrayWithIsOfficeModeEnabled,
  updateIsOfficeModeEnabledAction
} from 'state/actions/isOfficeModeEnabled';
import { BaseHookProps } from '../shared/types';

const useIsOfficeModeEnabled = ({ handleFetchError }: BaseHookProps) => {
  const visitorGroupsWithIsOfficeModeEnabled = useReactiveVar(visitorGroupsWithIsOfficeModeEnabledVar);

  const [getVisitorGroupsWithIsOfficeModeEnabled, { loading }] =
    useLazyQuery<GetVisitorGroupsForTenantWithIsOfficeModeEnabled>(
      GET_VISITOR_GROUPS_FOR_TENANT_WITH_IS_OFFICE_MODE_ENABLED,
      {
        onCompleted: (data) => {
          setIsOfficeModeEnabledAction(data.getVisitorGroupsForTenant);
        },
        onError: () => {
          handleFetchError('Error while fetching groups with officeMode');
        },
        fetchPolicy: 'network-only'
      }
    );

  const checkIsOfficeModeEnabledForVisitorGroup = (id: string) => {
    const visitorGroupById = visitorGroupsWithIsOfficeModeEnabled.find((group) => group.id === id);
    return (
      visitorGroupById &&
      (visitorGroupById.defaultAccessGrants.some((grant) => grant.isOfficeModeEnabled) ||
        visitorGroupById.scheduledAccessGrants.some((grant) => grant.isOfficeModeEnabled))
    );
  };

  const getAccessGrantWithIsOfficeModeEnabledForGroup = (
    group: GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant,
    isOfficeModeEnabledForGroup: boolean
  ) => {
    const { defaultAccessGrants, scheduledAccessGrants } = group;
    return [...defaultAccessGrants, ...scheduledAccessGrants].find(
      (grant) => grant.isOfficeModeEnabled === isOfficeModeEnabledForGroup
    );
  };

  const [
    updateAccessGrantIsOfficeModeEnabledMutation,
    { loading: updateAccessGrantIsOfficeModeEnabledMutationLoading }
  ] = useMutation<SetAccessGrantsIsOfficeModeEnabled, SetAccessGrantsIsOfficeModeEnabledVariables>(
    UPDATE_ACCESS_GRANT_WITH_IS_OFFICE_MODE_ENABLED,
    {
      onCompleted: (data) => {
        updateIsOfficeModeEnabledAction(data.setAccessGrantsIsOfficeModeEnabled.id);
      },
      onError: () => {
        handleFetchError('Error while updating Access Grant');
      }
    }
  );

  useEffect(() => {
    getVisitorGroupsWithIsOfficeModeEnabled();
  }, [getVisitorGroupsWithIsOfficeModeEnabled]);

  const updateAccessGrantIsOfficeModeEnabled = async (groupId: string) => {
    const isOfficeModeEnabledForGroup = checkIsOfficeModeEnabledForVisitorGroup(groupId);
    const groupWithAccessGrant = visitorGroupsWithIsOfficeModeEnabled.find((group) => group.id === groupId);
    if (groupWithAccessGrant && isOfficeModeEnabledForGroup !== undefined) {
      const accessGrant = getAccessGrantWithIsOfficeModeEnabledForGroup(
        groupWithAccessGrant,
        isOfficeModeEnabledForGroup
      );
      const { data } = await updateAccessGrantIsOfficeModeEnabledMutation({
        variables: {
          accessGrantId: accessGrant!.id,
          isOfficeModeEnabled: !isOfficeModeEnabledForGroup
        },
        // Here Apollo doesn't update cache automatically, we have to do it manually
        update(cache, { data: updatedAccessGrantId }) {
          if (updatedAccessGrantId?.setAccessGrantsIsOfficeModeEnabled) {
            const updatedVisitorGroups = visitorGroupsWithIsOfficeModeEnabled.map((group) => ({
              ...group,
              defaultAccessGrants: updateAccessGrantsArrayWithIsOfficeModeEnabled(
                updatedAccessGrantId.setAccessGrantsIsOfficeModeEnabled.id,
                group.defaultAccessGrants
              ),
              scheduledAccessGrants: updateAccessGrantsArrayWithIsOfficeModeEnabled(
                updatedAccessGrantId.setAccessGrantsIsOfficeModeEnabled.id,
                group.scheduledAccessGrants
              )
            }));

            cache.writeQuery({
              query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_IS_OFFICE_MODE_ENABLED,
              data: {
                getVisitorGroupsForTenant: updatedVisitorGroups
              }
            });
          }
        }
      });

      return data;
    }
    return null;
  };

  return {
    visitorGroupsWithIsOfficeModeEnabled,
    checkIsOfficeModeEnabledForVisitorGroup,
    updateAccessGrantIsOfficeModeEnabled,
    updateAccessGrantIsOfficeModeEnabledMutationLoading,
    loading
  };
};

export default useIsOfficeModeEnabled;
