import jwt_decode from 'jwt-decode';

interface DecodedToken {
  baseUrl?: string;
  exp?: number;
  iat?: number;
  id?: string;
  mobileId?: string;
  nbf?: string;
  orgId?: string;
  role?: string;
  type?: string;
}

const getQrCodeValue = (token: string) => {
  if (token) {
    const decodedToken: DecodedToken = jwt_decode(token);
    const { baseUrl } = decodedToken;
    if (baseUrl) {
      return `${new URL(baseUrl).origin}/register/?code=${token}`;
    }
  }
  return '';
};

export default { getQrCodeValue };
