import React from 'react';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import useTranslation from 'hooks/useTranslation/useTranslation';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';

export interface Props {
  id?: string;
}

const StatusDisconnected: React.FC<Props> = ({ id }) => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper id={id} justifyContent="flex-start" alignItems="center">
      <Icon color="error" name="StatusDisconnected" viewBox="0 0 14 14" />
      <Typography color="error" variant="label">
        {translation.disconnected}
      </Typography>
    </ComponentWrapper>
  );
};
export default StatusDisconnected;
