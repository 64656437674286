import { schedulesVar } from 'state/vars';
import { GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';
import { UpdateAccessGrant_updateAccessGrant } from 'graphql/generated/UpdateAccessGrant';

export const setSchedulesAction = (schedules: GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant[]) => {
  schedulesVar(schedules);
};

export const updateAccessGrantAction = (updatedSchedule: UpdateAccessGrant_updateAccessGrant) => {
  const currentVisitorGroupsWithSchedules = schedulesVar();
  const visitorGroupWithUpdatedScheduleIndex = currentVisitorGroupsWithSchedules.findIndex((group) =>
    group[
      group.defaultAccessGrants.length > group.scheduledAccessGrants.length
        ? 'defaultAccessGrants'
        : 'scheduledAccessGrants'
    ].some((grant) => grant.id === updatedSchedule.id)
  );
  const visitorGroupWithUpdatedScheduleAccessMode =
    currentVisitorGroupsWithSchedules[visitorGroupWithUpdatedScheduleIndex].defaultAccessGrants.length >
    currentVisitorGroupsWithSchedules[visitorGroupWithUpdatedScheduleIndex].scheduledAccessGrants.length;
  const updatedVisitorGroups = currentVisitorGroupsWithSchedules.map((group, index) =>
    index === visitorGroupWithUpdatedScheduleIndex
      ? {
          ...group,
          defaultAccessGrants: visitorGroupWithUpdatedScheduleAccessMode ? [updatedSchedule] : [],
          scheduledAccessGrants: visitorGroupWithUpdatedScheduleAccessMode ? [] : [updatedSchedule]
        }
      : group
  );

  schedulesVar(updatedVisitorGroups);
};
