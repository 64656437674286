/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenant_getVisitorGroupsForTenant_defaultAccessGrants,
  GetVisitorGroupsForTenant_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenant';

interface Props {
  id: string;
  defaultAccessGrants?: Array<GetVisitorGroupsForTenant_getVisitorGroupsForTenant_defaultAccessGrants>;
  scheduledAccessGrants?: Array<GetVisitorGroupsForTenant_getVisitorGroupsForTenant_defaultAccessGrants>;
  name?: string;
  externalRef?: string;
  type?: string;
}

export function createMockedVisitorGroupWithType({
  id,
  defaultAccessGrants = [],
  scheduledAccessGrants = [],
  name = 'mockedVisitorGroupName',
  externalRef = '',
  type = ''
}: Props): GetVisitorGroupsForTenant_getVisitorGroupsForTenant {
  return {
    __typename: 'VisitorGroup',
    id,
    name,
    defaultAccessGrants,
    scheduledAccessGrants,
    externalRef,
    type
  };
}
