import React from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useExternalUsers from 'hooks/useExternalUsers/useExternalUsers';
import useTranslation from 'hooks/useTranslation/useTranslation';

const DeleteExternalUserGroup: React.FC = () => {
  const { translation } = useTranslation();
  const { hideModal, modalState } = useModal();
  const { hideDrawer } = useDrawer();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });

  const { deleteVisitorGroupById, deleteVisitorGroupByIdMutationLoading } = useExternalUsers({
    handleFetchError
  });

  const handleDeleteExternalUserGroup = () => {
    if (!deleteVisitorGroupByIdMutationLoading) {
      deleteVisitorGroupById(modalState.contentValue.visitorGroupId);
      hideModal();
      hideDrawer();
    }
  };

  return (
    <Modal>
      <ComponentWrapper
        flexDirection="column"
        width="36rem"
        gap="2rem"
        margin="1rem 0"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="DeleteBin" color="dTextHigh" width={40} height={40} viewBox="0 0 24 24" id="delete-bin-icon" />
        <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
          <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
            <Typography textAlign="center" variant="title2" color="dTextHigh">
              {translation.are_you_sure_that_you_want_to_delete_this_group}
            </Typography>
          </ComponentWrapper>
          <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
            <Typography textAlign="center" variant="body3" color="dTextHigh">
              {`${translation.all_external_group_member_schedules_and_access_rights_linked_to_this_group_will_be_removed}.`}
            </Typography>
          </ComponentWrapper>
          <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
            <Typography textAlign="center" variant="body3" color="dTextHigh">
              {translation.this_is_not_reversible}
            </Typography>
          </ComponentWrapper>
        </ComponentWrapper>
        <ComponentWrapper width="100%">
          <Button backgroundColor="transparent" textColor="b1" onClick={hideModal} id="cancel-button-modal">
            {translation.no_thank_you}
          </Button>
          <Button onClick={handleDeleteExternalUserGroup} id="ok-button-modal">
            {translation.yes_i_m_sure}
          </Button>
        </ComponentWrapper>
      </ComponentWrapper>
    </Modal>
  );
};

export default DeleteExternalUserGroup;
