import React, { useState } from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Radio from 'ui/atoms/Radio/Radio';
import useTranslation from 'hooks/useTranslation/useTranslation';

const CreateExportAccessEvents: React.FC = () => {
  const { translation } = useTranslation();
  const [isFilteredOptionSelected, setIsFilteredOptionSelected] = useState(true);
  const { hideModal, showModal } = useModal();
  const handleExportAccessEvents = () => {
    showModal({ type: 'preparingExportAccessEvents', contentValue: { isFilteredOptionSelected } });
  };

  return (
    <Modal>
      <ComponentWrapper
        flexDirection="column"
        width="36rem"
        gap="2rem"
        margin="1rem 0"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="Export" color="dTextHigh" width={40} height={40} viewBox="0 0 24 24" id="export-icon" />
        <Typography variant="title2" color="dTextHigh">
          {translation.create_export}
        </Typography>
        <ComponentWrapper flexDirection="column" gap="1rem" justifyContent="center" alignItems="center">
          <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="title4" color="dTextHigh">
              {
                translation.please_note_that_one_or_more_filters_have_been_applied_to_the_access_events_only_the_results_matching_these_filters_will_be_included_in_the_export
              }
            </Typography>
          </ComponentWrapper>
          <ComponentWrapper
            width="100%"
            flexDirection="column"
            gap="0.5rem"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Radio
              checked={isFilteredOptionSelected}
              onClick={() => setIsFilteredOptionSelected(true)}
              onChange={() => null}
              value="option1"
              disabled={false}
              label={translation.export_filtered_events_only}
              id="create-export-access-events-filtered-events-radio"
            />
            <Radio
              checked={!isFilteredOptionSelected}
              onClick={() => setIsFilteredOptionSelected(false)}
              onChange={() => null}
              value="option2"
              disabled={false}
              label={translation.export_all_events}
              id="create-export-access-events-all-events-radio"
            />
          </ComponentWrapper>
        </ComponentWrapper>
        <ComponentWrapper width="100%">
          <Button backgroundColor="transparent" textColor="b1" onClick={hideModal} id="cancel-button-modal">
            {translation.cancel}
          </Button>
          <Button onClick={handleExportAccessEvents} id="ok-button-modal">
            {translation.export}
          </Button>
        </ComponentWrapper>
      </ComponentWrapper>
    </Modal>
  );
};

export default CreateExportAccessEvents;
