/* eslint-disable import/prefer-default-export */
import { GetDoorsForTenant, GetDoorsForTenant_getDoorsForTenant } from 'graphql/generated/GetDoorsForTenant';
import { createMockedDoor } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/door';

export function mockedGetDoorsForTenantData(
  getDoorsForTenant: Array<GetDoorsForTenant_getDoorsForTenant>
): GetDoorsForTenant {
  return {
    getDoorsForTenant
  };
}

export const mockedGetDoorsForTenantDataForQuery = mockedGetDoorsForTenantData([
  createMockedDoor({ id: 'mockedDoorId0', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 0' }),
  createMockedDoor({ id: 'mockedDoorId1', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 1' }),
  createMockedDoor({ id: 'mockedDoorId2', batteryWarningLevel: 0, domSerialNumber: '66', name: 'door 2' })
]);

export const mockedGetDoorsForTenantDataAdmin = mockedGetDoorsForTenantData([
  createMockedDoor({ id: 'mockedDoorId1', batteryWarningLevel: 0, domSerialNumber: '65', name: 'Test door 1' }),
  createMockedDoor({ id: 'mockedDoorId2', batteryWarningLevel: 3, domSerialNumber: '7F', name: 'Test door 2' }),
  createMockedDoor({ id: 'mockedDoorId3', batteryWarningLevel: 2, domSerialNumber: 'A3', name: 'Test door 3' }),
  createMockedDoor({ id: 'mockedDoorId4', batteryWarningLevel: 1, domSerialNumber: 'ZZ', name: 'Test door 4' }),
  createMockedDoor({ id: 'mockedDoorId5', batteryWarningLevel: 0, domSerialNumber: 'ZZZ', name: 'Test door 5' }),
  createMockedDoor({ id: 'mockedDoorId6', batteryWarningLevel: 0, domSerialNumber: '66', name: 'Test door 6' })
]);
