import { isEqual, orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { schedulesVar } from 'state/vars';
import { SchedulesState } from 'state/types';
import { GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';
import { setSchedulesAction } from 'state/actions/schedules';
import { Translation } from 'hooks/useTranslation/useTranslation';

interface Sorting {
  isAscending?: boolean;
  columnName?: string;
}

export const handleSorting = (
  translation: Translation,
  item: GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant,
  columnName?: string
) => {
  switch (columnName) {
    case translation.name:
    default:
      return item.name;
  }
};

export const getSortedArray = (schedules: SchedulesState, sorting: Sorting, translation: Translation) =>
  orderBy(schedules, (item) => handleSorting(translation, item, sorting.columnName), [
    sorting.isAscending ? 'asc' : 'desc'
  ]);

const useSortingSchedulesTable = (translation: Translation) => {
  const schedules = useReactiveVar(schedulesVar);
  const [sorting, setSorting] = useState<Sorting>({ isAscending: true, columnName: translation.name });

  const handleSortingOnClick = useCallback(
    (columnName: string) => {
      if (sorting.isAscending !== undefined && sorting.columnName === columnName) {
        return setSorting({ isAscending: !sorting.isAscending, columnName });
      }
      return setSorting({ isAscending: true, columnName });
    },
    [sorting.columnName, sorting.isAscending]
  );

  const handleSortingIcon = (columnName: string) => {
    if (sorting.isAscending !== undefined)
      if (sorting.columnName === columnName) return sorting.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  useEffect(() => {
    const sortedSchedules = getSortedArray(schedules, sorting, translation);
    if (!isEqual(sortedSchedules, schedules)) setSchedulesAction(sortedSchedules);
  }, [schedules, sorting, translation]);

  return { handleSortingOnClick, handleSortingIcon };
};

export default useSortingSchedulesTable;
