import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { StaticTimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/theme';
import { FlexDirection } from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  id?: string;
  value: null | dayjs.Dayjs;
  setValue: (value: null | dayjs.Dayjs) => void;
  minTime?: null | dayjs.Dayjs;
  maxTime?: null | dayjs.Dayjs;
  disabled?: boolean;
  label?: string;
  flexDirection?: FlexDirection;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const defaultStyles = {
  root: {
    '& .MuiDialogActions-root': {
      display: 'none'
    },
    '& .MuiPickersToolbar-root': {
      '& .MuiSvgIcon-root': {
        display: 'none'
      }
    },
    '& .MuiClockPointer-root': {
      backgroundColor: `${colors.primary}`
    },
    '& .MuiClock-pin': {
      backgroundColor: `${colors.primary}`
    },
    '& .MuiClockPointer-thumb': {
      backgroundColor: `${colors.primary}`,
      borderColor: `${colors.primary}`
    },
    '& .MuiClock-clock': {
      backgroundColor: `${colors.transparent}`
    }
  }
};

const useStyles = makeStyles(() => defaultStyles);

const useStylesDirectionColumn = makeStyles(() => ({
  ...defaultStyles,
  root: {
    ...defaultStyles.root,
    '& .MuiCalendarOrClockPicker-root': {
      flexDirection: 'column'
    }
  }
}));

const TimePickerComponent: React.FC<Props> = ({
  id,
  value,
  setValue,
  minTime,
  maxTime,
  disabled,
  onKeyDown,
  flexDirection = 'row'
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = flexDirection === 'row' ? useStyles() : useStylesDirectionColumn();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} id={id}>
      <StaticTimePicker
        ampm={false}
        className={classes.root}
        value={value}
        openTo="hours"
        toolbarTitle=""
        disabled={disabled}
        minTime={minTime}
        maxTime={maxTime}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
      />
    </LocalizationProvider>
  );
};

export default TimePickerComponent;
