import React from 'react';
import styled from 'styled-components';
import AvatarGroup, { Props as AvatarGroupProps } from 'ui/molecules/AvatarGroup/AvatarGroup';

export interface CssProps {
  flex?: string;
}
export interface Props extends CssProps {
  avatarGroupProps: AvatarGroupProps;
  id?: string;
}

const TableCellWrapper = styled.div<Partial<CssProps>>`
  display: flex;
  flex-direction: row;
  min-width: 0;
  gap: 0.5rem;
  align-items: center;
  ${({ flex }) => flex && `flex: ${flex}`};
`;

const AvatarGroupCell: React.FC<Props> = ({ avatarGroupProps, flex, id }) => (
  <TableCellWrapper flex={flex} id={id}>
    <AvatarGroup {...avatarGroupProps} />
  </TableCellWrapper>
);

export default AvatarGroupCell;
