/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenant,
  GetVisitorGroupsForTenant_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenant';
import { createMockedAccessGrant } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/accessGrant';
import { createMockedDoor } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/door';
import { createMockedVisitorGroupWithType } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithType';

export function getVisitorGroupsForTenantWithTypesDataForQuery(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenant_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenant {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithTypesDataForQuery = getVisitorGroupsForTenantWithTypesDataForQuery([
  createMockedVisitorGroupWithType({
    id: 'mockedVisitorGroupId0',
    defaultAccessGrants: [
      createMockedAccessGrant({ id: 'mockedAccessGrantId0', doors: [createMockedDoor({ id: 'mockedDoorId0' })] })
    ]
  }),
  createMockedVisitorGroupWithType({
    id: 'mockedVisitorGroupId1',
    scheduledAccessGrants: [
      createMockedAccessGrant({ id: 'mockedAccessGrantId1', doors: [createMockedDoor({ id: 'mockedDoorId1' })] })
    ]
  })
]);

export const mockedGetVisitorGroupsForTenantWithTypesDataAdmin = getVisitorGroupsForTenantWithTypesDataForQuery([
  createMockedVisitorGroupWithType({
    id: 'mockedIdInstallers',
    scheduledAccessGrants: [createMockedAccessGrant({ doors: [], id: 'mockedAccessGrantIdInstallers' })],
    defaultAccessGrants: [],
    name: 'installers',
    externalRef: 'mockedExternalRefInstallers',
    type: 'INSTALLERS'
  }),
  createMockedVisitorGroupWithType({
    id: 'mockedIdCleaners',
    scheduledAccessGrants: [createMockedAccessGrant({ doors: [], id: 'mockedAccessGrantIdCleaners' })],
    defaultAccessGrants: [],
    name: 'cleaners',
    externalRef: 'mockedExternalRefCleaners',
    type: 'CO_CONTRACTORS'
  }),
  createMockedVisitorGroupWithType({
    id: 'mockedIdCo-contractors',
    scheduledAccessGrants: [createMockedAccessGrant({ doors: [], id: 'mockedAccessGrantIdCo-contractors' })],
    defaultAccessGrants: [],
    name: 'co-contractors',
    externalRef: 'mockedExternalRefCo-contractors',
    type: 'CO_CONTRACTORS'
  }),
  createMockedVisitorGroupWithType({
    id: 'mockedIdAdmins',
    defaultAccessGrants: [createMockedAccessGrant({ doors: [], id: 'mockedAccessGrantIdAdmins' })],
    name: 'admins',
    type: 'AZURE_AD',
    externalRef: 'mockedExternalRefAdmins'
  }),
  createMockedVisitorGroupWithType({
    id: 'mockedIdEmployees',
    defaultAccessGrants: [createMockedAccessGrant({ doors: [], id: 'mockedAccessGrantIdEmployees' })],
    name: 'employees',
    type: 'AZURE_AD',
    externalRef: 'mockedExternalRefEmployees'
  }),
  createMockedVisitorGroupWithType({
    id: 'mockedIdVisitor_group_1',
    defaultAccessGrants: [createMockedAccessGrant({ doors: [], id: 'mockedAccessGrantIdVisitor_group_1' })],
    name: 'visitor_group_1',
    type: 'AZURE_AD',
    externalRef: 'mockedExternalRefVisitor_group_1'
  }),
  createMockedVisitorGroupWithType({
    id: 'mockedIdVisitor_group_2',
    defaultAccessGrants: [createMockedAccessGrant({ doors: [], id: 'mockedAccessGrantIdVisitor_group_2' })],
    name: 'visitor_group_2',
    type: 'AZURE_AD',
    externalRef: 'mockedExternalRefVisitor_group_2'
  }),
  createMockedVisitorGroupWithType({
    id: 'mockedIdVisitor_group_3',
    defaultAccessGrants: [createMockedAccessGrant({ doors: [], id: 'mockedAccessGrantIdVisitor_group_3' })],
    name: 'visitor_group_3',
    type: 'AZURE_AD',
    externalRef: 'mockedExternalRefVisitor_group_3'
  }),
  createMockedVisitorGroupWithType({
    id: 'mockedIdVisitors',
    defaultAccessGrants: [createMockedAccessGrant({ doors: [], id: 'mockedAccessGrantIdVisitors' })],
    name: 'visitors',
    type: 'EXTERNAL_VISITORS',
    externalRef: 'mockedExternalRefVisitors'
  })
]);
