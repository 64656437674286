import { ManageLockGroupForm, createHeaders } from 'components/Modal/variants/LockGroup/ManageLockGroup';
import useTranslation from 'hooks/useTranslation/useTranslation';
import { kebabCase } from 'lodash';
import React from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { DoorsWithLocationsStateComplete } from 'state/types';
import styled from 'styled-components';
import Button from 'ui/atoms/Button/Button';
import Icon, { Icons } from 'ui/atoms/Icon/Icon';
import TableCell from 'ui/atoms/TableCell/TableCell';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableRow from 'ui/molecules/TableRow/TableRow';
import Table from 'ui/organisms/Table/Table';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { SkeletonArrayType } from 'utils/Array/Array.util';

const StyledTableWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.b6};
  border-radius: 0.25rem;
  width: 46rem;
  height: 22rem;
  background-color: ${({ theme }) => theme.colors.b5};
`;

export interface Props {
  control: Control<ManageLockGroupForm, object>;
  register: UseFormRegister<ManageLockGroupForm>;
  isEditing: boolean;
  inputLockGroupNameValue: string;
  inputLockGroupNameOnChange: (value: string) => void;
  lockGroupNameInputValidation: string | undefined;
  showValidation: boolean;
  disableConfirmButton: boolean;
  inputFilterLockGroupNameValue: string;
  inputFilterLockGroupNameOnChange: (value: string) => void;
  dataLoading: boolean;
  skeletonArray: SkeletonArrayType[];
  data: DoorsWithLocationsStateComplete;
  selectedDoors: string[];
  handleSelectDoor: (doorId: string) => void;
  handleCancelButton: () => void;
  handleConfirmButton: () => void;
  handleSortingOnClick: (columnName: string) => void;
  handleSortingIcon: (columnName: string) => 'ArrowUp' | 'ArrowDown' | undefined;
}

const CreateOrEditLockGroup: React.FC<Props> = ({
  control,
  register,
  isEditing,
  inputLockGroupNameOnChange,
  lockGroupNameInputValidation,
  showValidation,
  disableConfirmButton,
  inputLockGroupNameValue,
  inputFilterLockGroupNameValue,
  inputFilterLockGroupNameOnChange,
  dataLoading,
  skeletonArray,
  data,
  selectedDoors,
  handleSelectDoor,
  handleCancelButton,
  handleConfirmButton,
  handleSortingOnClick,
  handleSortingIcon
}) => {
  const { translation } = useTranslation();
  const headers = createHeaders(translation);
  return (
    <ComponentWrapper flexDirection="column" width="46rem" gap="1rem" justifyContent="center" alignItems="center">
      <Icon name="Folder" color="dTextHigh" width={40} height={40} viewBox="0 0 24 24" id="folder-icon" />
      <Typography variant="title2" color="dTextHigh">
        {`${isEditing ? `${translation.edit}` : `${translation.create}`} ${translation.lock_group}`}
      </Typography>
      <ComponentWrapper flexDirection="column" width="46rem">
        <Controller
          control={control}
          name="filterStr"
          render={() => (
            <Input
              {...register('name')}
              label={translation.lock_group_name}
              failed={showValidation && lockGroupNameInputValidation !== undefined}
              onChange={(event) => inputLockGroupNameOnChange(event.target.value)}
              value={inputLockGroupNameValue}
              failedText={lockGroupNameInputValidation}
              failedId="drawer-input-lock-group-name-validation"
              id="drawer-input-lock-group-name"
              customRequired
            />
          )}
        />
      </ComponentWrapper>
      <ComponentWrapper flexDirection="column" width="46rem">
        <Controller
          control={control}
          name="filterStr"
          render={() => (
            <Input
              {...register('filterStr')}
              placeholder={translation.search_lock}
              onChange={(event) => inputFilterLockGroupNameOnChange(event.target.value)}
              value={inputFilterLockGroupNameValue}
              id="search-lock-input"
              customRequired
            />
          )}
        />
      </ComponentWrapper>
      <StyledTableWrapper>
        <ComponentWrapper height="22rem">
          <Table
            id="locks-section"
            backgroundColor="transparent"
            scrollbarTrackColor="b6"
            scrollbarThumbColor="b5"
            header={
              <TableHeaderRow placeForAvatarOrSwitchOrCheckbox>
                {headers.map((header) => (
                  <TableHeader
                    headerText={header}
                    headerTextColor="dTextHigh"
                    id={kebabCase(`header-${header}`)}
                    flex="0 0 40%"
                    iconSorting={handleSortingIcon(header)}
                    iconSortingColor="dTextHigh"
                    onClick={() => handleSortingOnClick(header)}
                  />
                ))}
              </TableHeaderRow>
            }
          >
            {dataLoading
              ? skeletonArray.map((_, index) => (
                  <TableRow
                    beforeContentIconProps={{
                      name: '' as Icons,
                      height: 28,
                      width: 28,
                      viewBox: '0 0 32 32'
                    }}
                    id={`row-skeleton-${index}`}
                    marginAfterContent
                    key={`skeletonTableRow-${_.id}`}
                    backgroundColor="transparent"
                  >
                    {headers.map(() => (
                      <TableCell isLoading firstLineText="" flex="0 0 40%" />
                    ))}
                  </TableRow>
                ))
              : data.map((door, id) => (
                  <TableRow
                    backgroundColor="transparent"
                    beforeContentCheckboxProps={{
                      checkboxType: 'dark',
                      checked: selectedDoors.some((doorId) => doorId === door.id),
                      id: `lock-${id}-checkbox`,
                      key: `door-checkbox-key-${id}`,
                      onClick: () => handleSelectDoor(door.id)
                    }}
                    key={`tableRow-${door.id}`}
                  >
                    <TableCell
                      firstLineText={door.name}
                      firstLineColor="dTextHigh"
                      firstLineId={`door-${id}-name`}
                      flex="0 0 40%"
                    />
                    <TableCell
                      firstLineText={door.externalLocation?.name ? door.externalLocation?.name : '-'}
                      firstLineColor="dTextHigh"
                      firstLineId={`door-${id}-location`}
                      flex="0 0 40%"
                    />
                  </TableRow>
                ))}
          </Table>
        </ComponentWrapper>
      </StyledTableWrapper>
      <ComponentWrapper justifyContent="start" width="46rem">
        <Typography variant="body2" color="dTextHigh">
          {`${selectedDoors.length} ${translation.selected}`}
        </Typography>
      </ComponentWrapper>
      <ComponentWrapper width="25.875rem" gap="0.75rem">
        <Button onClick={handleCancelButton} backgroundColor="transparent" textColor="b1">
          {translation.cancel}
        </Button>
        <Button disabled={disableConfirmButton} onClick={handleConfirmButton} id="submit-lock-group-button">
          {isEditing ? `${translation.edit}` : `${translation.create}`}
        </Button>
      </ComponentWrapper>
    </ComponentWrapper>
  );
};

export default CreateOrEditLockGroup;
