import { LocationType } from 'graphql/generated/globalTypes';
import useDoorsAndLocations from 'hooks/useDoorsAndLocations/useDoorsAndLocations';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useModal from 'hooks/useModal/useModal';
import useSortingLocks from 'hooks/useSorting/useSortingLocks/useSortingLocks';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GetDoorsForTenantWithLocations_getDoorsForTenant_manualLocations_complete } from 'state/types';
import Modal from 'ui/atoms/Modal/Modal';
import CreateOrEditLockGroup from 'ui/organisms/Modal/variants/CreateOrEditLockGroup/CreateOrEditLockGroup';
import ArrayUtil from 'utils/Array/Array.util';
import useTranslation, { Translation } from 'hooks/useTranslation/useTranslation';
import { getFilterData, handleCancelButton, handleSelectDoor } from './shared';

export const createHeaders = (translation: Translation) => [translation.name, translation.room];

export interface ManageLockGroupForm {
  name: string;
  filterStr: string;
}

const ManageLockGroup: React.FC = () => {
  const { translation } = useTranslation();
  const { hideModal, modalState } = useModal();
  const { isEditing } = modalState.contentValue;
  const { register, setValue, control } = useForm<ManageLockGroupForm>();
  const [lockGroupName, setLockGroupName] = useState<string>(
    isEditing && modalState.contentValue.lockGroupName ? modalState.contentValue.lockGroupName : ''
  );
  const [lockGroupNameInputValidation, setLockGroupNameInputValidation] = useState<string | undefined>(undefined);
  const [showValidation, setShowValidation] = useState(false);
  const [filterStr, setFilterStr] = useState('');
  const [selectedDoors, setSelectedDoors] = useState<string[]>(
    modalState.contentValue.selectedDoors
      ? (
          modalState.contentValue
            .selectedDoors as GetDoorsForTenantWithLocations_getDoorsForTenant_manualLocations_complete['doors']
        ).map((door) => door.id)
      : []
  );
  const skeletonArray = ArrayUtil.SkeletonArray();
  const { showDrawer } = useDrawer();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    doorsWithLocationsLoading,
    locationsWithDoors,
    locationsWithDoorsLoading,
    addLocation,
    addLocationLoading,
    updateLocation,
    updateLocationLoading
  } = useDoorsAndLocations({
    handleFetchError
  });
  const { handleSortingOnClick, handleSortingIcon, getSortedArray } = useSortingLocks(translation);

  const handleConfirmButton = async () => {
    setShowValidation(true);
    if (
      lockGroupName &&
      lockGroupNameInputValidation === undefined &&
      !doorsWithLocationsLoading &&
      !locationsWithDoorsLoading &&
      !updateLocationLoading &&
      !addLocationLoading
    )
      if (isEditing) {
        await updateLocation(modalState.contentValue.locationId, {
          doorIds: selectedDoors,
          name: lockGroupName,
          type: LocationType.MANUAL
        });
        showDrawer({
          type: 'manageLockGroups'
        });
        hideModal();
      } else {
        await addLocation({ doorIds: selectedDoors, name: lockGroupName, type: LocationType.MANUAL });
        showDrawer({
          type: 'manageLockGroups'
        });
        hideModal();
      }
  };

  const handleLockGroupNameOnChange = (value: string) => {
    setLockGroupName(value);
    setLockGroupNameInputValidation(undefined);
    const inputValueLength = 48;
    if (locationsWithDoors.some((location) => location.name === value))
      setLockGroupNameInputValidation(translation.this_group_name_is_already_in_use_please_choose_a_unique_name);
    if (value === '') setLockGroupNameInputValidation(translation.please_enter_a_group_name_this_field_cannot_be_empty);
    else {
      if (/^\s*$/.test(value))
        setLockGroupNameInputValidation(translation.group_name_must_contain_at_least_one_character_please_try_again);
      if (/[!@#$%^&*(),.?":{}|<>]/.test(value))
        setLockGroupNameInputValidation(
          translation.special_characters_like__are_not_allowed_in_group_names_please_try_again
        );
      if (value.length > inputValueLength)
        setLockGroupNameInputValidation(
          translation.name_cannot_be_longer_then_48_characters_please_shorten_it_and_try_again
        );
    }
  };

  useEffect(() => {
    if (showValidation && lockGroupName === '')
      setLockGroupNameInputValidation(translation.please_enter_a_group_name_this_field_cannot_be_empty);
  }, [lockGroupName, showValidation, translation.please_enter_a_group_name_this_field_cannot_be_empty]);

  useEffect(() => setValue('filterStr', filterStr), [filterStr, setValue]);
  useEffect(() => setValue('name', lockGroupName), [lockGroupName, setValue]);

  return (
    <Modal>
      <CreateOrEditLockGroup
        control={control}
        register={register}
        isEditing={isEditing}
        inputLockGroupNameValue={lockGroupName}
        inputLockGroupNameOnChange={handleLockGroupNameOnChange}
        lockGroupNameInputValidation={lockGroupNameInputValidation}
        showValidation={showValidation}
        inputFilterLockGroupNameValue={filterStr}
        inputFilterLockGroupNameOnChange={setFilterStr}
        dataLoading={doorsWithLocationsLoading}
        skeletonArray={skeletonArray}
        data={getFilterData(filterStr, getSortedArray())}
        selectedDoors={selectedDoors}
        handleSelectDoor={(doorId: string) => handleSelectDoor(doorId, selectedDoors, setSelectedDoors)}
        handleCancelButton={() => handleCancelButton(showDrawer, hideModal)}
        handleConfirmButton={handleConfirmButton}
        disableConfirmButton={
          showValidation &&
          lockGroupNameInputValidation !== undefined &&
          !locationsWithDoorsLoading &&
          !doorsWithLocationsLoading &&
          !addLocationLoading &&
          !updateLocationLoading
        }
        handleSortingOnClick={handleSortingOnClick}
        handleSortingIcon={handleSortingIcon}
      />
    </Modal>
  );
};

export default ManageLockGroup;
