import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import FieldTemplate, { Props as FieldTemplateProps } from 'ui/shared/Field/FieldTemplate';
import styled from 'styled-components';
import { DateTimeSelectItem } from 'components/Drawer/variants/accessEvents/AccessEventsFilters/dataTimeSelectOptions';
import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/theme';

const StyledAutocomplete = styled(Autocomplete)`
  padding: 0.375rem 0.625rem;
  background: 0 ${({ theme }) => theme.colors.b5};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.125rem;
  margin-top: 0.375rem;
  color: ${(props) => props.theme.colors.dTextHigh};
  padding: 0;

  &:hover &:focus-visible {
    border: 0 !important;
  }

  .MuiFormControl-root {
    padding: 0;
  }

  .MuiOutlinedInput-root {
    padding: 0 !important;
    border: 0 !important;

    &:focus &:hover &:focus-visible {
      border: 0 !important;
    }
  }

  .MuiInputBase-input {
    border: 1px solid ${({ theme }) => theme.colors.b6} !important;
    border: 0 !important;

    &:focus &:hover &:focus-visible {
      border: 0 !important;
    }
  }

  .MuiAutocomplete-popper {
    z-index: 1501 !important;
  }
`;

const useStyles = makeStyles({
  root: {
    '.MuiAutocomplete-option': {
      display: 'flex !important',
      justifyContent: 'center !important'
    }
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.75rem'
    },

    '*::-webkit-scrollbar-track': {
      backgroundColor: `${colors.b5}`
    },

    '*::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.b3}`
    }
  }
});

export type Value = null | string | DateTimeSelectItem;
export interface OptionProps<T extends Value> {
  value: T;
  label: string;
}

export interface Props {
  fieldTemplateProps?: FieldTemplateProps;
  autocompleteProps: Pick<
    React.ComponentProps<typeof Autocomplete>,
    | 'id'
    | 'options'
    | 'sx'
    | 'onChange'
    | 'value'
    | 'inputValue'
    | 'onInputChange'
    | 'disableClearable'
    | 'isOptionEqualToValue'
    | 'getOptionLabel'
    | 'onKeyDown'
    | 'filterOptions'
    | 'renderOption'
  >;
  textFieldProps?: Pick<React.ComponentProps<typeof TextField>, 'id' | 'placeholder'>;
}

const SelectMUI = React.forwardRef<HTMLInputElement | null, Props>((props, ref) => {
  const { fieldTemplateProps, autocompleteProps, textFieldProps } = props;
  const classes = useStyles();
  return (
    <FieldTemplate {...fieldTemplateProps}>
      <StyledAutocomplete
        sx={{ width: 367 }}
        {...autocompleteProps}
        disablePortal
        ref={ref}
        className={classes.root}
        renderInput={(params) => <TextField {...params} {...textFieldProps} />}
      />
    </FieldTemplate>
  );
});

export default SelectMUI;
