/* eslint-disable import/prefer-default-export */
import {
  GET_DOORS_FOR_TENANT,
  GET_CURRENT_HOST,
  GET_LOCATIONS_FOR_TENANT,
  GET_VISITOR_GROUPS_FOR_TENANT,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_SCHEDULES,
  GET_AVAILABLE_OFFICE_DAYS,
  GET_OFFICE_DAYS,
  GET_DOORS_FOR_TENANT_WITH_OFFICE_MODE,
  GET_VISITOR_GROUPS_WITH_IS_ADMIN_GROUP,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_IS_OFFICE_MODE_ENABLED,
  GET_LOCATIONS_WITH_DOORS_FOR_TENANT,
  GET_DOORS_FOR_TENANT_WITH_LOCATIONS,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
  GET_VISITOR_GROUPS_FOR_TENANT_WITH_ACCESS_GRANTS
} from 'graphql/queries';
import { getDateWithFormat } from 'utils/DateFormat/DateFormat.util';
import dayjs from 'dayjs';
import { mockedGetCurrentHostAdminDataForQuery } from '../graphqlApollo/query/getCurrentHost';
import { mockedGetDoorsForTenantDataForQuery } from '../graphqlApollo/query/getDoorsForTenant';
import { mockedGetLocationsForTenantDataAdmin } from '../graphqlApollo/query/getLocationForTenant';
import { mockedGetVisitorGroupsWithSchedulesForTenantDataAdmin } from '../graphqlApollo/query/getVisitorGroupsForTenantWithSchedules';
import { mockedGetVisitorGroupsForTenantWithMembersAndTypesDataAdmin } from '../graphqlApollo/query/getVisitorGroupsForTenantWithMembersAndTypes';
import { mockedGetVisitorGroupsForTenantWithTypesDataAdmin } from '../graphqlApollo/query/getVisitorGroupsForTenantWithTypes';
import { mockedGetAvailableDaysDataAdmin } from '../graphqlApollo/query/getAvailableOfficeDays';
import { mockedGetDoorsWithOfficeDaysForTenantDataAdmin } from '../graphqlApollo/query/getDoorsForTenantWithOfficeMode';
import { mockedGetVisitorGroupsForTenantWithIsAdminGroupDataAdmin } from '../graphqlApollo/query/getVisitorGroupsForTenantWithIsAdminGroupDataForQuery';
import { mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledDataAdmin } from '../graphqlApollo/query/getVisitorGroupsForTenantWithIsOfficeModeEnabled';
import { mockedGetLocationWithDoorsForTenantDataAdmin } from '../graphqlApollo/query/getLocationWithDoorsForTenant';
import { mockedGetDoorsForTenantWithLocationsDataAdmin } from '../graphqlApollo/query/getDoorsForTenantWithLocations';
import { mockedGetVisitorGroupsForTenantWithMembersAndRoleDataAdmin } from '../graphqlApollo/query/getVisitorGroupsForTenantWithMembersAndRole';
import { mockedGetVisitorGroupsForTenantWithAccessGrantsDataAdmin } from '../graphqlApollo/query/getVisitorGroupsForTenantWithAccessGrants';

export const queries = [
  {
    request: {
      query: GET_OFFICE_DAYS,
      variables: {
        filter: {
          dateRange: {
            rangeStart: getDateWithFormat(
              'AWSDateTime',
              dayjs().year(),
              dayjs().month(),
              dayjs().startOf('month').date()
            ),
            rangeEnd: getDateWithFormat(
              'AWSDateTime',
              dayjs().year(),
              dayjs().add(1, 'month').month(),
              dayjs().endOf('month').date()
            )
          }
        }
      }
    },
    newData: () => ({
      data: []
    })
  },
  {
    request: {
      query: GET_AVAILABLE_OFFICE_DAYS,
      variables: {
        from: dayjs().startOf('month').format('YYYY-MM-DD'),
        to: dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD')
      }
    },
    newData: () => ({
      data: mockedGetAvailableDaysDataAdmin
    })
  },
  {
    request: {
      query: GET_CURRENT_HOST
    },
    newData: () => ({
      data: mockedGetCurrentHostAdminDataForQuery
    })
  },
  {
    request: {
      query: GET_DOORS_FOR_TENANT
    },
    newData: () => ({
      data: mockedGetDoorsForTenantDataForQuery
    })
  },
  {
    request: {
      query: GET_DOORS_FOR_TENANT_WITH_OFFICE_MODE
    },
    newData: () => ({
      data: mockedGetDoorsWithOfficeDaysForTenantDataAdmin
    })
  },
  {
    request: {
      query: GET_LOCATIONS_FOR_TENANT
    },
    newData: () => ({
      data: mockedGetLocationsForTenantDataAdmin
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS_AND_ROLE,
      variables: {
        filter: {
          type: 'CO_CONTRACTORS'
        }
      }
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithMembersAndRoleDataAdmin
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_MEMBERS,
      variables: {
        filter: {
          type: 'INSTALLERS'
        }
      }
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithMembersAndTypesDataAdmin
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_WITH_IS_ADMIN_GROUP,
      variables: {
        filter: {
          type: 'AZURE_AD'
        }
      }
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithIsAdminGroupDataAdmin
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_SCHEDULES
    },
    newData: () => ({
      data: mockedGetVisitorGroupsWithSchedulesForTenantDataAdmin
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithTypesDataAdmin
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_IS_OFFICE_MODE_ENABLED
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithIsOfficeModeEnabledDataAdmin
    })
  },
  {
    request: {
      query: GET_LOCATIONS_WITH_DOORS_FOR_TENANT
    },
    newData: () => ({
      data: mockedGetLocationWithDoorsForTenantDataAdmin
    })
  },
  {
    request: {
      query: GET_DOORS_FOR_TENANT_WITH_LOCATIONS
    },
    newData: () => ({
      data: mockedGetDoorsForTenantWithLocationsDataAdmin
    })
  },
  {
    request: {
      query: GET_VISITOR_GROUPS_FOR_TENANT_WITH_ACCESS_GRANTS
    },
    newData: () => ({
      data: mockedGetVisitorGroupsForTenantWithAccessGrantsDataAdmin
    })
  }
];
