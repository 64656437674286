import { GET_ACCESS_EVENTS_FOR_TENANT } from 'graphql/queries';
import { useEffect, useState } from 'react';
import {
  GetAccessEventsForTenant_getAccessEventsForTenant_items as AccessEvents,
  GetAccessEventsForTenant,
  GetAccessEventsForTenantVariables
} from 'graphql/generated/GetAccessEventsForTenant';
import { useLazyQuery } from '@apollo/client';
import { BaseHookProps } from 'hooks/shared/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AccessEventsDateRange, MobileOpCode } from 'graphql/generated/globalTypes';
import { AccessEventsFilterWithDateTimeSelectedOption } from 'hooks/useAccessEventsFilters/useAccessEventsFilters';

dayjs.extend(utc);

interface Props extends BaseHookProps {
  fetchNextData: boolean;
  filter: null | AccessEventsFilterWithDateTimeSelectedOption;
}

const getDateRange = (dateRange: AccessEventsDateRange) => ({
  rangeStart: dayjs(dateRange.rangeStart).second(0).millisecond(0).utc().format(),
  rangeEnd: dayjs(dateRange.rangeEnd).second(0).millisecond(0).subtract(1, 'millisecond').utc().format()
});

const useAccessEvents = ({ handleFetchError, fetchNextData, filter }: Props) => {
  const [accessEvent, setAccessEvent] = useState<AccessEvents[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [getAccessEvents, { loading }] = useLazyQuery<GetAccessEventsForTenant, GetAccessEventsForTenantVariables>(
    GET_ACCESS_EVENTS_FOR_TENANT,
    {
      onCompleted: (data) => {
        if (data.getAccessEventsForTenant.nextToken !== nextToken)
          setAccessEvent([...accessEvent, ...data.getAccessEventsForTenant.items]);
        else setAccessEvent(data.getAccessEventsForTenant.items);
        setNextToken(data.getAccessEventsForTenant.nextToken);
      },
      onError: () => {
        handleFetchError(`Error while fetching Access Events`);
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    setAccessEvent([]);
  }, [filter]);

  useEffect(() => {
    if (filter) {
      const { dataTimeSelectedOption, ...accessEventFiltersWithoutIsFilterApplied } = filter;
      getAccessEvents({
        variables: {
          filter: {
            ...accessEventFiltersWithoutIsFilterApplied,
            actions: accessEventFiltersWithoutIsFilterApplied.actions
              ? [...accessEventFiltersWithoutIsFilterApplied.actions]
              : [MobileOpCode.ACCESS_DENIED, MobileOpCode.ACCESS_GRANTED],
            dateRange: filter.dateRange ? getDateRange(filter.dateRange) : undefined
          }
        }
      });
    } else {
      getAccessEvents({
        variables: { filter: { actions: [MobileOpCode.ACCESS_DENIED, MobileOpCode.ACCESS_GRANTED] } }
      });
    }
  }, [filter, getAccessEvents]);

  useEffect(() => {
    if (fetchNextData) {
      if (filter) {
        const { dataTimeSelectedOption, ...accessEventFiltersWithoutIsFilterApplied } = filter;
        getAccessEvents({
          variables: {
            nextToken,
            filter: {
              ...accessEventFiltersWithoutIsFilterApplied,
              actions: accessEventFiltersWithoutIsFilterApplied.actions
                ? [...accessEventFiltersWithoutIsFilterApplied.actions]
                : [MobileOpCode.ACCESS_DENIED, MobileOpCode.ACCESS_GRANTED],
              dateRange: filter.dateRange ? getDateRange(filter.dateRange) : undefined
            }
          }
        });
      } else {
        getAccessEvents({
          variables: {
            nextToken,
            filter: {
              actions: [MobileOpCode.ACCESS_DENIED, MobileOpCode.ACCESS_GRANTED]
            }
          }
        });
      }
    }
  }, [fetchNextData, filter, getAccessEvents, nextToken]);

  return {
    accessEvents: accessEvent,
    isNextDataToDownload: nextToken !== null && !loading,
    loading
  };
};

export default useAccessEvents;
