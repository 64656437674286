import React from 'react';
import styled, { css } from 'styled-components';
import { SnackbarContent, VariantType } from 'notistack';
import { BORDER_RADIUS_MULTIPLIER } from 'ui/shared/const';
import Icon from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';

export type SnackbarType = Extract<VariantType, 'error' | 'success'>;

export interface Props {
  id: string;
  snackbartype?: SnackbarType;
  message: string;
  isMobile: boolean;
  onClose?: () => void;
}

function handleSnackbarVariant(variant: SnackbarType) {
  switch (variant) {
    case 'error':
      return css`
        background-color: ${({ theme }) => theme.colors.error};
      `;
    case 'success':
    default:
      return css`
        background-color: ${({ theme }) => theme.colors.success};
      `;
  }
}

const StyledSnackbar = styled.div<Props>`
  color: ${({ theme }) => theme.colors.lTextHigh};
  min-width: 38rem;
  height: 4.875rem;
  margin-bottom: 0.5rem;
  border-radius: ${({ theme }) => BORDER_RADIUS_MULTIPLIER * theme.borderRadius}px;
  display: flex;
  align-items: center;

  text-align: center;
  ${(props: Props) =>
    props.snackbartype ? handleSnackbarVariant(props.snackbartype) : handleSnackbarVariant('success')}

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
      margin: 0 auto 0.5rem auto;
      justify-content: space-between;
      min-width: 85vw;
    `
      : ''};
`;

const IconWrapper = styled.div`
  padding: 1.9375rem 1.3125rem 1.9375rem 2rem;
`;

const TypographyWrapper = styled.div`
  width: 30.125rem;
  text-align: start;
`;

function handleSnackbarCloseButtonBackgroundColor(variant: SnackbarType) {
  switch (variant) {
    case 'error':
      return css`
        background-color: ${({ theme }) => theme.colors.error1};
      `;
    case 'success':
    default:
      return css`
        background-color: ${({ theme }) => theme.colors.success1};
      `;
  }
}

const StyledCloseButton = styled.div<Props>`
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props: Props) =>
    props.snackbartype
      ? handleSnackbarCloseButtonBackgroundColor(props.snackbartype)
      : handleSnackbarCloseButtonBackgroundColor('success')}

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
        margin-right: 1rem;
      `
      : ''};
`;

const Snackbar = React.forwardRef<HTMLDivElement | null, Props>((props, ref) => (
  <SnackbarContent ref={ref} id={`snackbar-${props.snackbartype}`}>
    <StyledSnackbar {...props} snackbartype={props.snackbartype || 'success'}>
      <IconWrapper>
        {props.snackbartype === 'success' && <Icon width={16} height={16} name="Smile" />}
        {props.snackbartype === 'error' && <Icon width={16} height={16} name="Sad" />}
      </IconWrapper>
      <TypographyWrapper>
        <Typography variant={props.isMobile ? 'body3' : 'body2'}>{props.message}</Typography>
      </TypographyWrapper>
      <StyledCloseButton {...props} onClick={props.onClose} snackbartype={props.snackbartype || 'success'}>
        <Icon width={14} height={14} name="Close" />
      </StyledCloseButton>
    </StyledSnackbar>
  </SnackbarContent>
));

export default Snackbar;
