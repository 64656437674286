/* eslint-disable import/prefer-default-export */
import {
  GetAccessEventsForTenant_getAccessEventsForTenant,
  GetAccessEventsForTenant_getAccessEventsForTenant_items
} from 'graphql/generated/GetAccessEventsForTenant';

interface Props {
  items: Array<GetAccessEventsForTenant_getAccessEventsForTenant_items>;
  nextToken?: string | null;
}

export function createMockedAccessEventsPage({
  items,
  nextToken = null
}: Props): GetAccessEventsForTenant_getAccessEventsForTenant {
  return {
    __typename: 'AccessEventsPage',
    items,
    nextToken
  };
}
