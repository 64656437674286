import React from 'react';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import useTranslation from 'hooks/useTranslation/useTranslation';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';

export interface Props {
  id?: string;
}

const StatusConnected: React.FC<Props> = ({ id }) => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper id={id} justifyContent="flex-start" alignItems="center">
      <Icon color="success" name="StatusConnected" viewBox="0 0 14 14" />
      <Typography color="success" variant="label">
        {translation.connected}
      </Typography>
    </ComponentWrapper>
  );
};

export default StatusConnected;
