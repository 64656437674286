import React from 'react';

const Sad: React.FC = () => (
  <path
    d="M8 0C5.86312 0 3.85416 0.832156 2.34313 2.34313C0.832156 3.85416 0 5.86312 0 8C0 10.1369 0.832156 12.1458 2.34313 13.6569C3.85416 15.1678 5.86312 16 8 16C10.1369 16 12.1458 15.1678 13.6569 13.6569C15.1678 12.1458 16 10.1369 16 8C16 5.86312 15.1678 3.85416 13.6569 2.34313C12.1458 0.832156 10.1369 0 8 0ZM8 14.75C4.27803 14.75 1.25 11.722 1.25 8C1.25 4.27803 4.27803 1.25 8 1.25C11.722 1.25 14.75 4.27803 14.75 8C14.75 11.722 11.722 14.75 8 14.75ZM10.9293 6.61913C10.6381 6.80447 10.2517 6.71863 10.0665 6.42737C10.0588 6.41528 9.28094 5.21684 7.96875 5.21684C6.65656 5.21684 5.87875 6.41528 5.87103 6.42737C5.68572 6.71859 5.29944 6.80444 5.00822 6.61913C4.717 6.43381 4.63116 6.0475 4.81647 5.75631C4.86297 5.68325 5.97809 3.96684 7.96875 3.96684C9.95941 3.96684 11.0745 5.68322 11.121 5.75631C11.3063 6.04753 11.2205 6.43381 10.9293 6.61913ZM5.25 10.8438C5.68147 10.8438 6.03125 10.494 6.03125 10.0625C6.03125 9.63103 5.68147 9.28125 5.25 9.28125C4.81853 9.28125 4.46875 9.63103 4.46875 10.0625C4.46875 10.494 4.81853 10.8438 5.25 10.8438ZM9.9375 10.0625C9.9375 9.63103 10.2873 9.28125 10.7188 9.28125C11.1502 9.28125 11.5 9.63103 11.5 10.0625C11.5 10.494 11.1502 10.8438 10.7188 10.8438C10.2873 10.8438 9.9375 10.494 9.9375 10.0625Z"
    fill="white"
  />
);

export default Sad;
