import React from 'react';
import { SnackbarProvider as SnackbarProviderNotistack } from 'notistack';
import Snackbar from 'ui/molecules/Snackbar/Snackbar';
import { isMobile } from 'react-device-detect';

const SnackbarProvider: React.FC = ({ children }, props) => (
  <SnackbarProviderNotistack
    {...props}
    maxSnack={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    content={(key, message) => <Snackbar id={`${key}`} message={message as string} isMobile={isMobile} />}
  >
    {children}
  </SnackbarProviderNotistack>
);
export default SnackbarProvider;
