import React from 'react';

const Tools: React.FC = () => (
  <path
    transform="translate(0, 0)"
    d="M2.33004 0.270994C2.95409 0.0483674 3.62851 0.00744884 4.2749 0.152995C4.92129 0.298541 5.51308 0.624573 5.98148 1.09319C6.44989 1.5618 6.77565 2.15374 6.92091 2.80019C7.06617 3.44664 7.02495 4.12105 6.80204 4.74499L17.647 15.59L15.525 17.711L4.68004 6.86699C4.05599 7.08962 3.38157 7.13054 2.73519 6.98499C2.0888 6.83945 1.497 6.51341 1.0286 6.0448C0.560199 5.57619 0.234432 4.98425 0.089175 4.3378C-0.0560821 3.69135 -0.014862 3.01694 0.208043 2.39299L2.44404 4.62999C2.58241 4.77326 2.74793 4.88753 2.93094 4.96615C3.11394 5.04476 3.31077 5.08614 3.50994 5.08787C3.70911 5.0896 3.90663 5.05165 4.09097 4.97623C4.27532 4.9008 4.4428 4.78943 4.58364 4.64859C4.72447 4.50775 4.83585 4.34027 4.91128 4.15592C4.9867 3.97158 5.02465 3.77406 5.02292 3.57489C5.02119 3.37572 4.97981 3.17889 4.9012 2.99589C4.82258 2.81288 4.70831 2.64736 4.56504 2.50899L2.32904 0.269994L2.33004 0.270994ZM12.697 2.15499L15.879 0.386994L17.293 1.80099L15.525 4.98299L13.757 5.33699L11.637 7.45799L10.222 6.04399L12.343 3.92299L12.697 2.15499ZM5.62604 9.93299L7.74704 12.055L2.79704 17.005C2.52408 17.2754 2.15776 17.4308 1.77362 17.439C1.38949 17.4473 1.01683 17.3078 0.732497 17.0494C0.448164 16.791 0.27384 16.4333 0.245474 16.0501C0.217108 15.6669 0.336863 15.2875 0.580043 14.99L0.677043 14.883L5.62704 9.93299H5.62604Z"
  />
);

export default Tools;
