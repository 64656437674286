import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { ASSIGN_TO_ACCESS_GRANTS, UNASSIGN_TO_ACCESS_GRANTS } from 'graphql/mutations';
import { AssignToAccessGrants, AssignToAccessGrantsVariables } from 'graphql/generated/AssignToAccessGrants';
import { UnassignToAccessGrants, UnassignToAccessGrantsVariables } from 'graphql/generated/UnassignToAccessGrants';
import { visitorsGroupsWithAccessGrantsVar } from 'state/vars';
import { GET_VISITOR_GROUPS_FOR_TENANT_WITH_ACCESS_GRANTS } from 'graphql/queries';
import {
  GetVisitorGroupsForTenantWithAccessGrants,
  GetVisitorGroupsForTenantWithAccessGrantsVariables
} from 'graphql/generated/GetVisitorGroupsForTenantWithAccessGrants';
import { useEffect } from 'react';
import { updateToAccessGrantsAction, setVisitorGroupsWithAccessGrantsAction } from 'state/actions/accessGrants';
import { BaseHookProps } from '../shared/types';

const useAccessGrants = ({ handleFetchError }: BaseHookProps) => {
  const visitorsGroupsWithAccessGrants = useReactiveVar(visitorsGroupsWithAccessGrantsVar);

  const [getVisitorsGroupsMembers, { loading }] = useLazyQuery<
    GetVisitorGroupsForTenantWithAccessGrants,
    GetVisitorGroupsForTenantWithAccessGrantsVariables
  >(GET_VISITOR_GROUPS_FOR_TENANT_WITH_ACCESS_GRANTS, {
    onCompleted: (data) => {
      setVisitorGroupsWithAccessGrantsAction(
        data.getVisitorGroupsForTenant.filter((group) => group.type !== 'ATLAS_INTERNAL')
      );
    },
    onError: () => {
      handleFetchError('Error while fetching visitor groups');
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    getVisitorsGroupsMembers();
  }, [getVisitorsGroupsMembers]);

  const [assignToAccessGrantsMutation, { loading: assignToAccessGrantsMutationLoading }] = useMutation<
    AssignToAccessGrants,
    AssignToAccessGrantsVariables
  >(ASSIGN_TO_ACCESS_GRANTS, {
    onCompleted: (data) => {
      data.assignToAccessGrants.forEach((grant) => updateToAccessGrantsAction(grant));
    },
    onError: () => {
      handleFetchError('Error while adding access');
    }
  });

  const [unassignFromAccessGrantsMutation, { loading: unassignFromAccessGrantsMutationLoading }] = useMutation<
    UnassignToAccessGrants,
    UnassignToAccessGrantsVariables
  >(UNASSIGN_TO_ACCESS_GRANTS, {
    onCompleted: (data) => {
      data.unassignFromAccessGrants.forEach((grant) => updateToAccessGrantsAction(grant));
    },
    onError: () => {
      handleFetchError('Error while deleting access');
    }
  });

  const assignToAccessGrants = async (accessGrantIds: string[], doorIds: string[], locationIds: string[]) => {
    const { data } = await assignToAccessGrantsMutation({
      variables: {
        accessGrantIds,
        doorIds,
        locationIds
      }
    });
    return data;
  };

  const unassignFromAccessGrants = async (accessGrantIds: string[], doorIds: string[], locationIds: string[]) => {
    const { data } = await unassignFromAccessGrantsMutation({
      variables: {
        accessGrantIds,
        doorIds,
        locationIds
      }
    });
    return data;
  };

  return {
    visitorsGroupsWithAccessGrants,
    loading,
    assignToAccessGrants,
    assignToAccessGrantsMutationLoading,
    unassignFromAccessGrants,
    unassignFromAccessGrantsMutationLoading
  };
};

export default useAccessGrants;
