import { useCallback, useEffect } from 'react';
import { setIsMobileLandscapeAction } from 'state/actions/ui';

export const IS_MOBILE_WIDTH = 1000;
export const IS_MOBILE_HEIGHT = 750;

const isMobileLandscape = (screenWidth: number, screenHeight: number) =>
  screenWidth > screenHeight && screenHeight < IS_MOBILE_HEIGHT;

const isMobileAction = (actionIfTrue: () => void, actionIfFalse?: () => void) => {
  if (window.innerWidth < IS_MOBILE_WIDTH || isMobileLandscape(window.innerWidth, window.innerHeight)) {
    actionIfTrue();
  } else if (actionIfFalse) actionIfFalse();
};

const useMobileDetector = () => {
  const detectAndSet = useCallback(() => {
    isMobileAction(() => {
      // eslint-disable-next-line no-magic-numbers
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`); // set --vh variable for correct handling vh in mobile browsers
      setIsMobileLandscapeAction(isMobileLandscape(window.innerWidth, window.innerHeight));
    });
  }, []);
  useEffect(() => {
    detectAndSet();
    window.addEventListener('resize', detectAndSet);
  }, [detectAndSet]);

  return () => {
    window.removeEventListener('resize', detectAndSet);
  };
};

export default useMobileDetector;
