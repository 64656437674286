import React from 'react';
import styled from 'styled-components';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Icon from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';

export interface Props {
  text: string;
  isSelected: boolean;
  onClick: () => void;
  id?: string;
}

const EmptySpace = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

const SortingMenuItem: React.FC<Props> = ({ text, isSelected, onClick, id }) => (
  <ComponentWrapper
    columnGap="1rem"
    id={id}
    height="2.5rem"
    alignItems="center"
    justifyContent="left"
    width="13rem"
    onClick={onClick}
    cursor="pointer"
  >
    {isSelected ? (
      <ComponentWrapper>
        <Icon color="primary" name="Checkmark" />
      </ComponentWrapper>
    ) : (
      <EmptySpace />
    )}
    <Typography color={isSelected ? 'primary' : 'dTextHigh'}>{text}</Typography>
  </ComponentWrapper>
);

export default SortingMenuItem;
