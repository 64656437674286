import { EditExternalUserGroupForm } from 'components/Drawer/types';
import useExternalUsers from 'hooks/useExternalUsers/useExternalUsers';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useModal from 'hooks/useModal/useModal';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EditExternalUserGroupDrawer } from 'state/types';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import EditExternalUserGroup from 'ui/organisms/Drawer/variants/EditExternalUserGroup';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import useTranslation from 'hooks/useTranslation/useTranslation';

const EditExternalUserGroupVariant: React.FC = () => {
  const { translation } = useTranslation();
  const { drawerState } = useDrawer<EditExternalUserGroupDrawer>();
  const [nameInputValue, setNameInputValue] = useState(drawerState.contentValue.name);
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [isMaximumUserLimitEnable, setIsMaximumUserLimitEnable] = useState<boolean>(
    !!drawerState.contentValue.maxNumberOfMembers
  );
  const [maximumUserLimitValue, setMaximumUserLimitValue] = useState<number | null>(
    drawerState.contentValue.maxNumberOfMembers
  );
  const [maximumUserLimitInputValidation, setMaximumUserLimitInputValidation] = useState<string | undefined>(undefined);
  const [showValidation, setShowValidation] = useState(false);
  const { register, handleSubmit, setValue } = useForm<EditExternalUserGroupForm>();
  const { hideDrawer } = useDrawer();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    externalUsersGroups,
    updateVisitorGroupWithMaxNumberOfMembers,
    updateVisitorGroupWithMaxNumberOfMembersMutationLoading
  } = useExternalUsers({
    handleFetchError
  });
  const { showModal } = useModal();

  const handleNameInputOnChange = (value: string) => {
    setNameInputValue(value);
    if (externalUsersGroups.some((group) => group.name === value) && value !== drawerState.contentValue.name)
      setIsNameUnique(false);
    else setIsNameUnique(true);
  };

  const handleIsMaximumUserLimitEnableOnChange = () => {
    setIsMaximumUserLimitEnable(!isMaximumUserLimitEnable);
  };

  const handleMaximumUserLimitValueOnChange = (value: number) => {
    setMaximumUserLimitValue(value);
  };

  const handleDeleteExternalUserGroup = () => {
    if (drawerState.contentValue.visitorGroupId)
      showModal({
        type: 'deleteExternalUserGroupNew',
        contentValue: { visitorGroupId: drawerState.contentValue.visitorGroupId }
      });
  };

  const onSubmit = async () => {
    setShowValidation(true);
    // TODO add validations
    if (!updateVisitorGroupWithMaxNumberOfMembersMutationLoading && maximumUserLimitInputValidation === undefined) {
      await updateVisitorGroupWithMaxNumberOfMembers(
        nameInputValue,
        drawerState.contentValue.visitorGroupId,
        isMaximumUserLimitEnable ? maximumUserLimitValue! : null
      );
      hideDrawer();
    }
  };

  useEffect(() => {
    const numberOfMembersInGroup = externalUsersGroups.find(
      (group) => group.id === drawerState.contentValue.visitorGroupId
    )?.members.length;
    if (
      isMaximumUserLimitEnable &&
      maximumUserLimitValue &&
      numberOfMembersInGroup &&
      maximumUserLimitValue < numberOfMembersInGroup
    )
      setMaximumUserLimitInputValidation(translation.value_cannot_be_lower_than_current_amount_of_users_in_the_group);
    else setMaximumUserLimitInputValidation(undefined);
  }, [
    externalUsersGroups,
    drawerState.contentValue.visitorGroupId,
    isMaximumUserLimitEnable,
    maximumUserLimitValue,
    translation.value_cannot_be_lower_than_current_amount_of_users_in_the_group
  ]);

  useEffect(() => {
    setValue('name', nameInputValue);
    setValue('maxNumberOfMembers', maximumUserLimitValue);
  }, [maximumUserLimitValue, nameInputValue, setValue]);

  return (
    <DrawerTemplate
      title={translation.edit_group}
      confirmButtonText={translation.save}
      closeButtonText={translation.cancel}
      id="drawer-edit-external-group"
      headerSideText={
        <ComponentWrapper id="drawer-delete-external-group">
          <Typography variant="clickable" color="dTextHigh">
            {translation.delete}
          </Typography>
          <Icon name="DeleteBin" color="dTextHigh" height="16" width="16" data-testid="svg-delete" />
        </ComponentWrapper>
      }
      headerSideTextOnClick={handleDeleteExternalUserGroup}
      onSubmit={handleSubmit(onSubmit)}
      disableConfirmButton={!isNameUnique}
    >
      <EditExternalUserGroup
        register={register}
        nameInputValue={nameInputValue}
        handleNameInputOnChange={handleNameInputOnChange}
        isNameUnique={isNameUnique}
        switchIsMaximumUserLimitEnable={isMaximumUserLimitEnable}
        handleSwitchIsMaximumUserLimitEnableOnChange={handleIsMaximumUserLimitEnableOnChange}
        inputsMaximumUserLimitValue={maximumUserLimitValue}
        handlesMaximumUserLimitInputOnChange={handleMaximumUserLimitValueOnChange}
        showValidation={showValidation}
        inputsMaximumUserLimitValueValidation={maximumUserLimitInputValidation}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
      />
    </DrawerTemplate>
  );
};
export default EditExternalUserGroupVariant;
