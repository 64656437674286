/* eslint-disable import/prefer-default-export */

import {
  AssignToAccessGrants,
  AssignToAccessGrants_assignToAccessGrants
} from 'graphql/generated/AssignToAccessGrants';
import { createMockedAccessGrantWithLocations } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/accessGrantWithLocations';
import { createMockedDoorWithLocations } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/doorWithLocations';
import { createLocationWithDoors } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/locationWithDoors';

export function mockedAssignToAccessGrantsData(
  assignToAccessGrants: AssignToAccessGrants_assignToAccessGrants[]
): AssignToAccessGrants {
  return {
    assignToAccessGrants
  };
}

export const mockedAssignToAccessGrantsDataForMutation = mockedAssignToAccessGrantsData([
  createMockedAccessGrantWithLocations({
    id: 'mockedAccessGrantId0',
    doors: [],
    locations: [createLocationWithDoors({ id: 'mockedLocationId' })]
  })
]);

export const mockedAssignToAccessGrantsDataForMutation1 = mockedAssignToAccessGrantsData([
  createMockedAccessGrantWithLocations({
    id: 'mockedAccessGrantId0',
    doors: [createMockedDoorWithLocations({ id: 'mockedDoorId0' })]
  })
]);

export const mockedAssignToAccessGrantsDataForMutation2 = mockedAssignToAccessGrantsData([
  createMockedAccessGrantWithLocations({
    id: 'mockedAccessGrantId0',
    doors: [createMockedDoorWithLocations({ id: 'mockedDoorId0' })]
  }),
  createMockedAccessGrantWithLocations({
    id: 'mockedAccessGrantId1',
    doors: [createMockedDoorWithLocations({ id: 'mockedDoorId0' })]
  }),
  createMockedAccessGrantWithLocations({
    id: 'mockedAccessGrantId2',
    doors: [createMockedDoorWithLocations({ id: 'mockedDoorId0' })]
  })
]);
