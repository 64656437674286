import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const Overlay = styled.div`
  z-index: 2;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.bgDark};
`;

const Logout = styled.div`
  margin-bottom: 5rem;
`;

export interface Props {
  singOut: () => void;
}

const MobileNav: React.FC<Props> = ({ singOut }) => {
  const { translation } = useTranslation();
  return (
    <Overlay>
      <ComponentWrapper justifyContent="center" alignItems="flex-end" height="100%">
        <Logout onClick={singOut} id="logout-button">
          <Typography variant="clickable">{translation.logout}</Typography>
        </Logout>
      </ComponentWrapper>
    </Overlay>
  );
};
export default MobileNav;
