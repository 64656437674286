import { isEqual, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { installersGroupsVar } from 'state/vars';
import { GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members } from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { setInstallersGroupsAction } from 'state/actions/installersGroups';
import { Translation } from 'hooks/useTranslation/useTranslation';

interface Sorting {
  isAscending?: boolean;
  columnName?: string;
}

export const handleSorting = (
  translation: Translation,
  item: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members,
  columnName?: string
) => {
  switch (columnName) {
    case translation.atlas_config_app:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return !item.mobileDevices.find(({ role, isRegistered }) => role === 'CONFIG' && isRegistered)?.isRegistered;
    case translation.atlas_access_app:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return !item.mobileDevices.find(({ role, isRegistered }) => role === 'ACCESS' && isRegistered)?.isRegistered;
    case translation.installer:
    default:
      return item.name;
  }
};

export const getSortedArray = (
  translation: Translation,
  installersMembers: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members[],
  sorting: Sorting
) =>
  orderBy(installersMembers, (item) => handleSorting(translation, item, sorting.columnName as string), [
    sorting.isAscending ? 'asc' : 'desc'
  ]);

const useSortingInstallersTable = (translation: Translation) => {
  const installers = useReactiveVar(installersGroupsVar);
  const [sorting, setSorting] = useState<Sorting>({ isAscending: true, columnName: translation.installer });

  const handleSortingOnClick = (columnName: string) => {
    if (sorting.isAscending !== undefined && sorting.columnName === columnName) {
      return setSorting({ isAscending: !sorting.isAscending, columnName });
    }
    return setSorting({ isAscending: true, columnName });
  };

  const handleSortingIcon = (columnName: string) => {
    if (sorting.isAscending !== undefined)
      if (sorting.columnName === columnName) return sorting.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  useEffect(() => {
    const sortedInstallersGroups = installers.map((group) => ({
      ...group,
      members: getSortedArray(translation, group.members, sorting)
    }));
    if (!isEqual(sortedInstallersGroups, installers)) setInstallersGroupsAction(sortedInstallersGroups);
  }, [installers, sorting, translation]);

  return { handleSortingOnClick, handleSortingIcon };
};

export default useSortingInstallersTable;
