/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ListboxMultiselect = styled.ul`
  margin: 2px 0 0;
  padding: 0;
  list-style: none;
  background-color: ${({ theme }) => theme.colors.b5};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 4x 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.colors.b6};

  & li {
    margin: 0 1rem;
    padding: 8px 0;
    display: flex;
    gap: 1rem;
    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    color: ${({ theme }) => theme.colors.b5};
  }
`;
