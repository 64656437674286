/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithMembers,
  GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { createMockedMobileDevice } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/mobileDevice';
import { createMockedVisitor } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitor';
import { createMockedVisitorGroupWithMembersAndType } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithMembersAndTypeCoContractors';
import { createMockedVisitorWithVisitorGroupMembership } from '../objectsBaseOnSchemaType/visitorWithVisitorGroupMembership';

export function getVisitorGroupsForTenantWithMembersAndTypesDataForQuery(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithMembers {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQuery =
  getVisitorGroupsForTenantWithMembersAndTypesDataForQuery([
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupIdExternalUsers1',
      members: [
        createMockedVisitor({
          id: 'mockedVisitorIdExternalUser1',
          name: 'mockedVisitorNameExternalUser1',
          email: 'mockedVisitorEmailExternalUser1'
        }),
        createMockedVisitor({
          id: 'mockedVisitorIdExternalUser2',
          name: 'mockedVisitorNameExternalUser2',
          email: 'mockedVisitorEmailExternalUser2',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })]
        })
      ],
      name: 'co-contractors1',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs'
    }),
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupIdExternalUsers2',
      members: [
        createMockedVisitor({
          id: 'mockedVisitorIdExternalUser3',
          name: 'mockedVisitorNameExternalUser3',
          email: 'mockedVisitorEmailExternalUser3'
        }),
        createMockedVisitor({
          id: 'mockedVisitorIdExternalUser4',
          name: 'mockedVisitorNameExternalUser4',
          email: 'mockedVisitorEmailExternalUser4',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })]
        }),
        createMockedVisitor({
          id: 'mockedVisitorIdExternalUser5',
          name: 'mockedVisitorNameExternalUser5',
          email: 'mockedVisitorEmailExternalUser5',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: false })]
        })
      ],
      name: 'co-contractors2',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs1'
    }),
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupIdExternalUsers3',
      members: [
        createMockedVisitor({
          id: 'mockedVisitorIdExternalUser6',
          name: 'mockedVisitorNameExternalUser6',
          email: 'mockedVisitorEmailExternalUser6'
        })
      ],
      name: 'co-contractors3',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs2'
    }),
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupIdExternalUsers4',
      name: 'co-contractors4',
      type: 'CO_CONTRACTORS',
      externalRef: 'mockedVisitorGroupExternalRefs3'
    })
  ]);

export const mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQueryWithoutFilters =
  getVisitorGroupsForTenantWithMembersAndTypesDataForQuery([
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupId1WithoutFilters',
      members: [
        createMockedVisitor({
          id: 'mockedVisitorId1WithoutFilters',
          name: 'mockedVisitorName1WithoutFilters',
          email: 'mockedVisitorEmail1WithoutFilters'
        }),
        createMockedVisitor({
          id: 'mockedVisitorId2WithoutFilters',
          name: 'mockedVisitorName2WithoutFilters',
          email: 'mockedVisitorEmail2WithoutFilters',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: true })]
        })
      ],
      name: 'mockedVisitorGroupWithoutFilters',
      type: 'AZURE_AD',
      externalRef: 'mockedVisitorGroupExternalRefsWithoutFilters'
    })
  ]);

export const mockedGetVisitorGroupsForTenantWithMembersAndTypesDataForQuery1 =
  getVisitorGroupsForTenantWithMembersAndTypesDataForQuery([
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedVisitorGroupIdInstallers1',
      members: [
        createMockedVisitor({
          id: 'mockedVisitorIdInstaller1',
          name: 'mockedVisitorNameInstaller1',
          email: 'mockedVisitorEmailInstaller1'
        }),
        createMockedVisitor({
          id: 'mockedVisitorIdInstaller2',
          name: 'mockedVisitorNameInstaller2',
          email: 'mockedVisitorEmailInstaller2',
          mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: true })]
        })
      ],
      name: 'installers1',
      type: 'INSTALLERS',
      externalRef: 'mockedVisitorGroupExternalRefsInstallers'
    })
  ]);

export const mockedGetVisitorGroupsForTenantWithMembersAndTypesDataAdmin =
  getVisitorGroupsForTenantWithMembersAndTypesDataForQuery([
    createMockedVisitorGroupWithMembersAndType({
      id: 'mockedIdInstallers',
      members: [
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdInstaller1',
          name: 'installer1',
          email: 'installer1@atlas.com',
          mobileDevices: [
            createMockedMobileDevice({ id: 'mockedMobileDeviceInstaller1CONFIG', isRegistered: true, role: 'CONFIG' }),
            createMockedMobileDevice({ id: 'mockedMobileDeviceInstaller1ACCESS', isRegistered: true, role: 'ACCESS' })
          ],
          isEditable: true
        }),
        createMockedVisitorWithVisitorGroupMembership({
          id: 'mockedVisitorIdInstaller2',
          name: 'installer2',
          email: 'installer2@atlas.com',
          mobileDevices: [
            createMockedMobileDevice({ id: 'mockedMobileDeviceInstaller2ACCESS', isRegistered: false, role: 'ACCESS' }),
            createMockedMobileDevice({ id: 'mockedMobileDeviceInstaller2CONFIG', isRegistered: false, role: 'CONFIG' })
          ],
          isEditable: true
        })
      ],
      name: 'installers',
      type: 'INSTALLERS',
      externalRef: 'mockedExternalRefInstallers'
    })
  ]);
