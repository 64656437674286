/* eslint-disable import/prefer-default-export */

import { AddOfficeDay, AddOfficeDay_addOfficeDay } from 'graphql/generated/AddOfficeDay';
import { createMockedOfficeDay } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/officeDay';
import { VISIT_TO_ADD_DATE_END } from '../dateUsedInMocks';

export function mockedAddOfficeDayData(addOfficeDay: AddOfficeDay_addOfficeDay): AddOfficeDay {
  return {
    addOfficeDay
  };
}

export const mockedAddOfficeDayDataForMutation = mockedAddOfficeDayData(
  createMockedOfficeDay({ date: VISIT_TO_ADD_DATE_END })
);
