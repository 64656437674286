import { AddInstallerForm } from 'components/Drawer/types';
import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import Input from 'ui/molecules/Input/Input';

export interface Props {
  register: UseFormRegister<AddInstallerForm>;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  nameInputValidation: string | undefined;
  emailInputValue: string;
  handleEmailInputValueChange: (value: string) => void;
  emailInputValidation: string | undefined;
  showValidation: boolean;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}
const AddInstaller: React.FC<Props> = ({
  register,
  nameInputValue,
  handleNameInputOnChange,
  nameInputValidation,
  emailInputValue,
  handleEmailInputValueChange,
  emailInputValidation,
  showValidation,
  inputOnKeyDown
}) => {
  const { translation } = useTranslation();
  return (
    <>
      <Input
        {...register('name')}
        onChange={(event) => handleNameInputOnChange(event.target.value)}
        customRequired
        label={`${translation.name}*`}
        failed={showValidation && nameInputValidation !== undefined}
        value={nameInputValue}
        failedText={nameInputValidation}
        failedId="drawer-input-name-validation"
        id="drawer-input-name"
        onKeyDown={inputOnKeyDown}
      />
      <Input
        {...register('email')}
        customRequired
        onChange={(event) => handleEmailInputValueChange(event.target.value)}
        label={`${translation.e_mail}*`}
        failed={showValidation && emailInputValidation !== undefined}
        value={emailInputValue}
        failedText={emailInputValidation}
        failedId="drawer-input-email-validation"
        id="drawer-input-email"
        onKeyDown={inputOnKeyDown}
      />
    </>
  );
};

export default AddInstaller;
