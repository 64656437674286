import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from 'pages/Login/Login';
import Welcome from 'pages/Welcome/Welcome';
import LandingPage from 'pages/LandingPage/LandingPage';
import LicensePortalPage from 'pages/LicencePortal/LicensePortalPage';
import SyncingUsersAndFinishingPage from 'pages/SyncingUsersAndFinishing/SyncingUsersAndFinishing';
import Dashboard from '../Dashboard/Dashboard';

export interface Props {
  isLoading: boolean;
  isUserAuthenticated: boolean;
}

const Router: React.FC<Props> = ({ isLoading, isUserAuthenticated }) => (
  <BrowserRouter>
    {isLoading ? (
      <Welcome />
    ) : (
      <Routes>
        <Route path="/connect-to-atlas" element={<LicensePortalPage />} />
        <Route path="/synchronize-with-entra" element={<SyncingUsersAndFinishingPage />} />
        <Route path="/landing/access" element={<LandingPage type="access" />} />
        <Route path="/landing/config" element={<LandingPage type="config" />} />
        <Route path="*" element={isUserAuthenticated ? <Dashboard /> : <Login />} />
      </Routes>
    )}
  </BrowserRouter>
);

export default Router;
