import { DrawerStates, ModalState, SortingMenuState } from 'state/types';
import { uiVar } from 'state/vars';

export const setDrawerAction = (drawerState: DrawerStates) => {
  const currentState = uiVar();
  uiVar({ ...currentState, drawerState });
};

export const setModalAction = (modalState: ModalState) => {
  const currentState = uiVar();
  uiVar({ ...currentState, modalState });
};

export const setSortingMenuAccessProfilesAction = (sortingMenuState: SortingMenuState) => {
  const currentState = uiVar();
  uiVar({ ...currentState, sortingMenuState });
};

export const setIsMobileLandscapeAction = (isMobileLandscape: boolean) => {
  const currentState = uiVar();
  uiVar({ ...currentState, isMobileLandscape });
};
