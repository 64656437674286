/* eslint-disable import/prefer-default-export */
import { AddOfficeDay_addOfficeDay } from 'graphql/generated/AddOfficeDay';

interface Props {
  date: GraphQL_AWSDate;
}

export function createMockedOfficeDay({ date }: Props): AddOfficeDay_addOfficeDay {
  return {
    __typename: 'OfficeDay',
    date
  };
}
