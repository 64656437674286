/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';
import {
  OnOfficeDayDeletedById,
  OnOfficeDayDeletedById_onOfficeDayDeletedById
} from 'graphql/generated/OnOfficeDayDeletedById';
import { createMockedSubscriptionOfficeDayDeleted } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/subscriptionOfficeDayDeleted';
import { getDateWithFormat } from 'utils/DateFormat/DateFormat.util';
import { firstDate } from '../dateUsedInMocks';

export function mockedOnOfficeDayDeletedData(
  onOfficeDayDeletedById: OnOfficeDayDeletedById_onOfficeDayDeletedById | null
): OnOfficeDayDeletedById {
  return {
    onOfficeDayDeletedById
  };
}

export const mockedOnOfficeDayDeletedDataForSubscription = mockedOnOfficeDayDeletedData(
  createMockedSubscriptionOfficeDayDeleted({
    date: getDateWithFormat(
      'AWSDate',
      dayjs(firstDate).year(),
      dayjs(firstDate).month(),
      dayjs(firstDate).startOf('month').date()
    )
  })
);
