import { EditExternalUserForm } from 'components/Drawer/types';
import { Role } from 'graphql/generated/globalTypes';
import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import Button from 'ui/atoms/Button/Button';
import Icon from 'ui/atoms/Icon/Icon';
import StatusConnected from 'ui/atoms/StatusConnected/StatusConnected';
import StatusDisconnected from 'ui/atoms/StatusDisconnected/StatusDisconnected';
import Switch from 'ui/atoms/Switch/Switch';
import Tooltip from 'ui/atoms/Tooltip/Tooltip';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import Select, { OptionProps } from 'ui/molecules/Select/Select';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const StyledIconBackground = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: ${(props) => props.theme.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  align-items: center;
  margin-top: 1.5rem;
`;

export interface Props {
  register: UseFormRegister<EditExternalUserForm>;
  nameInputValue: string | undefined;
  handleNameInputOnChange: (value: string) => void;
  nameInputValidation: string | undefined;
  emailInputValue: string | undefined;
  group: string;
  status: boolean | undefined;
  handleOpenChangeGroupExternalUserModal: () => void;
  showValidation: boolean;
  isResendInvitationButtonVisible: boolean | undefined;
  handleOnResendInvitationClick: () => void;
  switchIsExpirationDateEnabled: boolean | undefined;
  handleSwitchIsExpirationDateOnChange: () => void;
  inputExpirationDateToValue: string | undefined;
  handleExpirationDateInputOnClick: () => void;
  inputExpirationDateToValueValidation: string | undefined;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  control: Control<EditExternalUserForm, object>;
  selectValue: null | OptionProps<Role | null>;
  inputValue: string;
  selectOption: OptionProps<Role | null>[];
  handleSelectInputChange: (value: string) => void;
  handleSelectChange: (value: OptionProps<Role> | null) => void;
}

const EditExternalUser: React.FC<Props> = ({
  register,
  nameInputValue,
  emailInputValue,
  handleNameInputOnChange,
  nameInputValidation,
  group,
  status,
  handleOpenChangeGroupExternalUserModal,
  showValidation,
  isResendInvitationButtonVisible,
  handleOnResendInvitationClick,
  switchIsExpirationDateEnabled,
  handleSwitchIsExpirationDateOnChange,
  inputExpirationDateToValue,
  handleExpirationDateInputOnClick,
  inputExpirationDateToValueValidation,
  inputOnKeyDown,
  control,
  selectValue,
  inputValue,
  selectOption,
  handleSelectChange,
  handleSelectInputChange
}) => {
  const { translation } = useTranslation();

  return (
    <ComponentWrapper flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center" gap="1rem">
      <Typography color="dTextHigh" variant="label">
        {translation.atlas_access_app}
      </Typography>
      {status ? <StatusConnected /> : <StatusDisconnected />}
      <Typography color="dTextHigh" variant="label">
        {translation.group}
      </Typography>
      <ComponentWrapper alignItems="center" justifyContent="space-between" width="100%">
        <Typography color="dTextHigh" variant="body1">
          {group}
        </Typography>
        <StyledIconBackground>
          <Icon
            name="OpenModal"
            color="lTextHigh"
            onClick={handleOpenChangeGroupExternalUserModal}
            id="drawer-group-button"
          />
        </StyledIconBackground>
      </ComponentWrapper>
      <Input
        {...register('name')}
        onChange={(event) => handleNameInputOnChange(event.target.value)}
        customRequired
        label={`${translation.name}*`}
        value={nameInputValue}
        id="drawer-input-name"
        failed={showValidation && nameInputValidation !== undefined}
        failedText={nameInputValidation}
        failedId="drawer-input-name-validation"
        onKeyDown={inputOnKeyDown}
      />
      <Input
        {...register('email')}
        required
        label={`${translation.e_mail}*`}
        value={emailInputValue}
        id="drawer-input-email"
        disabled
        onKeyDown={inputOnKeyDown}
      />
      <ComponentWrapper>
        <Controller
          control={control}
          name="role"
          render={() => (
            <Select
              fieldTemplateProps={{
                label: translation.role
              }}
              autocompleteProps={{
                options: selectOption,
                value: selectValue,
                inputValue,
                onInputChange: (_, value) => {
                  if (value !== 'undefined') handleSelectInputChange(value);
                },
                onChange: (_, value) => {
                  handleSelectChange(value as OptionProps<Role>);
                },
                // @ts-ignore
                getOptionLabel: (option) => option.label as string,
                id: 'drawer-select-role-id',
                onKeyDown: inputOnKeyDown,
                sx: { width: 343 },
                disableClearable: true
              }}
              textFieldProps={{ placeholder: translation.no_role_selected }}
              {...register('role')}
            />
          )}
        />
        <Tooltip
          elementOnHover={
            <ComponentWrapper flexDirection="column" width="20rem" padding="1rem">
              <Typography variant="body4" color="lTextHigh">
                {
                  translation.a_user_with_the_owner_role_has_the_ability_to_invite_other_users_to_this_group_up_to_maximum_user_count
                }
              </Typography>
            </ComponentWrapper>
          }
          id="role-tooltip"
        >
          <IconWrapper>
            <Icon name="Info" id="locks-i-icon-role" />
          </IconWrapper>
        </Tooltip>
      </ComponentWrapper>
      {isResendInvitationButtonVisible && (
        <Button type="button" onClick={handleOnResendInvitationClick} id="resend-access-invitation-button">
          {translation.resend_invitation}
        </Button>
      )}

      <Input
        {...register('expirationDate')}
        customRequired
        label={translation.expiration_date}
        failed={showValidation && inputExpirationDateToValueValidation !== undefined}
        value={inputExpirationDateToValue}
        failedText={inputExpirationDateToValueValidation}
        disabled={!switchIsExpirationDateEnabled}
        failedId="drawer-input-expiration-date-validation"
        id="drawer-input-expiration-date"
        onKeyDown={inputOnKeyDown}
        onClick={switchIsExpirationDateEnabled ? handleExpirationDateInputOnClick : undefined}
      />
      <ComponentWrapper flexDirection="row" gap="1rem" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="body1" color="dTextHigh">
          {translation.never_expire}
        </Typography>
        <Switch
          variant="dark"
          {...register('isOfficeModeEnabled')}
          id="drawer-enable-expiration-date-span"
          inputId="drawer-enable-expiration-date-input"
          checked={!switchIsExpirationDateEnabled}
          onClick={handleSwitchIsExpirationDateOnChange}
          onKeyDown={inputOnKeyDown}
        />
      </ComponentWrapper>
    </ComponentWrapper>
  );
};

export default EditExternalUser;
