/* eslint-disable import/prefer-default-export */

import { DeleteVisitorGroupById } from 'graphql/generated/DeleteVisitorGroupById';

export function mockedDeleteVisitorGroupData(deleteVisitorGroupById: string | null): DeleteVisitorGroupById {
  return {
    deleteVisitorGroupById
  };
}

export const mockedDeleteVisitorGroupDataForMutation = mockedDeleteVisitorGroupData('mockedVisitorGroupId1');
