import styled from 'styled-components';
import * as React from 'react';
import Label from 'ui/atoms/Label/Label';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Typography from 'ui/atoms/Typography/Typography';
import ChipMultiselect from 'ui/atoms/ChipMultiselect/ChipMultiselect';
import { ListboxMultiselect } from 'ui/atoms/ListboxMultiselect/ListboxMultiselect';
import Icon from 'ui/atoms/Icon/Icon';
import { Value } from 'components/Multiselect/Multiselect';
import { AutocompleteGetTagProps, UseAutocompleteRenderedOption } from '@mui/base';

const InputWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.b6};
  background-color: ${({ theme }) => theme.colors.b5};
  padding: 0.375rem 0.625rem;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  color: ${(props) => props.theme.colors.dTextHigh};
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: overlay;
  overflow-x: hidden;
`;

const StyledInput = styled.input`
  border: none;
  letter-spacing: 0.05em;
  color: ${(props) => props.theme.colors.dTextHigh};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.125rem;
  line-height: 1.625rem;
  background-color: ${({ theme }) => theme.colors.b5};
  padding: 0;

  &:focus {
    border: none;
    outline: none;
  }
`;

const IconWrapper = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 1.5rem;
  margin: 0;
  padding: 0;
  height: 1.5rem;
`;

export interface Props {
  label: string;
  placeholder?: string;
  id: string;
  hideClearAllButton: boolean;
  setAnchorEl: () => void;
  value: Value[];
  getInputProps: () => React.InputHTMLAttributes<HTMLInputElement> & {
    ref: React.Ref<HTMLInputElement>;
  };
  getTagProps: AutocompleteGetTagProps;
  getListboxProps: () => React.HTMLAttributes<HTMLUListElement>;
  getOptionProps: (renderedOption: UseAutocompleteRenderedOption<Value>) => React.HTMLAttributes<HTMLLIElement>;
  groupedOptions: Value[];
  getClearProps: () => React.HTMLAttributes<HTMLButtonElement>;
}

const MultiselectMUI: React.FC<Props> = ({
  label,
  placeholder,
  setAnchorEl,
  value,
  getInputProps,
  getTagProps,
  getListboxProps,
  getOptionProps,
  groupedOptions,
  getClearProps,
  id,
  hideClearAllButton
}) => (
  <ComponentWrapper gap="0.375rem" flexDirection="column" width="100%">
    <Label>{label}</Label>
    <InputWrapper onClick={() => document.getElementById('MultiselectInputId')?.focus()} ref={setAnchorEl} id={id}>
      <ComponentWrapper flexWrap="wrap" gap="0.5rem" flexDirection="row" width="100%" maxHeight="10rem">
        {value.map((option, index: number) => (
          <ChipMultiselect label={option.label} id={`chip-${index}`} {...getTagProps({ index })} />
        ))}
        <ComponentWrapper flexDirection="row" justifyContent="center">
          <StyledInput
            {...getInputProps()}
            id="MultiselectInputId"
            placeholder={value.length === 0 ? placeholder : ''}
          />
        </ComponentWrapper>
      </ComponentWrapper>
      {value.length > 0 && (
        <ComponentWrapper flex="0 0 1.5rem" margin="0.5rem 0" alignItems="start" justifyContent="end">
          {!hideClearAllButton && (
            <IconWrapper {...getClearProps()}>
              <Icon name="Close" color="dTextHigh" />
            </IconWrapper>
          )}
        </ComponentWrapper>
      )}
    </InputWrapper>
    {groupedOptions.length > 0 ? (
      <ListboxMultiselect {...getListboxProps()}>
        {groupedOptions.map((option, index) => (
          <li {...getOptionProps({ option, index })} id={`option+${index}`}>
            <Typography
              variant="body3"
              color={value.some((item) => option.label === item.label) ? 'primary' : 'dTextHigh'}
            >
              {option.label}
            </Typography>
          </li>
        ))}
      </ListboxMultiselect>
    ) : null}
  </ComponentWrapper>
);
export default MultiselectMUI;
