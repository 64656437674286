/* eslint-disable import/prefer-default-export */
/* eslint-disable no-magic-numbers */
import { Translation } from 'hooks/useTranslation/useTranslation';
import { OptionProps } from 'ui/molecules/Select/Select';

export const createReasonSelectOptions = (translation: Translation): Array<OptionProps<null | string>> => [
  {
    value: 'No token',
    label: translation.no_token
  },
  {
    value: 'Unknown',
    label: translation.unknown
  }
];
