import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Typography from '../Typography/Typography';

export interface UIProps {
  text: string;
  numberSup?: number;
  selected: boolean;
  index?: number;
  location?: string;
}

export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, UIProps {}

const StyledTab = styled.div<Partial<Props>>`
  display: flex;
  flex-direction: row;
  gap: 0.0625rem;

  &:hover {
    h2,
    sup {
      color: ${({ theme }) => theme.colors.lTextHigh};
    }
  }

  &.selected {
    h2 {
      display: inline-block;
      border-bottom: 0.125rem solid ${({ theme }) => theme.colors.primary};
      padding-bottom: 0.25rem;
      color: ${({ theme }) => theme.colors.lTextHigh};
    }
    sup {
      color: ${({ theme }) => theme.colors.lTextHigh};
    }
  }
`;

const StyledTypographyWrapper = styled.div`
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Tab: React.FC<Props> = ({ text, numberSup, selected, index, children, location, id, ref, ...props }) => (
  <StyledTab {...props} className={selected ? 'selected' : ''}>
    <StyledTypographyWrapper>
      {location ? (
        <StyledLink to={location}>
          <Typography variant="title4" color="lTextMedium" id={`tab-${index}`}>
            {text}
          </Typography>
        </StyledLink>
      ) : (
        <Typography variant="title4" color="lTextMedium" id={`tab-${index}`}>
          {text}
        </Typography>
      )}
    </StyledTypographyWrapper>
    {numberSup && (
      <Typography variant="super" color="lTextMedium">
        {`(${numberSup})`}
      </Typography>
    )}
  </StyledTab>
);

export default Tab;
