import React from 'react';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Typography from '../../atoms/Typography/Typography';

export interface Props {
  text: string;
  id?: string;
}

const SortingMenuHeader: React.FC<Props> = ({ text, id }) => (
  <ComponentWrapper
    margin="0 0 0 2.5rem"
    id={id}
    height="2.5rem"
    alignItems="center"
    justifyContent="left"
    width="13rem"
  >
    <Typography color="dTextMedium">{text}</Typography>
  </ComponentWrapper>
);

export default SortingMenuHeader;
