/* eslint-disable import/prefer-default-export */

import { DeleteLocation, DeleteLocation_deleteLocation } from 'graphql/generated/DeleteLocation';

export function mockedDeleteLocationData(deleteLocation: DeleteLocation_deleteLocation): DeleteLocation {
  return {
    deleteLocation
  };
}

export function mockedDeleteLocationDeleteLocationData(id: string): DeleteLocation_deleteLocation {
  return {
    __typename: 'Location',
    id
  };
}

export const mockedDeleteLocationDataForMutation = mockedDeleteLocationData(
  mockedDeleteLocationDeleteLocationData('mockedAddedLocationId')
);
