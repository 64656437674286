import styled from 'styled-components';
import { Colors } from 'theme/theme';
import { WidthOrHeight } from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  marginTop?: string;
  width?: WidthOrHeight;
  color?: keyof Colors;
  height?: WidthOrHeight;
}

const Separator = styled.div<Props>`
  ${({ color, theme }) =>
    color ? `border-top: 1px solid ${theme.colors[color]};` : `border-top: 1px solid ${theme.colors.b3};`};

  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  ${({ width }) => (width ? `width:${width};` : 'width: 100%')};
  ${({ height }) => (height ? `height:${height};` : 'height: 100%')};

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
    width: calc(100vw - 7.375vw);
    margin: 0 3.375vw 0 4vw;
    `
      : ''};
`;

export default Separator;
