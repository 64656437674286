/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members,
  GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members_mobileDevices
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { createMockedMobileDevice } from './mobileDevice';

interface Props {
  id: string;
  name?: string;
  email?: GraphQL_AWSEmail;
  mobileDevices?: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members_mobileDevices[];
  isEditable?: boolean;
  expirationDate?: string | null;
}

export function createMockedVisitor({
  id,
  name = 'mockedVisitorName',
  email = 'mockedVisitorEmail',
  mobileDevices = [createMockedMobileDevice({ id: 'mockedMobileDevice' })],
  isEditable = true,
  expirationDate = null
}: Props): GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members {
  return {
    __typename: 'Visitor',
    id,
    name,
    email,
    mobileDevices,
    isEditable,
    expirationDate
  };
}
