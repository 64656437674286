import React from 'react';
import TabsUI from 'ui/molecules/Tabs/Tabs';
import { Props as TabProps } from 'ui/atoms/Tab/Tab';
import { useLocation } from 'react-router-dom';

export type TabsType = Omit<TabProps, 'selected' | 'numberSup'>;

export interface Props {
  tabs: TabsType[];
}

const Tabs: React.FC<Props> = ({ tabs }) => {
  const routerLocation = useLocation();

  const getTabsName = (currentLocation: string) => {
    const data: TabProps[] = [];
    tabs.forEach((tab) => {
      data.push({
        text: tab.text,
        selected: tab.location === currentLocation,
        location: tab.location,
        id: tab.id
      });
    });
    return data;
  };

  return <TabsUI tabs={getTabsName(routerLocation.pathname)} />;
};

export default Tabs;
