/* eslint-disable no-magic-numbers */
/* eslint-disable import/prefer-default-export */

import { GetTenantName } from 'graphql/generated/GetTenantName';

export function mockedGetTenantNameData(getTenantName: string): GetTenantName {
  return {
    getTenantName
  };
}

export const mockedGetTenantNameDataForQuery = mockedGetTenantNameData('mockedTenantName');
