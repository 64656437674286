import { AddInstallerForm } from 'components/Drawer/types';
import useInstallers from 'hooks/useInstallers/useInstallers';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import AddInstaller from 'ui/organisms/Drawer/variants/AddInstaller';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import useTranslation from 'hooks/useTranslation/useTranslation';

const AddInstallerVariant: React.FC = () => {
  const { translation } = useTranslation();
  const { register, handleSubmit, setValue } = useForm<AddInstallerForm>();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { installersGroups, setInstallerMember, setInstallerMemberLoading } = useInstallers({
    handleFetchError
  });
  const { hideDrawer } = useDrawer();
  const [nameInputValue, setNameInputValue] = useState('');
  const [nameInputValidation, setNameInputValidation] = useState<string | undefined>(undefined);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [emailInputValidation, setEmailInputValidation] = useState<string | undefined>(undefined);
  const [showValidation, setShowValidation] = useState(false);

  const onSubmit = async () => {
    setShowValidation(true);
    if (nameInputValidation === undefined && emailInputValidation === undefined && !setInstallerMemberLoading) {
      try {
        const response = await setInstallerMember(emailInputValue, nameInputValue);
        if (response?.setInstaller.name !== nameInputValue && !response?.setInstaller.isEditable)
          enqueueSnackbar(
            translation.the_user_you_have_added_into_atlas_is_already_existing_therefor_we_reuse_the_already_known_user_name,
            { snackbartype: 'success' }
          );
      } finally {
        hideDrawer();
      }
    }
  };

  useEffect(() => {
    setValue('name', nameInputValue);
    setValue('email', emailInputValue);
  }, [emailInputValue, nameInputValue, setValue]);

  useEffect(() => {
    if (nameInputValue === '') setNameInputValidation(translation.name_cannot_be_empty);
    else setNameInputValidation(undefined);
  }, [nameInputValue, translation.name_cannot_be_empty]);

  useEffect(() => {
    if (emailInputValue === '') setEmailInputValidation(translation.please_enter_an_email_address);
    else if (
      !emailInputValue
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      setEmailInputValidation(translation.this_is_not_a_correct_email_address);
    else if (installersGroups.some(({ members }) => members.some((member) => member.email === emailInputValue)))
      setEmailInputValidation(translation.email_has_already_been_added);
    else setEmailInputValidation(undefined);
  }, [
    installersGroups,
    emailInputValue,
    translation.this_is_not_a_correct_email_address,
    translation.email_has_already_been_added,
    translation.please_enter_an_email_address
  ]);

  return (
    <DrawerTemplate
      title={translation.add_installer}
      confirmButtonText={translation.add}
      closeButtonText={translation.cancel}
      id="drawer-add-installer-group"
      onSubmit={handleSubmit(onSubmit)}
      disableConfirmButton={showValidation && (nameInputValidation !== undefined || emailInputValidation !== undefined)}
    >
      <AddInstaller
        register={register}
        nameInputValue={nameInputValue}
        handleNameInputOnChange={setNameInputValue}
        nameInputValidation={nameInputValidation}
        emailInputValue={emailInputValue}
        handleEmailInputValueChange={setEmailInputValue}
        emailInputValidation={emailInputValidation}
        showValidation={showValidation}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
      />
    </DrawerTemplate>
  );
};
export default AddInstallerVariant;
