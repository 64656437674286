import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  IPublicClientApplication
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'environment/SsoConfig';
import { useEffect, useState } from 'react';

const FOUR_MINUTES = 240000;

const noTokenPromise = Promise.resolve({
  accessToken: 'no-token'
} as AuthenticationResult);

const getTokenPromise = (loggedAccount: AccountInfo, instance: IPublicClientApplication) =>
  instance
    .acquireTokenSilent({
      ...loginRequest,
      account: loggedAccount
    })
    .catch((error: unknown) => {
      localStorage.clear();
      if (error instanceof InteractionRequiredAuthError) {
        // assertion needed to avoid ts error, acquireTokenRedirect redirect us to MS login page anyway
        return instance.acquireTokenRedirect(loginRequest) as unknown as AuthenticationResult;
      }

      return noTokenPromise;
    });

const useToken = () => {
  const { accounts, instance } = useMsal();
  const [loggedAccount] = accounts;
  const [, refreshToken] = useState(false);

  // Refreshing Access Token in background
  useEffect(() => {
    const refreshTokenInterval = setInterval(async () => {
      refreshToken((currValue) => !currValue);
    }, FOUR_MINUTES);
    return () => clearInterval(refreshTokenInterval);
  }, [instance, loggedAccount, refreshToken]);

  const getToken = () => getTokenPromise(loggedAccount, instance).then(({ accessToken }) => accessToken); // Apollo Client constructor need "string | () => Promise<string>" type as a token

  return { getToken };
};

export default useToken;
