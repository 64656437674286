import Calendar from 'components/Calendar/Calendar';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Icon from 'ui/atoms/Icon/Icon';
import ArrayUtil from 'utils/Array/Array.util';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useReactiveVar } from '@apollo/client';
import { uiVar } from 'state/vars';
import { setRequestStateAction } from 'state/actions/requestState';
import PageContent from 'ui/templates/PageContent/PageContent';
import useOfficeDays from 'hooks/useOfficeDays/useOfficeDays';
import { OfficeDaysState } from 'state/types';
import useTranslation from 'hooks/useTranslation/useTranslation';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { createReservationsTabs } from '../reservationsTabsConst';
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

dayjs.extend(isBetween);

interface CalendarWrapperProps {
  isMobile: boolean;
}

const CalendarWrapper = styled.div<CalendarWrapperProps>`
  ${({ isMobile }) =>
    isMobile
      ? `
    margin: 10vh auto;
    padding: 0 1rem 2rem 0;
    overflow: hidden auto;
    `
      : `
    margin: 3.75rem auto 0 auto;
    padding-right: 1rem;
    `};
`;

const IconWrapper = styled.div`
  margin: 4.3125rem 8.3125rem 2.8125rem auto;
`;

const OfficeDays: React.FC = () => {
  const { translation, getLanguage } = useTranslation();
  const reservationsTabs = createReservationsTabs(translation);
  const TODAY = dayjs().locale(getLanguage());
  const [dateOfRender, setDateOfRender] = useState(TODAY);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { isMobile } = useReactiveVar(uiVar);
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    addOfficeDay,
    addOfficeDayLoading,
    officeDays,
    officeDaysLoading,
    deleteOfficeDayVisit,
    deleteOfficeDayLoading,
    officeAvailableDays
  } = useOfficeDays({
    handleFetchError
  });

  const disableDates = (() => {
    const nextMonthDate = dayjs()
      .locale(getLanguage())
      .startOf('M')
      .month(+dayjs().locale(getLanguage()).format('M'));
    const daysInCurrentMonth: Dayjs[] = ArrayUtil.Range(dayjs().locale(getLanguage()).daysInMonth()).map((day) =>
      dayjs()
        .locale(getLanguage())
        .date(day + 1)
    );
    const daysInNextMonth: Dayjs[] = ArrayUtil.Range(nextMonthDate.daysInMonth()).map((day) =>
      nextMonthDate.date(day + 1)
    );
    const month: Dayjs[] = [...daysInCurrentMonth, ...daysInNextMonth];
    const disabled = month.filter(
      (day) => !officeAvailableDays.some((availableDay) => dayjs(availableDay.date).isSame(day, 'day'))
    );

    return disabled;
  })();

  const selectedDays = useMemo(() => {
    if (!officeDays) return [];
    const days = officeDays.map((visit) => dayjs(visit.date));
    return Array.from(new Set(days));
  }, [officeDays]);

  const handleRemoveVisits = async (visitsWithSameDate: OfficeDaysState) => {
    try {
      const deleteVisitsPromises = visitsWithSameDate.map(async (visit) => {
        await deleteOfficeDayVisit(dayjs(visit.date));
      });

      await Promise.all(deleteVisitsPromises);

      enqueueSnackbar(`${translation.your_visit_is_successfully_canceled}`, { snackbartype: 'success' });
    } catch (err: unknown) {
      enqueueSnackbar(`${translation.error_while_deleting_visit}`, { snackbartype: 'error' });
    }
  };

  const handleAddVisit = async (day: number) => {
    const officeDay = await addOfficeDay(dateOfRender.date(day));
    if (officeDay)
      enqueueSnackbar(
        `${translation.you_can_visit_the_office_on} ${dayjs(officeDay.addOfficeDay.date).format('D MMMM')}`,
        {
          snackbartype: 'success'
        }
      );
  };

  const handleSelectDate = async (day: number) => {
    if (addOfficeDayLoading || officeDaysLoading || deleteOfficeDayLoading) return;
    setRequestStateAction(true);
    const selectedMonth = dateOfRender.get('month');
    const visitsWithSameDate =
      officeDays.filter((visit) => {
        const visitDate = dayjs(visit.date).get('date');
        const visitMonth = dayjs(visit.date).get('month');
        return visitDate === day && visitMonth === selectedMonth;
      }) || [];
    if (visitsWithSameDate.length > 0) {
      await handleRemoveVisits(visitsWithSameDate);
    } else {
      await handleAddVisit(day);
    }
  };

  const handleShownNextMonth = () => {
    setDateOfRender(dateOfRender.add(1, 'month'));
  };

  const handleShownPreviousMonth = () => {
    setDateOfRender(dateOfRender.subtract(1, 'month'));
  };

  return (
    <PageContent
      title="ATLAS"
      titleId="location"
      tabs={reservationsTabs}
      isMobile={isMobile}
      isMobileMenuOpen={isMobileMenuOpen}
      setIsMobileMenuOpen={setIsMobileMenuOpen}
      withScroll
    >
      <ComponentWrapper flexDirection="column">
        <CalendarWrapper isMobile={isMobile}>
          <Calendar
            disableDates={disableDates}
            selectedDates={selectedDays}
            dateOfRender={dateOfRender}
            isMobileMenuOpen={isMobileMenuOpen}
            onSelectDate={handleSelectDate}
            showNextMonth={handleShownNextMonth}
            showPreviousMonth={handleShownPreviousMonth}
          />
        </CalendarWrapper>
        {!isMobile && (
          <IconWrapper>
            {/* <ComponentWrapper alignSelf="flex-end"> */}
            <Icon name="Legend" width={288} height={58} />
          </IconWrapper>
        )}
      </ComponentWrapper>
    </PageContent>
  );
};

export default OfficeDays;
