import { Icons } from 'ui/atoms/Icon/Icon';

export type VisitorGroupType = 'AZURE_AD' | 'EXTERNAL_VISITORS' | 'CO_CONTRACTORS';

const getVisitorGroupIcon = (groupType: VisitorGroupType): Icons => {
  switch (groupType) {
    case 'AZURE_AD':
      return 'ADSyncedUser';
    case 'EXTERNAL_VISITORS':
      return 'Visitor';
    case 'CO_CONTRACTORS':
    default:
      return 'CoContractor';
  }
};

export default { getVisitorGroupIcon };
