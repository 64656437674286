import React from 'react';
import TextField from 'ui/atoms/TextField/TextField';
import FieldTemplate, { Props as WrapperProps } from '../../shared/Field/FieldTemplate';
import { Props as FieldProps } from '../../shared/Field/Field';

export interface Props extends WrapperProps, FieldProps<'textField'> {}

const Input = React.forwardRef<HTMLInputElement | null, Props>((props, ref) => {
  const {
    label,
    failed,
    hideValidationText,
    hideValidationEmptySpace,
    hideMarginTop,
    failedText,
    failedId,
    required,
    customRequired,
    readOnly,
    hidden,
    disabled,
    ...restProps
  } = props;
  return (
    <FieldTemplate
      label={label}
      failed={failed}
      hideValidationText={hideValidationText}
      hideValidationEmptySpace={hideValidationEmptySpace}
      failedText={failedText}
      failedId={failedId}
      required={required}
      customRequired={customRequired}
      hidden={hidden}
      disabled={disabled}
    >
      <TextField
        disabled={readOnly || disabled}
        {...restProps}
        failed={failed}
        required={required}
        customRequired={customRequired}
        hideMarginTop={hideMarginTop}
        ref={ref}
      />
    </FieldTemplate>
  );
});

export default Input;
