import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import { Button, Typography } from '@material-ui/core';
import { muiTheme } from 'theme/theme';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export interface Props {
  handleButtonOnClick: () => void;
}

const WelcomePage: React.FC<Props> = ({ handleButtonOnClick }) => (
  <>
    <BackgroundImage id="background" />
    <Wrapper>
      <ComponentWrapper gap="0.5rem">
        <Typography variant="h4" style={{ color: muiTheme.palette.primary.contrastText }}>
          Welcome to
        </Typography>
        <Typography variant="h4" color="primary">
          ATLAS
        </Typography>
      </ComponentWrapper>
      <ComponentWrapper width="14.5rem" alignSelf="center">
        <Button onClick={handleButtonOnClick} variant="contained" size="large" color="primary" fullWidth>
          Start setup
        </Button>
      </ComponentWrapper>
    </Wrapper>
  </>
);

export default WelcomePage;
