import { useMutation } from '@apollo/client';
import {
  UnregisterMobileDeviceById,
  UnregisterMobileDeviceByIdVariables
} from 'graphql/generated/UnregisterMobileDeviceById';
import { UNREGISTER_MOBILE_DEVICE_BY_ID } from 'graphql/mutations';
import { BaseHookProps } from 'hooks/shared/types';

const useUnregisterMobileDevice = ({ handleFetchError }: BaseHookProps) => {
  const [unregisterMobileDeviceByIdMutation, { loading: unregisterMobileDeviceByIdMutationLoading }] = useMutation<
    UnregisterMobileDeviceById,
    UnregisterMobileDeviceByIdVariables
  >(UNREGISTER_MOBILE_DEVICE_BY_ID, {
    onError: () => handleFetchError('Error while unregister Mobile Device')
  });

  const unregisterMobileDeviceById = async (visitorId: string, mobileDeviceId: string) => {
    const { data } = await unregisterMobileDeviceByIdMutation({
      variables: {
        visitorId,
        mobileDeviceId
      }
    });

    return data;
  };

  return {
    unregisterMobileDeviceById,
    unregisterMobileDeviceByIdMutationLoading
  };
};

export default useUnregisterMobileDevice;
