import { useReactiveVar } from '@apollo/client';
import { setModalAction } from 'state/actions/ui';
import { ModalState } from 'state/types';
import { uiVar } from 'state/vars';

const useModal = () => {
  const { modalState } = useReactiveVar(uiVar);

  const hideModal = () => setModalAction(null);

  const showModal = (modal: ModalState) => {
    setModalAction(modal);
  };

  return { hideModal, showModal, modalState };
};

export default useModal;
