import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Title from 'ui/atoms/Title/Title';
import { Button, Checkbox, Typography } from '@material-ui/core';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const TitleWrapper = styled.div`
  position: absolute;
  top: 4rem;
  left: 8rem;
`;

const Wrapper = styled.div`
  position: absolute;
  margin: 10rem 8rem 2rem 8rem;
  width: calc(100vw - 16rem);
  height: calc(100vh - 12rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem 5rem;
  background-color: ${({ theme }) => theme.colors.bgLight};
`;

const RulesWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.dTextMedium};
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 1rem 4rem;
  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.dTextLow};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.dTextMedium};
  }
`;

export interface Props {
  title: string;
  rulesContent: string;
  rulesName: string;
  isCheckBoxChecked: boolean;
  handleCheckBoxOnClick: () => void;
  handleButtonOnClick: () => void;
}

const RulesPage: React.FC<Props> = ({
  title,
  rulesContent,
  rulesName,
  isCheckBoxChecked,
  handleButtonOnClick,
  handleCheckBoxOnClick
}) => (
  <>
    <BackgroundImage id="background" />
    <TitleWrapper>
      <Title withAdditionalText />
    </TitleWrapper>
    <Wrapper>
      <ComponentWrapper>
        <Typography variant="h4" color="textPrimary">
          {`${title}`}
        </Typography>
      </ComponentWrapper>
      <RulesWrapper>
        <Typography color="textPrimary">{rulesContent}</Typography>
      </RulesWrapper>
      <ComponentWrapper alignSelf="flex-start" gap="1rem" alignItems="center" justifyContent="center">
        <Checkbox checked={isCheckBoxChecked} onClick={handleCheckBoxOnClick} color="primary" />
        <Typography variant="body1" color="textPrimary">
          I have read and agree to the terms of the
          <span style={{ fontWeight: 'bold' }}> {rulesName}</span>
        </Typography>
      </ComponentWrapper>
      <ComponentWrapper width="15rem" alignSelf="flex-end">
        <Button
          fullWidth
          disabled={!isCheckBoxChecked}
          onClick={handleButtonOnClick}
          variant="contained"
          size="large"
          color="primary"
        >
          I agree
        </Button>
      </ComponentWrapper>
    </Wrapper>
  </>
);

export default RulesPage;
