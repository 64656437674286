/* eslint-disable import/prefer-default-export */

import {
  AddVisitorGroupWithRetail,
  AddVisitorGroupWithRetail_addVisitorGroup
} from 'graphql/generated/AddVisitorGroupWithRetail';
import { createMockedVisitorGroupWithMembersAndRoleAndMaxNumberOfUsersCoContractors } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithMembersAndRoleAndMaxNumberOfUsersCoContractors';

export function mockedAddVisitorGroupData(
  addVisitorGroup: AddVisitorGroupWithRetail_addVisitorGroup
): AddVisitorGroupWithRetail {
  return {
    addVisitorGroup
  };
}

export const mockedAddVisitorGroupDataForMutation = mockedAddVisitorGroupData(
  createMockedVisitorGroupWithMembersAndRoleAndMaxNumberOfUsersCoContractors({
    id: 'mockedVisitorGroupIdExternalUsersNew',
    members: [],
    name: 'co-contractors-new',
    maxNumberOfMembers: 2
  })
);
