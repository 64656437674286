import React from 'react';

const Reader: React.FC = () => (
  <>
    <g clipPath="url(#clip0_4945_104394)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8666 4.71544V27.2807C29.8666 29.8818 27.9133 31.998 25.5122 31.998C25.4776 31.998 6.41848 31.9995 6.38402 31.9986C4.0307 31.9387 2.1333 29.8461 2.1333 27.2825L2.1333 4.71728C2.1333 2.15366 4.0307 0.0610793 6.38402 0.00120974C6.41848 0.000332995 25.4776 -0.00195312 25.5122 -0.00195312C27.9133 -0.00195312 29.8666 2.11427 29.8666 4.71544ZM25.5122 30.6502C27.2273 30.6502 28.6225 29.1386 28.6225 27.2807V4.71544C28.6225 2.85746 27.2273 1.34587 25.5122 1.34587C25.4776 1.34587 6.41844 1.34834 6.38402 1.34956C4.71681 1.40901 3.37741 2.89689 3.37741 4.71728V27.2825C3.37741 29.1029 4.71681 30.5908 6.38402 30.6502C6.41844 30.6514 25.4776 30.6502 25.5122 30.6502Z"
      />
      <path d="M14.9333 13.9732C14.9333 14.5034 15.3631 14.9332 15.8933 14.9332C16.4235 14.9332 16.8533 14.5034 16.8533 13.9732C16.8533 13.443 16.4235 13.0132 15.8933 13.0132C15.3631 13.0132 14.9333 13.443 14.9333 13.9732Z" />
      <path d="M14.9333 11.4131C14.9333 11.9433 15.3631 12.3731 15.8933 12.3731C16.4235 12.3731 16.8533 11.9433 16.8533 11.4131C16.8533 10.8829 16.4235 10.4531 15.8933 10.4531C15.3631 10.4531 14.9333 10.8829 14.9333 11.4131Z" />
      <path d="M14.9333 8.85307C14.9333 9.38326 15.3631 9.81307 15.8933 9.81307C16.4235 9.81307 16.8533 9.38326 16.8533 8.85307C16.8533 8.32287 16.4235 7.89307 15.8933 7.89307C15.3631 7.89307 14.9333 8.32287 14.9333 8.85307Z" />
      <path d="M14.9333 6.29301C14.9333 6.8232 15.3631 7.25301 15.8933 7.25301C16.4235 7.25301 16.8533 6.8232 16.8533 6.29301C16.8533 5.76281 16.4235 5.33301 15.8933 5.33301C15.3631 5.33301 14.9333 5.76281 14.9333 6.29301Z" />
      <rect x="13.5" y="21.5" width="5" height="5" rx="1.5" />
    </g>
  </>
);

export default Reader;
