/* eslint-disable import/prefer-default-export */

import { SetVisitorById, SetVisitorById_setVisitorById } from 'graphql/generated/SetVisitorById';
import { createMockedVisitor } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitor';

export function mockedSetVisitorByIdData(setVisitorById: SetVisitorById_setVisitorById): SetVisitorById {
  return {
    setVisitorById
  };
}

export const mockedSetVisitorDataForMutation = mockedSetVisitorByIdData(
  createMockedVisitor({ id: 'mockedExternalUserId' })
);

export const mockedSetVisitorDataForMutation1 = mockedSetVisitorByIdData(
  createMockedVisitor({
    id: 'mockedVisitorIdExternalUser6',
    name: 'mockedVisitorEmailExternalUser6Edited',
    email: 'mockedVisitorEmailExternalUser6'
  })
);

export const mockedUpdateInstallerDataForMutation = mockedSetVisitorByIdData(
  createMockedVisitor({
    id: 'mockedVisitorIdInstaller1',
    name: 'mockedVisitorNameInstaller1Updated',
    email: 'mockedVisitorEmailInstaller1'
  })
);
