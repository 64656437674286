import React from 'react';

const BatteryUnknown: React.FC = () => (
  <>
    <path d="M8 19H3C2.73478 19 2.48043 18.8946 2.29289 18.7071C2.10536 18.5196 2 18.2652 2 18V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H9.625L8.458 7H4V17H8V19ZM12.375 19L13.542 17H18V7H14V5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H12.375ZM21 9H23V15H21V9Z" />
    <path d="M10.0166 13.4727V12.8848C10.0166 12.6022 10.0508 12.347 10.1191 12.1191C10.1921 11.8913 10.3197 11.6748 10.502 11.4697C10.6842 11.2646 10.9372 11.0596 11.2607 10.8545C11.5706 10.6585 11.7985 10.4899 11.9443 10.3486C12.0902 10.2028 12.1859 10.0706 12.2314 9.95215C12.277 9.8291 12.2998 9.70605 12.2998 9.58301C12.2998 9.3916 12.2132 9.24121 12.04 9.13184C11.8669 9.0179 11.6413 8.96094 11.3633 8.96094C11.0169 8.96094 10.641 9.02246 10.2354 9.14551C9.83431 9.26855 9.40592 9.45085 8.9502 9.69238L8 7.83301C8.51497 7.53678 9.06413 7.30208 9.64746 7.12891C10.2308 6.95117 10.8665 6.8623 11.5547 6.8623C12.5892 6.8623 13.3913 7.09245 13.9609 7.55273C14.5306 8.00846 14.8154 8.61003 14.8154 9.35742C14.8154 9.71289 14.7607 10.0387 14.6514 10.335C14.542 10.6312 14.3688 10.9092 14.1318 11.1689C13.8949 11.4287 13.5827 11.6839 13.1953 11.9346C12.9219 12.1077 12.7054 12.2559 12.5459 12.3789C12.391 12.502 12.2793 12.6204 12.2109 12.7344C12.1471 12.8483 12.1152 12.9782 12.1152 13.124V13.4727H10.0166ZM9.72266 15.8652C9.72266 15.3822 9.86165 15.0426 10.1396 14.8467C10.4222 14.6507 10.764 14.5527 11.165 14.5527C11.5479 14.5527 11.8783 14.6507 12.1562 14.8467C12.4388 15.0426 12.5801 15.3822 12.5801 15.8652C12.5801 16.3255 12.4388 16.6582 12.1562 16.8633C11.8783 17.0684 11.5479 17.1709 11.165 17.1709C10.764 17.1709 10.4222 17.0684 10.1396 16.8633C9.86165 16.6582 9.72266 16.3255 9.72266 15.8652Z" />
  </>
);

export default BatteryUnknown;
