import React from 'react';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import TitleUI from 'ui/atoms/Title/Title';

export interface Props {
  onClick?: () => void;
}

const Title: React.FC<Props> = ({ onClick }) => (
  <ComponentWrapper onClick={onClick} cursor={onClick !== undefined ? 'pointer' : 'auto'}>
    <TitleUI />
  </ComponentWrapper>
);

export default Title;
