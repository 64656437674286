/* eslint-disable import/prefer-default-export */
import { TabsType } from 'components/Tabs/Tabs';
import { Translation } from 'hooks/useTranslation/useTranslation';

export const createReservationsTabs = (translation: Translation): TabsType[] => [
  {
    text: translation.office_days,
    location: '/reservations/office-days'
  }
];
