import React from 'react';
import styled, { css } from 'styled-components';

export type CalendarDayVariant = 'Default' | 'Selected' | 'Disabled' | 'Empty' | 'Past' | 'PastSelected';
export type CalendarDayBorderRadius =
  | 'Default'
  | 'TopRounding'
  | 'LeftTopRounding'
  | 'RightTopRounding'
  | 'RightBottomRounding'
  | 'LeftBottomRounding'
  | 'BottomRounding';

export interface UIProps {
  variant?: CalendarDayVariant;
  borderRadius?: CalendarDayBorderRadius;
}

export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, UIProps {}

function handleCalendarDayVariant(variant: CalendarDayVariant) {
  switch (variant) {
    case 'Past':
      return css`
        pointer-events: none;
        cursor: default;

        h1 {
          opacity: 0.5;
        }
      `;
    case 'PastSelected':
      return css`
        background-color: ${({ theme }) => theme.colors.primary};
        pointer-events: none;
        cursor: default;

        h1 {
          opacity: 0.3;
        }
      `;
    case 'Selected':
      return css`
        background-color: ${({ theme }) => theme.colors.primary};
        cursor: pointer;
      `;
    case 'Disabled':
      return css`
        background-color: ${({ theme }) => theme.colors.b3};
        pointer-events: none;
        cursor: default;

        h1 {
          opacity: 0.5;
        }
      `;
    case 'Default':
    default:
      return css`
        background-color: ${({ theme }) => theme.colors.bgDark};
        cursor: pointer;
      `;
  }
}

function handleCalendarDayBorderRadius(borderRadius: CalendarDayBorderRadius) {
  switch (borderRadius) {
    case 'TopRounding':
      return css`
        border-radius: 0.25rem 0.25rem 0 0;
      `;
    case 'LeftTopRounding':
      return css`
        border-radius: 0.25rem 0 0 0;
      `;
    case 'RightTopRounding':
      return css`
        border-radius: 0 0.25rem 0 0;
      `;
    case 'LeftBottomRounding':
      return css`
        border-radius: 0 0 0 0.25rem;
      `;
    case 'RightBottomRounding':
      return css`
        border-radius: 0 0 0.25rem 0;
      `;
    case 'BottomRounding':
      return css`
        border-radius: 0 0 0.25rem 0.25rem;
      `;
    case 'Default':
    default:
      return css``;
  }
}

export const Day = css`
  width: 5rem;
  height: 5rem;
  margin: -1px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
    width: calc(95vw / 7);
    height: calc(95vw / 7);
    max-width: 6rem;
    max-height: 6rem;
    `
      : ''};
`;

const StyledCalendarDay = styled.div<Required<UIProps>>`
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  user-select: none;
  ${(props) => handleCalendarDayVariant(props.variant)}
  ${(props) => handleCalendarDayBorderRadius(props.borderRadius)}

  :hover {
    h1 {
      color: ${({ theme }) => theme.colors.lTextMedium};
    }
  }

  ${Day};
`;

const StyledEmptyDay = styled.div`
  pointer-events: none;
  cursor: default;

  ${Day};
`;

const CalendarDay: React.FC<Props> = ({ variant = 'Default', borderRadius = 'Default', children, ref, ...props }) =>
  variant === 'Empty' ? (
    <StyledEmptyDay {...props} />
  ) : (
    <StyledCalendarDay
      {...props}
      variant={variant}
      borderRadius={borderRadius}
      data-testid={`Calendar Day variant-${variant} borderRadius-${borderRadius}`}
    >
      {children}
    </StyledCalendarDay>
  );

export default CalendarDay;
