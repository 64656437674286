/* eslint-disable import/prefer-default-export */

import { SetIsAdminForGroup } from 'graphql/generated/SetIsAdminForGroup';

export function mockedSetIsAdminForGroup(groupId: string): SetIsAdminForGroup {
  return {
    setIsAdminForGroup: groupId
  };
}

export const mockedSetIsAdminForGroupDataForMutation = mockedSetIsAdminForGroup('mockedVisitorGroupId1');
