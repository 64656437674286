import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Title from 'ui/atoms/Title/Title';
import Icon from 'ui/atoms/Icon/Icon';
import { Button, Divider, Typography } from '@material-ui/core';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const TitleWrapper = styled.div`
  position: absolute;
  top: 4rem;
  left: 8rem;
`;

const Wrapper = styled.div`
  position: absolute;
  margin: 10rem 8rem 4rem 8rem;
  width: calc(100vw - 16rem);
  height: calc(100vh - 13rem);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem 5rem;
  background-color: ${({ theme }) => theme.colors.bgLight};
`;

export interface Props {
  handleOnConnectButtonClick: () => void;
}

const ConnectEntraID: React.FC<Props> = ({ handleOnConnectButtonClick }) => (
  <>
    <BackgroundImage id="background" />
    <TitleWrapper>
      <Title />
    </TitleWrapper>
    <Wrapper>
      <Typography variant="h4" color="textPrimary">
        Connecting Microsoft EntraID
      </Typography>
      <Typography variant="body1" color="textPrimary">
        To connect your Microsoft EntraID environment with ATLAS, follow these steps:
      </Typography>
      <ComponentWrapper flexDirection="column" gap="1rem">
        <Divider />
        <ComponentWrapper
          flexDirection="row"
          gap="1.5rem"
          alignItems="center"
          padding="0.5rem 0"
          width="100%"
          justifyContent="space-between"
        >
          <ComponentWrapper alignItems="center" gap="2rem">
            <Icon name="EntraId" height={36} width={36} />
            <Typography color="textPrimary">Microsoft EntraID</Typography>
          </ComponentWrapper>
          <ComponentWrapper width="13.75rem" gap="0.5rem">
            <Button fullWidth variant="contained" size="large" color="primary" onClick={handleOnConnectButtonClick}>
              Connect
            </Button>
          </ComponentWrapper>
        </ComponentWrapper>
        <Divider />
      </ComponentWrapper>
    </Wrapper>
  </>
);

export default ConnectEntraID;
