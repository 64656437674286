/* eslint-disable import/prefer-default-export */

import { UnregisterMobileDeviceById } from 'graphql/generated/UnregisterMobileDeviceById';

export function mockedUnregisterMobileDeviceData(unregisterMobileDeviceById: string): UnregisterMobileDeviceById {
  return {
    unregisterMobileDeviceById
  };
}

export const mockedUnregisterMobileDeviceDataForMutation = mockedUnregisterMobileDeviceData(
  'mockedAutomatedTestsUIAdminVisitorId'
);
