import { useLazyQuery, useMutation, useReactiveVar, WatchQueryFetchPolicy } from '@apollo/client';
import {
  AssignAccessGrantsToDoors,
  AssignAccessGrantsToDoorsVariables
} from 'graphql/generated/AssignAccessGrantsToDoors';
import { GetVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenant';
import {
  UnassignAccessGrantsFromDoors,
  UnassignAccessGrantsFromDoorsVariables
} from 'graphql/generated/UnassignAccessGrantsFromDoors';
import {
  ASSIGN_ACCESS_GRANTS_TO_DOORS,
  SET_ACCESS_GRANTS_FOR_GROUPS,
  UNASSIGN_ACCESS_GRANTS_FROM_DOORS
} from 'graphql/mutations';
import { GET_VISITOR_GROUPS_FOR_TENANT } from 'graphql/queries';
import { useEffect } from 'react';
import {
  setAccessGrantsForGroupAction,
  setVisitorsGroupsAction,
  updateAccessGrantsAction
} from 'state/actions/visitorsGroups';
import { VisitorsGroupsState } from 'state/types';
import { visitorsGroupsVar } from 'state/vars';
import {
  SetAccessGrantsForGroups,
  SetAccessGrantsForGroupsVariables
} from 'graphql/generated/SetAccessGrantsForGroups';
import { BaseHookProps } from '../shared/types';

export const getVisitorGroupsIdsAssignedForDoor = (visitorsGroups: VisitorsGroupsState, doorId: string): string[] =>
  visitorsGroups
    .filter(
      (usersGroup) =>
        usersGroup.scheduledAccessGrants.some((accessGrant) => accessGrant.doors.some(({ id }) => id === doorId)) ||
        usersGroup.defaultAccessGrants.some((accessGrant) => accessGrant.doors.some(({ id }) => id === doorId))
    )
    .map(({ id }) => id);

export const getDefaultAccessGrantsIdsAssignedForDoor = (
  visitorsGroups: VisitorsGroupsState,
  doorId: string
): string[] =>
  visitorsGroups
    .filter(
      (usersGroup) =>
        usersGroup.defaultAccessGrants.some((accessGrant) => accessGrant.doors.some(({ id }) => id === doorId)) ||
        usersGroup.scheduledAccessGrants.some((accessGrant) => accessGrant.doors.some(({ id }) => id === doorId))
    )
    .map(({ defaultAccessGrants, scheduledAccessGrants }) =>
      defaultAccessGrants.length > 0 ? defaultAccessGrants[0].id : scheduledAccessGrants[0].id
    );

export const getDoorsIdsAssignedForVisitorGroup = (visitorsGroups: VisitorsGroupsState, visitorGroupId: string) => {
  const index = visitorsGroups.findIndex(({ id }) => id === visitorGroupId);
  const arrOfAllIds: string[] = [];
  visitorsGroups[index].defaultAccessGrants.forEach((accessGrant) =>
    accessGrant.doors.forEach((door) => arrOfAllIds.push(door.id))
  );
  visitorsGroups[index].scheduledAccessGrants.forEach((accessGrant) =>
    accessGrant.doors.forEach((door) => arrOfAllIds.push(door.id))
  );
  return Array.from(new Set(arrOfAllIds));
};

interface Props extends BaseHookProps {
  fetchPolicy?: WatchQueryFetchPolicy | undefined;
}

const useVisitorsGroups = ({ handleFetchError, fetchPolicy = 'cache-first' }: Props) => {
  const visitorsGroups = useReactiveVar(visitorsGroupsVar);

  const [getVisitorGroupsForTenant, { loading }] = useLazyQuery<GetVisitorGroupsForTenant>(
    GET_VISITOR_GROUPS_FOR_TENANT,
    {
      onCompleted: (data) => {
        setVisitorsGroupsAction(data.getVisitorGroupsForTenant.filter((group) => group.type !== 'ATLAS_INTERNAL'));
      },
      onError: () => {
        handleFetchError('Error while fetching visitor groups');
      },
      fetchPolicy
    }
  );

  const [assignAccessGrantsToDoorsMutation, { loading: assignAccessGrantsToDoorsMutationLoading }] = useMutation<
    AssignAccessGrantsToDoors,
    AssignAccessGrantsToDoorsVariables
  >(ASSIGN_ACCESS_GRANTS_TO_DOORS, {
    onCompleted: (data) => {
      data.assignAccessGrantsToDoors.forEach((item) => updateAccessGrantsAction(item));
    },
    onError: () => {
      handleFetchError('Error while adding access');
    }
  });
  const [unassignAccessGrantsFromDoorsMutation, { loading: unassignAccessGrantsFromDoorsMutationLoading }] =
    useMutation<UnassignAccessGrantsFromDoors, UnassignAccessGrantsFromDoorsVariables>(
      UNASSIGN_ACCESS_GRANTS_FROM_DOORS,
      {
        onCompleted: (data) => {
          data.unassignAccessGrantsFromDoors.forEach((item) => updateAccessGrantsAction(item));
        },
        onError: () => {
          handleFetchError('Error while deleting access');
        }
      }
    );
  const [setAccessGrantsForGroupsMutation, { loading: setAccessGrantsForGroupsMutationLoading }] = useMutation<
    SetAccessGrantsForGroups,
    SetAccessGrantsForGroupsVariables
  >(SET_ACCESS_GRANTS_FOR_GROUPS, {
    onCompleted: (data) => {
      setAccessGrantsForGroupAction(data.setAccessGrantsForGroups);
    },
    onError: () => {
      handleFetchError('Error while setting access grants for group');
    }
  });

  useEffect(() => {
    getVisitorGroupsForTenant();
  }, [getVisitorGroupsForTenant]);

  const assignAccessGrantsToDoors = async (doorIds: string[], accessGrantIds: string[]) => {
    const { data } = await assignAccessGrantsToDoorsMutation({
      variables: {
        doorIds,
        accessGrantIds
      }
    });
    return data;
  };

  const unassignAccessGrantsFromDoors = async (doorIds: string[], accessGrantIds: string[]) => {
    const { data } = await unassignAccessGrantsFromDoorsMutation({
      variables: {
        doorIds,
        accessGrantIds
      }
    });
    return data;
  };

  const setAccessGrantsForGroups = async (
    visitorGroupId: string,
    defaultAccessGrantIds: string[],
    scheduledAccessGrantIds: string[]
  ) => {
    const { data } = await setAccessGrantsForGroupsMutation({
      variables: {
        visitorGroupId,
        defaultAccessGrantIds,
        scheduledAccessGrantIds
      },
      // Here Apollo doesn't update cache automatically, we have to do it manually
      update(cache, { data: updatedVisitorGroup }) {
        if (updatedVisitorGroup?.setAccessGrantsForGroups) {
          const updatedVisitorGroups = visitorsGroups.map((group) =>
            group.id === updatedVisitorGroup?.setAccessGrantsForGroups.id
              ? updatedVisitorGroup?.setAccessGrantsForGroups
              : group
          );

          cache.writeQuery({
            query: GET_VISITOR_GROUPS_FOR_TENANT,
            data: {
              getVisitorGroupsForTenant: updatedVisitorGroups
            }
          });
        }
      }
    });
    return data;
  };

  const setIsScheduledAccessGrantsEnabled = async (visitorGroupId: string, isScheduledAccessGrantsEnabled: boolean) => {
    const index = visitorsGroups?.findIndex((group) => group.id === visitorGroupId);
    if (
      index !== -1 &&
      visitorsGroups &&
      isScheduledAccessGrantsEnabled !==
        Boolean(visitorsGroups[index].defaultAccessGrants.length < visitorsGroups[index].scheduledAccessGrants.length)
    ) {
      const data = await setAccessGrantsForGroups(
        visitorsGroups[index].id,
        visitorsGroups[index].scheduledAccessGrants.map((grant) => grant.id),
        visitorsGroups[index].defaultAccessGrants.map((grant) => grant.id)
      );
      return data;
    }
    return null;
  };

  return {
    visitorsGroups,
    assignAccessGrantsToDoors,
    unassignAccessGrantsFromDoors,
    setIsScheduledAccessGrantsEnabled,
    getDoorsIdsAssignedForVisitorGroup,
    getVisitorGroupsIdsAssignedForDoor,
    getDefaultAccessGrantsIdsAssignedForDoor,
    loading,
    setAccessGrantsForGroupsMutationLoading,
    assignAccessGrantsToDoorsMutationLoading,
    unassignAccessGrantsFromDoorsMutationLoading
  };
};

export default useVisitorsGroups;
