import React from 'react';
import Router from 'components/Router/Router';
import useMobileDetector from 'hooks/useMobileDetector';
import useAuth from 'hooks/usaAuth/useAuth';

const App: React.FC = () => {
  const { isUserAuthenticated, isLoading } = useAuth();
  useMobileDetector();

  return <Router isLoading={isLoading} isUserAuthenticated={isUserAuthenticated} />;
};

export default App;
