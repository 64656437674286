/* eslint-disable no-magic-numbers */
/* eslint-disable import/prefer-default-export */

import {
  GetTenantRegistrationInfo,
  GetTenantRegistrationInfo_getTenantRegistrationInfo
} from 'graphql/generated/GetTenantRegistrationInfo';

export function mockedGetTenantRegistrationInfoData(
  getTenantRegistrationInfo: GetTenantRegistrationInfo_getTenantRegistrationInfo
): GetTenantRegistrationInfo {
  return {
    getTenantRegistrationInfo
  };
}

export const mockedGetTenantRegistrationInfoDataForQuery = mockedGetTenantRegistrationInfoData({
  __typename: 'TenantRegistrationInfo',
  tenantId: 'mockedTenantId',
  entraAdminConsentUrl: 'mockedEntraAdminConsentUrl'
});
