/* eslint-disable import/prefer-default-export */

import {
  SetVisitorByIdWithRole,
  SetVisitorByIdWithRole_setVisitorById
} from 'graphql/generated/SetVisitorByIdWithRole';
import { createMockedVisitorWithVisitorGroupMembership } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorWithVisitorGroupMembership';

export function mockedSetVisitorWithRoleData(
  setVisitorById: SetVisitorByIdWithRole_setVisitorById
): SetVisitorByIdWithRole {
  return {
    setVisitorById
  };
}

export const mockedSetVisitorWithRoleDataForMutation = mockedSetVisitorWithRoleData(
  createMockedVisitorWithVisitorGroupMembership({
    id: 'mockedVisitorIdExternalUser6',
    name: 'mockedVisitorEmailExternalUser6Edited',
    email: 'mockedVisitorEmailExternalUser6'
  })
);
