import React from 'react';

const ArrowUp: React.FC = () => (
  <>
    <path
      d="M3.33366 10L8.00033 5.33333L12.667 10"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export default ArrowUp;
