import { Colors } from 'theme/theme';
import { Icons, Props as IconProps } from 'ui/atoms/Icon/Icon';

export interface LockIconProperties {
  name: Icons;
  color?: keyof Colors;
  stroke?: keyof Colors;
}

export const getLockIconProperties = (
  doorSerialNumber: string | null,
  color: keyof Colors = 'lTextHigh'
): Pick<IconProps, 'name' | 'color' | 'stroke'> => {
  // eslint-disable-next-line no-magic-numbers
  const firstTwoChars = doorSerialNumber?.slice(0, 2);
  switch (firstTwoChars) {
    case 'A3':
      return {
        name: 'Cylinder',
        stroke: color
      };
    case '7F':
      return {
        name: 'Handle',
        color
      };
    case '65':
    case '66':
      return {
        name: 'Reader',
        color
      };
    default:
      return {
        name: '' as Icons
      };
  }
};

export default { getLockIconProperties };
