/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import {
  GetAccessEventsForTenant_getAccessEventsForTenant_items,
  GetAccessEventsForTenant_getAccessEventsForTenant_items_door,
  GetAccessEventsForTenant_getAccessEventsForTenant_items_visitor
} from 'graphql/generated/GetAccessEventsForTenant';
import { MobileOpCode } from 'graphql/generated/globalTypes';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface Props {
  id: string;
  visitor: GetAccessEventsForTenant_getAccessEventsForTenant_items_visitor;
  door: GetAccessEventsForTenant_getAccessEventsForTenant_items_door;
  createdAt?: GraphQL_AWSDateTime;
  action?: MobileOpCode;
  reason?: string | null;
}

export function createMockedAccessEvents({
  id,
  visitor,
  door,
  createdAt = dayjs().utc().format(),
  action = MobileOpCode.ACCESS_GRANTED,
  reason = null
}: Props): GetAccessEventsForTenant_getAccessEventsForTenant_items {
  return {
    __typename: 'AccessEvent',
    id,
    createdAt,
    visitor,
    door,
    action,
    reason
  };
}
