import React from 'react';

const CoContractor: React.FC = () => (
  <>
    <path
      d="M15.9994 15.1794C19.1715 15.1794 21.743 12.6079 21.743 9.43585C21.743 6.26375 19.1715 3.69226 15.9994 3.69226C12.8274 3.69226 10.2559 6.26375 10.2559 9.43585C10.2559 12.6079 12.8274 15.1794 15.9994 15.1794Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33301 28.3077C5.33301 25.4787 6.45681 22.7656 8.4572 20.7652C10.4576 18.7648 13.1707 17.641 15.9997 17.641C18.8287 17.641 21.5418 18.7648 23.5421 20.7652C25.5425 22.7656 26.6663 25.4787 26.6663 28.3077"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8975 18.4615V19.282C11.8975 20.3701 12.3297 21.4136 13.0991 22.183C13.8685 22.9523 14.912 23.3846 16 23.3846C17.0881 23.3846 18.1316 22.9523 18.901 22.183C19.6704 21.4136 20.1026 20.3701 20.1026 19.282V18.4615"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.43555 19.8947V28.3077" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.5635 19.8947V28.3077" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.085 9.9829V4.02051" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.9141 9.9829V4.02051" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.61523 9.98291H23.3845" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </>
);

export default CoContractor;
