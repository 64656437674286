/* eslint-disable no-magic-numbers */
import React from 'react';
import styled, { css } from 'styled-components';
import { TypographyVariant } from '../Typography/Typography';

export interface Props {
  variant: TypographyVariant;
  id?: string;
}

export const calculateDetails = (height: number) => css`
  height: ${height}rem;
`;

export const handleVariantDetails = (variant: TypographyVariant) => {
  switch (variant) {
    case 'loginTitle':
      return calculateDetails(6.75);
    case 'title1':
      return calculateDetails(3);
    case 'title2':
      return calculateDetails(2.25);
    case 'title3':
      return calculateDetails(2.25);
    case 'title4':
      return calculateDetails(1.6875);
    case 'body1':
      return calculateDetails(1.6875);
    case 'body2':
      return calculateDetails(1.5);
    case 'body3':
      return calculateDetails(1.3125);
    case 'body4':
      return calculateDetails(1.125);
    case 'button':
      return calculateDetails(1.5);
    case 'super':
      return calculateDetails(0.9375);
    case 'clickable':
      return calculateDetails(1.3125);
    case 'label':
    case 'helper':
      return calculateDetails(0.75);
    case 'body':
    default:
      return calculateDetails(1.6875);
  }
};

const StyledTypographySkeleton = styled.div<Props>`
  width: 100%;
  border-radius: 0.5rem;
  cursor: progress;
  ${(props) => handleVariantDetails(props.variant)};

  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({ theme }) => theme.colors.b4};
    }
    100% {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const TypographySkeleton: React.FC<Props> = ({ children, variant, id }) => (
  <StyledTypographySkeleton id={id} variant={variant}>
    {children}
  </StyledTypographySkeleton>
);

export default TypographySkeleton;
