import { EditExternalUserForm } from 'components/Drawer/types';
import useDrawer from 'hooks/useDrawer/useDrawer';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EditExternalUserDrawer } from 'state/types';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import useModal from 'hooks/useModal/useModal';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useExternalUsers from 'hooks/useExternalUsers/useExternalUsers';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import dayjs from 'dayjs';
import EditExternalUser from 'ui/organisms/Drawer/variants/EditExternalUser';
import { OptionProps } from 'ui/molecules/Select/Select';
import { Role } from 'graphql/generated/globalTypes';
import useTranslation, { Translation } from 'hooks/useTranslation/useTranslation';

import {
  handleExpirationDateInputValidation,
  handleExpirationDateInputValue,
  handleNameInputValidation,
  handleSwitchIsExpirationDateOnChange
} from '../externalUserGroup/shared';
import { getRoleOptionProp, createRoleSelectOptions } from './shared';

const getInitialSelectValue = (role: Role, translation: Translation) => {
  const roleOptionProp = getRoleOptionProp(role, translation);
  if (roleOptionProp) return roleOptionProp;
  return null;
};

const getInitialSelectInputValue = (role: Role, translation: Translation) => {
  const initialSelectValue = getInitialSelectValue(role, translation);
  if (initialSelectValue) return initialSelectValue.label;
  return '-';
};

const EditExternalUserVariant: React.FC = () => {
  const { translation } = useTranslation();
  const { register, handleSubmit, setValue, control } = useForm<EditExternalUserForm>();
  const { drawerState, hideDrawer } = useDrawer<EditExternalUserDrawer>();
  const { showModal } = useModal();
  const [nameInputValue, setNameInputValue] = useState<string>();
  const [emailInputValue, setEmailInputValue] = useState<string>();
  const [groupIdValue, setGroupIdValue] = useState<string>();
  const [mobileDeviceStatus, setMobileDeviceStatus] = useState<boolean>();
  const [nameInputValidation, setNameInputValidation] = useState<string | undefined>(undefined);
  const [isExpirationDateEnabled, setIsExpirationDateEnabled] = useState<boolean>();
  const [expirationDateInputValue, setExpirationDateInputValue] = useState<string>();
  const [expirationDateTo, setExpirationDateTo] = useState<string>();
  const [expirationDateInputValidation, setExpirationDateInputValidation] = useState<string | undefined>(undefined);
  const [showValidation, setShowValidation] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    externalUsersGroups,
    loadingWithRole: externalUsersGroupsLoading,
    updateVisitorMemberByIdWithRole,
    updateVisitorMemberByIdWithRoleLoading
  } = useExternalUsers({
    handleFetchError
  });
  const [selectInputValue, setSelectInputValue] = useState<string>(
    drawerState?.contentValue?.role
      ? getInitialSelectInputValue(drawerState.contentValue.role as Role, translation)
      : '-'
  );
  const [selectValue, setSelectValue] = useState<null | OptionProps<Role | null>>(
    drawerState?.contentValue?.role ? getInitialSelectValue(drawerState.contentValue.role as Role, translation) : null
  );

  const getVisitorGroupExternalRefByGroupId = (id: string) =>
    externalUsersGroups.filter((group) => group.id === id)[0].externalRef;

  const onSubmit = async () => {
    setShowValidation(true);
    if (
      !externalUsersGroupsLoading &&
      !updateVisitorMemberByIdWithRoleLoading &&
      emailInputValue &&
      nameInputValue &&
      expirationDateInputValidation === undefined &&
      drawerState.contentValue.group &&
      nameInputValidation === undefined &&
      selectValue &&
      selectValue.value
    ) {
      await updateVisitorMemberByIdWithRole(
        drawerState.contentValue.id,
        nameInputValue,
        [
          {
            visitorGroupExternalRef: getVisitorGroupExternalRefByGroupId(drawerState.contentValue.group),
            role: selectValue.value
          }
        ],
        isExpirationDateEnabled ? expirationDateTo : undefined
      );
      hideDrawer();
    }
  };

  const handleDeleteExternalUser = () => {
    showModal({ type: 'deleteExternalUser' });
  };

  const handleSelectChange = (value: OptionProps<Role> | null) => {
    if (value) setSelectValue(value);
    else setSelectValue({ label: '', value: null });
  };

  useEffect(() => {
    if (drawerState && drawerState.contentValue) {
      if (nameInputValue === undefined) setNameInputValue(drawerState.contentValue.name);
      if (emailInputValue === undefined) setEmailInputValue(drawerState.contentValue.email || undefined);
      if (groupIdValue === undefined) setGroupIdValue(drawerState.contentValue.group);
      if (mobileDeviceStatus === undefined)
        setMobileDeviceStatus(
          drawerState.contentValue.mobileDevices.find((device) => device.role === 'ACCESS')?.isRegistered
        );
      if (isExpirationDateEnabled === undefined)
        setIsExpirationDateEnabled(Boolean(drawerState.contentValue.expirationDate));
      if (expirationDateInputValue === undefined)
        setExpirationDateInputValue(dayjs(drawerState.contentValue.expirationDate).format('DD-MM-YYYY HH:mm') || '-');
      if (expirationDateTo === undefined) setExpirationDateTo(drawerState.contentValue.expirationDate || undefined);
    }
  }, [
    drawerState,
    emailInputValue,
    expirationDateInputValue,
    expirationDateTo,
    groupIdValue,
    isExpirationDateEnabled,
    mobileDeviceStatus,
    nameInputValue
  ]);

  useEffect(() => {
    handleNameInputValidation(translation, setNameInputValidation, nameInputValue);
  }, [nameInputValue, translation]);

  useEffect(() => {
    handleExpirationDateInputValidation(
      translation,
      setExpirationDateInputValidation,
      isExpirationDateEnabled,
      expirationDateTo
    );
  }, [expirationDateTo, isExpirationDateEnabled, translation]);

  useEffect(() => {
    handleExpirationDateInputValue(setExpirationDateInputValue, expirationDateTo);
  }, [expirationDateTo]);

  useEffect(() => {
    if (drawerState && drawerState.contentValue && drawerState.contentValue.group !== groupIdValue) {
      setGroupIdValue(drawerState.contentValue.group);
    }
  }, [drawerState, groupIdValue]);

  const getGroupNameById = (id: undefined | string) => {
    if (id && externalUsersGroups.some((group) => group.id === id))
      return externalUsersGroups.filter((group) => group.id === id)[0].name;
    return undefined;
  };

  const handleOpenChangeGroupExternalUserModal = () => {
    if (nameInputValue && groupIdValue)
      showModal({
        type: 'changeGroupExternalUser',
        contentValue: { name: nameInputValue, currentVisitorGroupId: groupIdValue }
      });
  };

  const handleOpenResendInvitationModal = () => {
    if (emailInputValue)
      showModal({
        type: 'resendInvitation',
        contentValue: {
          visitorId: drawerState.contentValue.id,
          email: emailInputValue,
          mobileDevices: drawerState.contentValue.mobileDevices,
          type: 'ACCESS'
        }
      });
  };

  const handleExpirationDateInputOnClick = () => {
    if (isExpirationDateEnabled)
      showModal({
        type: 'expirationDate',
        contentValue: {
          expirationDateTo: expirationDateTo ? dayjs(expirationDateTo) : null,
          setExpirationDateTo
        }
      });
  };

  useEffect(() => {
    if (nameInputValue) setValue('name', nameInputValue);
    if (emailInputValue) setValue('email', emailInputValue);
    if (groupIdValue) setValue('group', groupIdValue);
  }, [emailInputValue, groupIdValue, nameInputValue, setValue]);
  return (
    <DrawerTemplate
      title={translation.edit_external_user}
      headerSideText={
        <ComponentWrapper id="drawer-delete-external-group-member">
          <Typography variant="clickable" color="dTextHigh">
            {translation.delete}
          </Typography>
          <Icon name="DeleteBin" color="dTextHigh" height="16" width="16" data-testid="svg-delete" />
        </ComponentWrapper>
      }
      headerSideTextOnClick={handleDeleteExternalUser}
      confirmButtonText={translation.save}
      closeButtonText={translation.cancel}
      id="drawer-edit-external-group-member"
      onSubmit={handleSubmit(onSubmit)}
      disableConfirmButton={showValidation && nameInputValidation !== undefined}
    >
      <EditExternalUser
        register={register}
        nameInputValue={nameInputValue}
        handleOpenChangeGroupExternalUserModal={handleOpenChangeGroupExternalUserModal}
        handleNameInputOnChange={setNameInputValue}
        nameInputValidation={nameInputValidation}
        emailInputValue={emailInputValue}
        status={mobileDeviceStatus}
        group={getGroupNameById(groupIdValue) || ''}
        showValidation={showValidation}
        isResendInvitationButtonVisible={!mobileDeviceStatus}
        handleOnResendInvitationClick={handleOpenResendInvitationModal}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
        inputExpirationDateToValue={expirationDateInputValue}
        handleExpirationDateInputOnClick={handleExpirationDateInputOnClick}
        handleSwitchIsExpirationDateOnChange={() =>
          handleSwitchIsExpirationDateOnChange(
            setIsExpirationDateEnabled,
            setExpirationDateTo,
            setExpirationDateInputValue,
            isExpirationDateEnabled,
            expirationDateTo
          )
        }
        switchIsExpirationDateEnabled={isExpirationDateEnabled}
        inputExpirationDateToValueValidation={expirationDateInputValidation}
        control={control}
        selectValue={selectValue}
        inputValue={selectInputValue}
        selectOption={createRoleSelectOptions(translation)}
        handleSelectChange={handleSelectChange}
        handleSelectInputChange={setSelectInputValue}
      />
    </DrawerTemplate>
  );
};
export default EditExternalUserVariant;
