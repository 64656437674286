import React from 'react';

const EntraID: React.FC = () => (
  <>
    <path
      id="Vector"
      d="M7.60352 28.0635C8.37952 28.5475 9.66952 29.0855 11.0335 29.0855C12.2755 29.0855 13.4295 28.7255 14.3855 28.1115C14.3855 28.1115 14.3875 28.1115 14.3895 28.1095L17.9995 25.8535V33.9995C17.4275 33.9995 16.8515 33.8435 16.3515 33.5315L7.60352 28.0635Z"
      fill="#225086"
    />
    <path
      id="Vector_2"
      d="M15.706 3.014L0.705993 19.934C-0.452007 21.242 -0.150007 23.218 1.35199 24.156C1.35199 24.156 6.90399 27.626 7.60399 28.064C8.37999 28.548 9.66999 29.086 11.034 29.086C12.276 29.086 13.43 28.726 14.386 28.112C14.386 28.112 14.388 28.112 14.39 28.11L18 25.854L9.27199 20.398L18.002 10.55V2C17.154 2 16.306 2.338 15.706 3.014Z"
      fill="#66DDFF"
    />
    <path
      id="Vector_3"
      d="M9.27148 20.3988L9.37548 20.4628L17.9995 25.8548H18.0015V10.5528L17.9995 10.5508L9.27148 20.3988Z"
      fill="#CBF8FF"
    />
    <path
      id="Vector_4"
      d="M34.648 24.156C36.15 23.218 36.452 21.242 35.294 19.934L25.452 8.832C24.658 8.462 23.768 8.25 22.826 8.25C20.976 8.25 19.322 9.048 18.222 10.302L18.004 10.548L26.732 20.396L18.002 25.852V33.998C18.576 33.998 19.148 33.842 19.648 33.53L34.648 24.154V24.156Z"
      fill="#074793"
    />
    <path
      id="Vector_5"
      d="M18.002 2V10.55L18.22 10.304C19.32 9.05 20.974 8.252 22.824 8.252C23.768 8.252 24.656 8.466 25.45 8.834L20.292 3.016C19.694 2.34 18.846 2.002 18 2.002L18.002 2Z"
      fill="#0294E4"
    />
    <path id="Vector_6" d="M26.73 20.3968L18.002 10.5508V25.8508L26.73 20.3968Z" fill="#96BCC2" />
  </>
);

export default EntraID;
