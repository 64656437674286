import useDrawer from 'hooks/useDrawer/useDrawer';
import React, { useCallback, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { AccessEventsFiltersFormDrawer, DoorsState } from 'state/types';
import styled from 'styled-components';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import AccessEventsFiltersUI from 'ui/organisms/Drawer/variants/AccessEventsFilters';
import useAccessEventsFilters from 'hooks/useAccessEventsFilters/useAccessEventsFilters';
import useModal from 'hooks/useModal/useModal';
import { OptionProps } from 'ui/molecules/Select/Select';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useDoors from 'hooks/useDoors/useDoors';
import useVisitorsGroupsMembers from 'hooks/useVisitorsGroupsMembers/useVisitorsGroupsMembers';
import { GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members } from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { AccessEventsDateRange, MobileOpCode } from 'graphql/generated/globalTypes';
import { isEqual, sortBy, uniqWith } from 'lodash';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import useTranslation, { Translation } from 'hooks/useTranslation/useTranslation';
import { createDataTimeSelectOptions, DateTimeSelectItem } from './dataTimeSelectOptions';
import { createActionSelectOptions } from './actionSelectOptions';
import { createReasonSelectOptions } from './reasonSelectOptions';

const DeleteWrapper = styled.div`
  display: flex;
`;

const getVisitorOptionProp = (
  members: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members[],
  visitorId: string
) =>
  ({
    value: visitorId,
    label: members.find((visitor) => visitor.id === visitorId)?.name
  } as OptionProps<null | string>);

const getLockOptionProp = (doors: DoorsState, doorIds: string) =>
  ({
    value: doorIds,
    label: doors.find((door) => door.id === doorIds)?.name
  } as OptionProps<null | string>);

const getActionOptionProp = (action: MobileOpCode, translation: Translation) =>
  ({
    value: action,
    label: createActionSelectOptions(translation).find((item) => item.value === action)?.label
  } as unknown as OptionProps<null | MobileOpCode>);

const getReasonOptionProp = (reason: string, translation: Translation) =>
  ({
    value: reason,
    label: createReasonSelectOptions(translation).find((item) => item.value === reason)?.label
  } as unknown as OptionProps<null | string>);

const getDateTimeSelectOptionProp = (
  options: Array<OptionProps<null | DateTimeSelectItem>>,
  dateRangeSelect: DateTimeSelectItem | undefined,
  dateRange: AccessEventsDateRange | null | undefined
) => {
  if (dateRangeSelect) {
    const selectOption = options.find(
      (option) => option.value?.value === dateRangeSelect.value && option.value.unit === dateRangeSelect.unit
    );
    if (selectOption) return selectOption;
  }
  if (dateRange && dateRange.rangeEnd && dateRange.rangeStart) return options[0];
  return null;
};

const AccessEventsFilters: React.FC = () => {
  const { translation } = useTranslation();
  const dataTimeSelectOptions = createDataTimeSelectOptions(translation);
  const { register, handleSubmit, control } = useForm<FieldValues>();
  const { hideDrawer } = useDrawer<AccessEventsFiltersFormDrawer>();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { doors, loading: doorsLoading } = useDoors({ handleFetchError });
  const { visitorsGroupsMembers, loading: visitorGroupsLoading } = useVisitorsGroupsMembers({ handleFetchError });
  const getVisitorGroupsMembers = useCallback(
    () =>
      sortBy(uniqWith(visitorsGroupsMembers.map((group) => group.members).flat(), isEqual), (member) =>
        member.name.toLowerCase()
      ),
    [visitorsGroupsMembers]
  );
  const {
    accessEventFilters,
    addFilter,
    addDataTimeAccessEventFilter,
    removeDateTimeFilter,
    removeLockFilter,
    removeVisitorFilter,
    removeActionFilter,
    removeReasonFilter,
    applyFilters,
    isFilterApply
  } = useAccessEventsFilters();
  const [dataTimeSelectValue, setDataTimeSelectValue] = useState<null | OptionProps<null | DateTimeSelectItem>>(
    getDateTimeSelectOptionProp(
      dataTimeSelectOptions,
      accessEventFilters.appliedFilters.dataTimeSelectedOption,
      accessEventFilters.filters.dateRange
    )
  );
  const [dataTimeInputValue, setDataTimeInputValue] = useState<undefined | string>(dataTimeSelectValue?.label || '');
  const [visitorSelectValue, setVisitorSelectValue] = useState<null | OptionProps<null | string>>(
    accessEventFilters.appliedFilters.visitorIds
      ? getVisitorOptionProp(getVisitorGroupsMembers(), accessEventFilters.appliedFilters.visitorIds[0])
      : null
  );
  const [visitorInputValue, setVisitorInputValue] = useState<undefined | string>(visitorSelectValue?.label || '');
  const [lockSelectValue, setLockSelectValue] = useState<null | OptionProps<null | string>>(
    accessEventFilters.appliedFilters.doorIds
      ? getLockOptionProp(doors, accessEventFilters.appliedFilters.doorIds[0])
      : null
  );
  const [lockInputValue, setLockInputValue] = useState<undefined | string>(lockSelectValue?.label || '');

  const [actionSelectValue, setActionSelectValue] = useState<null | OptionProps<null | MobileOpCode>>(
    accessEventFilters.appliedFilters.actions && accessEventFilters.appliedFilters.actions[0]
      ? getActionOptionProp(accessEventFilters.appliedFilters.actions[0], translation)
      : null
  );
  const [actionInputValue, setActionInputValue] = useState<undefined | string>(actionSelectValue?.label || '');

  const [reasonSelectValue, setReasonSelectValue] = useState<null | OptionProps<null | string>>(
    accessEventFilters.appliedFilters.reason
      ? getReasonOptionProp(accessEventFilters.appliedFilters.reason, translation)
      : null
  );
  const [reasonInputValue, setReasonInputValue] = useState<undefined | string>(reasonSelectValue?.label || '');

  const { showModal } = useModal();

  const handleDataTimeSelectChange = (dateTime: null | OptionProps<null | DateTimeSelectItem>) => {
    if (dateTime) {
      if (dateTime.label === dataTimeSelectOptions[0].label) showModal({ type: 'dataTimePicker' });
      else if (dateTime.value) addDataTimeAccessEventFilter(dateTime.value);
      setDataTimeSelectValue(dateTime);
    } else {
      setDataTimeSelectValue(null);
      removeDateTimeFilter();
    }
  };

  const handleVisitorSelectChange = (visitor: null | OptionProps<null | string>) => {
    if (visitor && visitor.value) {
      addFilter({
        visitorIds: [visitor.value]
      });
      setVisitorSelectValue(visitor);
    } else {
      setVisitorSelectValue(null);
      removeVisitorFilter();
    }
  };

  const handleLockSelectChange = (lock: null | OptionProps<null | string>) => {
    if (lock && lock.value) {
      addFilter({
        doorIds: [lock.value]
      });
      setLockSelectValue(lock);
    } else {
      setLockSelectValue(null);
      removeLockFilter();
    }
  };

  const handleActionSelectChange = (action: null | OptionProps<null | MobileOpCode>) => {
    if (action && action.value) {
      addFilter({
        actions: [action.value]
      });
      setActionSelectValue(action);
    } else {
      setActionSelectValue(null);
      removeActionFilter();
    }
  };

  const handleReasonSelectChange = (reason: null | OptionProps<null | string>) => {
    if (reason && reason.value) {
      addFilter({
        reason: reason.value
      });
      setReasonSelectValue(reason);
    } else {
      setReasonSelectValue(null);
      removeReasonFilter();
    }
  };
  const handleClearFilters = () => {
    setDataTimeSelectValue(null);
    setDataTimeInputValue('');
    removeDateTimeFilter();

    setVisitorSelectValue(null);
    setVisitorInputValue('');
    removeVisitorFilter();

    setLockSelectValue(null);
    setLockInputValue('');
    removeLockFilter();

    setActionSelectValue(null);
    setActionInputValue('');
    removeActionFilter();

    setReasonSelectValue(null);
    setReasonInputValue('');
    removeReasonFilter();
  };

  const onSubmit = async () => {
    applyFilters();
    hideDrawer();
  };

  const handleCloseDrawer = () => {
    removeDateTimeFilter();
    removeLockFilter();
    removeVisitorFilter();
    removeActionFilter();
    removeReasonFilter();
  };

  return (
    <DrawerTemplate
      title={translation.filters}
      headerSideText={
        <DeleteWrapper id="drawer-clear-access-events-filters">
          <Typography variant="clickable" color="dTextHigh">
            {translation.clear_filter}
          </Typography>
          <Icon name="DeleteBin" color="dTextHigh" height="16" width="16" data-testid="svg-delete" />
        </DeleteWrapper>
      }
      headerSideTextOnClick={handleClearFilters}
      confirmButtonText={translation.apply_filter}
      disableConfirmButton={
        !isFilterApply() &&
        !(
          Boolean(
            !(
              typeof accessEventFilters.filters.dataTimeSelectedOption === 'undefined' ||
              accessEventFilters.filters.dataTimeSelectedOption === null
            )
          ) ||
          Boolean(
            !(
              typeof accessEventFilters.filters.dateRange === 'undefined' ||
              accessEventFilters.filters.dateRange === null
            )
          ) ||
          Boolean(accessEventFilters.filters.doorIds) ||
          Boolean(accessEventFilters.filters.visitorIds) ||
          Boolean(accessEventFilters.filters.actions) ||
          Boolean(accessEventFilters.filters.reason)
        )
      }
      closeButtonText={translation.close}
      handleCloseDrawer={handleCloseDrawer}
      id="drawer-edit-access-events-filters"
      onSubmit={handleSubmit(onSubmit)}
    >
      <AccessEventsFiltersUI
        register={register}
        control={control}
        dataTimeSelectValue={dataTimeSelectValue}
        dataTimeSelectOptions={dataTimeSelectOptions}
        dataTimeInputValue={dataTimeInputValue}
        handleDataTimeInputChange={setDataTimeInputValue}
        handleDataTimeSelectChange={handleDataTimeSelectChange}
        visitorSelectOptions={
          visitorGroupsLoading
            ? []
            : getVisitorGroupsMembers().map((member) => ({ value: member.id, label: member.name }))
        }
        visitorSelectValue={visitorSelectValue}
        handleVisitorSelectChange={handleVisitorSelectChange}
        visitorInputValue={visitorInputValue}
        handleVisitorInputChange={setVisitorInputValue}
        lockSelectOptions={doorsLoading ? [] : doors.map((door) => ({ value: door.id, label: door.name }))}
        lockSelectValue={lockSelectValue}
        handleLockSelectChange={handleLockSelectChange}
        lockInputValue={lockInputValue}
        handleLockInputChange={setLockInputValue}
        actionSelectOptions={createActionSelectOptions(translation)}
        actionSelectValue={actionSelectValue}
        handleActionSelectChange={handleActionSelectChange}
        actionInputValue={actionInputValue}
        handleActionInputChange={setActionInputValue}
        reasonSelectOptions={createReasonSelectOptions(translation)}
        reasonSelectValue={reasonSelectValue}
        handleReasonSelectChange={handleReasonSelectChange}
        reasonInputValue={reasonInputValue}
        handleReasonInputChange={setReasonInputValue}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
      />
    </DrawerTemplate>
  );
};
export default AccessEventsFilters;
