import React from 'react';
import styled from 'styled-components';

type Variant = 'light' | 'dark';

export interface Props {
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  variant?: Variant;
  id?: string;
  inputId?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 1rem;
  cursor: pointer;
`;

const StyledSpan = styled.span<Props>`
  position: absolute;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme, variant }) => (variant === 'light' ? theme.colors.bgLight : theme.colors.bgDark)};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 2.125rem;

  :before {
    position: absolute;
    content: '';
    height: 0.625rem;
    width: 0.625rem;
    left: 0.1875rem;
    bottom: 0.1875rem;
    background-color: ${({ theme, variant }) => (variant === 'light' ? theme.colors.bgDark : theme.colors.bgLight)};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  :checked + ${StyledSpan} {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  :checked + ${StyledSpan}:before {
    -webkit-transform: translateX(1rem);
    -ms-transform: translateX(1rem);
    transform: translateX(1rem);
  }
`;

const Switch: React.FC<Props> = ({
  checked,
  defaultChecked,
  disabled = false,
  variant = 'light',
  id,
  inputId,
  onClick,
  onKeyDown
}) => (
  <StyledLabel>
    <StyledInput
      onClick={onClick}
      type="checkbox"
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      id={inputId}
      onKeyDown={onKeyDown}
    />
    <StyledSpan disabled={disabled} variant={variant} id={id} />
  </StyledLabel>
);

export default Switch;
