/* eslint-disable import/prefer-default-export */

import { DeleteOfficeDay } from 'graphql/generated/DeleteOfficeDay';

export function mockedDeleteOfficeDayData(deleteOfficeDay: GraphQL_AWSDate): DeleteOfficeDay {
  return {
    deleteOfficeDay
  };
}

export const mockedDeleteOfficeDayDataForMutation = mockedDeleteOfficeDayData('mockedVisitId0');
