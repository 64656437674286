import dayjs from 'dayjs';
// import { VISIT_START_TIME, VISIT_END_TIME } from 'hooks/useOfficeDays/useOfficeDays';
import { getDateWithFormat } from 'utils/DateFormat/DateFormat.util';

/* eslint-disable no-magic-numbers */
export const firstDate = new Date(Date.UTC(2020, 2, 5, 8, 0, 0, 0));
export const TODAY_START = dayjs(firstDate).hour(7);
export const TODAY_END = dayjs(firstDate).hour(22);
export const VISIT_TO_ADD_DATE_START = getDateWithFormat('AWSDateTime', 2020, 2, 5);
export const VISIT_TO_ADD_DATE_END = getDateWithFormat('AWSDateTime', 2020, 2, 5);
