import React from 'react';
import styled from 'styled-components';

export interface Props {
  id?: string;
}

const StyledLabel = styled.label<Partial<Props>>`
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 1rem;
  cursor: progress;
`;

const StyledSpan = styled.span<Partial<Props>>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2.125rem;

  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({ theme }) => theme.colors.b4};
    }
    100% {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const SwitchSkeleton: React.FC<Props> = ({ id }) => (
  <StyledLabel>
    <StyledSpan id={id} />
  </StyledLabel>
);

export default SwitchSkeleton;
