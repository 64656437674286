/* eslint-disable import/prefer-default-export */

import { onRegistrationTokenSavedById_onRegistrationTokenSavedById } from 'graphql/generated/onRegistrationTokenSavedById';

interface Props {
  visitorId?: string;
  registrationToken?: string;
  mobileDeviceId?: string;
}

export function createMockedSubscriptionRegistrationTokenSaved({
  visitorId = 'mockedBobVisitorId',
  registrationToken = '',
  mobileDeviceId = 'mockedMobileDevice'
}: Props): onRegistrationTokenSavedById_onRegistrationTokenSavedById {
  return {
    __typename: 'SubscriptionRegistrationTokenSavedById',
    visitorId,
    registrationToken,
    mobileDeviceId
  };
}
