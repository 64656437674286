/* eslint-disable no-magic-numbers */
import React from 'react';
import styled from 'styled-components';
import { Colors } from 'theme/theme';
import CalendarDay, { CalendarDayBorderRadius, CalendarDayVariant, Day } from 'ui/atoms/CalendarDay/CalendarDay';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { kebabCase } from 'lodash';
import Typography, { TypographyVariant } from '../../atoms/Typography/Typography';
import CalendarArrow from '../CalendarArrow/CalendarArrow';

const StyledHeaderWrapper = styled.div`
  width: 35rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.125rem;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
    width: auto;
    margin-bottom: 1rem;
    `
      : ''};
`;

const StyledCalendarWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 35rem;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
    width: calc(95vw - 14px);
    max-width: calc(42rem - 14px);
    `
      : ''};
`;

const StyledNameDaysWrapper = styled.div`
  ${Day};
`;

const StyledEmptySpaceAroundArrow = styled.div`
  width: 3.375rem;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
      width: 1.875rem;
    `
      : ''};
`;

export interface Props {
  monthNameAndYear: string;
  nameOfDays: string[];
  arrayOfDays: number[];
  isNextMonthShown: boolean;
  isMobile: boolean;
  showNextMonth: () => void;
  showPreviousMonth: () => void;
  handleTextColor: (day: number) => keyof Colors;
  handleDayVariant: (day: number) => CalendarDayVariant;
  handleDayBorderRadius: (day: number) => CalendarDayBorderRadius;
  handleFontSize: (day: number) => TypographyVariant;
  onSelectDate?: (day: number) => void;
}

const Calendar: React.FC<Props> = ({
  monthNameAndYear,
  nameOfDays,
  arrayOfDays,
  isNextMonthShown,
  isMobile,
  showNextMonth,
  showPreviousMonth,
  handleTextColor,
  handleDayVariant,
  handleDayBorderRadius,
  handleFontSize,
  onSelectDate
}) => (
  <>
    <StyledHeaderWrapper>
      {!isNextMonthShown ? (
        <CalendarArrow isMobile={isMobile} isNextMonthShown={!isNextMonthShown} onArrowClick={showPreviousMonth} />
      ) : (
        <StyledEmptySpaceAroundArrow />
      )}
      <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center" id="calendar-header">
        <Typography variant={isMobile ? 'title2' : 'title1'} data-testid="Calendar Month Name And Year">
          {monthNameAndYear}
        </Typography>
      </ComponentWrapper>
      {isNextMonthShown ? (
        <CalendarArrow isMobile={isMobile} isNextMonthShown={!isNextMonthShown} onArrowClick={showNextMonth} />
      ) : (
        <StyledEmptySpaceAroundArrow />
      )}
    </StyledHeaderWrapper>
    <StyledCalendarWrapper id="calendar" data-testid="calendar">
      {nameOfDays.map((day) => (
        <StyledNameDaysWrapper key={day}>
          <Typography variant={isMobile ? 'title2' : 'title1'} data-testid="Calendar WeekDay Name">
            {day}
          </Typography>
        </StyledNameDaysWrapper>
      ))}
      {arrayOfDays.map((day, index) => (
        <CalendarDay
          key={day === undefined ? `empty-calendarDay-${index}` : `calendarDay-${day}`}
          variant={handleDayVariant(day)}
          onClick={() => onSelectDate && onSelectDate(day)}
          borderRadius={handleDayBorderRadius(day)}
          id={kebabCase(`calendar-${monthNameAndYear}-${day}`)}
        >
          <Typography variant={handleFontSize(day)} color={handleTextColor(day)}>
            {day < 10 ? `0${day}` : day}
          </Typography>
        </CalendarDay>
      ))}
    </StyledCalendarWrapper>
  </>
);
export default Calendar;
