import React from 'react';

const CalendarCheck: React.FC = () => (
  <>
    <path d="M4.41357 1.34158V4.80238C4.41357 5.43118 4.92237 5.93998 5.55117 5.93998C6.17997 5.93998 6.68877 5.43118 6.68877 4.80238V1.34158C6.68877 0.71278 6.17997 0.203979 5.55117 0.203979C4.92237 0.203979 4.41357 0.71278 4.41357 1.34158Z" />
    <path d="M17.5271 1.35354V4.81434C17.5271 5.44314 18.0359 5.95194 18.6647 5.95194C19.2935 5.95194 19.8023 5.44314 19.8023 4.81434V1.35354C19.8023 0.724742 19.2935 0.215942 18.6647 0.215942C18.0359 0.215942 17.5271 0.724742 17.5271 1.35354Z" />
    <path d="M7.7832 2.172H16.4184V3.6144H7.7832V2.172Z" />
    <path d="M21.0408 2.172V3.612H21.9384V22.3536H2.06159V3.6144H3.16079V2.1744H0.23999V23.7984H23.76V2.1744H21.0408V2.172Z" />
    <path d="M11.7144 16.692C12.1536 17.1048 12.1752 17.7936 11.7624 18.2328C11.3496 18.672 10.6608 18.6936 10.2216 18.2808L6.39603 14.6856C5.95683 14.2728 5.93523 13.584 6.34803 13.1448C6.76083 12.7056 7.44964 12.684 7.88884 13.0968L11.7144 16.692Z" />
    <path d="M12.0888 17.7408C11.6832 18.2736 10.9824 18.4248 10.524 18.0768C10.0656 17.7288 10.0224 17.0136 10.428 16.4808L16.2096 8.86323C16.6128 8.33043 17.316 8.17923 17.7744 8.52723C18.2328 8.87523 18.276 9.59043 17.8728 10.1256L12.0888 17.7408Z" />
  </>
);

export default CalendarCheck;
