import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Colors } from 'theme/theme';

export type CheckboxType = 'dark' | 'light';

const handleBorderColor = (
  checkboxType: CheckboxType,
  disabled: boolean = false,
  checked: boolean = false,
  hover: boolean = false,
  focus: boolean = false,
  indeterminate: boolean = false
): keyof Colors => {
  if (checkboxType === 'dark') {
    if (hover) return 'dTextHigh';
    if (focus && checked) return 'primary';
    if (focus && indeterminate) return 'primary';
    if (focus) return 'primary';
    if (checked) return 'dTextHigh';
    if (indeterminate) return 'dTextHigh';
    if (disabled) return 'dTextHigh';
    return 'dTextHigh';
  }
  if (hover) return 'lTextHigh';
  if (focus && checked) return 'primary';
  if (focus && indeterminate) return 'primary';
  if (focus) return 'primary';
  if (checked) return 'lTextHigh';
  if (indeterminate) return 'lTextHigh';
  if (disabled) return 'lTextHigh';
  return 'lTextHigh';
};

const handleBackgroundColor = (
  checkboxType: CheckboxType,
  disabled: boolean = false,
  checked: boolean = false,
  hover: boolean = false,
  focus: boolean = false,
  indeterminate: boolean = false
): keyof Colors => {
  if (hover) return 'transparent';
  if (focus && checked) return 'primary';
  if (focus && indeterminate) return 'primary';
  if (focus) return 'transparent';
  if (checked) return 'primary';
  if (indeterminate) return 'primary';
  if (disabled) return 'b6';
  return 'transparent';
};

const handleIconColor = (
  checkboxType: CheckboxType,
  disabled: boolean = false,
  checked: boolean = false,
  hover: boolean = false,
  focus: boolean = false,
  indeterminate: boolean = false
): keyof Colors => {
  if (checkboxType === 'dark') {
    if (hover) return 'transparent';
    if (focus && checked) return 'lTextHigh';
    if (focus && indeterminate) return 'transparent';
    if (focus) return 'transparent';
    if (checked) return 'dTextHigh';
    if (indeterminate) return 'dTextHigh';
    if (disabled) return 'dTextHigh';
    return 'transparent';
  }
  if (hover) return 'transparent';
  if (focus && checked) return 'lTextHigh';
  if (focus && indeterminate) return 'transparent';
  if (focus) return 'transparent';
  if (checked) return 'dTextHigh';
  if (indeterminate) return 'dTextHigh';
  if (disabled) return 'dTextHigh';
  return 'transparent';
};
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const Icon = styled.svg<Pick<Props, 'checkboxType' | 'disabled' | 'checked'>>`
  fill: none;
  stroke: ${({ theme, checkboxType, disabled, checked }) =>
    theme.colors[handleIconColor(checkboxType, disabled, checked)]};
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })<Props>`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<Pick<Props, 'checkboxType' | 'disabled' | 'checked'>>`
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: transparent;
  border-radius: 0.25rem;
  border: 1px solid
    ${({ theme, checkboxType, disabled, checked }) => theme.colors[handleBorderColor(checkboxType, disabled, checked)]};
  background-color: ${({ theme, checkboxType, disabled, checked }) =>
    theme.colors[handleBackgroundColor(checkboxType, disabled, checked)]};
  transition: all 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  checkboxType: CheckboxType;
}

const Checkbox = forwardRef<HTMLInputElement | null, Props>(({ children, ...props }, ref) => (
  <CheckboxContainer onClick={props.onClick}>
    <HiddenCheckbox {...props} type="checkbox" ref={null} />
    <StyledCheckbox checkboxType={props.checkboxType} disabled={props.disabled} checked={props.checked} ref={ref}>
      <Icon viewBox="0 0 24 24" checkboxType={props.checkboxType} disabled={props.disabled} checked={props.checked}>
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
));

export default Checkbox;
