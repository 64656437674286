/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants_accessSchedule_weekDays,
  GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule_weekDays
} from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';
import { DayName } from 'hooks/useSchedules/useSchedules';

interface Props {
  dayName: DayName;
  from: string;
  to: string;
}

export function createMockedAccessGrantWeekScheduleDay({
  dayName,
  from,
  to
}: Props):
  | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_scheduledAccessGrants_accessSchedule_weekDays
  | GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant_defaultAccessGrants_accessSchedule_weekDays {
  return {
    __typename: 'WeekScheduleDay',
    dayName,
    from,
    to
  };
}
