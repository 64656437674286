/* eslint-disable import/prefer-default-export */

import {
  GetVisitorGroupsForTenantWithIsAdminGroup,
  GetVisitorGroupsForTenantWithIsAdminGroup_getVisitorGroupsForTenant
} from 'graphql/generated/GetVisitorGroupsForTenantWithIsAdminGroup';
import { createMockedVisitorGroupWithIsAdminGroup } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithIsAdminGroup';

export function getVisitorGroupsForTenantWithIsAdminGroupDataForQuery(
  getVisitorGroupsForTenant: Array<GetVisitorGroupsForTenantWithIsAdminGroup_getVisitorGroupsForTenant>
): GetVisitorGroupsForTenantWithIsAdminGroup {
  return {
    getVisitorGroupsForTenant
  };
}

export const mockedGetVisitorGroupsForTenantWithIsAdminGroupDataForQuery =
  getVisitorGroupsForTenantWithIsAdminGroupDataForQuery([
    createMockedVisitorGroupWithIsAdminGroup({
      id: 'mockedVisitorGroupId1',
      name: 'noAdminGroup',
      isAdminGroup: false,
      externalRef: 'mockedVisitorGroupExternalRef1'
    }),
    createMockedVisitorGroupWithIsAdminGroup({
      id: 'mockedVisitorGroupId2',
      name: 'adminGroup',
      isAdminGroup: true,
      externalRef: 'mockedVisitorGroupExternalRef2'
    })
  ]);

export const mockedGetVisitorGroupsForTenantWithIsAdminGroupDataAdmin =
  getVisitorGroupsForTenantWithIsAdminGroupDataForQuery([
    createMockedVisitorGroupWithIsAdminGroup({
      id: 'mockedIdAdmins',
      name: 'admins',
      externalRef: 'mockedExternalRefAdmins',
      isAdminGroup: true
    }),
    createMockedVisitorGroupWithIsAdminGroup({
      id: 'mockedIdEmployees',
      name: 'employees',
      externalRef: 'mockedExternalRefEmployees',
      isAdminGroup: true
    }),
    createMockedVisitorGroupWithIsAdminGroup({
      id: 'mockedIdVisitor_group_1',
      name: 'visitor_group_1',
      externalRef: 'mockedExternalRefVisitor_group_1',
      isAdminGroup: true
    }),
    createMockedVisitorGroupWithIsAdminGroup({
      id: 'mockedIdVisitor_group_2',
      name: 'visitor_group_2',
      externalRef: 'mockedExternalRefVisitor_group_2',
      isAdminGroup: true
    }),
    createMockedVisitorGroupWithIsAdminGroup({
      id: 'mockedIdVisitor_group_3',
      name: 'visitor_group_3',
      externalRef: 'mockedExternalRefVisitor_group_3',
      isAdminGroup: true
    })
  ]);
