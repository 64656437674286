/* eslint-disable import/prefer-default-export */
import { MobileOpCode } from 'graphql/generated/globalTypes';
import { Translation } from 'hooks/useTranslation/useTranslation';
import { OptionProps } from 'ui/molecules/Select/Select';

export const createActionSelectOptions = (translation: Translation): Array<OptionProps<null | MobileOpCode>> => [
  {
    value: MobileOpCode.ACCESS_GRANTED,
    label: translation.access_granted
  },
  {
    value: MobileOpCode.ACCESS_DENIED,
    label: translation.access_denied
  }
];
