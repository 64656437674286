/* eslint-disable import/prefer-default-export */

import { DeleteDoorById } from 'graphql/generated/DeleteDoorById';

export function mockedDeleteDoorByIdData(deleteDoorById: string | null): DeleteDoorById {
  return {
    deleteDoorById
  };
}

export const mockedDeleteDoorByIdDataForMutation = mockedDeleteDoorByIdData('mockedDoorId0');
export const mockedDeleteDoorByIdDataForMutation1 = mockedDeleteDoorByIdData('mockedDoorId1');
