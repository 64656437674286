import React from 'react';

const ADSyncedUser: React.FC = () => (
  <>
    <path
      d="M16.7169 16.0326C19.9039 16.0326 22.4875 13.449 22.4875 10.262C22.4875 7.07497 19.9039 4.49139 16.7169 4.49139C13.5299 4.49139 10.9463 7.07497 10.9463 10.262C10.9463 13.449 13.5299 16.0326 16.7169 16.0326Z"
      strokeWidth="1.64874"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 29.2225C6 26.3802 7.12909 23.6543 9.13888 21.6446C11.1487 19.6348 13.8745 18.5057 16.7168 18.5057C19.5591 18.5057 22.2849 19.6348 24.2947 21.6446C26.3045 23.6543 27.4336 26.3802 27.4336 29.2225"
      strokeWidth="1.64874"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export default ADSyncedUser;
