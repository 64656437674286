/* eslint-disable import/prefer-default-export */
import { TabsType } from 'components/Tabs/Tabs';
import { Translation } from 'hooks/useTranslation/useTranslation';

export const createAccessTabs = (translation: Translation): TabsType[] => [
  {
    text: translation.access_profiles,
    location: '/access/access-profiles'
  },
  {
    text: translation.schedules,
    location: '/access/schedules'
  },
  {
    text: translation.access_settings,
    location: '/access/access-settings'
  }
];
