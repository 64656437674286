import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { InteractionStatus } from '@azure/msal-browser';
import { authenticationFailAction, authenticationSuccessAction } from 'state/actions/auth';
import { authVar } from 'state/vars';
import { GET_CURRENT_HOST } from 'graphql/queries';
import { GetCurrentHost, GetCurrentHost_getCurrentHost_mobileDevices } from 'graphql/generated/GetCurrentHost';
import { ON_MOBILE_DEVICE_REGISTERED_BY_ID, ON_REGISTRATION_TOKEN_SAVED_BY_ID } from 'graphql/subscriptions';
import {
  onMobileDeviceRegisteredById,
  onMobileDeviceRegisteredByIdVariables
} from 'graphql/generated/onMobileDeviceRegisteredById';
import {
  onRegistrationTokenSavedById,
  onRegistrationTokenSavedByIdVariables
} from 'graphql/generated/onRegistrationTokenSavedById';

const useAuth = () => {
  const { accounts, inProgress } = useMsal();
  const { user, loading } = useReactiveVar(authVar);
  const [loggedAccount] = accounts;
  const [requestedHostEmail, setRequestedHostEmail] = useState('');
  const [isHostQuerySent, setIsHostQuerySent] = useState(false);

  const [getCurrentHost, { error: hostError, data, subscribeToMore }] = useLazyQuery<GetCurrentHost>(GET_CURRENT_HOST);
  const hostEmail = data?.getCurrentHost.email;
  const hostID = data?.getCurrentHost.id;
  const hostIsAdmin = data?.getCurrentHost.isAdmin || false;
  const hostIsAccessByScheduleEnabled = data?.getCurrentHost.isAccessByScheduleEnabled || false;
  const hostMobileDevice = data?.getCurrentHost.mobileDevices;

  if (requestedHostEmail && !isHostQuerySent) {
    setIsHostQuerySent(true);
    // This request is sending 2 times because of bug in @apollo/client library
    getCurrentHost();
  }

  useEffect(() => {
    if (loggedAccount) {
      const { username: email, name } = loggedAccount;

      // GUARD - User which doesn't exist in DB doesn't have access
      setRequestedHostEmail(email);
      if (hostEmail && hostID) {
        authenticationSuccessAction({
          email,
          id: hostID,
          name: name || '',
          isAdmin: hostIsAdmin,
          isAccessByScheduleEnabled: hostIsAccessByScheduleEnabled,
          mobileDevices: hostMobileDevice
        });
      }
      if (hostError) {
        authenticationFailAction();
      }
    } else if (inProgress === InteractionStatus.None || undefined) {
      authenticationFailAction();
    }

    if (subscribeToMore && hostID) {
      subscribeToMore<onMobileDeviceRegisteredById, onMobileDeviceRegisteredByIdVariables>({
        document: ON_MOBILE_DEVICE_REGISTERED_BY_ID,
        variables: {
          visitorId: hostID
        },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        updateQuery: (prev, { subscriptionData: { data } }) => {
          if (!data) {
            return prev;
          }
          const newData = data.onMobileDeviceRegisteredById!;
          return {
            getCurrentHost: {
              ...prev.getCurrentHost,
              mobileDevices: [
                ...prev.getCurrentHost.mobileDevices.map((device) =>
                  device.id === newData.mobileDeviceId
                    ? ({
                        ...device,
                        isRegistered: true,
                        registrationDate: newData.registrationDate,
                        registrationToken: null
                      } as GetCurrentHost_getCurrentHost_mobileDevices)
                    : device
                )
              ]
            }
          };
        }
      });
      subscribeToMore<onRegistrationTokenSavedById, onRegistrationTokenSavedByIdVariables>({
        document: ON_REGISTRATION_TOKEN_SAVED_BY_ID,
        variables: {
          visitorId: hostID
        },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        updateQuery: (prev, { subscriptionData: { data } }) => {
          if (!data) {
            return prev;
          }
          const newData = data.onRegistrationTokenSavedById!;
          return {
            getCurrentHost: {
              ...prev.getCurrentHost,
              mobileDevices: [
                ...prev.getCurrentHost.mobileDevices.map((device) =>
                  device.role === 'ACCESS'
                    ? ({
                        ...device,
                        isRegistered: false,
                        id: newData.mobileDeviceId,
                        registrationToken: newData.registrationToken
                      } as GetCurrentHost_getCurrentHost_mobileDevices)
                    : device
                )
              ]
            }
          };
        }
      });
    }
  }, [
    getCurrentHost,
    hostEmail,
    hostError,
    hostID,
    hostIsAccessByScheduleEnabled,
    hostIsAdmin,
    hostMobileDevice,
    inProgress,
    loggedAccount,
    subscribeToMore
  ]);

  const isMobileDeviceRegisteredWithAccess = (
    mobileDevices: GetCurrentHost_getCurrentHost_mobileDevices[] = hostMobileDevice!
  ) => mobileDevices.some((mobileDevice) => mobileDevice.isRegistered && mobileDevice.role === 'ACCESS');

  const isWaitingForQrCodeValue = () => {
    if (
      hostMobileDevice?.some(
        (mobileDevices) =>
          mobileDevices.role === 'ACCESS' &&
          mobileDevices.isRegistered !== undefined &&
          mobileDevices.registrationToken !== undefined
      )
    )
      if (
        hostMobileDevice?.some(
          (mobileDevices) =>
            mobileDevices.role === 'ACCESS' && !mobileDevices.isRegistered && mobileDevices.registrationToken === null
        )
      )
        return true;
    return false;
  };

  return {
    isUserAuthenticated: Boolean(user),
    isLoading: loading || isWaitingForQrCodeValue(),
    isMobileDeviceRegisteredWithAccess
  };
};

export default useAuth;
