/* eslint-disable import/prefer-default-export */
import { GetTenantLegalDocuments_getTenantLegalDocuments } from 'graphql/generated/GetTenantLegalDocuments';
import { tenantLegalDocumentsVar } from 'state/vars';

export const setTenantLegalDocumentsAction = (
  tenantLegalDocuments: GetTenantLegalDocuments_getTenantLegalDocuments
) => {
  tenantLegalDocumentsVar(tenantLegalDocuments);
};

export const updateTenantLegalDocumentsAction = () => {
  const currentTenantLegal = tenantLegalDocumentsVar();
  if (currentTenantLegal) {
    const updatedTenantLegal = {
      ...currentTenantLegal,
      dpa: { ...currentTenantLegal.dpa, acceptedVersion: currentTenantLegal.dpa.currentVersion },
      sla: { ...currentTenantLegal.sla, acceptedVersion: currentTenantLegal.sla.currentVersion }
    };

    tenantLegalDocumentsVar(updatedTenantLegal);
  }
};
