/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import Search from 'ui/molecules/Search/Search';
import styled from 'styled-components';
import useDrawer from 'hooks/useDrawer/useDrawer';
import Drawer from 'components/Drawer/Drawer';
import PageContent from 'ui/templates/PageContent/PageContent';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import Table from 'ui/organisms/Table/Table';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import TableRow from 'ui/molecules/TableRow/TableRow';
import TableCell from 'ui/atoms/TableCell/TableCell';
import Icon, { Icons } from 'ui/atoms/Icon/Icon';
import Tooltip from 'ui/atoms/Tooltip/Tooltip';
import BatteryStatus from 'ui/organisms/BatteryStatus/BatteryStatus';
import BatteryStatusCell from 'ui/atoms/BatteryStatusCell/BatteryStatusCell';
import useBatteryStatusUI from 'hooks/useBatteryStatusUI/useBatteryStatusUI';
import ArrayUtil from 'utils/Array/Array.util';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import getLockIconProperties from 'utils/LocksIcon/LocksIcon.util';
import { StickyToTop } from 'ui/templates/StickyToTop/StickyToTop';
import Modal from 'components/Modal/Modal';
import AvatarGroupCell from 'ui/atoms/AvatarGroupCell/AvatarGroupCell';
import { DoorsWithLocationsState } from 'state/types';
import { GetDoorsForTenantWithLocations_getDoorsForTenant } from 'graphql/generated/GetDoorsForTenantWithLocations';
import useSortingLocks from 'hooks/useSorting/useSortingLocks/useSortingLocks';
import useDoorsAndLocations from 'hooks/useDoorsAndLocations/useDoorsAndLocations';
import { kebabCase, sortBy } from 'lodash';
import { GetLocationsWithDoorsForTenant_getLocationsForTenant } from 'graphql/generated/GetLocationsWithDoorsForTenant';
import useTranslation, { Translation } from 'hooks/useTranslation/useTranslation';
import { createHardwareTabs } from '../hardwareTabsConst';

const SearchWrapper = styled.div`
  margin: 1rem 0 2rem 0;
  width: 14.875rem;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const TableWrapper = styled.div`
  height: calc(100vh - 24rem);
  width: calc(100vw - 25rem);
  margin-right: 1rem;
`;

export const createColumns = (translation: Translation) => [
  { text: translation.name, width: 20 },
  { text: translation.room, width: 20 },
  { text: translation.group, width: 15 },
  { text: translation.office_mode, width: 15 },
  { text: translation.serial_number, width: 15 },
  { text: translation.battery_status, width: 15 }
];

const Locks = (): JSX.Element => {
  const { translation } = useTranslation();
  const columns = createColumns(translation);
  const hardwareTabs = createHardwareTabs(translation);

  const [filterStr, setFilterStr] = useState('');
  const { showDrawer } = useDrawer();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { doorsWithLocationsLoading, isOfficeMode, locationsWithDoorsLoading } = useDoorsAndLocations({
    handleFetchError
  });
  const { getBatteryStatus, getBatteryData } = useBatteryStatusUI(translation);
  const skeletonArray = ArrayUtil.SkeletonArray();
  const { handleSortingOnClick, handleSortingIcon, getSortedArray } = useSortingLocks(translation);

  const handleRowClick = (door: GetDoorsForTenantWithLocations_getDoorsForTenant) => {
    if (!doorsWithLocationsLoading && !locationsWithDoorsLoading)
      showDrawer({
        type: 'editLockWithGroups',
        contentValue: {
          name: door.name || '',
          location: door.externalLocation?.name || '',
          domSerialNumber: door.domSerialNumber || '',
          locationId: door.externalLocation?.id || '',
          doorId: door.id || '',
          isOfficeModeEnabled: isOfficeMode(door),
          officeModeFromValue:
            door.officeMode.weekDays.length > 0 ? door.officeMode.weekDays[0].from.slice(0, -3) : undefined,
          officeModeToValue:
            door.officeMode.weekDays.length > 0 ? door.officeMode.weekDays[0].to.slice(0, -3) : undefined,
          batteryWarningLevel: door.batteryWarningLevel,
          groupIds:
            (sortBy(
              door.manualLocations,
              (location) => location.name
            ) as GetLocationsWithDoorsForTenant_getLocationsForTenant[]) || []
        }
      });
  };

  const handleManageLockGroupsClick = () => {
    if (!doorsWithLocationsLoading)
      showDrawer({
        type: 'manageLockGroups'
      });
  };

  const getOfficeModeTime = (door: GetDoorsForTenantWithLocations_getDoorsForTenant): string =>
    `${door.officeMode.weekDays[0].from.slice(0, -3)} - ${door.officeMode.weekDays[0].to.slice(0, -3)}`;

  const getFilterData = (): DoorsWithLocationsState => {
    if (filterStr !== '')
      return getSortedArray().filter((element) =>
        [
          element.name,
          element.externalLocation?.name || '',
          element.domSerialNumber || '',
          getBatteryData(getBatteryStatus(element.batteryWarningLevel)).firstLineText,
          isOfficeMode(element) ? getOfficeModeTime(element) : '-'
        ].some((key) => key.toLowerCase().includes(filterStr.toLowerCase()))
      );
    return getSortedArray();
  };

  return (
    <PageContent
      title="ATLAS"
      titleId="location"
      tabs={hardwareTabs}
      buttonText={translation.manage_lock_groups}
      buttonOnClick={handleManageLockGroupsClick}
      buttonId="manage-lock-groups-button"
    >
      <StickyToTop>
        <ComponentWrapper alignItems="center" justifyContent="end" gap="0.5rem">
          <SearchWrapper>
            <Search onChange={(event) => setFilterStr(event.target.value)} value={filterStr} />
          </SearchWrapper>
          <Tooltip elementOnHover={<BatteryStatus />} id="locks-tooltip">
            <IconWrapper>
              <Icon name="Info" id="locks-i-icon" />
            </IconWrapper>
          </Tooltip>
        </ComponentWrapper>
      </StickyToTop>
      <TableWrapper>
        <Table
          header={
            <TableHeaderRow placeForAvatarOrSwitchOrCheckbox placeForArrow>
              {columns.map((column) => (
                <TableHeader
                  headerText={column.text}
                  id={kebabCase(`header-${column.text}`)}
                  flex={`0 0 ${column.width}%`}
                  iconSorting={handleSortingIcon(column.text)}
                  onClick={column.text !== 'Group' ? () => handleSortingOnClick(column.text) : undefined}
                  key={column.text}
                />
              ))}
            </TableHeaderRow>
          }
          headerId="lock-headers"
          withScroll
          rowsWrapperId="lock-list"
        >
          {doorsWithLocationsLoading
            ? skeletonArray.map((_, index) => (
                <TableRow
                  beforeContentIconProps={{
                    name: '' as Icons,
                    height: 28,
                    width: 28,
                    viewBox: '0 0 32 32'
                  }}
                  id={`row-skeleton-${index}`}
                  marginAfterContent
                  key={`skeletonTableRow-${_.id}`}
                >
                  {columns.map((column) => (
                    <TableCell isLoading firstLineText="" flex={`0 0 ${column.width}%`} />
                  ))}
                </TableRow>
              ))
            : getFilterData().map((door, id) => (
                <TableRow
                  onClick={() => handleRowClick(door)}
                  beforeContentIconProps={{
                    ...getLockIconProperties.getLockIconProperties(door.domSerialNumber),
                    height: 28,
                    width: 28,
                    viewBox: '0 0 32 32'
                  }}
                  id={`row-${id}`}
                  hoverEffect
                  hoverArrow
                  key={door.id}
                >
                  <TableCell
                    firstLineText={door.name}
                    firstLineId={`row-${id}-name`}
                    flex={`0 0 ${columns[0].width}%`}
                  />
                  <TableCell
                    firstLineText={door.externalLocation?.name || '-'}
                    firstLineId={`row-${id}-room`}
                    flex={`0 0 ${columns[1].width}%`}
                  />
                  {door.manualLocations && door.manualLocations.length > 0 ? (
                    <AvatarGroupCell
                      avatarGroupProps={{
                        avatars: sortBy(door.manualLocations, (location) => location.name)
                          .reverse()
                          .map((group) => ({
                            text: group.name,
                            variant: 'small',
                            backgroundColor: 'b4',
                            textColor: 'lTextHigh',
                            id: group.id
                          })),
                        tooltipPosition: getFilterData().length === id + 1 ? 'top' : 'bottom',
                        id: 'row-groups-avatar'
                      }}
                      flex={`0 0 ${columns[2].width}%`}
                    />
                  ) : (
                    <TableCell
                      firstLineText="-"
                      firstLineId={`row-${id}-no-groups`}
                      flex={`0 0 ${columns[2].width}%`}
                    />
                  )}

                  <TableCell
                    firstLineText={isOfficeMode(door) ? getOfficeModeTime(door) : '-'}
                    firstLineId={`row-${id}-office-mode`}
                    firstLineColor="lTextHigh"
                    flex={`0 0 ${columns[3].width}%`}
                  />
                  <TableCell
                    firstLineText={door.domSerialNumber || '-'}
                    firstLineId={`row-${id}-serial-number`}
                    flex={`0 0 ${columns[4].width}%`}
                  />
                  <BatteryStatusCell
                    status={getBatteryStatus(door.batteryWarningLevel)}
                    flex={`0 0 ${columns[5].width}%`}
                  />
                </TableRow>
              ))}
        </Table>
      </TableWrapper>

      <Drawer />
      <Modal />
    </PageContent>
  );
};

export default Locks;
