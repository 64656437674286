/* eslint-disable import/prefer-default-export */
import { TabsType } from 'components/Tabs/Tabs';
import { Translation } from 'hooks/useTranslation/useTranslation';

export const createMyProfileTabs = (translation: Translation): TabsType[] => [
  {
    text: translation.access_app,
    location: '/my-profile/access-app'
  }
];
