import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { FieldCss, Props as SharedProps } from '../../shared/Field/Field';

const StyledInput = styled.input`
  ${FieldCss}
`;

export interface Props extends SharedProps<'textField'> {}

const TextField = forwardRef<HTMLInputElement | null, Props>((props, ref) => <StyledInput {...props} ref={ref} />);

export default TextField;
