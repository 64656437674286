/* eslint-disable import/prefer-default-export */
import { TabsType } from 'components/Tabs/Tabs';
import { Translation } from 'hooks/useTranslation/useTranslation';

export const createHardwareTabs = (translation: Translation): TabsType[] => [
  {
    text: translation.locks,
    location: '/hardware/locks'
  }
];
