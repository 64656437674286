/* eslint-disable import/prefer-default-export */
import { UpdateVisitorGroup, UpdateVisitorGroup_updateVisitorGroup } from 'graphql/generated/UpdateVisitorGroup';
import { createMockedMobileDevice } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/mobileDevice';
import { createMockedVisitorGroupWithMembersAndRoleAndMaxNumberOfUsersCoContractors } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorGroupWithMembersAndRoleAndMaxNumberOfUsersCoContractors';
import { createMockedVisitorWithVisitorGroupMembership } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitorWithVisitorGroupMembership';

export function mockedUpdateVisitorGroupData(
  updateVisitorGroup: UpdateVisitorGroup_updateVisitorGroup
): UpdateVisitorGroup {
  return {
    updateVisitorGroup
  };
}

export const mockedUpdateVisitorGroupDataForMutation = mockedUpdateVisitorGroupData(
  createMockedVisitorGroupWithMembersAndRoleAndMaxNumberOfUsersCoContractors({
    id: 'mockedVisitorGroupIdExternalUsers1',
    members: [
      createMockedVisitorWithVisitorGroupMembership({
        id: 'mockedVisitorIdExternalUser1',
        name: 'mockedVisitorNameExternalUser1',
        email: 'mockedVisitorEmailExternalUser1'
      }),
      createMockedVisitorWithVisitorGroupMembership({
        id: 'mockedVisitorIdExternalUser2',
        name: 'mockedVisitorNameExternalUser2',
        email: 'mockedVisitorEmailExternalUser2',
        mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: true })]
      })
    ],
    name: 'co-contractors1-new',
    maxNumberOfMembers: 3
  })
);
