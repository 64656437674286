import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Colors } from 'theme/theme';
import Typography from '../Typography/Typography';

export interface Props extends HTMLAttributes<HTMLLabelElement> {
  color?: keyof Colors;
}
const StyledLabel = styled(Typography)`
  display: flex;
  flex-direction: column;
`;

const Label: React.FC<Props> = ({ children, color = 'dTextHigh' }, props) => (
  <StyledLabel variant="label" color={color} {...props}>
    {children}
  </StyledLabel>
);

export default Label;
