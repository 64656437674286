/* eslint-disable import/prefer-default-export */

import { OnOfficeDayDeletedById_onOfficeDayDeletedById } from 'graphql/generated/OnOfficeDayDeletedById';

interface Props {
  date: GraphQL_AWSDate;
}

export function createMockedSubscriptionOfficeDayDeleted({
  date
}: Props): OnOfficeDayDeletedById_onOfficeDayDeletedById {
  return {
    __typename: 'SubscriptionOfficeDayById',
    date
  };
}
