import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Title from 'ui/atoms/Title/Title';
import { Divider, Typography } from '@material-ui/core';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const TitleWrapper = styled.div`
  position: absolute;
  top: 4rem;
  left: 8rem;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: calc(50% - 6rem);
  transform: translate(-50%, -50%);
  margin: 0 6rem;
  width: calc(100vw - 12rem);
  min-height: 14.625rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem 5rem;
  background-color: ${({ theme }) => theme.colors.bgLight};
`;

const InvalidOTR: React.FC = () => (
  <>
    <BackgroundImage id="background" />
    <TitleWrapper>
      <Title withAdditionalText />
    </TitleWrapper>
    <Wrapper>
      <Typography variant="h4" color="textPrimary">
        Link expired or already used
      </Typography>
      <Divider />
      <ComponentWrapper flexDirection="column">
        <Typography variant="body1" color="textPrimary">
          The URL you have clicked is no longer valid. This may be due to the link expiring or being used already.
        </Typography>
        <Typography variant="body1" color="textPrimary">
          If you believe this is an error, please contact your reseller or{' '}
          <a href="https://tkhsecurity.com/">TKH Security</a> for assistance
        </Typography>
      </ComponentWrapper>
    </Wrapper>
  </>
);

export default InvalidOTR;
