import { DoorsWithLocationsStateComplete, DrawerStates } from 'state/types';

export const getFilterData = (
  filterStr: string,
  doorsWithLocations: DoorsWithLocationsStateComplete
): DoorsWithLocationsStateComplete => {
  if (filterStr !== '')
    return doorsWithLocations.filter((door) =>
      [door.name, door.externalLocation?.name || ''].some((key) => key.toLowerCase().includes(filterStr.toLowerCase()))
    );
  return doorsWithLocations;
};

export const handleSelectDoor = (
  doorId: string,
  selectedDoors: string[],
  setSelectedDoors: React.Dispatch<React.SetStateAction<string[]>>
) => {
  if (selectedDoors.some((door) => door === doorId)) setSelectedDoors(selectedDoors.filter((door) => door !== doorId));
  else setSelectedDoors([...selectedDoors, doorId]);
};

export const handleCancelButton = (showDrawer: (drawer: DrawerStates) => void, hideModal: () => void) => {
  showDrawer({
    type: 'manageLockGroups'
  });
  hideModal();
};
