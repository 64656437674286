/* eslint-disable no-underscore-dangle */
import { isEqual, orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { setVisitorsGroupsAction } from 'state/actions/visitorsGroups';
import { visitorsGroupsVar } from 'state/vars';
import { useReactiveVar } from '@apollo/client';
import { GetVisitorGroupsForTenant_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenant';
import { Translation } from 'hooks/useTranslation/useTranslation';

interface Sorting {
  isAscending?: boolean;
  columnName?: string;
}

const useSortingUserGroupsTable = (translation: Translation) => {
  const visitorsGroups = useReactiveVar(visitorsGroupsVar);
  const [sorting, setSorting] = useState<Sorting>({ isAscending: true, columnName: translation.groups });

  const handleSorting = useCallback(
    (item: GetVisitorGroupsForTenant_getVisitorGroupsForTenant, columnName?: string) => {
      switch (columnName) {
        case translation.groups:
        default:
          return item.name;
      }
    },
    [translation.groups]
  );

  const handleSortingOnClick = (columnName: string) => {
    if (sorting.isAscending !== undefined && sorting.columnName === columnName) {
      setSorting({ isAscending: !sorting.isAscending, columnName });
    } else {
      setSorting({ isAscending: true, columnName });
    }
  };

  const handleSortingIcon = (columnName: string) => {
    if (sorting.isAscending !== undefined)
      if (sorting.columnName === columnName) return sorting.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  const getSortedArray = useCallback(
    () =>
      orderBy(visitorsGroups, (item) => handleSorting(item, sorting.columnName), [
        sorting.isAscending ? 'asc' : 'desc'
      ]),
    [handleSorting, sorting.columnName, sorting.isAscending, visitorsGroups]
  );

  useEffect(() => {
    const sortedVisitorGroups = getSortedArray();
    if (!isEqual(sortedVisitorGroups, visitorsGroups)) setVisitorsGroupsAction(sortedVisitorGroups);
  }, [getSortedArray, visitorsGroups]);

  return { handleSortingOnClick, handleSortingIcon };
};

export default useSortingUserGroupsTable;
