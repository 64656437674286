import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/atoms/Typography/Typography';
import { BORDER_RADIUS_MULTIPLIER } from 'ui/shared/const';
import { useReactiveVar } from '@apollo/client';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import Button from 'ui/atoms/Button/Button';
import GooglePlay from 'assets/images/GooglePlay.png';
import AppStore from 'assets/images/AppStore.png';
import { useMsal } from '@azure/msal-react';
import QRCode from 'react-qr-code';
import { authVar, uiVar } from 'state/vars';
import { useNavigate } from 'react-router-dom';
import QRCodeValueUtil from 'utils/QRCodeValue/QRCodeValue.util';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Loader from 'ui/atoms/Loader/Loader';
import Title from 'components/Title/Title';
import useTranslation from 'hooks/useTranslation/useTranslation';

const Container = styled.div`
  ${({ theme: { isMobileLandscape } }) => (isMobileLandscape ? 'overflow: auto; height: 100vh;' : '')};
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${({ theme: { isMobile } }) => (isMobile ? `margin: 2rem 0;` : ``)};
`;

const Frame = styled.div`
  background-color: ${(props) => props.theme.colors.bgLight};
  display: flex;
  flex-direction: col;
  gap: 4.375rem;
  border-radius: ${(props) => props.theme.borderRadius * BORDER_RADIUS_MULTIPLIER}px;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `flex-direction: column;
  padding: 1.5rem;
  gap: 1.5rem;
  margin-top: 2rem;`
      : `flex-direction: row;
  padding: 3rem 5rem;
  gap: 4.375rem;`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ theme: { isMobile } }) => (isMobile ? `width: calc(90vw - 3rem);` : 'width: 26.375rem;')};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  ${({ theme: { isMobile } }) => (isMobile ? `flex-direction: row;` : 'flex-direction: column;')};
`;

const GooglePlayButton = styled.div`
  background-image: url(${GooglePlay});
  width: 7.5rem;
  height: 2.1875rem;
`;

const AppStoreButton = styled.div`
  background-image: url(${AppStore});
  width: 7.5rem;
  height: 2.5rem;
`;

const ButtonWrapper = styled.div`
  width: 15rem;
  ${({ theme: { isMobile } }) => (isMobile ? `margin: 0 auto 2rem auto` : 'margin: auto;')};
`;

const Registration: React.FC = () => {
  const { translation } = useTranslation();
  const { instance, accounts } = useMsal();
  const { isMobile } = useReactiveVar(uiVar);
  const { user } = useReactiveVar(authVar);
  const mobileDevice = user?.mobileDevices?.find((device) => device.role === 'ACCESS');
  const name = user?.name; // TODO: Nested destruction not work, dont know why
  const isAdmin = user?.isAdmin;
  const navigate = useNavigate();

  const handleBackButton = async () => {
    const previousPageIndex = -3;
    if (isAdmin) navigate(previousPageIndex);
    else await instance.logout({ account: accounts[0], postLogoutRedirectUri: process.env.REACT_APP_SSO_REDIRECT_URI });
  };

  return (
    <Container>
      <BackgroundImage id="background" />
      <Wrapper>
        {!isMobile && <Title />}
        <Frame>
          <ComponentWrapper flexDirection="column" gap="1.5rem">
            <Typography variant={isMobile ? 'title4' : 'title1'} color="dTextHigh">
              {translation.atlas_access_app}
            </Typography>
            <TextWrapper>
              <Typography variant={isMobile ? 'body4' : 'body3'} color="dTextHigh">
                {`${translation.hi} ${name},`}
              </Typography>
              <Typography variant={isMobile ? 'body4' : 'body3'} color="dTextHigh">
                {
                  translation.atlas_uses_your_mobile_device_as_your_personal_access_card_to_continue_it_is_required_that_you_link_your_access_profile_to_the_atlas_access_app
                }
              </Typography>
              <Typography variant={isMobile ? 'body4' : 'body3'} color="dTextHigh">
                {`1. ${translation.download_the_atlas_access_app_to_your_mobile_device}.`}
              </Typography>
              <Typography variant={isMobile ? 'body4' : 'body3'} color="dTextHigh">
                {`2. ${translation.follow_the_onboarding_steps_and_open_the_in_app_qr_scanner}.`}
              </Typography>
              {isMobile ? (
                <Typography variant={isMobile ? 'body4' : 'body3'} color="dTextHigh">
                  {`3. ${translation.login_into_the_atlas_web_portal_on_a_laptop_or_desktop_and_scan_the_qr_code_to_register_your_mobile_device}`}
                </Typography>
              ) : (
                <Typography variant={isMobile ? 'body4' : 'body3'} color="dTextHigh">
                  {`3. ${translation.scan_the_qr_code_to_register_your_mobile_device}.`}
                </Typography>
              )}
              <Typography variant={isMobile ? 'body4' : 'body3'} color="dTextHigh">
                {`4. ${translation.registering_can_take_up_60_seconds}.`}
              </Typography>
            </TextWrapper>
          </ComponentWrapper>
          <ComponentWrapper flexDirection="column" gap="5rem">
            {mobileDevice?.registrationToken ? (
              !isMobile && (
                <ComponentWrapper
                  alignItems="center"
                  justifyContent="center"
                  width="14rem"
                  height="14rem"
                  id="registration-page-qr-code"
                >
                  <QRCode value={QRCodeValueUtil.getQrCodeValue(mobileDevice?.registrationToken)} />
                </ComponentWrapper>
              )
            ) : (
              <ComponentWrapper
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="14rem"
                height="14rem"
              >
                <Loader size={64} />
                <Typography variant="title4" color="dTextHigh">
                  {translation.generating_qr}
                </Typography>
              </ComponentWrapper>
            )}
            <ButtonsWrapper>
              <a
                href="https://play.google.com/store/apps/details?id=com.tkhsecurity.siqura&hl=en_US/"
                target="_blank"
                rel="noreferrer"
                id="google-play-button"
              >
                <GooglePlayButton />
              </a>
              <a
                href="https://apps.apple.com/eng/app/atlas-access/id1625855308"
                target="_blank"
                rel="noreferrer"
                id="app-store-button"
              >
                <AppStoreButton />
              </a>
            </ButtonsWrapper>
          </ComponentWrapper>
        </Frame>
        <ButtonWrapper id="registration-page-button">
          <Button onClick={handleBackButton}>
            {!isAdmin || (isAdmin && isMobile) ? `${translation.logout}` : `${translation.back}`}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default Registration;
