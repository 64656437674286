/* eslint-disable import/prefer-default-export */
import {
  GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant_defaultAccessGrants,
  GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant_scheduledAccessGrants
} from 'graphql/generated/GetVisitorGroupsForTenantWithIsOfficeModeEnabled';

interface Props {
  id: string;
  isOfficeModeEnabled?: boolean;
}

export function createMockedAccessGrantWithIsOfficeModeEnabled({
  id,
  isOfficeModeEnabled = true
}: Props):
  | GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant_defaultAccessGrants
  | GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant_scheduledAccessGrants {
  return {
    __typename: 'AccessGrant',
    id,
    isOfficeModeEnabled
  };
}
