import React from 'react';

const Hamburger: React.FC = () => (
  <path
    d="M0 0.833496H24V3.50016H0V0.833496ZM0 10.1668H24V12.8335H0V10.1668ZM0 19.5002H24V22.1668H0V19.5002Z"
    fill="#FAFAF6"
  />
);

export default Hamburger;
