/* eslint-disable import/prefer-default-export */
import {
  GetLocationsForTenant,
  GetLocationsForTenant_getLocationsForTenant
} from 'graphql/generated/GetLocationsForTenant';
import { createMockedLocation } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/location';

export function mockedGetLocationsForTenantData(
  getLocationsForTenant: Array<GetLocationsForTenant_getLocationsForTenant>
): GetLocationsForTenant {
  return {
    getLocationsForTenant
  };
}

export const mockedGetLocationsForTenantDataUnitTests = mockedGetLocationsForTenantData([
  createMockedLocation({ id: 'mockedLocationId' })
]);

export const mockedGetLocationsForTenantDataAdmin = mockedGetLocationsForTenantData([
  createMockedLocation({ id: 'TestRoom1', name: 'Test room 1' }),
  createMockedLocation({ id: 'TestRoom2', name: 'Test room 2' }),
  createMockedLocation({ id: 'TestRoom3', name: 'Test room 3' }),
  createMockedLocation({ id: 'TestRoom4', name: 'Test room 4' })
]);
