/* eslint-disable import/prefer-default-export */
import { TabsType } from 'components/Tabs/Tabs';
import { Translation } from 'hooks/useTranslation/useTranslation';

export const createUsersTabs = (translation: Translation): TabsType[] => [
  {
    text: translation.external_groups,
    location: '/users/external-groups'
  },
  {
    text: translation.installers,
    location: '/users/Installers'
  }
];
