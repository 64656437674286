import React from 'react';

const Cylinder: React.FC = () => (
  <>
    <circle cx="15.5" cy="14.2393" r="12.7663" strokeWidth="1.46739" fill="none" />
    <mask id="path-2-inside-1_4945_104407">
      <path d="M9.04346 25.3911H10.1195V30.0868H20.8804V25.3911H21.9565V30.0868C21.9565 30.7351 21.4309 31.2607 20.7826 31.2607H10.2174C9.56903 31.2607 9.04346 30.7351 9.04346 30.0868V25.3911Z" />
    </mask>
    <path
      d="M9.04346 25.3911V24.2172H7.86954V25.3911H9.04346ZM21.9565 25.3911H23.1304V24.2172H21.9565V25.3911ZM20.8804 25.3911V24.2172H19.7065V25.3911H20.8804ZM10.1195 25.3911H11.2935V24.2172H10.1195V25.3911ZM10.1195 30.0868H8.94563V31.2607H10.1195V30.0868ZM20.8804 30.0868V31.2607H22.0543V30.0868H20.8804ZM20.7826 25.3911V30.0868H23.1304V25.3911H20.7826ZM20.7826 30.0868H10.2174V32.4346H20.7826V30.0868ZM10.2174 30.0868V25.3911H7.86954V30.0868H10.2174ZM20.8804 26.565H21.9565V24.2172H20.8804V26.565ZM9.04346 26.565H10.1195V24.2172H9.04346V26.565ZM8.94563 25.3911V30.0868H11.2935V25.3911H8.94563ZM10.1195 31.2607H20.8804V28.9129H10.1195V31.2607ZM22.0543 30.0868V25.3911H19.7065V30.0868H22.0543ZM10.2174 30.0868H10.2174H7.86954C7.86954 31.3834 8.9207 32.4346 10.2174 32.4346V30.0868ZM20.7826 30.0868V30.0868V32.4346C22.0793 32.4346 23.1304 31.3834 23.1304 30.0868H20.7826Z"
      mask="url(#path-2-inside-1_4945_104407)"
    />
  </>
);

export default Cylinder;
