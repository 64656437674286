import React from 'react';
import UIDrawer from 'ui/atoms/Drawer/Drawer';
import useDrawer from 'hooks/useDrawer/useDrawer';
import EditLock from './variants/locks/EditLock/EditLock';
import AccessApp from './variants/accessApp/accessApp';
import AccessEventsFilters from './variants/accessEvents/AccessEventsFilters/AccessEventsFilters';
import AddInstaller from './variants/installer/AddInstallerVariant';
import EditInstaller from './variants/installer/EditInstallerVariant';
import AddExternalMember from './variants/externalMembers/AddExternalMemberVariant';
import EditExternalMember from './variants/externalMembers/EditExternalMemberVariant';
import AddExternalUserGroup from './variants/externalUserGroup/AddExternalUserGroupVariant';
import EditExternalUserGroup from './variants/externalUserGroup/EditExternalUserGroupVariant';
import ManageLockGroups from './variants/locks/ManageLockGroups/ManageLockGroups';
import EditLockWithGroups from './variants/locks/EditLockWithGroups/EditLockWithGroups';

const Drawer: React.FC = () => {
  const { hideDrawer, drawerState } = useDrawer();

  const handleDrawerClose = () => hideDrawer();

  const renderCorrectVariant = () => {
    switch (drawerState?.type) {
      case 'editLock':
        return <EditLock />;
      case 'editLockWithGroups':
        return <EditLockWithGroups />;
      case 'accessEventsFilters':
        return <AccessEventsFilters />;
      case 'myProfile':
        return <AccessApp />;
      case 'editExternalUser':
        return <EditExternalMember />;
      case 'addExternalUser':
        return <AddExternalMember />;
      case 'addExternalUserGroup':
        return <AddExternalUserGroup />;
      case 'editExternalUserGroup':
        return <EditExternalUserGroup />;
      case 'addInstaller':
        return <AddInstaller />;
      case 'editInstaller':
        return <EditInstaller />;
      case 'manageLockGroups':
        return <ManageLockGroups />;
      default:
        return null;
    }
  };

  return (
    <UIDrawer
      open={Boolean(drawerState)}
      onClose={handleDrawerClose}
      onKeyDown={(event) => event.stopPropagation()}
      disableEscapeKeyDown
    >
      {renderCorrectVariant()}
    </UIDrawer>
  );
};

export default Drawer;
