/* eslint-disable import/prefer-default-export */
import { ADD_OFFICE_DAY, DELETE_OFFICE_DAY, UNREGISTER_MOBILE_DEVICE_BY_ID } from 'graphql/mutations';
import { mockedUnregisterMobileDeviceDataForMutation } from '../graphqlApollo/mutation/unregisterMobileDevice';
import { mockedAddOfficeDayDataForMutation } from '../graphqlApollo/mutation/addOfficeDay';
import { mockedDeleteOfficeDayDataForMutation } from '../graphqlApollo/mutation/removeVisit';

export const mutations = [
  {
    request: {
      query: ADD_OFFICE_DAY,
      variables: {
        input: { date: '2020-03-05' }
      }
    },
    newData: () => ({
      data: mockedAddOfficeDayDataForMutation
    })
  },
  {
    request: {
      query: DELETE_OFFICE_DAY,
      variables: { date: '2020-03-05' }
    },
    newData: () => ({
      data: mockedDeleteOfficeDayDataForMutation
    })
  },
  {
    request: {
      query: UNREGISTER_MOBILE_DEVICE_BY_ID,
      variables: { visitorId: 'mockedBobVisitorId', mobileDeviceId: 'mockedMobileDevice' }
    },
    newData: () => ({
      data: mockedUnregisterMobileDeviceDataForMutation
    })
  }
];
