/* eslint-disable no-magic-numbers */
import { Translation } from 'hooks/useTranslation/useTranslation';
import { Colors } from 'theme/theme';

export type BatteryStatus =
  | 'BatteryDefault'
  | 'BatteryLow'
  | 'BatteryCritical'
  | 'BatteryEmpty'
  | 'BatteryPowered'
  | 'BatteryUnknown';

const useBatteryStatusUI = (translation: Translation) => {
  const getBatteryStatus = (status: null | number): BatteryStatus => {
    switch (status) {
      case 0:
        return 'BatteryDefault';
      case 1:
        return 'BatteryLow';
      case 2:
        return 'BatteryCritical';
      case 3:
        return 'BatteryEmpty';
      case 4:
        return 'BatteryPowered';
      case null:
      default:
        return 'BatteryUnknown';
    }
  };

  interface BatteryStatusUI {
    iconColor: keyof Colors;
    iconOpacity: null | string;
    CellText: string;
    firstLineText: string;
    secondLineText: null | string;
    sortNumber: number;
    backgroundColor: keyof Colors;
    boxText: string;
  }

  const getBatteryData = (status: BatteryStatus): BatteryStatusUI => {
    switch (status) {
      case 'BatteryLow':
        return {
          iconColor: 'warning',
          iconOpacity: null,
          CellText: translation.warning,
          firstLineText: translation.warning,
          secondLineText: translation.action_required_replace_battery,
          sortNumber: 3,
          backgroundColor: 'yellowish',
          boxText: translation.battery_low
        };
      case 'BatteryCritical':
        return {
          iconColor: 'warning1',
          iconOpacity: null,
          CellText: translation.critical,
          firstLineText: translation.critical_lock_can_only_be_opened_with_calamity_card,
          secondLineText: translation.action_required_replace_battery,
          sortNumber: 2,
          backgroundColor: 'redish',
          boxText: translation.battery_critical
        };
      case 'BatteryEmpty':
        return {
          iconColor: 'error',
          iconOpacity: null,
          CellText: translation.empty,
          firstLineText: translation.empty_lock_is_not_working_anymore,
          secondLineText: translation.action_required_replace_battery,
          sortNumber: 1,
          backgroundColor: 'redish',
          boxText: translation.battery_empty
        };
      case 'BatteryPowered':
        return {
          iconColor: 'primary',
          iconOpacity: '0.25',
          CellText: '',
          firstLineText: translation.lock_is_powered,
          secondLineText: null,
          sortNumber: 5,
          backgroundColor: 'greenish',
          boxText: translation.powered
        };
      case 'BatteryDefault':
        return {
          iconColor: 'primary',
          iconOpacity: '0.25',
          CellText: '',
          firstLineText: translation.battery_level_is_normal,
          secondLineText: null,
          sortNumber: 4,
          backgroundColor: 'greenish',
          boxText: ''
        };
      default:
      case 'BatteryUnknown':
        return {
          iconColor: 'primary',
          iconOpacity: null,
          CellText: 'Unknown',
          firstLineText: translation.battery_state_unknown,
          secondLineText: null,
          sortNumber: 0,
          backgroundColor: 'greenish',
          boxText: translation.battery_state_unknown
        };
    }
  };

  return { getBatteryStatus, getBatteryData };
};

export default useBatteryStatusUI;
