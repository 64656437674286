import { visitorGroupsWithIsOfficeModeEnabledVar } from 'state/vars';
import {
  GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant,
  GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant_defaultAccessGrants
} from 'graphql/generated/GetVisitorGroupsForTenantWithIsOfficeModeEnabled';

export const setIsOfficeModeEnabledAction = (
  visitorGroups: GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant[]
) => {
  visitorGroupsWithIsOfficeModeEnabledVar(visitorGroups);
};

export const updateAccessGrantsArrayWithIsOfficeModeEnabled = (
  updatedAccessGrantId: string | null,
  accessGrants: GetVisitorGroupsForTenantWithIsOfficeModeEnabled_getVisitorGroupsForTenant_defaultAccessGrants[]
) =>
  accessGrants.map((accessGrant) =>
    accessGrant.id === updatedAccessGrantId
      ? { ...accessGrant, isOfficeModeEnabled: !accessGrant.isOfficeModeEnabled }
      : accessGrant
  );

export const updateIsOfficeModeEnabledAction = (updatedAccessGrantId: string | null) => {
  const currentVisitorGroupsWithIsOfficeModeEnabled = visitorGroupsWithIsOfficeModeEnabledVar();
  const updatedVisitorGroups = currentVisitorGroupsWithIsOfficeModeEnabled.map((group) => ({
    ...group,
    defaultAccessGrants: updateAccessGrantsArrayWithIsOfficeModeEnabled(
      updatedAccessGrantId,
      group.defaultAccessGrants
    ),
    scheduledAccessGrants: updateAccessGrantsArrayWithIsOfficeModeEnabled(
      updatedAccessGrantId,
      group.scheduledAccessGrants
    )
  }));

  visitorGroupsWithIsOfficeModeEnabledVar(updatedVisitorGroups);
};
