/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';
import {
  OnOfficeDayAddedById,
  OnOfficeDayAddedById_onOfficeDayAddedById
} from 'graphql/generated/OnOfficeDayAddedById';
import { createMockedSubscriptionOfficeDayAdded } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/subscriptionOfficeDayAdded';
import { getDateWithFormat } from 'utils/DateFormat/DateFormat.util';
import { firstDate } from '../dateUsedInMocks';

export function mockedOnOfficeDayAddedData(
  onOfficeDayAddedById: OnOfficeDayAddedById_onOfficeDayAddedById | null
): OnOfficeDayAddedById {
  return {
    onOfficeDayAddedById
  };
}

export const mockedOnOfficeDayAddedDataForSubscription = mockedOnOfficeDayAddedData(
  createMockedSubscriptionOfficeDayAdded({
    date: getDateWithFormat(
      'AWSDate',
      dayjs(firstDate).year(),
      dayjs(firstDate).month(),
      dayjs(firstDate).startOf('month').date()
    )
  })
);
