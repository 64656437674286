/* eslint-disable import/prefer-default-export */
import { GetAccessEventsForTenant_getAccessEventsForTenant_items_visitor } from 'graphql/generated/GetAccessEventsForTenant';

interface Props {
  id: string;
  name?: string;
  email?: GraphQL_AWSEmail;
}

export function createMockedAccessEventVisitor({
  id,
  name = 'mockedVisitorName',
  email = 'mockedVisitorEmail'
}: Props): GetAccessEventsForTenant_getAccessEventsForTenant_items_visitor {
  return {
    __typename: 'AccessEventVisitor',
    id,
    name,
    email
  };
}
