import React from 'react';
import { useLocation } from 'react-router-dom';
import SideNavUI from 'ui/organisms/SideNav/SideNav';
import { useReactiveVar } from '@apollo/client';
import { authVar } from 'state/vars';
import usePagesAvailability from 'hooks/usePagesAvailability/usePagesAvailability';
import useTranslation from 'hooks/useTranslation/useTranslation';

const SideNav: React.FC = () => {
  const { translation } = useTranslation();
  const location = useLocation();
  const { user } = useReactiveVar(authVar);
  const { getSideNavItems } = usePagesAvailability(translation);

  const getItems = (currentLocation: string) =>
    getSideNavItems().map((item) => ({
      ...item,
      isSelected: `/${currentLocation.split('/')[1]}`.includes(item.location)
    }));

  return <SideNavUI items={getItems(location.pathname)} isAdmin={user?.isAdmin!} />;
};

export default SideNav;
