import dayjs from 'dayjs';
import { Translation } from 'hooks/useTranslation/useTranslation';

export const handleNameInputValidation = (
  translation: Translation,
  setNameInputValidation: (value: string | undefined) => void,
  nameInputValue?: string
) => {
  if (nameInputValue === '') setNameInputValidation(translation.name_cannot_be_empty);
  else setNameInputValidation(undefined);
};

export const handleExpirationDateInputValidation = (
  translation: Translation,
  setExpirationDateInputValidation: (value: string | undefined) => void,
  isExpirationDateEnabled?: boolean,
  expirationDateTo?: string
) => {
  if (isExpirationDateEnabled && dayjs(expirationDateTo).isBefore(dayjs()))
    setExpirationDateInputValidation(translation.expiration_date_cannot_be_in_the_past);
  else setExpirationDateInputValidation(undefined);
};

export const handleExpirationDateInputValue = (
  setExpirationDateInputValue: (value: React.SetStateAction<string | undefined>) => void,
  expirationDateTo?: string
) => {
  if (expirationDateTo) setExpirationDateInputValue(dayjs(expirationDateTo).format('DD-MM-YYYY HH:mm'));
  else setExpirationDateInputValue('-');
};

export const handleSwitchIsExpirationDateOnChange = (
  setIsExpirationDateEnabled: (value: React.SetStateAction<boolean | undefined>) => void,
  setExpirationDateTo: (value: React.SetStateAction<string | undefined>) => void,
  setExpirationDateInputValue: (value: React.SetStateAction<string | undefined>) => void,
  isExpirationDateEnabled?: boolean,
  expirationDateTo?: string
) => {
  setIsExpirationDateEnabled(!isExpirationDateEnabled);
  if (expirationDateTo === undefined) {
    setExpirationDateTo(dayjs().add(1, 'day').toISOString());
  }
  setExpirationDateInputValue(!isExpirationDateEnabled ? dayjs(expirationDateTo).format('DD-MM-YYYY HH:mm') : '-');
};
