/* eslint-disable import/prefer-default-export */
import {
  GetDoorsForTenantWithOfficeMode,
  GetDoorsForTenantWithOfficeMode_getDoorsForTenant
} from 'graphql/generated/GetDoorsForTenantWithOfficeMode';
import { createMockedDoorWithOfficeMode } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/doorWithOfficeMode';
import { createMockedLocation } from '../objectsBaseOnSchemaType/location';

export function mockedGetDoorsWithOfficeModeForTenantData(
  getDoorsForTenant: Array<GetDoorsForTenantWithOfficeMode_getDoorsForTenant>
): GetDoorsForTenantWithOfficeMode {
  return {
    getDoorsForTenant
  };
}

export const mockedGetDoorsWithOfficeDaysForTenantDataUnitTests = mockedGetDoorsWithOfficeModeForTenantData([
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId0',
    batteryWarningLevel: 0,
    domSerialNumber: '66',
    name: 'door 0'
  }),
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId1',

    batteryWarningLevel: 0,
    domSerialNumber: '66',
    name: 'door 1'
  }),
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId1',
    batteryWarningLevel: 0,
    domSerialNumber: '66',
    name: 'door 1'
  })
]);

export const mockedGetDoorsWithOfficeDaysForTenantDataAdmin = mockedGetDoorsWithOfficeModeForTenantData([
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId1',
    batteryWarningLevel: 0,
    domSerialNumber: '65',
    name: 'Test door 1',
    location: createMockedLocation({ id: 'TestRoom1', name: 'Test room 1' })
  }),
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId2',
    batteryWarningLevel: 3,
    domSerialNumber: '7F',
    name: 'Test door 2',
    location: createMockedLocation({ id: 'TestRoom2', name: 'Test room 2' })
  }),
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId3',
    batteryWarningLevel: 2,
    domSerialNumber: 'A3',
    name: 'Test door 3',
    location: createMockedLocation({ id: 'TestRoom3', name: 'Test room 3' })
  }),
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId4',
    batteryWarningLevel: 1,
    domSerialNumber: 'ZZ',
    name: 'Test door 4'
  }),
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId5',
    batteryWarningLevel: 0,
    domSerialNumber: 'ZZZ',
    name: 'Test door 5'
  }),
  createMockedDoorWithOfficeMode({
    id: 'mockedDoorId6',
    batteryWarningLevel: 0,
    domSerialNumber: '66',
    name: 'Test door 6',
    location: createMockedLocation({ id: 'TestRoom6', name: 'Test room 6' })
  })
]);
