import { DateTimeSelectItem } from 'components/Drawer/variants/accessEvents/AccessEventsFilters/dataTimeSelectOptions';
import { MobileOpCode } from 'graphql/generated/globalTypes';
import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import { Control, Controller, FieldValues, UseFormRegister } from 'react-hook-form';
import Select, { OptionProps } from 'ui/molecules/Select/Select';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, object>;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;

  dataTimeSelectOptions: Array<OptionProps<null | DateTimeSelectItem>>;
  dataTimeInputValue?: string;
  handleDataTimeInputChange: (value: string | undefined) => void;
  dataTimeSelectValue?: null | OptionProps<null | DateTimeSelectItem>;
  handleDataTimeSelectChange: (value: null | OptionProps<null | DateTimeSelectItem>) => void;

  visitorSelectOptions: Array<OptionProps<null | string>>;
  visitorInputValue?: string;
  handleVisitorInputChange: (value: string | undefined) => void;
  visitorSelectValue?: null | OptionProps<null | string>;
  handleVisitorSelectChange: (value: null | OptionProps<null | string>) => void;

  lockSelectOptions: Array<OptionProps<null | string>>;
  lockInputValue?: string;
  handleLockInputChange: (value: string | undefined) => void;
  lockSelectValue?: null | OptionProps<null | string>;
  handleLockSelectChange: (value: null | OptionProps<null | string>) => void;

  actionSelectOptions: Array<OptionProps<null | MobileOpCode>>;
  actionInputValue?: string;
  handleActionInputChange: (value: string | undefined) => void;
  actionSelectValue: null | OptionProps<null | MobileOpCode>;
  handleActionSelectChange: (value: null | OptionProps<null | MobileOpCode>) => void;

  reasonSelectOptions: Array<OptionProps<null | string>>;
  reasonInputValue?: string;
  handleReasonInputChange: (value: string | undefined) => void;
  reasonSelectValue: null | OptionProps<null | string>;
  handleReasonSelectChange: (value: null | OptionProps<null | string>) => void;
}

const AccessEventsFilters: React.FC<Props> = ({
  register,
  control,
  dataTimeSelectOptions,
  dataTimeSelectValue,
  dataTimeInputValue,
  handleDataTimeInputChange,
  handleDataTimeSelectChange,
  visitorSelectOptions,
  visitorSelectValue,
  handleVisitorSelectChange,
  visitorInputValue,
  handleVisitorInputChange,
  lockSelectOptions,
  lockSelectValue,
  handleLockSelectChange,
  lockInputValue,
  handleLockInputChange,
  actionSelectOptions,
  actionInputValue,
  handleActionInputChange,
  actionSelectValue,
  handleActionSelectChange,
  reasonSelectOptions,
  reasonInputValue,
  handleReasonInputChange,
  reasonSelectValue,
  handleReasonSelectChange,
  inputOnKeyDown
}) => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper flexDirection="column">
      <Controller
        control={control}
        name="filters.dateRange"
        render={() => (
          <Select
            fieldTemplateProps={{
              label: translation.date_time_range,
              labelId: 'access-events-date-time-select-label',
              customRequired: true
            }}
            autocompleteProps={{
              value: dataTimeSelectValue,
              inputValue: dataTimeInputValue,
              onInputChange: (_, value) => {
                handleDataTimeInputChange(value);
              },
              options: dataTimeSelectOptions,
              onChange: (_, value) => {
                handleDataTimeSelectChange(value as OptionProps<null | DateTimeSelectItem>);
              },
              id: 'access-events-date-time-select-autocomplete',

              isOptionEqualToValue(option, value) {
                // @ts-ignore
                return (option.value === value.value && option.label === value.label) || value === null;
              },
              onKeyDown: inputOnKeyDown
            }}
            textFieldProps={{
              id: 'access-events-date-time-select-input'
            }}
            {...register('filters.dateRange')}
          />
        )}
      />
      <Controller
        control={control}
        name="filters.user"
        render={() => (
          <Select
            fieldTemplateProps={{
              label: translation.user,
              labelId: 'access-events-user-select-label',
              customRequired: true
            }}
            autocompleteProps={{
              value: visitorSelectValue,
              inputValue: visitorInputValue,
              onInputChange: (_, value) => {
                handleVisitorInputChange(value);
              },
              options: visitorSelectOptions,
              onChange: (_, value) => {
                handleVisitorSelectChange(value as OptionProps<null | string>);
              },
              id: 'access-events-user-select-autocomplete',
              isOptionEqualToValue(option, value) {
                return (
                  ((option as OptionProps<string | null>).value === (value as OptionProps<string | null>).value &&
                    (option as OptionProps<string | null>).label === (value as OptionProps<string | null>).label) ||
                  value === null
                );
              },
              renderOption: (props, option) => (
                <li {...props} key={(option as OptionProps<string | null>).value}>
                  {(option as OptionProps<string | null>).label}
                </li>
              )
            }}
            textFieldProps={{
              id: 'access-events-user-select-input'
            }}
            {...register('filters.user')}
          />
        )}
      />
      <Controller
        control={control}
        name="filters.lock"
        render={() => (
          <Select
            fieldTemplateProps={{
              label: translation.lock,
              labelId: 'access-events-lock-select-label',
              customRequired: true
            }}
            autocompleteProps={{
              value: lockSelectValue,
              inputValue: lockInputValue,
              onInputChange: (_, value) => {
                handleLockInputChange(value);
              },
              options: lockSelectOptions,
              onChange: (_, value) => {
                handleLockSelectChange(value as OptionProps<null | string>);
              },
              id: 'access-events-lock-select-autocomplete',

              isOptionEqualToValue(option, value) {
                // @ts-ignore
                return (option.value === value.value && option.label === value.label) || value === null;
              },
              onKeyDown: inputOnKeyDown
            }}
            textFieldProps={{
              id: 'access-events-lock-select-input'
            }}
            {...register('filters.lock')}
          />
        )}
      />
      <Controller
        control={control}
        name="filters.action"
        render={() => (
          <Select
            fieldTemplateProps={{
              label: translation.action,
              labelId: 'access-events-action-select-label',
              customRequired: true
            }}
            autocompleteProps={{
              value: actionSelectValue,
              inputValue: actionInputValue,
              onInputChange: (_, value) => {
                handleActionInputChange(value);
              },
              options: actionSelectOptions,
              onChange: (_, value) => {
                handleActionSelectChange(value as OptionProps<null | MobileOpCode>);
              },
              id: 'access-events-action-select-autocomplete',
              isOptionEqualToValue(option, value) {
                // @ts-ignore
                return (option.value === value.value && option.label === value.label) || value === null;
              },
              onKeyDown: inputOnKeyDown
            }}
            textFieldProps={{
              id: 'access-events-action-select-input'
            }}
            {...register('filters.action')}
          />
        )}
      />
      <Controller
        control={control}
        name="filters.reason"
        render={() => (
          <Select
            fieldTemplateProps={{
              label: translation.reason,
              labelId: 'access-events-reason-select-label',
              customRequired: true
            }}
            autocompleteProps={{
              value: reasonSelectValue,
              inputValue: reasonInputValue,
              onInputChange: (_, value) => {
                handleReasonInputChange(value);
              },
              options: reasonSelectOptions,
              onChange: (_, value) => {
                handleReasonSelectChange(value as OptionProps<null | string>);
              },
              id: 'access-events-reason-select-autocomplete',
              isOptionEqualToValue(option, value) {
                // @ts-ignore
                return (option.value === value.value && option.label === value.label) || value === null;
              },
              onKeyDown: inputOnKeyDown
            }}
            textFieldProps={{
              id: 'access-events-reason-select-input'
            }}
            {...register('filters.reason')}
          />
        )}
      />
    </ComponentWrapper>
  );
};

export default AccessEventsFilters;
