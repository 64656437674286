import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';
import Label from 'ui/atoms/Label/Label';
import Loader from 'ui/atoms/Loader/Loader';
import StatusDisconnected from 'ui/atoms/StatusDisconnected/StatusDisconnected';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  qrCodeValue: null | string;
}

const QRCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14rem;
  height: 14rem;
  margin: auto;
`;

const MyProfileStatusDisconnected: React.FC<Props> = ({ qrCodeValue }) => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper flexDirection="column" gap="1.5rem">
      <Label>{translation.status}</Label>
      <StatusDisconnected />
      <Label>{translation.qr_code}</Label>
      {qrCodeValue ? (
        <QRCodeWrapper id="drawer-qr-code">
          <QRCode value={qrCodeValue} />
        </QRCodeWrapper>
      ) : (
        <ComponentWrapper
          alignSelf="center"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="14rem"
          height="14rem"
          id="drawer-qr-code-loading"
        >
          <Loader size={64} />
          <Typography variant="title4" color="dTextHigh">
            {translation.generating_qr}
          </Typography>
        </ComponentWrapper>
      )}
      <ComponentWrapper flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="body3" color="dTextHigh">
          {
            translation.scan_the_qr_code_with_the_atlas_access_application_to_connect_your_mobile_device_to_your_atlas_access_profile
          }
        </Typography>
      </ComponentWrapper>
    </ComponentWrapper>
  );
};
export default MyProfileStatusDisconnected;
