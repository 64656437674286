import { DateTimeSelectItem } from 'components/Drawer/variants/accessEvents/AccessEventsFilters/dataTimeSelectOptions';
import dayjs from 'dayjs';
import { AccessEventsDateRange, AccessEventsFilter } from 'graphql/generated/globalTypes';
import { accessEventFiltersVar } from 'state/vars';

export const addAccessEventsFiltersAction = (newFilter: AccessEventsFilter) => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({ ...currentState, filters: { ...currentState.filters, ...newFilter } });
};

const getDateTimeValue = ({ value, unit }: DateTimeSelectItem): AccessEventsDateRange => ({
  rangeStart: dayjs().subtract(value, unit).utc().format(),
  rangeEnd: dayjs().utc().format()
});

export const addDataTimeAccessEventFilterAction = (dataTimeSelectedOption: DateTimeSelectItem) => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    filters: { ...currentState.filters, dataTimeSelectedOption, dateRange: undefined }
  });
};

export const addDataTimeCustomAccessEventFilterAction = (dateRange: AccessEventsDateRange) => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    filters: { ...currentState.filters, dateRange, dataTimeSelectedOption: undefined }
  });
};

export const removeAccessEventsDateTimeFilterAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    filters: { ...currentState.filters, dateRange: null, dataTimeSelectedOption: undefined }
  });
};

export const removeAccessEventsVisitorFilterAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    filters: { ...currentState.filters, visitorIds: null }
  });
};

export const removeAccessEventsLockFilterAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    filters: { ...currentState.filters, doorIds: null }
  });
};

export const removeAccessEventsActionFilterAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    filters: { ...currentState.filters, actions: null }
  });
};

export const removeAccessEventsReasonFilterAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    filters: { ...currentState.filters, reason: null }
  });
};

export const removeAccessEventsDateTimeAppliedFilterAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    appliedFilters: { ...currentState.appliedFilters, dateRange: null, dataTimeSelectedOption: undefined },
    filters: { ...currentState.filters, dateRange: null, dataTimeSelectedOption: undefined }
  });
};

export const removeAccessEventsVisitorAppliedFilterAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    appliedFilters: { ...currentState.appliedFilters, visitorIds: null },
    filters: { ...currentState.filters, visitorIds: null }
  });
};

export const removeAccessEventsLockAppliedFilterAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    appliedFilters: { ...currentState.appliedFilters, doorIds: null },
    filters: { ...currentState.filters, doorIds: null }
  });
};

export const removeAccessEventsActionAppliedFilterAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    appliedFilters: { ...currentState.appliedFilters, actions: null },
    filters: { ...currentState.filters, actions: null }
  });
};

export const removeAccessEventsReasonAppliedFilterAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    appliedFilters: { ...currentState.appliedFilters, reason: null },
    filters: { ...currentState.filters, reason: null }
  });
};

const getDateTimeFilter = (
  dataTimeSelectedOption: DateTimeSelectItem | undefined,
  dateRange: AccessEventsDateRange | null | undefined
) => {
  if (dateRange && dateRange.rangeStart && dateRange.rangeEnd) return dateRange;
  if (dataTimeSelectedOption) return getDateTimeValue(dataTimeSelectedOption);
  return undefined;
};

export const applyEventsFiltersAction = () => {
  const currentState = accessEventFiltersVar();
  accessEventFiltersVar({
    ...currentState,
    appliedFilters: {
      ...currentState.appliedFilters,
      dateRange: getDateTimeFilter(currentState.filters.dataTimeSelectedOption, currentState.filters.dateRange),
      doorIds: currentState.filters.doorIds,
      visitorIds: currentState.filters.visitorIds,
      dataTimeSelectedOption: currentState.filters.dataTimeSelectedOption,
      actions: currentState.filters.actions,
      reason: currentState.filters.reason
    }
  });
};
