/* eslint-disable import/prefer-default-export */

import { UnsetInstallerById, UnsetInstallerById_unsetInstallerById } from 'graphql/generated/UnsetInstallerById';
import { createMockedVisitor } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/visitor';

export function mockedUnsetInstallerByIdData(
  unsetInstallerById: UnsetInstallerById_unsetInstallerById
): UnsetInstallerById {
  return {
    unsetInstallerById
  };
}

export const mockedUnsetInstallerDataDataForMutation = mockedUnsetInstallerByIdData(
  createMockedVisitor({
    id: 'mockedVisitorIdInstaller1',
    name: 'mockedVisitorNameInstaller1',
    email: 'mockedVisitorEmailInstaller1'
  })
);
