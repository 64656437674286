import React from 'react';
import { ApolloProvider, useReactiveVar } from '@apollo/client';
import { MockedProvider } from '@apollo/client/testing';
import getApolloClient, { cache } from 'environment/ApolloClientVMS';
import useToken from 'hooks/useToken/useToken';
import adminMocks from 'mocks/admin/mocks';
import nonAdminMocks from 'mocks/nonAdmin/mocks';
import mocks from 'mocks/unitTests/mocks';
import { tenantsServiceTokenVar } from 'state/vars';

const isMockedMode = process.env.REACT_APP_MODE === 'mocked';
const isMockedNonAdminMode = process.env.REACT_APP_MODE === 'mockedNonAdmin';
const isMockedAdminMode = process.env.REACT_APP_MODE === 'mockedAdmin';

export const getMocks = () => {
  if (isMockedMode) return mocks;
  if (isMockedNonAdminMode) return nonAdminMocks;
  if (isMockedAdminMode) return adminMocks;
  return undefined;
};

const Apollo: React.FC = ({ children }) => {
  const { getToken } = useToken();
  const linkToken = useReactiveVar(tenantsServiceTokenVar);

  if (isMockedNonAdminMode || isMockedAdminMode || isMockedMode) {
    return (
      <MockedProvider mocks={getMocks()} cache={cache}>
        {children}
      </MockedProvider>
    );
  }

  if (linkToken && linkToken !== '') {
    const apolloClient = getApolloClient(linkToken);
    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
  }
  const apolloClient = getApolloClient(getToken);
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default Apollo;
