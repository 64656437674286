import React from 'react';

const Lock: React.FC = () => (
  <>
    <path d="M25.1935 13.5111H18.7415V4.71739C18.7415 2.11622 16.7808 0 14.3707 0C11.9607 0 10 2.11622 10 4.71739L10 27.2826C10 29.8838 11.9607 32 14.3707 32C16.7808 32 18.7415 29.8838 18.7415 27.2826V17.5546H25.1935C26.2264 17.5546 27.0667 16.6476 27.0667 15.5329C27.0667 14.4181 26.2264 13.5111 25.1935 13.5111ZM17.4927 27.2826C17.4927 29.1406 16.0922 30.6522 14.3707 30.6522C12.6493 30.6522 11.2488 29.1406 11.2488 27.2826V10.6667V4.71739C11.2488 2.85941 12.6493 1.34783 14.3707 1.34783C16.0922 1.34783 17.4927 2.85941 17.4927 4.71739V10.6667H11.2488V11.3778V12.0889H17.4927V13.5111H14.3707C13.3379 13.5111 12.4976 14.4181 12.4976 15.5329C12.4976 16.6476 13.3379 17.5546 14.3707 17.5546H17.4927V27.2826ZM25.1935 16.2068H14.3707C14.0264 16.2068 13.7463 15.9044 13.7463 15.5329C13.7463 15.1613 14.0264 14.8589 14.3707 14.8589H25.1935C25.5378 14.8589 25.8179 15.1613 25.8179 15.5329C25.8179 15.9044 25.5378 16.2068 25.1935 16.2068Z" />
    <path d="M13.7334 5.86683C13.7334 6.16138 13.9722 6.40016 14.2667 6.40016C14.5613 6.40016 14.8 6.16138 14.8 5.86683C14.8 5.57228 14.5613 5.3335 14.2667 5.3335C13.9722 5.3335 13.7334 5.57228 13.7334 5.86683Z" />
    <path d="M13.7334 4.26673C13.7334 4.56128 13.9722 4.80007 14.2667 4.80007C14.5613 4.80007 14.8 4.56128 14.8 4.26673C14.8 3.97218 14.5613 3.7334 14.2667 3.7334C13.9722 3.7334 13.7334 3.97218 13.7334 4.26673Z" />
    <path d="M13.7334 2.66663C13.7334 2.96119 13.9722 3.19997 14.2667 3.19997C14.5613 3.19997 14.8 2.96119 14.8 2.66663C14.8 2.37208 14.5613 2.1333 14.2667 2.1333C13.9722 2.1333 13.7334 2.37208 13.7334 2.66663Z" />
    <path d="M13.7334 7.46644C13.7334 7.76099 13.9722 7.99977 14.2667 7.99977C14.5613 7.99977 14.8 7.76099 14.8 7.46644C14.8 7.17189 14.5613 6.93311 14.2667 6.93311C13.9722 6.93311 13.7334 7.17189 13.7334 7.46644Z" />
  </>
);

export default Lock;
