import React from 'react';
import styled from 'styled-components';
import { Colors } from 'theme/theme';
import Briefcase from './variants/Briefcase';
import Close from './variants/Close';
import Filter from './variants/Filter';
import MapPin from './variants/MapPin';
import MapPinTime from './variants/MapPinTime';
import History from './variants/History';
import Sad from './variants/Sad';
import Search from './variants/Search';
import Settings from './variants/Settings';
import Smile from './variants/Smile';
import Sort from './variants/Sort';
import Trash from './variants/Trash';
import User from './variants/User';
import UserCheck from './variants/UserCheck';
import UserPlus from './variants/UserPlus';
import DeleteBin from './variants/DeleteBin';
import Edit from './variants/Edit';
import Legend from './variants/Legend';
import ArrowLeft from './variants/ArrowLeft';
import ArrowRight from './variants/ArrowRight';
import Hamburger from './variants/Hamburger';
import Tools from './variants/Tools';
import Profile from './variants/Profile';
import LockList from './variants/LockList';
import Home from './variants/Home';
import ArrowRightTable from './variants/ArrowRightTable';
import Booklet from './variants/Booklet';
import Info from './variants/Info';
import CalendarCheckFill from './variants/CalendarCheckFill';
import Clock from './variants/Clock';
import CalendarCheck from './variants/CalendarCheck';
import BatteryDefault from './variants/BatteryDefault';
import BatteryLow from './variants/BatteryLow';
import BatteryCritical from './variants/BatteryCritical';
import BatteryEmpty from './variants/BatteryEmpty';
import BatteryPowered from './variants/BatteryPowered';
import BatteryUnknown from './variants/BatteryUnknown';
import SwitchArrow from './variants/SwitchArrow';
import Reader from './variants/Reader';
import Handle from './variants/Handle';
import Cylinder from './variants/Cylinder';
import StatusConnected from './variants/StatusConnected';
import StatusDisconnected from './variants/StatusDisconnected';
import Alert from './variants/Alert';
import ArrowDown from './variants/ArrowDown';
import ArrowUp from './variants/ArrowUp';
import Checkmark from './variants/Checkmark';
import ScheduledAccess from './variants/ScheduledAccess';
import UserCoContractors from './variants/UserCoContractors';
import Calendar from './variants/Calendar';
import CoContractor from './variants/CoContractor';
import EditPen from './variants/EditPen';
import ADSyncedUser from './variants/ADSyncedUser';
import Visitor from './variants/Visitor';
import OpenModal from './variants/OpenModal';
import SendEmail from './variants/SendEmail';
import Permissions from './variants/Permissions';
import Hardware from './variants/Hardware';
import UserMenu from './variants/UserMenu';
import Access from './variants/Access';
import Folder from './variants/Folder';
import MicrosoftOutlook from './variants/MicrosoftOutlook';
import TodayOutlined from './variants/TodayOutlined';
import Export from './variants/Export';
import Sorting from './variants/Sorting';
import Star from './variants/Star';
import MobileDeviceConnected from './variants/MobileDeviceConnected';
import MobileDeviceDisconnected from './variants/MobileDeviceDisconnected';
import LogOut from './variants/LogOut';
import EntraId from './variants/EntraId';

export type Icons =
  | 'Briefcase'
  | 'Close'
  | 'Edit'
  | 'DeleteBin'
  | 'Filter'
  | 'Hamburger'
  | 'MapPin'
  | 'MapPinTime'
  | 'History'
  | 'Sad'
  | 'Search'
  | 'Settings'
  | 'Smile'
  | 'Sort'
  | 'Tools'
  | 'Trash'
  | 'User'
  | 'UserCheck'
  | 'UserPlus'
  | 'Legend'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'Profile'
  | 'LockList'
  | 'Home'
  | 'ArrowRightTable'
  | 'Booklet'
  | 'Info'
  | 'CalendarCheckFill'
  | 'Clock'
  | 'CalendarCheck'
  | 'BatteryDefault'
  | 'BatteryLow'
  | 'BatteryCritical'
  | 'BatteryEmpty'
  | 'BatteryPowered'
  | 'BatteryUnknown'
  | 'SwitchArrow'
  | 'Reader'
  | 'Handle'
  | 'Cylinder'
  | 'StatusConnected'
  | 'StatusDisconnected'
  | 'Alert'
  | 'ArrowDown'
  | 'ArrowUp'
  | 'Checkmark'
  | 'UserCoContractors'
  | 'ScheduledAccess'
  | 'Calendar'
  | 'CoContractor'
  | 'EditPen'
  | 'ADSyncedUser'
  | 'Visitor'
  | 'OpenModal'
  | 'Permissions'
  | 'SendEmail'
  | 'UserMenu'
  | 'Access'
  | 'Hardware'
  | 'Folder'
  | 'MicrosoftOutlook'
  | 'TodayOutlined'
  | 'Export'
  | 'Sorting'
  | 'Star'
  | 'MobileDeviceConnected'
  | 'MobileDeviceDisconnected'
  | 'EntraId'
  | 'LogOut';

interface CssProps {
  color?: keyof Colors;
  stroke?: keyof Colors;
}

type Size = string | number;

export type Props = React.SVGProps<SVGSVGElement> &
  CssProps & {
    name: Icons;
    height?: Size;
    width?: Size;
    viewBox?: string;
    id?: string;
  };

const StyledSVG = styled.svg<Required<CssProps>>`
  fill: ${(props) => props.theme.colors[props.color]};
  stroke: ${(props) => props.theme.colors[props.stroke]};
`;

const Icon: React.FC<Props> = ({
  color = 'lTextMedium',
  height = '24',
  width = '24',
  viewBox = `0 0 ${width} ${height}`,
  stroke = 'transparent',
  ref,
  name,
  ...props
}) => {
  const getIcon = () => {
    switch (name) {
      case 'Briefcase':
        return <Briefcase />;
      case 'Close':
        return <Close />;
      case 'Edit':
        return <Edit />;
      case 'DeleteBin':
        return <DeleteBin />;
      case 'Filter':
        return <Filter />;
      case 'Hamburger':
        return <Hamburger />;
      case 'MapPin':
        return <MapPin />;
      case 'MapPinTime':
        return <MapPinTime />;
      case 'History':
        return <History />;
      case 'Sad':
        return <Sad />;
      case 'Search':
        return <Search />;
      case 'Settings':
        return <Settings />;
      case 'Smile':
        return <Smile />;
      case 'Sort':
        return <Sort />;
      case 'Trash':
        return <Trash />;
      case 'Tools':
        return <Tools />;
      case 'User':
        return <User />;
      case 'UserCheck':
        return <UserCheck />;
      case 'UserPlus':
        return <UserPlus />;
      case 'Legend':
        return <Legend />;
      case 'ArrowLeft':
        return <ArrowLeft />;
      case 'ArrowRight':
        return <ArrowRight />;
      case 'Profile':
        return <Profile />;
      case 'LockList':
        return <LockList />;
      case 'Home':
        return <Home />;
      case 'ArrowRightTable':
        return <ArrowRightTable />;
      case 'Booklet':
        return <Booklet />;
      case 'Info':
        return <Info />;
      case 'CalendarCheckFill':
        return <CalendarCheckFill />;
      case 'Clock':
        return <Clock />;
      case 'CalendarCheck':
        return <CalendarCheck />;
      case 'BatteryDefault':
        return <BatteryDefault />;
      case 'BatteryLow':
        return <BatteryLow />;
      case 'BatteryCritical':
        return <BatteryCritical />;
      case 'BatteryEmpty':
        return <BatteryEmpty />;
      case 'BatteryPowered':
        return <BatteryPowered />;
      case 'BatteryUnknown':
        return <BatteryUnknown />;
      case 'SwitchArrow':
        return <SwitchArrow />;
      case 'Reader':
        return <Reader />;
      case 'Handle':
        return <Handle />;
      case 'Cylinder':
        return <Cylinder />;
      case 'StatusConnected':
        return <StatusConnected />;
      case 'StatusDisconnected':
        return <StatusDisconnected />;
      case 'Alert':
        return <Alert />;
      case 'ArrowDown':
        return <ArrowDown />;
      case 'ArrowUp':
        return <ArrowUp />;
      case 'Checkmark':
        return <Checkmark />;
      case 'ScheduledAccess':
        return <ScheduledAccess />;
      case 'UserCoContractors':
        return <UserCoContractors />;
      case 'Calendar':
        return <Calendar />;
      case 'CoContractor':
        return <CoContractor />;
      case 'EditPen':
        return <EditPen />;
      case 'ADSyncedUser':
        return <ADSyncedUser />;
      case 'Visitor':
        return <Visitor />;
      case 'OpenModal':
        return <OpenModal />;
      case 'SendEmail':
        return <SendEmail />;
      case 'Permissions':
        return <Permissions />;
      case 'UserMenu':
        return <UserMenu />;
      case 'Access':
        return <Access />;
      case 'Hardware':
        return <Hardware />;
      case 'Folder':
        return <Folder />;
      case 'MicrosoftOutlook':
        return <MicrosoftOutlook />;
      case 'TodayOutlined':
        return <TodayOutlined />;
      case 'Export':
        return <Export />;
      case 'Sorting':
        return <Sorting />;
      case 'Star':
        return <Star />;
      case 'MobileDeviceConnected':
        return <MobileDeviceConnected />;
      case 'MobileDeviceDisconnected':
        return <MobileDeviceDisconnected />;
      case 'LogOut':
        return <LogOut />;
      case 'EntraId':
        return <EntraId />;
      default:
        throw new Error('Icon name not specified');
    }
  };

  return (
    <StyledSVG
      {...props}
      viewBox={viewBox}
      height={height}
      width={width}
      stroke={stroke}
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      {getIcon()}
    </StyledSVG>
  );
};

export default Icon;
