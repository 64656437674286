import { GetDoorsForTenantWithOfficeMode_getDoorsForTenant as Door } from 'graphql/generated/GetDoorsForTenantWithOfficeMode';
import { doorsVar } from 'state/vars';

export const setDoorsAction = (doors: Door[]) => {
  doorsVar(doors);
};

export const updateDoorsAction = (updatedDoor: Door) => {
  const currentDoors = doorsVar();
  const updatedDoors = currentDoors.map((door) => {
    if (door.id === updatedDoor.id) {
      return updatedDoor;
    }
    return door;
  });

  doorsVar(updatedDoors);
};

export const deleteDoorsAction = (deletedDoorId: string) => {
  const currentDoors = doorsVar();
  const updatedDoors = currentDoors.filter((door) => door.id !== deletedDoorId);

  doorsVar(updatedDoors);
};
