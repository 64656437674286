/* eslint-disable import/prefer-default-export */

import {
  onRegistrationTokenSavedById as onRegistrationTokenSavedType,
  onRegistrationTokenSavedById_onRegistrationTokenSavedById
} from 'graphql/generated/onRegistrationTokenSavedById';
import { createMockedSubscriptionRegistrationTokenSaved } from 'mocks/graphqlApollo/objectsBaseOnSchemaType/subscriptionRegistrationTokenSaved';

export function mockedOnRegistrationTokenSavedData(
  onRegistrationTokenSavedById: onRegistrationTokenSavedById_onRegistrationTokenSavedById | null
): onRegistrationTokenSavedType {
  return {
    onRegistrationTokenSavedById
  };
}

export const mockedOnRegistrationTokenSavedDataForSubscription = mockedOnRegistrationTokenSavedData(
  createMockedSubscriptionRegistrationTokenSaved({})
);
