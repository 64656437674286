/* eslint-disable import/prefer-default-export */
import { GetAccessEventsForTenant_getAccessEventsForTenant_items_door } from 'graphql/generated/GetAccessEventsForTenant';

interface Props {
  id: string;
  name?: string;
}

export function createMockedAccessEventDoor({
  id,
  name = 'mockedDoorName'
}: Props): GetAccessEventsForTenant_getAccessEventsForTenant_items_door {
  return {
    __typename: 'AccessEventDoor',
    id,
    name
  };
}
