import { GET_LOCATIONS_FOR_TENANT } from 'graphql/queries';
import { useEffect } from 'react';
import { GetLocationsForTenant } from 'graphql/generated/GetLocationsForTenant';
import { setLocationsAction } from 'state/actions/locations';
import { locationsVar } from 'state/vars';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { LocationType } from 'graphql/generated/globalTypes';
import { BaseHookProps } from './shared/types';

const useLocations = ({ handleFetchError }: BaseHookProps) => {
  const locations = useReactiveVar(locationsVar);

  const [getLocationsForTenant, { loading }] = useLazyQuery<GetLocationsForTenant>(GET_LOCATIONS_FOR_TENANT, {
    onCompleted: (data) => {
      setLocationsAction(data.getLocationsForTenant.filter((location) => location.type === LocationType.EXTERNAL));
    },
    onError: () => {
      handleFetchError('Error while fetching locations');
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    getLocationsForTenant();
  }, [getLocationsForTenant]);

  return { locations, loading };
};

export default useLocations;
