/* eslint-disable no-magic-numbers */
import React from 'react';
import styled from 'styled-components';
import Avatar, { Props as AvatarProps } from 'ui/atoms/Avatar/Avatar';
import Tooltip from 'ui/atoms/Tooltip/Tooltip';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  avatars: AvatarProps[];
  id: string;
  tooltipPosition?: 'top' | 'bottom';
}

const AvatarWrapper = styled.div`
  margin-left: -0.5rem;
  z-index: 2;
  &:hover {
    z-index: 3;
  }
`;

const AvatarGroupWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const MAX_AVATARS_DISPLAYED = 4;

const AvatarGroup: React.FC<Props> = ({ avatars, tooltipPosition = 'bottom', id }) => (
  <ComponentWrapper flexDirection="row" id={id} alignItems="center">
    <AvatarGroupWrapper>
      {avatars.length > MAX_AVATARS_DISPLAYED && (
        <Tooltip
          top={tooltipPosition === 'bottom' ? '80%' : '-80%'}
          elementOnHover={
            <ComponentWrapper margin="0.5rem" flexDirection="column">
              {avatars.map(
                (avatar, index) =>
                  index >= MAX_AVATARS_DISPLAYED && (
                    <Typography variant="body4" color="lTextHigh" key={avatar.id}>
                      {avatar.text}
                    </Typography>
                  )
              )}
            </ComponentWrapper>
          }
        >
          <AvatarWrapper>
            <Avatar
              text={`+${avatars.length - MAX_AVATARS_DISPLAYED}`}
              variant="small"
              backgroundColor="b1"
              textColor="lTextHigh"
            />
          </AvatarWrapper>
        </Tooltip>
      )}
      {avatars.map(
        (avatar, index) =>
          index < MAX_AVATARS_DISPLAYED && (
            <AvatarWrapper key={`Avatar-Wrapper-${avatar.id}`}>
              <Tooltip
                top={tooltipPosition === 'bottom' ? '80%' : '-80%'}
                elementOnHover={
                  <ComponentWrapper margin="0.5rem">
                    <Typography variant="body4" color="lTextHigh">
                      {avatar.text}
                    </Typography>
                  </ComponentWrapper>
                }
              >
                <Avatar {...avatar} key={avatar.id} />
              </Tooltip>
            </AvatarWrapper>
          )
      )}
    </AvatarGroupWrapper>
  </ComponentWrapper>
);

export default AvatarGroup;
